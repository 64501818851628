import {
  InterpreterWorker,
  ModelSchemaCategories,
  PasteMapperOptions,
  PasteMapperResult,
  QualifiedRef,
  ReferenceData,
  SimplePasteMapperOptions,
  SimplePasteMapperResult,
} from 'types';
import type { Interpreter } from 'interpreter/interpreter';
import { isExpressionString, isNone, isSome } from 'helpers/utils';
import { isGridNameRowValue, coordsToLocalRef } from 'interpreter/helpers';
import { mathjs, parse } from 'interpreter/t3math';
import { serialize } from 'interpreter/t3math-extra';
import { globalGetState, intToAlphaColumn } from 'utils/utils-extra';
import { cellRx, colRx, rowRx } from './helpers';
import { t3dev } from 't3dev';

/**
 * process paste data re-mapping
 */
export function pasteMapper<
  R extends PasteMapperResult[],
  O extends PasteMapperOptions[]
>(itpr: Interpreter<R, O>): Interpreter<R, O> {
  if (isNone(itpr.opts)) return itpr;
  itpr.results = [] as any;

  itpr.opts.extraOpts.forEach((mapping) => {
    const { value, rawSource, rawDestination, destination, entity } = mapping;
    if (isNone(rawSource) || isNone(destination)) return;
    let serialized = value;

    const [dRow, dColumn] = destination;
    const [sRow, sColumn] = rawSource;
    const [rdRow, rdColumn] = rawDestination;

    // only map expressions
    // ignore name row, but don't ignore name-column
    if (sRow !== 0 && value?.startsWith('=')) {
      let parsed = parse(value.slice(1));

      parsed = parsed.transform((node, _path, _parent) => {
        if (!mathjs.isSymbolNode(node)) return node;
        const exp = itpr.grab(node.name, entity);
        if (isNone(exp)) return node;

        const refData = itpr.refData(node.name, entity);

        if (isNone(refData)) return node;

        // global references are treated as absolute!
        // we do not update them when pasting to a new location
        if (refData.global) return node;

        // adjust relative references based on the offset of the pasted area
        const oRow = exp.row - sRow + (rdRow - dRow);
        const oColumn = exp.col - sColumn + (rdColumn - dColumn);
        const mRow = dRow + oRow; //, 0, Number.MAX_SAFE_INTEGER);
        const mColumn = dColumn + oColumn; //, 0, Number.MAX_SAFE_INTEGER);
        let movedRef: string = coordsToLocalRef(mRow, mColumn);

        // respect the user's capitalization as a friendly gesture c:
        if (refData.uppercase) movedRef = movedRef.toUpperCase();

        return new mathjs.SymbolNode(movedRef);
      });

      serialized = `=${serialize(parsed)}`;
    }
    if (isNone(itpr.results)) return;
    itpr.results.push({
      row: dRow,
      column: dColumn,
      value: serialized,
      entity,
    });
  });

  return itpr;
}

export const simplePasteMapper: InterpreterWorker<
  SimplePasteMapperResult[],
  SimplePasteMapperOptions
> = (itpr) => {
  if (isNone(itpr.opts)) return itpr;
  itpr.results = [];

  const expressionMap = itpr.opts.extraOpts.expressionMap;
  const fullMap = itpr.opts.extraOpts.fullMap;

  itpr.opts.extraOpts.data.forEach(
    ({ value, source, destination, category }) => {
      try {
        // adjust relative references based on the offset of the pasted area
        let serialized = value;

        if (
          destination.parentId &&
          !isGridNameRowValue(destination.parentId) &&
          isSome(value) &&
          isExpressionString(value)
        ) {
          let padding = value.match(/=(?<pad>\s*)[^\s]*/)?.groups?.pad || ' ';
          let parsed = parse(value.slice(1));

          parsed = parsed.transform((node, _a, _b) => {
            if (!mathjs.isSymbolNode(node)) {
              return node;
            } else if (
              !node.name.match(cellRx) &&
              !node.name.match(rowRx) &&
              !node.name.match(colRx)
            ) {
              // we only need to do translation-replacement shennanigans if the
              // symbol is a reference to another part of the grid.  since this
              // doesn't reference another part of the grid just return as-is.
              return node;
            } else {
              const entry =
                expressionMap[source.parentId][source.schemaId][
                  node.name as QualifiedRef
                ];

              // source cell is the cell containing the ref
              const src = {
                // map copied IDs to their pasted IDs
                rowId: fullMap[entry[0]],
                colId: fullMap[entry[1]],
                ent: entry[2],
                ref: entry[3],

                // original data for the pre-pasted cell reference
                data: itpr.refData(
                  entry[3],
                  entry[2] as ModelSchemaCategories
                ) as ReferenceData,

                // get coords of the cell *after* past
                coords: [
                  itpr.mappedTableSort.entityMap[entry[2]][fullMap[entry[0]]],
                  itpr.mappedTableSort.schemaMap[entry[2]][fullMap[entry[1]]],
                ],
              };

              // target cell is the cell being pointed at
              const tar = {
                // map copied IDs to their pasted IDs
                rowId: fullMap[entry[5]],
                colId: fullMap[entry[6]],
                ent: entry[4],
                ref: entry[7],

                // original data for the pre-paseted cell reference
                data: itpr.refData(
                  entry[7],
                  entry[4] as ModelSchemaCategories
                ) as ReferenceData,

                // get coords of the cell *after* paste
                coords: [
                  itpr.mappedTableSort.entityMap[entry[4]][fullMap[entry[5]]],
                  itpr.mappedTableSort.schemaMap[entry[4]][fullMap[entry[6]]],
                ],
              };

              let prefix = tar.data.global ? `${tar.ent[0]}_` : '';

              // if not a lane, we start by trying to map row and column to the target
              let row: '_' | number = isSome(tar.data.coords[0])
                ? tar.coords[0]
                : '_';
              let col: '_' | number = isSome(tar.data.coords[1])
                ? tar.coords[1]
                : '_';

              // target was not copied over, time for plan B
              if (isNone(row)) {
                if (tar.data.global) {
                  // if this ref is global, we leave it as is
                  row = tar.data.coords[0] as number;
                } else {
                  // if local, we adjust based on the diff
                  // and the new location of the pasted cell
                  const diff =
                    (tar.data.coords[0] as number) -
                    (src.data.coords[0] as number);
                  row = src.coords[0] + diff;
                }
              }

              // do the same for the column as we did for the row
              if (isNone(col)) {
                if (tar.data.global) {
                  col = tar.data.coords[1] as number;
                } else {
                  const diff =
                    (tar.data.coords[1] as number) -
                    (src.data.coords[1] as number);
                  col = src.coords[1] + diff;
                }
              }

              // now just format the new cell reference nicely and return it
              let colLetter = col === '_' ? col : intToAlphaColumn(col);
              let newRef = `${prefix}${colLetter}${row}`;
              newRef = tar.data.uppercase
                ? newRef.toUpperCase()
                : newRef.toLowerCase();
              return new mathjs.SymbolNode(newRef);
            }
          });

          // reinsert the = and padding from before we parsed the equation
          serialized = `=${padding}${serialize(parsed)}`;
        }

        if (isNone(itpr.results)) return itpr;

        itpr.results.push({
          destination,
          category: category,
          value: serialized,
        });
      } catch (e: any) {
        t3dev().log.error(
          'simple paste mapper error:',
          Object.clone({
            source,
            destination,
            value,
            entity: category,
            state: itpr.state,
            destState: globalGetState(),
          }),
          e
        );
      }
    }
  );
  return itpr;
};
