import { reducerFromHandlers, TABLE } from 'modules/common';
import {
  EDGE,
  NODE,
  SET,
  PROPERTY_SCHEMA,
  BACKGROUND_IMAGE,
  PIXI_ACTION,
  PROPERTY_VALUE,
  MODEL_PROP,
  MODEL_CALC,
  DRAWING,
  TEXT,
} from 'utils/constants';
import {
  handleBackgroundImageDelete,
  handleDrawingDelete,
  handleEdgeDelete,
  handleModelCalcDelete,
  handleModelPropDelete,
  handleNodeDelete,
  handlePathDelete,
  handlePixiDelete,
  handlePropertyUpdate,
  handleSchemaDelete,
  handleSetDelete,
  handleTextShapesDelete,
} from './helpers';
import { handleApplySort, reconstructSort } from '../table/sort';
import { PATH } from 'utils/constants-extra';

/**
 * manages redux actions that cross multiple state boundaries
 */
export const highOrderReducer = reducerFromHandlers({
  [EDGE.DELETE]: handleEdgeDelete,
  [MODEL_PROP.DELETE]: handleModelPropDelete,
  [MODEL_CALC.DELETE]: handleModelCalcDelete,
  [NODE.DELETE]: handleNodeDelete,
  [DRAWING.DELETE]: handleDrawingDelete,
  [PATH.DELETE]: handlePathDelete,
  [SET.DELETE]: handleSetDelete,
  [PROPERTY_SCHEMA.DELETE]: handleSchemaDelete,
  [BACKGROUND_IMAGE.DELETE]: handleBackgroundImageDelete,
  [PIXI_ACTION.DELETE]: handlePixiDelete,
  [TABLE.SORT.APPLY]: handleApplySort,
  [TABLE.SORT.RESET]: reconstructSort,
  [TEXT.DELETE]: handleTextShapesDelete,
  [PROPERTY_VALUE.UPDATE]: handlePropertyUpdate,
});
