import {
  ActionFunc,
  ApiMeta,
  MetraActionFunc,
  MetraApiAction,
  NormalizedResult,
  NucleoQuery,
  SortDef,
  ThunkAction,
  ThunkActionFunc,
} from 'types';
import {
  apiGet,
  apiPatch,
  apiDelete,
  ENTITIES,
  PROJECTS,
  ARCHIVE,
  isApiError,
  isApiErrorAction,
  isApiResponseAction,
} from 'modules/common';
import { MESSAGES } from 'modules/ui/messages';
import { buildOrgUrl } from 'utils/url-builders';
import { setDisplaySearchResults } from 'modules/search/actions';
import { resetSelected } from 'modules/archive/archive-table-helpers';

/*
 *
 * SCHEMAS
 *
 */
export const SCHEMA_NAME = 'projects';

/**
 * sets project loaded state
 */
export const setProjectLoaded: MetraActionFunc<[boolean], boolean> = (
  value
) => ({
  type: PROJECTS.LOADED,
  payload: value,
});

/**
 * getProjects retrieves projects from nucleo
 * @param withDeletes - get deleted projects
 * @param [query]- additional query params
 */
export const getProjects: ThunkActionFunc<
  [withDeletes: boolean, query?: NucleoQuery],
  Promise<MetraApiAction<NormalizedResult<'projects'>>>
> = (withDeletes = false, query = {}) => {
  let params = query;
  const signal = query.signal as AbortSignal;
  //
  // DONT contaminate url params with non-url data.
  //
  delete params.signal;

  const meta = {} as ApiMeta;
  if (withDeletes) {
    params = {
      ...params,
      show_archived: true, // eslint-disable-line camelcase
    };
    meta.schema = 'projects';
  }

  return async (dispatch) => {
    dispatch({
      type: PROJECTS.LOADED,
      payload: false,
    });
    const result = await dispatch(
      apiGet<NormalizedResult<'projects'>>({
        entity: SCHEMA_NAME,
        collection: withDeletes ? 'deletedProjects' : 'projects',
        types: [ENTITIES.ACTION_SUCCESS, PROJECTS.GET_FAILURE],
        error: MESSAGES.ERROR.GET.PROJECT,
        params,
        meta,
        signal,
      })
    );

    // NOTE: we do this here instead of api core, because this is the ONLY
    // api call that cares about this, and api core doesnt need to care
    // about it
    if (
      isApiErrorAction(result) &&
      isApiError(result) &&
      [404, 403].includes(result.payload.status)
    ) {
      window.location.replace(`${window.location.origin + buildOrgUrl()}/404`);
      return result;
    }

    if (isApiResponseAction(result) && !withDeletes) {
      dispatch({
        type: PROJECTS.LOADED,
        payload: true,
      });
    }

    return result;
  };
};

/**
 * search for projects
 */
export const searchProjects: ThunkActionFunc<
  [query: NucleoQuery],
  Promise<MetraApiAction<NormalizedResult<'projects'>>>
> = (query = {}) => {
  let params = query;
  return async (dispatch) => {
    dispatch(setDisplaySearchResults(true));
    return await dispatch(
      apiGet<NormalizedResult<'projects'>>({
        entity: SCHEMA_NAME,
        collection: 'projectSearchResults',
        types: [ENTITIES.ACTION_SUCCESS, PROJECTS.GET_FAILURE],
        error: MESSAGES.ERROR.GET.PROJECT,
        params,
      })
    );
  };
};

export const getArchivedProjects = (): ThunkAction<
  Promise<MetraApiAction<NormalizedResult<'projects'>>>
> => {
  return apiGet<NormalizedResult<'projects'>>({
    entity: SCHEMA_NAME,
    params: { show_archived: true }, // eslint-disable-line camelcase
    types: [ARCHIVE.GET_SUCCESS, ARCHIVE.GET_FAILURE],
    error: MESSAGES.ERROR.GET.ARCHIVED_PROJECTS,
  });
};

/**
 * @param id - project id
 */
export const selectProject: MetraActionFunc<[id: Numberish], Numberish> = (
  id
) => ({
  type: PROJECTS.SELECTED,
  payload: id,
});

/**
 * archives the project with the given id
 * @param id
 * @param [query]
 * @param [guildCName]
 */
export const archiveProject: ThunkActionFunc<
  [id: Numberish, query: NucleoQuery, guildCName: string],
  Promise<MetraApiAction<NormalizedResult<'projects'>>>
> = (id, query = {}, guildCName = '') => {
  return async (dispatch) => {
    await dispatch(
      apiPatch({
        entity: SCHEMA_NAME,
        record: `${id}`,
        body: JSON.stringify({
          // eslint-disable-next-line camelcase
          archived_at: new Date().toISOString(),
        }),
        headers: {
          'Content-Type': 'application/json',
        },
        types: [ENTITIES.ACTION_SUCCESS, PROJECTS.DELETE_FAILURE],
        error: MESSAGES.ERROR.ARCHIVE.PROJECT,
        success: MESSAGES.SUCCESS.ARCHIVE.PROJECT,
        undo: () => {
          dispatch(restoreProject(id, guildCName, false));
        },
        guildCName,
      })
    );
    return dispatch(getProjects(false, query));
  };
};

/**
 * changes ownership of the project with the given projectId to the user
 * identified by newOwnerUuid
 * @param projectId
 * @param newOwnerUuid
 * @param [query]
 * @param [guildCName]
 */
export const transferProjectOwnership: ThunkActionFunc<
  [
    projectId: Numberish,
    newOwnerUuid: Numberish,
    query: NucleoQuery,
    guildCName: string
  ],
  Promise<MetraApiAction<NormalizedResult<'projects'>>>
> = (projectId, newOwnerUuid, query = {}, guildCName = '') => {
  return async (dispatch) => {
    await dispatch(
      apiPatch({
        entity: SCHEMA_NAME,
        record: `${projectId}/transfer-ownership`,
        body: JSON.stringify({
          // eslint-disable-next-line camelcase
          user_id: newOwnerUuid,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
        types: [ENTITIES.ACTION_SUCCESS, PROJECTS.UPDATE_FAILURE],
        error: MESSAGES.ERROR.UPDATE,
        success: MESSAGES.SUCCESS.UPDATE_OWNER,
        guildCName,
      })
    );
    return dispatch(getProjects(false, query));
  };
};

/**
 * deletes the project with the given id
 * @param id
 * @param [query] - query to use
 * @param [guildCName]
 */
export const deleteProject: ThunkActionFunc<
  [id: Numberish, query?: NucleoQuery, guildCName?: string],
  Promise<MetraApiAction<NormalizedResult<'projects'>>>
> = (id, query = {}, guildCName = '') => {
  return async (dispatch) => {
    await dispatch(
      apiDelete({
        entity: SCHEMA_NAME,
        record: `${id}`,
        types: [ENTITIES.ACTION_SUCCESS, PROJECTS.DELETE_FAILURE],
        error: MESSAGES.ERROR.DELETE.PROJECT,
        success: MESSAGES.SUCCESS.DELETE.PROJECT,
        guildCName,
      })
    );
    await dispatch(resetSelected());
    return dispatch(getProjects(false, query));
  };
};

export const restoreProject: ThunkActionFunc<
  [id: Numberish, guildCName: string, showArchived: boolean],
  Promise<MetraApiAction<NormalizedResult<'projects'>>>
> = (id, guildCName = '', showArchived = true) => {
  return async (dispatch) => {
    const result = await dispatch(
      apiPatch<NormalizedResult<'projects'>>({
        entity: SCHEMA_NAME,
        record: `${id}`,
        body: JSON.stringify({
          // eslint-disable-next-line camelcase
          archived_at: null,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
        types: [ENTITIES.ACTION_SUCCESS, PROJECTS.RESTORE_FAILURE],
        error: MESSAGES.ERROR.RESTORE.PROJECT,
        success: MESSAGES.SUCCESS.RESTORE.PROJECT,
        guildCName,
      })
    );
    dispatch(getProjects(showArchived));
    return result;
  };
};

/**
 * @param tags
 */
export const setProjectTags: ActionFunc<Numberish[]> = (tags) => {
  return {
    type: PROJECTS.FILTER_TAGS,
    payload: tags,
  };
};

/**
 * @param  cardView
 */
export const setProjectSortBy: ActionFunc<SortDef> = (sortBy) => {
  return {
    type: PROJECTS.SORT_BY,
    payload: sortBy,
  };
};

/**
 * @param cardView
 */
export const setCardView: ActionFunc<boolean> = (cardView) => ({
  type: PROJECTS.CARD_VIEW,
  payload: cardView,
});

/**
 * @param showModal
 */
export const showLicenseLimitError: ActionFunc<boolean> = (showModal) => ({
  type: PROJECTS.SHOW_LICENSE_ERROR_MODAL,
  payload: showModal,
});
