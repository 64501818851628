import { v4 as uuid } from 'uuid';
import type { SyntheticEvent } from 'react';
import type {
  EventEmitter,
  EventManagerListener,
  EventContext,
  EventArgs,
  EventReturnValue,
  EventString,
  EventUnsubFunc,
  OptionArray,
  ShapeConfig,
} from 'types';
import { t3dev } from 't3dev';

/**
 * a deterministic and efficient Event Manager.
 */
export class EventManager {
  private static __emitters: Record<
    EventString,
    Record<UUID, EventEmitter<any[], any, any>>
  > | null;
  private static initialized = false;

  /**
   * initialize EventManager.
   */
  static init(): void {
    EventManager.__emitters = {};
    EventManager.initialized = true;
  }

  static get isInitialized(): boolean {
    return EventManager.initialized;
  }

  /**
   * destroy the EventManager and unregister all listeners.
   */
  static destroy(): void {
    if (EventManager.__emitters) {
      Object.keys(EventManager.__emitters).forEach((eventType) => {
        Object.keys(eventType).forEach((emitterId) => {
          delete EventManager.__emitters?.[eventType][emitterId];
        });
        delete EventManager.__emitters?.[eventType];
      });
      EventManager.__emitters = null;
    }
    EventManager.initialized = false;
  }

  /**
   * emit an event
   * @param eventType - event to emit.
   * @param args - arguments to pass to listener.
   * @returns results of all listener calls in the order emitted
   */
  static emit<R extends OptionArray, T extends any[] = any>(
    eventType: EventString,
    ...args: EventArgs<T>
  ): EventReturnValue<R> {
    if (EventManager.__emitters == null) {
      throw new Error('failed to emit event: EventManager not initialized');
    }

    if (typeof eventType !== 'string') {
      console.warn(
        `emitted eventType should be a string but is ${typeof eventType}:`,
        eventType
      );
    }
    const results = [] as any;

    if (!EventManager.__emitters[eventType]) {
      !t3dev().testing &&
        t3dev().log.warn('no listener for emitter call', eventType);
      return results;
    }

    for (const owner in EventManager.__emitters[eventType]) {
      const emitter = EventManager.__emitters[eventType][owner];
      emitter.called = true;
      const returnValue = emitter.listener.call(emitter.context, ...args);
      // if something is returned i.e., non-void, then append value
      if (returnValue != undefined) results.push(returnValue);
    }

    return results;
  }

  static called(eventType: EventString): boolean {
    if (!EventManager.__emitters) return false;
    return Object.reduce(
      EventManager.__emitters[eventType],
      (value, [_type, emitter]) => value || emitter.called,
      false
    );
  }

  /**
   * register an event listener.
   * @param eventType - event to listen for.
   * @param listener - listener function that will be called.
   * @param context - context passed to the handler.
   * @returns unsubscription function.
   */
  static on<T extends any[] = any[], R = any, C = any>(
    eventType: EventString,
    listener: EventManagerListener<T, R>,
    context: EventContext<C> = {}
  ): EventUnsubFunc {
    if (EventManager.__emitters == null) {
      throw new Error('failed to register event: EventManager not initialized');
    }

    if (typeof eventType !== 'string') {
      console.warn(
        `registered eventType should be a string but is ${typeof eventType}`
      );
    }
    if (!EventManager.__emitters[eventType])
      EventManager.__emitters[eventType] = {};
    const id = uuid();
    EventManager.__emitters[eventType][id] = {
      called: false,
      id,
      listener,
      context,
    };
    //return an unsub function
    return () => {
      // protect against deletion if destroy() called before unsub
      if (EventManager?.__emitters?.[eventType]) {
        delete EventManager.__emitters[eventType][id];
      }
    };
  }

  static once<T extends any[] = any[], R = any, C = any>(
    eventType: EventString,
    listener: EventManagerListener<T, R>,
    context: EventContext<C> = {}
  ): void {
    if (EventManager.__emitters == null) {
      throw new Error('failed to register event: EventManager not initialized');
    }

    if (typeof eventType !== 'string') {
      console.warn(
        `registered eventType should be a string but is ${typeof eventType}`
      );
    }
    if (!EventManager.__emitters[eventType])
      EventManager.__emitters[eventType] = {};
    const id = uuid();
    const singleUseListener = (...args: T): void | R => {
      const value = listener(...args);
      if (EventManager?.__emitters?.[eventType]) {
        delete EventManager.__emitters[eventType][id];
      }
      return value;
    };

    EventManager.__emitters[eventType][id] = {
      called: false,
      id,
      listener: singleUseListener,
      context,
    };
  }

  static reset(): void {
    if (EventManager.__emitters) {
      Object.keys(EventManager.__emitters).forEach((eventType) => {
        Object.keys(eventType).forEach((emitterId) => {
          delete EventManager.__emitters?.[eventType][emitterId];
        });
        delete EventManager.__emitters?.[eventType];
      });
      EventManager.__emitters = {};
    }
    EventManager.initialized = false;
  }
}

/**
 * create a unique event id based on a shape configuration.
 * @param config - Parameter description.
 * @returns unique event type.
 */
export const directMessageId = (config: ShapeConfig): string => {
  return (config.id ? config.id : config) as string;
};

export const emitManagedEvent =
  <R extends any[], T extends any[] = any>(
    eventType: EventString,
    ...args: EventArgs<T>
  ) =>
  (event?: SyntheticEvent): EventReturnValue<R> => {
    const extendedArgs = args;
    extendedArgs.push(event);
    return EventManager.emit<R, T>(eventType, ...extendedArgs);
  };

t3dev().setValue('em', EventManager);
