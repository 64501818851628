import { TABLE } from 'modules/common';
import {
  clearSelection,
  setAnchor,
  setDropAnchor,
} from 'modules/model/properties/selection';
import { selectExactly, setMptGrabFocus } from 'modules/model/base/actions';
import { GRID, TAB, SHAPE, ACTIONS } from 'utils/constants-extra';
import { EventManager } from 'metra-events';
import { setSelection } from 'actions/actions-helpers';

/** @type {import('types').TableViewReducer} */
export const initialTableViewState = {
  type: 'nodes',
  tab: TAB.MODEL_NOTES,
  rowMax: 10,
  colMax: 10,
  rows: {
    edges: 0,
    nodes: 0,
    sets: 0,
    modelProps: 0,
    modelCalcs: 0,
    paths: 0,
  },
  cols: {
    edges: 0,
    nodes: 0,
    sets: 0,
    modelProps: 0,
    modelCalcs: 0,
    paths: 0,
  },
};

export const initViewport = (value = initialTableViewState) => ({
  type: TABLE.VIEWPORT.INIT,
  payload: value,
});

export const updateViewportRows = (value) => ({
  type: TABLE.VIEWPORT.UPDATE.ROWS,
  payload: Math.max(0, value),
});

export const updateViewportCols = (value) => ({
  type: TABLE.VIEWPORT.UPDATE.COLS,
  payload: Math.max(0, value),
});

export const updateViewportRowMax = (value) => ({
  type: TABLE.VIEWPORT.UPDATE.ROWMAX,
  payload: value,
});

export const updateViewportColMax = (value) => ({
  type: TABLE.VIEWPORT.UPDATE.COLMAX,
  payload: value,
});

export const updateDataViewType = (type) => (dispatch) => {
  dispatch(clearSelection());
  dispatch({
    type: TABLE.VIEWPORT.UPDATE.TYPE,
    payload: type,
  });
};

export const updateDataViewTab = (tab) => ({
  type: TABLE.VIEWPORT.UPDATE.TAB,
  payload: tab,
});

export const trySelectNameCell = (id) => async (dispatch, getState) => {
  const modelReducer = getState().modelReducer;
  const type = modelReducer.shapes[id]
    ? modelReducer.shapes[id].type
    : modelReducer.sets[id]
    ? SHAPE.SET
    : null;
  if (!type) return;
  const nameColumn = {
    [SHAPE.NODE]: GRID.NAME_COLUMN.NODES,
    [SHAPE.EDGE]: GRID.NAME_COLUMN.EDGES,
    [SHAPE.SET]: GRID.NAME_COLUMN.SETS,
  };
  const tableViewType = {
    [SHAPE.NODE]: 'nodes',
    [SHAPE.EDGE]: 'edges',
    [SHAPE.SET]: 'sets',
  };

  // if this is a set, we select it regardless
  if (type === SHAPE.SET) {
    dispatch(selectExactly(modelReducer.base.selected.shapes, [id]));
    if (modelReducer.base.selected.shapes.length === 1) {
      EventManager.emit(ACTIONS.SELECT_ONE, modelReducer.base.selected.shapes);
    } else {
      EventManager.emit(
        ACTIONS.SELECT_MULTI,
        modelReducer.base.selected.shapes
      );
    }
  }

  // we only select the name cell if the MPT is already open and already
  // showing the appropriate tab
  if (
    modelReducer.base.showPropsPanel &&
    modelReducer.tableView.type === tableViewType[type]
  ) {
    const tableSort = modelReducer.tableSort;
    const entities = tableSort.entities[tableViewType[type]];
    // rowIndex + 1 to account for the prop-names row at the top
    const rowIndex = entities.indexOf(id) + 1;
    dispatch(setAnchor([rowIndex, 0]));
    dispatch(setSelection([[tableViewType[type], id, nameColumn[type]]]));
    const dropAnchor = { rowId: id, columnId: nameColumn[type] };
    const anchorRow = rowIndex < entities.length ? rowIndex + 1 : 0;
    dispatch(setDropAnchor(dropAnchor, anchorRow, true));
    dispatch(setMptGrabFocus(true));
  }
};

export const tableViewReducer = (state = initialTableViewState, action) => {
  switch (action.type) {
    case TABLE.FILTER.UPDATE:
      // reset the view data if the filter changes
      return {
        ...state,
        rows: {
          nodes: 0,
          edges: 0,
          sets: 0,
          modelProps: 0,
          modelCalcs: 0,
          paths: 0,
        },
      };
    case TABLE.VIEWPORT.INIT:
      return action.payload;
    case TABLE.VIEWPORT.UPDATE.TYPE:
      return {
        ...state,
        type: action.payload,
      };
    case TABLE.VIEWPORT.UPDATE.TAB:
      return {
        ...state,
        tab: action.payload,
      };
    case TABLE.VIEWPORT.UPDATE.ROWS: {
      if (isNaN(action.payload)) return state;
      const newRows = { ...state.rows };
      newRows[state.type] = action.payload;
      return {
        ...state,
        rows: newRows,
      };
    }
    case TABLE.VIEWPORT.UPDATE.COLS: {
      if (isNaN(action.payload)) return state;
      const newCols = { ...state.cols };
      newCols[state.type] = action.payload;
      return {
        ...state,
        cols: newCols,
      };
    }
    case TABLE.VIEWPORT.UPDATE.ROWMAX:
      return {
        ...state,
        rowMax: action.payload,
      };
    case TABLE.VIEWPORT.UPDATE.COLMAX:
      return {
        ...state,
        colMax: action.payload,
      };
    default:
      return state;
  }
};
