import type { Reducer } from 'redux';
import { MetraSimpleAction } from 'types';
import { cloneDeep } from 'lodash';
import { FILE_DATA } from './action-types';

type Status = 'loading' | 'error' | 'dirty' | 'saving' | 'saved';

export const FILE_DATA_STATUS = {
  LOADING: 'loading',
  ERROR: 'error',
  DIRTY: 'dirty',
  SAVING: 'saving',
  SAVED: 'saved',
} as const;

export type FileData = {
  id: number;
  status: Status;
  versionId: Option<number>;
  belongsTo: Option<number>;
  error: Option<string>;
  objectUrl: Option<string>;
  name: Option<string>;
  projectId: Option<number>;
  updatedAt: Option<string>;
};

export type FileDataState = Record<string, FileData>;

export const initialState: FileDataState = {};

export const reducer: Reducer<
  FileDataState,
  MetraSimpleAction<Omit<FileData, 'updatedAt'>>
> = (state = initialState, action) => {
  const { type, payload } = action;

  if (type === FILE_DATA.READ) {
    if (payload.id > 0) {
      return {
        ...state,
        [payload.id]: {
          ...payload,
          status: FILE_DATA_STATUS.LOADING,
          updatedAt: new Date().toISOString(),
        },
      };
    }

    return state;
  }

  if (type === FILE_DATA.CREATE) {
    return {
      ...state,
      [payload.id]: {
        ...payload,
        updatedAt: new Date().toISOString(),
        error: null,
        status: FILE_DATA_STATUS.SAVED,
      },
    };
  }

  if (type === FILE_DATA.UPDATE) {
    const record = state[payload.id];

    if (record) {
      return {
        ...state,
        [record.id]: {
          ...record,
          ...payload,
          updatedAt: new Date().toISOString(),
        },
      };
    }

    return state;
  }

  if (type === FILE_DATA.DELETE) {
    const updates = cloneDeep(state);
    const mediaId = Number(payload);

    if (!isNaN(mediaId) && mediaId > 0) {
      delete updates[mediaId];

      return updates;
    }

    return state;
  }

  return state;
};
