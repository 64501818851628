import { isNone } from 'helpers/utils';
import { Interpreter } from 'interpreter/interpreter';
import { mathjs } from 'interpreter/t3math';
import { buildCellMappings } from './helpers';
import { entToPrefix, isString } from 'interpreter/helpers';

/**
 * cellMappings - key of [originalCellId], value of [updatedCellId]
 */
export function renamer<R, O>(itpr: Interpreter<R, O>): Interpreter<R, O> {
  const { oldSort, newSort } = itpr.sort;
  const mappings = buildCellMappings(oldSort, newSort, itpr);

  // rename variables as appropriate
  itpr.expressionList.forEach((exp) => {
    if (
      exp.isPlain ||
      isNone(exp.parsed) ||
      isString(exp.parsed) ||
      exp.hasErrors ||
      !('transform' in exp.parsed)
    )
      return null;

    exp.parsed = exp.parsed.transform((node) => {
      if (!mathjs.isSymbolNode(node)) return node;
      // look for a value to transform to
      let updatedValue = mappings[node.name.toLowerCase()] || node.name;
      // IF namesUpdated is already populated
      // THEN use the current value
      // ELSE if updatedValue is NOT node.name
      // THEN use updatedValue
      // OTHERWISE keep current value
      exp.namesUpdated = exp.namesUpdated
        ? exp.namesUpdated
        : updatedValue !== node.name
        ? updatedValue
        : exp.namesUpdated;
      const renamed = new mathjs.SymbolNode(updatedValue);
      // remember if the reference being modified started life as a qualified or
      // relative reference
      if (exp.localCellReferences.includes(node))
        exp.localCellReferences.push(renamed);
      return renamed;
    });
  });

  // Everything that looked like b3 got replaced with something that looked like
  // n_b3.  Where this happened, replace the fully-qualified (n_b3) with the
  // relative reference (b3).
  itpr.expressionList.forEach((exp) => {
    if (exp.isPlain || isNone(exp.parsed) || isString(exp.parsed)) return null;
    if (exp.hasErrors || !('transform' in exp.parsed)) return null;
    const newValue = exp.parsed.transform((node) => {
      if (!mathjs.isSymbolNode(node)) return node;
      const prefix = entToPrefix(exp.ent);
      if (prefix && !node.name.startsWith(prefix)) return node;
      // If the reference started life qualified (like n_b3), leave it as a
      // qualified reference.
      if (!exp.localCellReferences.includes(node)) return node;
      return new mathjs.SymbolNode(node.name.substring(2));
    });

    exp.parsed = newValue;
  });

  return itpr;
}
