import {
  apiGet,
  isApiAction,
  isApiErrorAction,
  isPayloadAction,
} from 'modules/common/api';
import { CONFIG } from 'modules/common/constants';
import { Reducer } from 'redux';
import { ConfigReducer, MetraAction, ThunkAction } from 'types';

export const SCHEMA_NAME = 'guilds';

export const initialConfigState: ConfigReducer = {
  helpDocsUrl: '/404',
  loaded: false,
  logoutUrl: '/404',
  matomoEnabled: false,
  matomoSiteId: 1, // default value for production
  matomoUrl: 'https://matomo.tenet3.com',
  mib: false,
  sentryEnabled: false,
  sentryMegalithDsn: '',
  updatePasswordUrl: '/404',
};

export declare type NucleoConfigResponse = {
  /* eslint-disable camelcase */
  help_docs_url: string;
  logout_url: string;
  matomo_enabled: boolean;
  matomo_site_id: number;
  matomo_url: string;
  mib: boolean;
  sentry_enabled: boolean;
  sentry_megalith_dsn: string;
  update_password_url: string;
  /* eslint-enable camelcase */
};

export const loadConfig =
  (): ThunkAction<Promise<void>> => async (dispatch) => {
    await dispatch(
      apiGet<NucleoConfigResponse>({
        entity: 'config',
        types: [CONFIG.SET_CONFIG, CONFIG.GET_FAILURE],
        meta: {
          excludeGuild: true,
        },
      })
    );
  };

export const configReducer: Reducer<
  ConfigReducer,
  MetraAction<Partial<NucleoConfigResponse>, any, void>
> = (state = initialConfigState, action): ConfigReducer => {
  switch (action.type) {
    case CONFIG.SET_CONFIG:
      if (isApiErrorAction(action)) {
        return state;
      } else if (isPayloadAction(action)) {
        return {
          ...state,
          /* eslint-disable camelcase */
          helpDocsUrl:
            action.payload?.help_docs_url ?? initialConfigState.helpDocsUrl,
          loaded: true,
          logoutUrl: action.payload?.logout_url ?? initialConfigState.logoutUrl,
          matomoEnabled:
            action.payload?.matomo_enabled ?? initialConfigState.matomoEnabled,
          matomoSiteId:
            action.payload?.matomo_site_id ?? initialConfigState.matomoSiteId,
          matomoUrl: action.payload?.matomo_url ?? initialConfigState.matomoUrl,
          mib: action.payload?.mib ?? initialConfigState.mib,
          sentryEnabled:
            action.payload?.sentry_enabled ?? initialConfigState.sentryEnabled,
          sentryMegalithDsn:
            action.payload?.sentry_megalith_dsn ??
            initialConfigState.sentryMegalithDsn,
          updatePasswordUrl:
            action.payload?.update_password_url ??
            initialConfigState.updatePasswordUrl,
          /* eslint-enable camelcase */
        };
      } else {
        return state;
      }
    default:
      return state;
  }
};

export default configReducer;
