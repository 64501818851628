import {
  generateDefaultActionHandlers,
  reducerFromHandlers,
} from 'modules/common';
import { MODEL_PROP } from 'utils/constants';

/**
 * @param {import('types').ModelProp} modelProp
 * @returns {import('types').MetraSimpleAction<import('types').ModelProp[]>}
 */
export const addModelProp = (modelProp) => addModelProps([modelProp]);
/**
 * @param {import('types').ModelProp[]} modelProps
 * @returns {import('types').MetraSimpleAction<import('types/index').ModelProp[]>}
 */
export const addModelProps = (modelProps) => ({
  type: MODEL_PROP.CREATE,
  payload: modelProps,
});

/**
 * @param {import('types').ModelProp} modelProp
 * @returns {import('types').MetraSimpleAction<import('types').ModelProp[]>}
 */
export const updateModelProp = (modelProp) => updateModelProps([modelProp]);
/**
 * @param {import('types').ModelProp[]} modelProps
 * @returns {import('types').MetraSimpleAction<import('types/index').ModelProp[]>}
 */
export const updateModelProps = (modelProps) => ({
  type: MODEL_PROP.UPDATE,
  payload: modelProps,
});

/**
 * @param {import('types').ModelProp} modelProp
 * @returns {import('types').MetraSimpleAction<import('types').ModelProp[]>}
 */
export const deleteModelProp = (modelProp) => deleteModelProps([modelProp]);
/**
 * @param {import('types').ModelProp[]} modelProps
 * @returns {import('types').MetraSimpleAction<import('types').ModelProp[]>}
 */
export const deleteModelProps = (modelProps) => ({
  type: MODEL_PROP.DELETE,
  payload: modelProps,
});

export const initModelProps = (modelProps) => ({
  type: MODEL_PROP.INITIALIZE,
  payload: modelProps,
});

export const initModelPropReducer = (state, modelProps = {}) => modelProps;

export const modelPropReducer = reducerFromHandlers(
  generateDefaultActionHandlers(MODEL_PROP, {
    [MODEL_PROP.INITIALIZE]: initModelPropReducer,
    [MODEL_PROP.DELETE]: (state) => state, //this is taken care of by the higherOrderReducer
  })
);
