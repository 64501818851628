import urljoin from 'url-join';
import { TRUE_API_URI } from './settings';

/**
 * Construct base URL in case `url` is relative
 * @constant
 */
export const DEFAULT_BASE_URL = new URL(window.location.href).origin;

/**
 * Adds query parameters to a URL
 * @param {string} url - a valid string url
 * @param {Record<string, string>} parameters - query parameters
 * @returns {URL} returns a URL object - https://developer.mozilla.org/en-US/docs/Web/API/URL
 */
export const addParameters = (url, parameters = {}) => {
  // If `url` is relative, it will be appended to DEFAULT_BASE_URL.
  // Otherwise, DEFAULT_BASE_URL is ignored.
  // SEE https://developer.mozilla.org/en-US/docs/Web/API/URL/URL
  const _url = new URL(url, DEFAULT_BASE_URL);

  Object.keys(parameters).forEach((key) => {
    if (!parameters[key] && parameters[key] !== '') return; //ignore param if there is no actual value
    _url.searchParams.append(key, parameters[key]);
  });

  return _url;
};

/**
 * Joins/Concats the pathname and search attributes of a URL
 * i.e, if the url is http://example.com/api/media?project=35, the pathname is /api/media,
 * and the search(aka query params) is ?project=35. This function returns those joined together.
 * @param {URL} url - a URL object(https://developer.mozilla.org/en-US/docs/Web/API/URL)
 * @returns {string} the relative url string
 */
export const getRelativeUrl = (url) => {
  return url.pathname + url.search;
};

/**
 * Constructs the URL for an entity.
 *
 * @param {string} entity - The entity URL resource
 * @param {string} [recordId=''] - the record id, defaults to ''
 * @param {Record<string, string> } [parameters={}] - query parameters as object, defaults to {}
 *
 * @returns {string} a url that routes to the entity
 */
export const UrlFor = (entity, recordId = '', parameters = {}) => {
  //ensure url-join has a valid starting address
  const baseURI = TRUE_API_URI;
  let url = '';
  if (recordId) {
    url = urljoin(baseURI, entity, recordId.toString());
  } else {
    url = urljoin(baseURI, entity);
  }

  const result = getRelativeUrl(addParameters(url, parameters));
  return result;
};

/**
 * Constructs the explicit URL for an entity.
 *
 * @param {string} explicit - The entity URL resource
 * @param {string} [recordId='']- the record id, defaults to ''
 * @param {Record<string, string>}[parameters={}] - query parameters as object, defaults to {}
 *
 * @returns {string} an explicit url that routes to the entity
 */
export const UrlForExplicit = (explicit, recordId = '', parameters = {}) => {
  let url = '';
  if (recordId) {
    url = urljoin(explicit, recordId.toString());
  } else {
    url = urljoin(explicit);
  }
  const result = addParameters(url, parameters).href;
  return result;
};
