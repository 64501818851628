import { isPayloadAction } from 'modules/common';
import { CREATE_GUILD, GUILD, TOGGLE_GUILD } from 'modules/common/constants';
import { Reducer } from 'redux';
import { CreateGuildReducer, MetraAction } from 'types';

export const SCHEMA_NAME = 'guilds';

export declare interface CreateGuildReducerAction {
  response?: {
    error?: Record<string, string[]>;
  };
}

export const initialCreateGuildState: CreateGuildReducer = {
  guild: undefined,
  guildModalType: 'create',
  showGuildModal: false,
  showLicenseErrorModal: false,
  showToggleModal: false,
  createErrors: undefined,
};

export const createGuildReducer: Reducer<
  CreateGuildReducer,
  MetraAction<Partial<CreateGuildReducerAction>, any, void>
> = (state = initialCreateGuildState, action): CreateGuildReducer => {
  if (isPayloadAction(action)) {
    switch (action.type) {
      case CREATE_GUILD.SHOW_GUILD_MODAL:
        return {
          ...state,
          createErrors: undefined,
          ...action.payload,
        };
      case CREATE_GUILD.SHOW_LICENSE_ERROR_MODAL:
        return {
          ...state,
          ...action.payload,
        };
      case GUILD.CREATE_FAILURE:
        return {
          ...state,
          createErrors: action.payload.response?.error,
        };
      case GUILD.CLEAR_ERRORS:
        return {
          ...state,
          createErrors: undefined,
        };
      case TOGGLE_GUILD.SHOW_TOGGLE_MODAL:
        return {
          ...state,
          createErrors: undefined,
          ...action.payload,
        };
      default:
        return state;
    }
  } else {
    return state;
  }
};

export default createGuildReducer;
