export enum AssetLoadState {
  NONE,
  STARTED,
  COMPLETED,
  GOOD,
  BAD,
  ERROR,
  DONE,
}

export enum ThumbnailMode {
  NONE,
  SMALL,
  LARGE,
}
