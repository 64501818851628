import { ComponentSymbol, Component } from 'ecs2/core/component';

export {
  ComponentSymbol,
  Component,
  ValueComponent,
} from 'ecs2/core/component';

/**
 * confirms whether the given object is a Component Type or Component Instance
 */
export function isComponent<T extends typeof Component | Component>(
  object: any | T
): object is T {
  return object[ComponentSymbol] != null;
}

/**
 * confirms whether the given component is of the stated component type
 */
export function isComponentOfType<T extends typeof Component | Component>(
  object: Component | typeof Component,
  type: T
): object is T {
  return object.type === type.type;
}
