import type { ModelReducer, TransitionData } from 'types';
import type { Entity } from 'ecs/Entity';
import type { EcsInstance } from 'ecs/EcsInstance';
import { isNone, isSome } from 'helpers/utils';
import { getContainingSets, getEntitySets, topContainerSet } from './utils';
import { maybeSetCollapsed } from './collapsed';
import { Edge, Node, ShapeUUID } from 'engine/components';

export function maybeSetVisible(uuid: string, data: TransitionData): boolean {
  const setData = data.model.sets?.[uuid];
  if (isNone(setData)) return false;
  const maybeVisible = data?.setVisibleTransitions?.[setData.id];
  const tryVisible = isSome(maybeVisible) && maybeVisible.after;
  return (setData.visible && isNone(maybeVisible)) || tryVisible;
}

/**
 * check if an entity is in a visible collapsed set
 * @param data current transition data
 */
export function isInVisCollapsedSet(data: TransitionData): boolean {
  const { setData } = data;
  const sets = getEntitySets(data);

  // check to see if any set is collapsed and visible
  for (let i = sets.length; i--; ) {
    const setId = sets[i];
    // skip the provided set if provided
    if (isSome(setData) && setId === setData.id) continue;

    const isVisible = maybeSetVisible(setId, data);
    const isCollapsed = maybeSetCollapsed(setId, data);
    // IF this set is collapsed
    // AND it is visible OR will be visible
    // THEN return true
    // OTHERWISE return false
    if (isVisible && !isCollapsed) return true;
  }
  return false;
}

/**
 * checks if a given entity is in any non-visible set
 * @param entity entity to check
 * @param ecs current ecs instance
 * @param model current model state
 * @returns `true` if in a non-vis set, otherwise `false`
 */
export function isInAnyNonVisibleSet(
  entity: Entity,
  ecs: EcsInstance,
  model: ModelReducer
): boolean {
  const [uuid, node, edge] = ecs.retrieve(entity, [ShapeUUID, Node, Edge]);
  if (isNone(uuid)) return false;
  let sets: string[] = [];
  if (isSome(node)) {
    sets = model.nodes[uuid.value].setIds;
  } else if (isSome(edge)) {
    sets = model.edges[uuid.value].setIds;
  }
  for (let i = sets.length; i--; ) {
    if (!model.sets[sets[i]].visible) return true;
  }
  return false;
}

/**
 * checks if a given entity is in any visible set
 * @param data - current transition data
 * @returns `true` if in a visible set, otherwise `false`
 */
export function isInAnyVisibileSet(data: TransitionData): boolean {
  const { model } = data;
  const sets = getEntitySets(data);
  // if any are visible return true, otherwise false
  return sets.some((setId) => {
    const setData = model.sets?.[setId];
    if (isNone(setData)) return false;
    const vis = determineSetVisibility({
      ...data,
      setData,
    });
    return vis;
  });
}

export function determineSetVisibility(data: TransitionData): boolean {
  const { setData, model } = data;
  const { sets } = model;

  const isVisible = maybeSetVisible(setData.id, data);
  // const _isCollapsed = maybeSetCollapsed(setData.id, data);

  // get the containg sets
  const containingSets = getContainingSets(setData.id, model);

  let isTopSet = true;
  let topSet = setData.id;
  // this set is contained in another set, so...
  if (containingSets.length > 1) {
    // which is the top set?
    topSet = topContainerSet(setData.id, Object.keys(sets), model);
    isTopSet = topSet === setData.id;
  }

  // IF set is the top set
  // AND set is visible
  // OR set is trying to become visible
  // THEN set should be visible
  if (isTopSet && isVisible) return true;

  const topSetData = sets?.[topSet];

  if (isSome(topSetData)) {
    const topSetIsCollapsed = maybeSetCollapsed(topSetData.id, data);
    // IF set is not the top set
    // AND top containing set is collapsed
    // THEN this set should not be visible
    if (!isTopSet && topSetIsCollapsed) return false;

    const topSetIsVisible = maybeSetVisible(topSetData.id, data);
    // IF set is not the top set
    // AND top containing set is visible
    // THEN this set should be visible
    if (!isTopSet && topSetIsVisible) return true;
  }

  // OTHERWISE return current visibility state
  return isVisible;
}
