import { MPT_POPOUT_EDITOR } from 'modules/common';
import { MetraActionFunc } from 'types';

export const wiggleStartMPTPopoutEditor: MetraActionFunc = () => ({
  type: MPT_POPOUT_EDITOR.WIGGLE.START,
});

export const wiggleStopMPTPopoutEditor: MetraActionFunc = () => ({
  type: MPT_POPOUT_EDITOR.WIGGLE.STOP,
});
