import { cloneDeep } from 'lodash';
import { isApiAction, isSimpleAction } from 'modules/common/api';
import { WINDOW } from 'modules/common/constants';
import { WindowReducer } from 'types/modules';
import { WindowActionData } from './actions';
import { SITE_HEADER_HEIGHT } from 'utils/constants';
import { MetraAction } from 'types/index';
import { Reducer } from 'redux';

export const initialState: WindowReducer = {
  active: {},
};

export const reducer: Reducer<
  WindowReducer,
  MetraAction<WindowActionData, any, void>
> = (state = initialState, action) => {
  if (isApiAction(action)) return state;
  if (isSimpleAction(action)) {
    switch (action.type) {
      case WINDOW.OPEN: {
        const { handle, type, data, hideOnClose, backgroundColor } =
          action.payload;

        const scrollbar = !!action.payload.scrollbar;

        const minWidth =
          action.payload.minWidth != null ? action.payload.minWidth : 260;

        const minHeight =
          action.payload.minHeight != null ? action.payload.minHeight : 300;

        const width = action.payload.width || minWidth;
        const height = action.payload.height || minHeight;

        let top =
          action.payload.top != null
            ? action.payload.top
            : window.innerHeight / 2 - height / 2;
        let left =
          action.payload.left != null
            ? action.payload.left
            : window.innerWidth / 2 - width / 2;

        top = Math.max(
          SITE_HEADER_HEIGHT,
          Math.min(window.innerHeight - height, top)
        );

        left = Math.max(0, Math.min(window.innerWidth - width, left));

        const resizable = action.payload.resizable !== false;
        const noXout = action.payload.noXout || false;

        if (handle == null || type == null) return state;

        const newState = cloneDeep(state);
        newState.active[handle] = {
          type,
          top,
          left,
          width,
          height,
          minWidth,
          minHeight,
          scrollbar,
          data,
          hideOnClose,
          hidden: false,
          noXout,
          backgroundColor,
          resizable,
        };
        return newState;
      }

      case WINDOW.EDIT: {
        const {
          handle,
          width,
          height,
          minWidth,
          minHeight,
          data,
          hideOnClose,
        } = action?.payload ?? {};
        let { top, left } = action?.payload ?? {};

        if (handle == null) return state;
        let changed = false;
        const newState = cloneDeep(state);
        const active = newState.active[handle];
        if (!active) return state;

        if (top !== undefined) {
          top = Math.max(
            SITE_HEADER_HEIGHT,
            Math.min(window.innerHeight - active.height, top)
          );
        }

        if (left !== undefined) {
          left = Math.max(0, Math.min(window.innerWidth - active.width, left));
        }

        // clearing values must be done explicitly using `null`
        if (top !== undefined && active.top !== top) {
          active.top = top;
          changed = true;
        }

        if (left !== undefined && active.left !== left) {
          active.left = left;
          changed = true;
        }

        if (width !== undefined && active.width !== width) {
          active.width = width;
          changed = true;
        }

        if (height !== undefined && active.height !== height) {
          active.height = height;
          changed = true;
        }

        if (minWidth !== undefined && active.minWidth !== minWidth) {
          active.minWidth = minWidth;
          changed = true;
        }

        if (minHeight !== undefined && active.minHeight !== minHeight) {
          active.minHeight = minHeight;
          changed = true;
        }

        if (data !== undefined && active.data !== data) {
          active.data = data;
          changed = true;
        }

        if (hideOnClose !== undefined && active.hideOnClose !== hideOnClose) {
          active.hideOnClose = hideOnClose;
          changed = true;
        }

        return changed ? newState : state;
      }

      case WINDOW.CLOSE: {
        const { handle } = action.payload;
        if (handle == null) return state;
        if (state.active[handle] == null) return state;
        const newState = cloneDeep(state);

        if (state.active[handle].hideOnClose) {
          newState.active[handle].hidden = true;
        } else {
          delete newState.active[handle];
        }
        return newState;
      }

      // this is called when leaving the editor
      // so it should clear even hideable windows
      case WINDOW.CLOSE_ALL: {
        return initialState;
      }

      default: {
        return state;
      }
    }
  } else {
    if (action.type === WINDOW.CLOSE_ALL) return initialState;
    return state;
  }
};
