import {
  generateDefaultActionHandlers,
  reducerFromHandlers,
} from 'modules/common';
import { MODEL_CALC } from 'utils/constants';

/**
 * @param {import('types').ModelCalcs} modelCalc
 * @returns {import('types').MetraSimpleAction<import('types').ModelCalcs[]>}
 */
export const addModelCalc = (modelCalc) => addModelCalcs([modelCalc]);
/**
 * @param {import('types').ModelCalcs[]} modelCalc
 * @returns {import('types').MetraSimpleAction<import('types').ModelCalcs[]>}
 */
export const addModelCalcs = (modelCalcs) => ({
  type: MODEL_CALC.CREATE,
  payload: modelCalcs,
});

/**
 * @param {import('types').ModelCalcs} modelCalc
 * @returns {import('types').MetraSimpleAction<import('types').ModelCalcs[]>}
 */
export const updateModelCalc = (modelCalc) => updateModelCalcs([modelCalc]);

/**
 * @param {import('types').ModelCalcs[]} modelCalc
 * @returns {import('types').MetraSimpleAction<import('types').ModelCalcs[]>}
 */
export const updateModelCalcs = (modelCalcs) => ({
  type: MODEL_CALC.UPDATE,
  payload: modelCalcs,
});

/**
 * @param {import('types').ModelCalcs} modelCalc
 * @returns {import('types').MetraSimpleAction<import('types').ModelCalcs[]>}
 */
export const deleteModelCalc = (modelCalc) => deleteModelCalcs([modelCalc]);
/**
 * @param {import('types').ModelCalcs[]} modelCalcs
 * @returns {import('types').MetraSimpleAction<import('types').ModelCalcs[]>}
 */
export const deleteModelCalcs = (modelCalcs) => ({
  type: MODEL_CALC.DELETE,
  payload: modelCalcs,
});

/**
 * @param {Record<UUID, import('types').ModelCalcs>} modelCalcs
 * @returns {import('types').MetraSimpleAction<Record<UUID, import('types').ModelCalcs>>}
 */
export const initModelCalcs = (modelCalcs) => ({
  type: MODEL_CALC.INITIALIZE,
  payload: modelCalcs,
});

export const initModelCalcReducer = (state, modelCalcs = {}) => modelCalcs;

export const modelCalcReducer = reducerFromHandlers(
  generateDefaultActionHandlers(MODEL_CALC, {
    [MODEL_CALC.INITIALIZE]: initModelCalcReducer,
    [MODEL_CALC.DELETE]: (state) => state, //this is taken care of by the higherOrderReducer
  })
);
