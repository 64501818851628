import React from 'react';
import './brokenLinkIcon.scss';
import { ASSETS, useAsset } from 'assets/manager';

declare interface BrokenLinkIconProps {
  link: string;
}

const BrokenLinkIcon: React.FC<BrokenLinkIconProps> = ({ link }) => {
  const Icon = useAsset(ASSETS.BROKEN_LINK);
  return (
    <div className="broken-link" data-testid="broken-link">
      <div className="broken-link-icon">
        <Icon title="broken link" alt="broken-link" />
      </div>
      <p className="broken-link-text">{link}</p>
    </div>
  );
};

export default BrokenLinkIcon;
