import { OrientedBounds, QTree } from 'engine/components';
import { SubAtoms } from '../sub-atoms';
import { CircleRange, type RectangleRange } from 'engine/range';
import { convexCollision, pointInAABB, pointInRectangle } from 'utils/geometry';

import type { EntityId, Query } from '..';
import type { Vector2 } from 'utils/vector';
import { isSome } from 'helpers/utils';

// helpers to query both quadtrees and return unified results as EIDs
export class CollisionAtoms extends SubAtoms {
  private _qtree!: Query;

  init() {
    this._qtree = this.sys2.query(QTree);
  }

  queryPoint(point: Vector2) {
    // queries are in world coordinates, but we want to query a single pixel in screen coords
    // so we scale 1 by the zoom for good position even when zoomed in
    const zoom = this.sys2.engine.getCamera().getScale().x;
    const range = new CircleRange(point.x, point.y, 4 / zoom);
    const results: Set<EntityId> = new Set();
    for (const spatial of this.sys2.engine.queryShapes(range)) {
      results.add(spatial.owner);
    }

    const qtree2 = this.getQTree();
    if (qtree2) {
      const bboxes = qtree2.queryPoint(point);
      for (const bbox of bboxes) {
        const obb = this.sys2.maybe(bbox.owner, OrientedBounds);
        if (obb) {
          if (!pointInRectangle(point, obb.toPolygon())) {
            continue;
          }
        } else {
          if (!pointInAABB(point, bbox)) {
            continue;
          }
        }

        results.add(bbox.owner);
      }
    }

    return results;
  }

  queryRange(range: RectangleRange) {
    // see what lands in the selector's range
    const results: Set<EntityId> = new Set();
    for (const spatial of this.sys2.engine.queryShapes(range)) {
      results.add(spatial.owner);
    }

    const qtree = this.getQTree();
    const bboxes = isSome(qtree) ? qtree.query(range) : [];
    for (const bbox of bboxes) {
      const obb = this.sys2.maybe(bbox.owner, OrientedBounds);
      const collider = obb ? obb.toPolygon() : bbox.toPolygon();
      if (!convexCollision(range.toPolygon(), collider)) {
        continue;
      }
      results.add(bbox.owner);
    }

    return results;
  }

  getQTree() {
    const qtree = this._qtree.first();
    if (!qtree) return;
    return this.sys2.assert(qtree, QTree).val;
  }
}
