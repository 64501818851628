import { Middleware } from 'redux';
import { v4 as uuid } from 'uuid';
import { ThunkAPI } from 'types';

/**
 * A redux middleware that adds an Id to every action that lacks one
 */
export function createActionIdMiddleware() {
  const middleware =
    (api: ThunkAPI): ReturnType<Middleware> =>
    (next) =>
    (action) => {
      action.__id = action.__id || uuid().substring(0, 8);

      // account for thunks
      if (action === 'function') {
        const { dispatch, getState, extraArgs } = api;
        return action(dispatch, getState, extraArgs);
      }

      return next(action);
    };
  return middleware;
}

/**
 * Returns a re-implementation of redux-thunk that tracks the chains of thunks through
 * the system, so that asyncronous call chains can be reconstructed even from syncronous
 * logging environments. Requires that all incoming actions have a id, which is, by default,
 * at the key `__id`.
 */
export function createTrackableThunkMiddleware(idKey = '__id') {
  const middleware =
    (api: ThunkAPI): ReturnType<Middleware> =>
    (next) =>
    (action) => {
      // In order to track callers, we make a list of parents on every action that comes through
      action.__parents = action.__parents || [];

      // non-thunks skip the decoration "pass through" like normal below
      // but thunks (i.e., 'functions') need to be decorated
      if (typeof action === 'function') {
        const { dispatch, getState, extraArgs } = api;
        /**
         * A wrapped version of the store's normal `dispatch` that adds this thunk's ID to
         * the list of __parents on all actions dispatched by this thunk.
         */
        // FIXME: this should not be done, this is an example of a store enhancer
        //        and as such this entire "middleware" should be moved to that
        const wrappedDispatch = (childAction: any) => {
          childAction.__parents = action.__parents.concat(action[idKey]);
          return childAction(dispatch, getState, extraArgs);
        };
        // Since it's a thunk, we have to call the action, which means log the breadcrumb here
        return action(wrappedDispatch, getState, extraArgs);
      }

      return next(action);
    };

  return middleware;
}

/**
 * Convenience export of the trackableThunkMiddleware that can be consumed directly by
 * `applyMiddleware`.
 */
// export const trackableThunkMiddleware = createTrackableThunkMiddleware();
