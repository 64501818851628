import { isPayloadAction } from 'modules/common';
import { LINK_FILE } from 'modules/common/constants';
import { Reducer } from 'redux';
import { MetraAction } from 'types';
import { FileLinkReducer } from 'types/modules/fileLink';

export const SCHEMA_NAME = 'guilds';

export const initialFileLinkState: FileLinkReducer = {
  rowId: '',
  columnId: '',
  insertUrlLocation: {
    selectionStart: 0,
    selectionEnd: 0,
  },
  resumeEditing: false,
  resumeEditLocation: {
    selectionStart: 0,
    selectionEnd: 0,
  },
};

export const setLinkFromCell = (cellProperties: Partial<FileLinkReducer>) => {
  return {
    type: LINK_FILE.SET_TARGET_CELL,
    payload: cellProperties,
  };
};

export const setResumeEditing = (resumeEditing: boolean) => {
  return {
    type: LINK_FILE.SET_RESUME_EDITING,
    payload: { resumeEditing },
  };
};

export const setResumeEditLocation = (resumeEditLocation: {
  selectionStart: number;
  selectionEnd: number;
}) => {
  return {
    type: LINK_FILE.SET_RESUME_EDIT_LOCATION,
    payload: { resumeEditLocation },
  };
};

export const setInsertUrlLocation = (insertUrlLocation: {
  selectionStart: number;
  selectionEnd: number;
}) => {
  return {
    type: LINK_FILE.SET_INSERT_LOCATION,
    payload: { insertUrlLocation },
  };
};

export const fileLinkReducer: Reducer<
  FileLinkReducer,
  MetraAction<Partial<FileLinkReducer>, any, void>
> = (state = initialFileLinkState, action): FileLinkReducer => {
  if (!isPayloadAction(action)) return state;
  switch (action.type) {
    case LINK_FILE.SET_INSERT_LOCATION:
      return {
        ...state,
        ...action.payload,
      };
    case LINK_FILE.SET_RESUME_EDITING:
      return {
        ...state,
        ...action.payload,
      };
    case LINK_FILE.SET_RESUME_EDIT_LOCATION:
      return {
        ...state,
        ...action.payload,
      };
    case LINK_FILE.SET_TARGET_CELL:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default fileLinkReducer;
