import { MODEL_LINKS } from 'modules/common';
import { MetraSimpleAction, ModelLinksReducer } from 'types';

/**
 * byId – is used by the frontend to store fallback urls and other frontend related data for model
 * links. ids and notes are persisted to nucleo.
 */
export const initialModelLinksState: ModelLinksReducer = {
  ids: [],
  notes: {},
};

export const initModelLinks = (links: {
  ids: number[];
  notes: Record<string, string>;
}) => ({
  type: MODEL_LINKS.INITIALIZE,
  payload: {
    ids: links.ids,
    notes: links.notes,
  },
});

export const modelLinksReducer = (
  state = initialModelLinksState,
  action: MetraSimpleAction<any>
): ModelLinksReducer => {
  const { type, payload } = action;

  if (type === MODEL_LINKS.SET || type === MODEL_LINKS.INITIALIZE) {
    return {
      ids: payload.ids,
      notes: payload.notes,
    };
  }

  return state;
};
