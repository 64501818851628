import cloneDeep from 'clone-deep';
import { QPV } from 'modules/common';
import { NODE, EDGE } from 'utils/constants';

export const qpvReducer = (state = {}, action) => {
  let newState;
  switch (action.type) {
    case QPV.OPEN:
      // TODO: reenable when we want multiple QPV open at a time
      //newState = cloneDeep(state);
      newState = {};
      newState[action.payload] = true;
      return newState;
    case QPV.CLOSE:
      newState = cloneDeep(state);
      delete newState[action.payload];
      return newState;
    case NODE.DELETE:
      newState = cloneDeep(state);
      action.payload.forEach((node) => delete newState[node.id]);
      return newState;
    case EDGE.DELETE:
      newState = cloneDeep(state);
      action.payload.forEach((edge) => delete newState[edge.id]);
      return newState;
    default:
      return state;
  }
};
