import { HOTKEY_MAP } from 'modules/common';

export const initialHotkeyMapState = {
  show: false,
  top: 100,
  right: 100,
};

export function hotkeyMapReducer(state = initialHotkeyMapState, action) {
  switch (action.type) {
    case HOTKEY_MAP.SHOW:
      return {
        ...state,
        show: true,
      };
    case HOTKEY_MAP.HIDE:
      return {
        ...state,
        show: false,
      };
    case HOTKEY_MAP.MOVE:
      return {
        ...state,
        top: action.payload.top,
        right: action.payload.right,
      };
    default:
      return state;
  }
}
