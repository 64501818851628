import { isSome } from 'helpers/utils';
import { match, matchPath } from 'react-router-dom';

export type ModelRouteParams = {
  orgId: string;
  guildCName: string;
  projectId: string;
  mediaId: string;
  guildId?: string;
  versionId?: string;
};

export function modelPathMatch(url: URL): match<ModelRouteParams> | null {
  // Possible Model URLs to test
  const pathsToMatch = [
    '/org/:orgId/models/:mediaId',
    '/org/:orgId/models/:mediaId/version/:versionId',
    '/org/:orgId/guild/:guildId/projects/:projectId/models/:mediaId',
    '/api/org/:orgId/guild/:guildId/projects/:projectId/models/:mediaId',
  ];
  // Default to no results
  let match = null;
  pathsToMatch.some((path) => {
    const matchResults = matchPath<ModelRouteParams>(url.pathname, {
      path,
      exact: true,
    });
    match = matchResults;
    // If results are truthy, we will exit loop and return value, else we continue to next path
    return isSome(matchResults);
  });
  return match;
}

export function dealWithSpacesInURL(url: string) {
  // when the URL is built, spaces are 'encoded' to + and normal + are encoded to %2b
  const spacesEncoded = url.replaceAll('+', '%20');
  // once the spaces are encoded, we can decode them properly
  const decodedURL = decodeURIComponent(spacesEncoded);
  return decodedURL;
}

export function extractSearchText(search: string): [string, boolean] {
  const searchPrefix = '?search=';
  const advancedText = '&exp-search=t';

  // decode the url search params to plaintext
  let searchText = dealWithSpacesInURL(search.toLowerCase());
  const isAdvanced = searchText.includes(advancedText);
  if (isAdvanced) {
    // if advanced search, ignore the text at the end
    const index = searchText.indexOf(advancedText);
    searchText = decodeURIComponent(
      searchText.substring(searchPrefix.length, index)
    );
  } else {
    searchText = decodeURIComponent(searchText.substring(searchPrefix.length));
  }
  return [searchText, isAdvanced];
}
