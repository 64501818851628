import type { Interpreter } from 'interpreter/interpreter';
import { isNone } from 'helpers/utils';

const symbolRx = /([_$a-zA-Z]+[_$a-z0-9]*)/gi;
const colRx = /^(?<type>[cmnseCMNSE]_)?(?<col>[a-z]+)_$/i;
const rowRx = /^(?<type>[cmnseCMNSE]_)?_(?<row>[0-9]+)$/i;

/**
 * The preparser using string manipulation to modify expressions before
 * they get parsed by MathJS. This lets us do things that aren't supported
 * or easy to do within MathJS. Most importantly: we update any col
 * references to ensure they are entity-qualified (e.g., b_ => n_b_).
 * @template Returns
 * @template Options
 * @param itpr
 */
export function renamePreparser<R, O>(
  itpr: Interpreter<R, O>
): Interpreter<R, O> {
  itpr.expressionList.forEach((exp) => {
    if (isNone(exp.value)) return;
    exp.preparsed = exp.value;
    if (exp.isPlain) return;

    // remove trailing semi-colons so values are always returned
    exp.preparsed = exp.preparsed.replace(/;+$/, '');

    // ensure column references are entity-qualified
    // e.g. b_ becomes n_b_
    const qualifiedCols = exp.preparsed.replace(symbolRx, (sym) => {
      const colRef = sym.match(colRx);
      if (!colRef) return sym;
      if (colRef.groups && colRef.groups.type) return sym.toLowerCase();
      return (exp.ent[0] + '_' + sym).toLowerCase();
    });
    exp.preparsed = qualifiedCols;

    // ensure entity-qualified row references are lower-case
    // e.g. N__3 becomes n__3
    // we will defer qualifying non-qualified row references until parsing;
    // [we need to be able to track whether row-refs started out qualified or
    // whether we temporarily modified them to be that way].
    const qualifiedRows = exp.preparsed.replace(symbolRx, (sym) => {
      const rowRef = sym.match(rowRx);
      if (!rowRef) return sym;
      return sym.toLowerCase();
    });
    exp.preparsed = qualifiedRows;
  });
  return itpr;
}
