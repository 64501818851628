import { cloneDeep } from 'lodash';
import {
  generateDefaultActionHandlers,
  reducerFromHandlers,
} from 'modules/common';
import { EventManager } from 'metra-events';
import { PIXI_ACTION } from 'utils/constants';
import { GLOBAL_EVENT, ENGINE } from 'utils/constants-extra';

/**
 * add shape using shape config.
 * @param {Array<ShapeConfig>} shapeConfigs - shape configs.
 * @returns {import('types').MetraThunkAction<ShapeConfig[], void, void>} pixi action
 */
export const addShapesByConfig = (shapeConfigs) => (dispatch) => {
  dispatch({
    type: PIXI_ACTION.CREATE,
    payload: shapeConfigs,
  });
  EventManager.emit(ENGINE.LOAD.SHAPES, shapeConfigs);
};
/**
 * add a shape by config.
 * @param {import('types').ShapeConfig} shapeConfig - Parameter description.
 * @param {string} eventType - event to issue after dispatch.
 * @param {number} layer - layer to issue alongside event.
 * @returns {import('types').MetraThunkAction<import('types').ShapeConfig, void, void>} pixi thunk action
 */
export const addShapeByConfig =
  (shapeConfig, eventType = ENGINE.LOAD.SHAPE.REGULAR, layer = 0) =>
  (dispatch) => {
    dispatch({
      type: PIXI_ACTION.CREATE,
      payload: shapeConfig,
    });
    EventManager.emit(eventType, shapeConfig, layer);
  };

/**
 * update shapes by shape configs.
 * @param {import('types').ShapeConfig[]} shapeConfigs - shapes to update
 * @returns {import('types').MetraSimpleAction<import('types').ShapeConfig[]>} pixi action.
 */
export const updateShapesByConfig = (shapeConfigs) => ({
  type: PIXI_ACTION.UPDATE,
  payload: shapeConfigs,
});
/**
 * update a shape by shape config.
 * @param {import('types').ShapeConfig} shapeConfig - shape to update.
 * @returns {import('types').MetraSimpleAction<import('types').ShapeConfig[]>} pixi action.
 */
export const updateShapeByConfig = (shapeConfig) =>
  updateShapesByConfig([shapeConfig]);

/*
 *
 * @param {string[]} shapeIds
 * @param {Partial<import('types').ShapeConfig>} properties
 * @returns {import('types').MetraAction<{
 *   shapeIds: string[],
 *   properties: import('types').ShapeConfig
 * }>} pixi action.
 */
export const updateShapeProperties = (shapeIds, properties) => ({
  type: PIXI_ACTION.UPDATE_PROPERTIES,
  payload: { shapeIds, properties },
});

export const batchUpdateShapeProperties = (idPropertyTuples) => ({
  type: PIXI_ACTION.BATCH_UPDATE_PROPERTIES,
  payload: idPropertyTuples,
});

/**
 * delete shapes by shape configs.
 * @param {ShapeConfig[]} shapeConfigs - shape configurations.
 * @returns {Return Type} pixi actions.
 */
export const deleteShapesByConfig =
  (shapeConfigs, eventType = GLOBAL_EVENT.SHAPES_DELETE, layer = 0) =>
  (dispatch) => {
    dispatch({
      type: PIXI_ACTION.DELETE,
      payload: shapeConfigs,
    });
    EventManager.emit(
      eventType,
      shapeConfigs.map((s) => s.id),
      layer
    );
  };
/**
 * delete a shape by config.
 * @param {import('types').ShapeConfig} shapeConfig - shape configuration.
 * @returns {import('types').MetraSimpleAction<import('types').ShapeConfig[]>} pixi action.
 */
export const deleteShapeByConfig = (
  shapeConfig,
  eventType = GLOBAL_EVENT.SHAPES_DELETE
) => deleteShapesByConfig([shapeConfig], eventType);
/**
 * delete a shape.
 * @param {import('types').Shape} shape - shape to delete.
 * @returns {import('types').MetraSimpleAction<import('types').ShapeConfig[]>} pixi action.
 */
export const deleteShape = (shape) => deleteShapesByConfig([shape.config]);

/**
 * delete shapes by configs
 * differentiated from 'deleteShapesByConfig` because it does not emit a delete event.
 * generally used as a leaf function, where it is already responding to a delete event.
 * @param {import('types').ShapeConfig[]} shapeConfigs
 * @returns {import('types').MetraSimpleAction<import('types').ShapeConfig[]>}
 */
export const deleteShapesByConfigNoEmit = (shapeConfigs) => ({
  type: PIXI_ACTION.DELETE,
  payload: shapeConfigs,
});

/**
 * init shapes initializes the state with the passed shape configs
 * @param {import('types').ModelReducer['shapes']} shapeConfigs - a record of shape configurations.
 * @return {import('types').MetraSimpleAction<import('types').ModelReducer['shapes']>}
 */
export const initShapes = (shapeConfigs) => {
  return {
    type: PIXI_ACTION.INIT_SHAPES,
    payload: shapeConfigs,
  };
};

/**
 * initReducer takes a payload and overlays the state with it or sets empty state
 * @param {Object} state - current redux state
 * @param {Object} payload -  state to overlay.
 * @returns {Object} updated state.
 */
export const initShapesReducer = (_state, shapes = {}) => shapes;

export const updatePropertiesReducer = (state, { shapeIds, properties }) => {
  if (Object.keys(properties).length < 1) return state;
  return shapeIds.reduce((newState, shapeId) => {
    const shape = newState[shapeId];
    if (!shape) return newState;
    newState[shapeId] = { ...shape, ...properties };
    return newState;
  }, cloneDeep(state));
};

export const batchUpdatePropertiesReducer = (state, tuples) => {
  if (tuples.length < 1) return state;
  const newState = cloneDeep(state);
  for (let [id, properties] of tuples) {
    const shape = newState[id];
    if (!shape) continue;
    newState[id] = { ...shape, ...properties };
  }

  return newState;
};

/**
 * action handlers
 * @param {PIXI_ACTION} PIXI - pixi actions.
 * @param {Object} initReducer - reducer configuration.
 */
export const actionHandlers = generateDefaultActionHandlers(PIXI_ACTION, {
  [PIXI_ACTION.INIT_SHAPES]: initShapesReducer,
  [PIXI_ACTION.DELETE]: (state) => state,
  [PIXI_ACTION.UPDATE_PROPERTIES]: updatePropertiesReducer,
  [PIXI_ACTION.BATCH_UPDATE_PROPERTIES]: batchUpdatePropertiesReducer,
});

/**
 * pixi reducer.
 * @type {import('types').MetraReducer<any, any, void, import('types').ModelReducer['shapes']>}
 */
export const shapesReducer = reducerFromHandlers(actionHandlers);
