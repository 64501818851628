import { InjectedNode, ModelSchemaCategories, ReferenceData } from 'types';
import type { Interpreter } from 'interpreter/interpreter';
import type { Expression } from 'interpreter/expression';
import { PARSED } from 'interpreter/constants';
import {
  coordsToRef,
  entToPrefix,
  getEntityRow,
  isSourceTargetType,
  isTripleType,
  lookupEndpointEntity,
  lookupEntity,
} from 'interpreter/helpers';
import { parse, mathjs } from 'interpreter/t3math';
import { t3dev } from 't3dev';
import { isModelPath } from 'helpers/data';
import { isNone } from 'helpers/utils';

function createUndefinedEntity<R, O>(
  exp: Expression<R, O>,
  entityId: UUID,
  msg: string
) {
  // user is looking for an entity that doesn't exist - this can happen with
  // rows in the Path tab if the entity was deleted.
  exp.errors.push(`Syntax Error: ${msg}`);
  t3dev().log.error(`Entity ${entityId} ${msg}`);
  return new mathjs.Node();
}

function resolveEntityName(
  itpr: Interpreter,
  exp: Expression,
  mathNodeName: string,
  entityId: UUID,
  entityType: ModelSchemaCategories
): math.MathNode | void {
  const currentEntity = lookupEntity(itpr.state, entityType, entityId);
  if (!currentEntity)
    return createUndefinedEntity(
      exp,
      entityId,
      `${mathNodeName} does not exist`
    );

  const [endpointEntity, endpointType] = lookupEndpointEntity(
    itpr.state,
    currentEntity,
    mathNodeName
  );
  if (!endpointEntity || !endpointType)
    return createUndefinedEntity(
      exp,
      entityId,
      `${mathNodeName} does not exist`
    );
  const endpointPrefix = entToPrefix(endpointType);
  const endpointRow = getEntityRow(
    itpr.mappedTableSort,
    endpointEntity,
    endpointType
  );

  const nameFunctionCall = new mathjs.FunctionNode(
    new mathjs.SymbolNode('getName'),
    [new mathjs.SymbolNode(endpointPrefix + '_' + endpointRow)]
  );

  // Adding only the Node Name Cell as a dependency
  const startRef = coordsToRef(endpointType, endpointRow, 0);
  const start = itpr.grab(startRef);
  if (exp.deps && start) {
    exp.deps.addDependency(start);
  }

  return nameFunctionCall;
}

function resolveEntity<R, O>(
  itpr: Interpreter<R, O>,
  exp: Expression<R, O>,
  mathNodeName: string,
  entityId: UUID,
  entityType: ModelSchemaCategories
): math.MathNode {
  const currentEntity = lookupEntity(itpr.state, entityType, entityId);
  if (!currentEntity)
    return createUndefinedEntity(exp, entityId, 'entity does not exist');

  const [endpointEntity, endpointType] = lookupEndpointEntity(
    itpr.state,
    currentEntity,
    mathNodeName
  );
  if (!endpointEntity || !endpointType)
    return createUndefinedEntity(
      exp,
      entityId,
      `${mathNodeName} does not exist`
    );

  const endpointRow = getEntityRow(
    itpr.mappedTableSort,
    endpointEntity,
    endpointType
  );
  const endpointPrefix = entToPrefix(endpointType);
  if (!endpointPrefix)
    return createUndefinedEntity(
      exp,
      endpointEntity.id,
      `${mathNodeName} has invalid prefix`
    );

  const entityFunctionCall = new mathjs.FunctionNode(
    new mathjs.SymbolNode('entity'),
    [new mathjs.SymbolNode(endpointPrefix + '_' + endpointRow)]
  );

  let colcount = itpr.state.tableSort.schemas?.[endpointType]?.length ?? -1;

  const startRef = coordsToRef(endpointType, endpointRow, 0);
  const endRef = coordsToRef(endpointType, endpointRow, colcount);
  const start = itpr.grab(startRef);
  const end = itpr.grab(endRef);
  if (exp.deps && start && end) {
    exp.deps.addDepRange(start, end);
  }

  return entityFunctionCall;
}

/**
 * The parser mainly runs a preparsed string through MathJS's parser
 * then passes it on to the evaluator.
 *
 * During parsing we support the `source` and `target` tokens for edges and
 * paths - these are replaced with corresonding calls to
 * entity('n__{node-row-number}').
 *
 * Here we also convert all Cell References to their lower-case, fully qualified
 * version (IE A1 -> n_a1)
 */
export function parser<Results, Options>(
  itpr: Interpreter<Results, Options>
): Interpreter<Results, Options> {
  const cacheState = itpr.dependencyCache.frozen;

  itpr.expressionList.forEach((exp) => {
    if (itpr.dependencyCache.frozen && itpr?.modified?.includes(exp.ref)) {
      itpr.dependencyCache.thaw();
      // no need to clear, preparser did it
    }
    // if this is plain text, skip parsing
    if (exp.isPlain) {
      exp.parsed = new mathjs.ConstantNode(exp.preparsed);
    } else {
      // parse the expression
      try {
        exp.parsed = parse(exp.preparsed.slice(1).trim());
      } catch (e: any) {
        exp.errors.push(`Syntax Error: ${e.message}`);
        t3dev().log.error('EXPRESSION SYNTAX ERROR', e.message);
        return;
      }

      exp.parsed = exp.parsed.transform((mathNode) => {
        if (!mathjs.isSymbolNode(mathNode)) return mathNode;

        // Replace source, target with an object that has the Name property
        if (isSourceTargetType(mathNode.name)) {
          return resolveEntityName(
            itpr,
            exp,
            mathNode.name,
            exp.ids.parentId,
            exp.ent
          );
        } else if (isTripleType(mathNode.name)) {
          const currentEntity = lookupEntity(
            itpr.state,
            exp.ent,
            exp.ids.parentId
          );
          if (isNone(currentEntity)) return mathNode;
          if (isModelPath(currentEntity)) {
            const arrayNode = new mathjs.ArrayNode(
              currentEntity.triples.map((tripple) => {
                return new mathjs.ObjectNode({
                  source: resolveEntity(
                    itpr,
                    exp,
                    'source',
                    tripple.source,
                    'nodes'
                  ),
                  edge: resolveEntity(itpr, exp, 'edge', tripple.edge, 'edges'),
                  target: resolveEntity(
                    itpr,
                    exp,
                    'target',
                    tripple.target,
                    'nodes'
                  ),
                });
              })
            );
            return arrayNode;
          } else {
            return mathNode;
          }
        }

        // qualify any relative reference
        // B4 becomes n_b4 on Nodes tab, e_b4 on Edges, etc
        const dep = itpr.grab(mathNode.name, exp.ent);
        if (isNone(dep)) return mathNode;
        if (exp.deps) {
          exp.deps.addDependency(dep);
        }

        // we need to remember if the reference started as a fully-qualified or
        // as a relative reference; at the end of the whole shebang we need to
        // keep relative references relative, and fully-qualified references
        // fully-qualified
        if (dep.ref === mathNode.name) return mathNode;
        const modifiedNode: InjectedNode = new mathjs.SymbolNode(dep.ref);
        modifiedNode.refData = itpr.refData(
          mathNode.name,
          exp.ent
        ) as ReferenceData;
        exp.localCellReferences.push(modifiedNode);
        return modifiedNode;
      });
    }

    exp.status = PARSED;
    // if cache started frozen, refreeze
    itpr.dependencyCache.frozen = cacheState;
  });

  return itpr;
}
