import { AnyMetraMathNode, ModelReducer, ScriptScope } from 'types';
import { getInterpreter, isMetraNode } from './helpers';
import { mathImport, validateStyle } from './t3math';
import { t3dev } from 't3dev';

// NOTE: KEEP this commented out code so we can update validate style alter
//
// const propStyleMap = {
//   [SHAPE.NODE]: {
//     color: 'color',
//     location: 'pos',
//     opacity: 'alpha',
//     scale: 'scale',
//     shape: 'asset',
//   },
//   [SHAPE.EDGE]: {
//     arrowhead: 'arrowhead',
//     opacity: 'alpha',
//     size: 'width',
//     stroke: 'asset',
//   },
//   [SHAPE.SET]: { color: 'color', opacity: 'alpha' },
// } as const;

// const validateStyle = (
//   id: UUID,
//   style: Record<string, Partial<ShapeConfig>>,
//   model: ModelReducer
// ) => {
//   const validStyle = {};
//   const shapeType = (
//     model.sets[id] ? SHAPE.SET : model.shapes[id].type
//   ) as MetraConstantValues<'shape', 'node' | 'edge' | 'set'>;

//   const propMap = propStyleMap[shapeType];

//   Object.forEach(style, ([prop, value]) => {
//     // const value = style[prop];

//     if (!(prop in propMap)) {
//       throw new Error(`invalid property for updateStyle: ${prop}`);
//     }

//     const validProp = propMap[prop];
//     if (!validProp) {
//       throw new Error(`invalid property for updateStyle: ${prop}`);
//     }

//     if (prop === 'shape') {
//       const shapeMap = {
//         circle: 'default',
//         triangle: 'triangle',
//         square: 'square',
//       };
//       const validShape = shapeMap[value];
//       if (!validShape) {
//         throw new Error(`invalid shape type for updateStyle: ${value}`);
//       }

//       validStyle[validProp] = validShape;
//     }

//     if (prop === 'stroke') {
//       const strokeMap = {
//         solid: 'solidLine',
//         dotted: 'sqDashLine',
//         'short-dash': 'shortDashLine',
//         'long-dash': 'longDashLine',
//         'dot-dash': 'sqLongDashLine',
//       };

//       const validStroke = strokeMap[value];
//       if (!validStroke) {
//         throw new Error(`invalid stroke type for updateStyle: ${value}`);
//       }

//       validStyle[validProp] = validStroke;
//     }

//     if (prop === 'arrowhead') {
//       const arrowheadMap = {
//         curved: 'curvedArrowhead',
//         filled: 'filledArrow',
//         unfilled: 'unfilledArrow',
//         dot: 'filledDot',
//         'dot-arrow': 'curvedArrowheadFilledDot',
//         'dot-plus': 'circlePlus',
//         diamond: 'filledDiamond',
//         'unfilled-diamond': 'unfilledDiamond',
//       };
//       const validArrowhead = arrowheadMap[value];
//       if (!validArrowhead) {
//         throw new Error(`invalid arrowhead type for updateStyle: ${value}`);
//       }

//       validStyle[validProp] = validArrowhead;
//     }

//     if (prop === 'color') {
//       if (!`${value}`.match(/^#[0-9a-fA-F]{6}$/)) {
//         throw new Error(`invalid color hash for updateStyle: ${value}`);
//       }

//       validStyle[validProp] = value;
//     }

//     if (prop === 'location') {
//       if (!isNumber(value.x)) {
//         throw new Error(`invalid x location value for updateStyle: ${value.x}`);
//       }
//       if (!isNumber(value.y)) {
//         throw new Error(`invalid y location value for updateStyle: ${value.y}`);
//       }

//       const x = clamp(Number(value.x), -Number.MAX_VALUE, Number.MAX_VALUE);
//       const y = clamp(Number(value.y), -Number.MAX_VALUE, Number.MAX_VALUE);

//       validStyle[validProp] = new Vector2(x, y);
//     }

//     if (prop === 'opacity') {
//       if (!isNumber(value)) {
//         throw new Error(`invalid opacity value for updateStyle: ${value}`);
//       }

//       const validOpacity = Math.min(1, Math.max(0, Number(value)));
//       validStyle[validProp] = validOpacity;
//     }

//     if (prop === 'scale') {
//       if (!isNumber(value)) {
//         throw new Error(`invalid scale value for updateStyle: ${value}`);
//       }

//       const validScale = clamp(
//         Number(value),
//         SIZING.NODE.MIN_SCALE,
//         SIZING.NODE.MAX_SCALE
//       );
//       validStyle[validProp] = new Vector2(validScale, validScale);
//     }

//     if (prop === 'size') {
//       if (!isNumber(value)) {
//         throw new Error(`invalid size value for updateStyle ${value}`);
//       }

//       const validSize = clamp(
//         Math.round(value),
//         SIZING.EDGE.WIDTH.MIN,
//         SIZING.EDGE.WIDTH.MAX
//       );
//       validStyle[validProp] = validSize;
//     }
//   });

//   return validStyle;
// };

export function updateStyle(
  args: [mathNode: AnyMetraMathNode],
  _math: math.MathJsStatic,
  scope: ScriptScope<math.MathNode>
) {
  if (scope.get('isMetraScript')) {
    throw new Error('updateStyle() cannot be called from within a script');
  }

  if (!args[0]) {
    throw new Error('updateStyle() must take a map of shape properties');
  }
  const itpr = getInterpreter<ModelReducer, any>();
  const [mathNode] = args;
  if (!isMetraNode(mathNode)) return;
  const { root } = mathNode;
  const exp = itpr.getExp(root);
  if (!exp) return;
  if (['modelProps', 'modelCalcs'].includes(exp.ent)) {
    throw new Error(
      'updateStyle() does not apply to model properties or calculations'
    );
  }
  const style = mathNode.evaluate(scope);

  const id = exp.ids.parentId;

  const validStyle = validateStyle(id, style, itpr.state);

  if (!itpr.results) {
    itpr.results = {} as ModelReducer;
  }
  if (!itpr.results.shapes) {
    itpr.results.shapes = {};
  }
  itpr.results.shapes[id] = { ...itpr.results.shapes?.[id], ...validStyle };
}
updateStyle.rawArgs = true;
mathImport({ updateStyle });
