/*eslint no-console: "off" */
import { throttle } from 'lodash';
import {
  MetraDispatch,
  ShapeConfig,
  TimeLog,
  ModelImage,
  GetStateFunc,
  RootReducer,
  ModelReducer,
  PrunedModel,
  ModelDrawing,
  ModelText,
} from 'types';
import {
  ANDROID_OS,
  I_OS,
  LINUX_OS,
  MAC_OS,
  SHAPE,
  WINDOWS_OS,
} from './constants-extra';
import { t3dev } from 't3dev';
import { pruneSchema } from 'modules/model/schema/selectors';
import { pruneSheets } from 'modules/model/sheets/selectors';

/**
Returns the first item in an iterable, if it exists.
Useful for quickly getting the first item of a Set, without spreading it into an array
**/
export function getFirst<T>(obj: Iterable<T>): Option<T> {
  for (const item of obj) {
    return item;
  }
}

/**
Returns whether an object is empty in O(1) time.
Better than `Object.values().length` which is O(n)
**/
export function isEmpty(obj: Record<string, any>) {
  if (obj == null) return true;

  for (const _key in obj) {
    return false;
  }

  return true;
}

export function logError(err: any, msg: string, meta?: any): void {
  if (err instanceof Error) {
    let full = `\n|> NAME: ${err.name}\n|> MESSAGE: ${err.message}\n|> CAUSE: ${err.cause}`;
    if (meta) {
      const end = '\n|> FULL (click to expand):\n';
      t3dev().log.error(msg, full, '\n|> METADATA:', meta, end, err);
    } else {
      full += '\n|> FULL (click to expand):\n';
      t3dev().log.error(msg, full, err);
    }
  } else {
    t3dev().log.error(msg, err, meta);
  }
}

/**
 * getOS returns the string of an OS
 *
 * @deprecated the use of navigator.platform is deprecated and can be removed anytime
 * @returns string
 */
export const getOS = (): OSType => {
  var userAgent = window.navigator.userAgent,
    platform = window.navigator.platform,
    macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
    windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
    iosPlatforms = ['iPhone', 'iPad', 'iPod'];
  let os!: OSType;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = MAC_OS;
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = I_OS;
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = WINDOWS_OS;
  } else if (/Android/.test(userAgent)) {
    os = ANDROID_OS;
  } else if (!os && /Linux/.test(platform)) {
    os = LINUX_OS;
  }

  return os;
};

/**
 * Test if the user is using a Mac, primarily to test control and meta keys on keydown
 *
 * @returns boolean
 */
export function getIsMac() {
  // userAgentData has no support in firefox and safari
  // and typescript complains because it is experimental
  const platform = (navigator as any).userAgentData?.platform?.toLowerCase();
  let result = false;

  if (platform) {
    result = platform.indexOf('mac') >= 0;
  } else {
    // it must be firefox or safari, fallback to userAgent
    // again, this is not full proof, but its the best we can do
    // we can invest in https://www.npmjs.com/package/ua-parser-js and get a commercial license
    // for better matching... searching for "macintosh" will return desktop operating system
    // we don't care about mobile devices at this time and if we do in the future I suggest using
    // something like the ua-parser package, which has been battle tested with all possible ua strings
    // there are a gazillion of them.
    result = navigator.userAgent.toLowerCase().indexOf('macintosh') >= 0;
  }

  return result;
}

type BuildOsModifierReturnValue = {
  os: 'Mac' | 'Non-Mac';
  long: 'Command' | 'Control'; // For human-facing views.
  short: 'Cmd' | 'Ctrl'; // For human-facing views.
  modifier: 'metaKey' | 'ctrlKey'; // For event property inspection.
};

/**
 * Builds a modifier key object based on whether or not the user is using a mac
 *
 * @returns object
 */
export function buildOsModifier(): BuildOsModifierReturnValue {
  if (getIsMac()) {
    return {
      os: 'Mac',
      long: 'Command',
      short: 'Cmd',
      modifier: 'metaKey',
    };
  } else {
    return {
      os: 'Non-Mac',
      long: 'Control',
      short: 'Ctrl',
      modifier: 'ctrlKey',
    };
  }
}

/**
 * gets the modifier key that was pressed. handles mac and non-mac users
 *
 * @param event
 * @returns
 */
export function getIsModifierKeyPressed(
  event:
    | React.MouseEvent<Element, MouseEvent>
    | React.KeyboardEvent<Element>
    | KeyboardEvent
) {
  const osModifierKey = buildOsModifier();

  return event[osModifierKey.modifier];
}

/**
 * globally available get state
 */
export let globalGetState: GetStateFunc<RootReducer> = () => null as any;
export const setGlobalGetState = <S = RootReducer>(
  getState: GetStateFunc<S>
) => {
  globalGetState = getState as GetStateFunc<RootReducer>;
};

/**
 * remove an element from an array,
 * @param arr - array to remove element from
 * @param val - value to be removed from the array
 */
export function removeFromArray<T>(arr: T[], val: T): void {
  const i = arr.indexOf(val);
  i > -1 && arr.splice(i, 1);
}

/**
 * returns a count of the data entity type.
 * @param  data - Parameter description.
 * @returns  count of entity type
 */
export function getCount<T>(data: Record<PropertyKey, T>): number {
  return Object.values(data).length;
}

/**
 * Returns all image shapes from object of shapes
 * @param shapes - shapes records
 * @param key - key to be used for new object
 * @return object of image shapes
 */
export function getImageShapes(
  shapes: Record<UUID, ShapeConfig>,
  key: keyof ShapeConfig = 'id'
): Record<UUID, ModelImage> {
  const imageEntries = Object.values(shapes)
    .filter((shape) => shape.type === SHAPE.BACKGROUND)
    .map((shape) => [key in shape ? shape[key] : shape.id, shape]);

  return Object.fromEntries(imageEntries);
}

/**
 * Returns all drawing shapes from object of shapes
 * @param shapes - shapes records
 * @param key - key to be used for new object
 * @return object of drawing shapes
 */
export function getDrawingShapes(
  shapes: Record<UUID, ShapeConfig>,
  key: keyof ShapeConfig = 'id'
): Record<UUID, ModelDrawing> {
  const drawingEntries = Object.values(shapes)
    .filter((shape) => shape.type === SHAPE.DRAWING)
    .map((shape) => [key in shape ? shape[key] : shape.id, shape]);

  return Object.fromEntries(drawingEntries);
}

/**
 * Returns all text shapes from object of shapes
 * @param shapes - shapes records
 * @param key - key to be used for new object
 * @return object of text shapes
 */
export function getTextShapes(
  shapes: Record<UUID, ShapeConfig>,
  key: keyof ShapeConfig = 'id'
): Record<UUID, ModelText> {
  const textEntries = Object.values(shapes)
    .filter((shape) => shape.type === SHAPE.TEXT)
    .map((shape) => [key in shape ? shape[key] : shape.id, shape]);

  return Object.fromEntries(textEntries);
}

/**
 * for debugging purposes, log to console with a number of miliseconds since last call
 * @param notToExceed - only log messages that exceed this value in miliseconds
 * @param [options] function to use instead of `console.log`
 */
export function makeTimeLog(
  notToExceed = 0,
  options: {
    logFunc?: typeof console.log;
    throttleTime?: number;
    override?: boolean;
  } = {}
): TimeLog {
  let { logFunc, throttleTime, override } = options;
  logFunc ||= console.log;
  throttleTime ||= 100;
  override ||= false;
  // return a void function if debug tools are not enabled for this environment
  if (!t3dev().enabled && !t3dev().testing && !override) {
    const voidFunc = () => false;
    voidFunc.start = () => undefined;
    voidFunc.avg = () => -1;
    return voidFunc;
  }
  let iterations = 0;
  let lastTimeRun: number = performance.now();
  let _notToExceed = notToExceed;
  let printed = false;
  let delta = 0;
  let now = 0;
  let avg = 0;

  function average(): number {
    return avg;
  }

  function start(newNotToExceed = notToExceed): void {
    lastTimeRun = performance.now();
    _notToExceed = newNotToExceed;
  }

  const innerLog = throttle(logFunc, throttleTime);

  // attempt to log if `notToExceed` time is exceeded,
  function timelog(...args: any[]): boolean {
    printed = false;
    now = performance.now();
    delta = now - lastTimeRun;
    avg = iterations > 0 ? avg + (delta - avg) / iterations : 0;
    if (delta >= _notToExceed) {
      innerLog(`(${delta}ms | ${avg}ms avg)[${iterations}]:`, ...args);
      printed = true;
      iterations = 0;
    }
    lastTimeRun = now;
    iterations++;
    return printed;
  }

  timelog.avg = average;
  timelog.start = start;
  return timelog;
}

export let dispatcher!: MetraDispatch;
export const setDispatcher = (dispatch: MetraDispatch): void => {
  dispatcher = dispatch as any;
};

// /**
//  *
//  * @param file
//  * @returns the file name string
//  */
// export const _getFileName = (file: File | MediaEntry): string => {
//   if (isMediaEntry(file)) {
//     return file.name;
//   }
//   if (file.path) {
//     return file.path;
//   }

//   if (file.webkitRelativePath === undefined || file.webkitRelativePath === '') {
//     return file.filepath ? file.filepath : file.name;
//   }
//   return file.webkitRelativePath;
// };

/**
 * integer-to-column-alpha.
 * @param int - integer to convert.
 * @returns a character representing that int.
 */
export const intToAlphaColumn = (int: number): string => {
  let returnString = '';
  let modulus;
  int++;

  while (int > 0) {
    modulus = (int - 1) % 26;
    returnString = String.fromCharCode(65 + modulus) + returnString;
    int = Math.trunc((int - modulus) / 26);
  }

  return returnString;
};

/**
 * makes a label using the type and data length.
 * @param type - string of type to create.
 * @param data - data used to count which the next # should be.
 * @returns  the new label.
 */
export const makeLabel = <T>(
  type: string,
  data: AsRecord<T>,
  skipCount: number = 0
): string => {
  return `${type}${getCount(data) + (1 + skipCount)}`;
};

/**
 * @param type - string of type to create.
 * @param data - data used to count which the next # should be.
 */
export const makeLetterLabel = <T>(
  type: string,
  data: AsRecord<T>,
  skipCount: number = 0
): string => `${type} ${intToAlphaColumn(getCount(data) + (1 + skipCount))}`;

/**
 *
 * @param str
 * @returns
 */
export const alphaColumnToInt = (str: string): number => {
  const codes = [...str]
    .map((c) => c.toUpperCase().charCodeAt(0) - 64)
    .reverse();

  let result = -1;
  codes.forEach((c, i) => {
    result += c * 26 ** i;
  });

  return result;
};

export function pruneModelForSave(modelState: ModelReducer): PrunedModel {
  if (!modelState || !Object.keys(modelState).length) return modelState;

  const filteredShapes: Record<UUID, ShapeConfig> = {};
  for (let uuid in modelState.shapes) {
    if (!('ephemeral' in modelState.shapes[uuid])) {
      filteredShapes[uuid] = modelState.shapes[uuid];
    }
  }

  const pruned: PrunedModel = {
    gridSettings: modelState.gridSettings,
    base: {
      version: modelState.base.version,
      allSetsAlpha: modelState.base.allSetsAlpha,
      allNodesAlpha: modelState.base.allNodesAlpha,
      allEdgesAlpha: modelState.base.allEdgesAlpha,
      collapsed: modelState.base.collapsed,
      recentColors: modelState.base.recentColors,
      hiddenSetColors: modelState.base.hiddenSetColors,
      showNodeLabels: modelState.base.showNodeLabels,
      showEdgeLabels: modelState.base.showEdgeLabels,
      filterAlpha: modelState.base.filterAlpha,
      selectionEmphasis: modelState.base.selectionEmphasis,
      labelSize: modelState.base.labelSize,
      darkMode: modelState.base.darkMode,
      filterImages: modelState.base.filterImages,
      filterOverlay: modelState.base.filterOverlay,
    },
    edges: modelState.edges,
    edgeColumns: modelState.edgeColumns,
    expressions: {
      errors: modelState.expressions.errors,
      values: modelState.expressions.values,
    },
    filters: modelState.filters,
    filterSort: modelState.filterSort,
    modelProps: modelState.modelProps,
    modelCalcs: modelState.modelCalcs,
    nodes: modelState.nodes,
    notes: modelState.notes,
    paths: modelState.paths,
    propSizes: modelState.propSizes,
    propSchemas: modelState.propSchemas,
    propValues: modelState.propValues,
    sets: modelState.sets,
    shapes: filteredShapes,
    tableSort: modelState.tableSort,
    modelLinks: {
      ids: modelState.modelLinks.ids,
      notes: modelState.modelLinks.notes,
    },
  };

  const schema = pruneSchema(modelState.schema);
  if (schema != null) pruned.schema = schema;

  const sheets = pruneSheets(modelState.sheets);
  if (sheets != null) pruned.sheets = sheets;

  return pruned;
}

export function pruneModelForHistory(model: ModelReducer) {
  const prunedModel = pruneModelForSave(model);

  return {
    ...prunedModel,
    base: {
      ...prunedModel.base,
      selected: {
        ...model.base.selected,
      },
    },
  };
}

export function deepFreeze(obj: any) {
  Object.freeze(obj);
  if (obj == null) {
    return obj;
  }

  Object.getOwnPropertyNames(obj).forEach(function (prop) {
    if (
      obj[prop] != null &&
      (typeof obj[prop] === 'object' || typeof obj[prop] === 'function') &&
      !Object.isFrozen(obj[prop])
    ) {
      deepFreeze(obj[prop]);
    }
  });

  return obj;
}
