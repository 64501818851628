import { createAction } from '@reduxjs/toolkit';
import { gidMaker } from '../gid';
import { ActionFn, MetraActionFunc, ThunkActionFunc } from 'types';

// TYPES ------------------
export type UndoableSignature = ReturnType<typeof wrapperAction>;

type UndoableActionFn<T extends any[], R> = (
  ...args: T
) => UndoableActionReturnType<T, R>;

export interface UndoableActionReturnType<T extends any[], R>
  extends UndoableSignature {
  payload: {
    name: string;
    args: T;
    undoable: boolean;
  };
}

export interface UndoableAction<T extends any[], R>
  extends UndoableActionFn<T, R> {
  undoable: (...args: T) => ReturnType<typeof wrapperAction>;
  action: ActionFn<T, R>;
  id: string;
}

interface UndoableActionMaker {
  <T extends any[], R>(actionFn: ThunkActionFunc<T, R>): UndoableAction<T, R>;
  <T extends any[], R>(actionFn: MetraActionFunc<T, R>): UndoableAction<T, R>;
}
// -----------------------

// Ensure all functions are keyed by a unique ID
// Important so that functions with the same name don't collide
const { gid } = gidMaker({ prefix: 'fn' });

const undoableRegistry: Record<string, UndoableAction<any, any>> = {};

/** Used by middleware to look up an action by its ID
 * This is the same ID passed as `name` in the action blob when dispatched
 **/
export const getUndoableFn = (name: string) => {
  return undoableRegistry[name] as Option<UndoableAction<any, any>>;
};

/** The official wrapper action that is dispatched
 * This will be picked up by the undoable middleware and
 * Used to look up and call the original function
 **/
export const wrapperAction = createAction<{
  name: string;
  args: any[];
  undoable: boolean;
}>('UNDOABLE_WRAPPER');

let signatures: { undo: UndoableSignature[]; redo: UndoableSignature[] } = {
  undo: [],
  redo: [],
};

/** Used by the middleware to receive the undo+redo signatures
 * from an action after it has been called. This is how we get
 * the signatures created from `onUndo` and `onRedo`
 **/
export const consumeHistorySignatures = () => {
  const previous = signatures;
  signatures = { undo: [], redo: [] };
  return previous;
};

/** Used within an undoable action to define the
 * actions to be performed on undo. Any number of
 * undoable actions can be passed in as args, like so:
 * ```typescript
 * onUndo(someUndoable(123), anotherUndoable(456));
 * ```
 **/
export const onUndo = (...args: UndoableSignature[]) => {
  signatures.undo = [...signatures.undo, ...args];
};

/** Used within an undoable action to define the
 * actions to be performed on redo. Any number of
 * undoable actions can be passed in as args, like so:
 * ```typescript
 * onRedo(someUndoable(123), anotherUndoable(456));
 * ```
 **/
export const onRedo = (...args: UndoableSignature[]) => {
  signatures.redo = [...signatures.redo, ...args];
};

/** Wrapper to turn any redux action or thunk into an undoable action.
 * 
 *  Use:
 * ```typescript
    import { undoableAction, onUndo, onRedo } from 'modules/model/history/undoable-action';

    const myAction = undoableAction(function _myAction(foo: number) {
      return (dispatch, getState) => {
        // do my action
    
        onUndo(someUndoableAction(123), otherAction(456));
        onRedo(myAction(foo));
      };
    });

    // Dispatches the action without creating history entry
    dispatch(myAction(123));

    // Dispatches the action and creates an undo/redo history entry
    dispatch(myAction.undoable(123));
 * ```
 **/
export const undoableAction: UndoableActionMaker = function undoableAction<
  T extends any[],
  R
>(actionFn: ActionFn<T, R>) {
  const uniqueId = gid();
  const name = actionFn.name;
  const id = `${uniqueId}_${name}`;
  const wrappers = {
    [`${name}(non-undoable)`]: (...args: T) => {
      return wrapperAction({ name: id, args, undoable: false });
    },

    [`${name}(undoable)`]: (...args: T) => {
      return wrapperAction({ name: id, args, undoable: true });
    },
  };

  const nonUndoable = wrappers[`${name}(non-undoable)`] as Partial<
    UndoableAction<T, R>
  >;
  nonUndoable.undoable = wrappers[`${name}(undoable)`];
  nonUndoable.id = id;
  nonUndoable.action = actionFn;

  undoableRegistry[id] = nonUndoable as UndoableAction<T, R>;

  return nonUndoable as UndoableAction<T, R>;
};
