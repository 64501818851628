import type { Middleware } from 'redux';
import type { RootReducer } from 'types';
import {
  wrapperAction,
  getUndoableFn,
  consumeHistorySignatures,
} from 'modules/model/history/undoable-action';
import { createHistory } from 'modules/model/history/actions';
import { t3dev } from 't3dev';

export const undoableMiddleware: Middleware<{}, RootReducer> =
  (store) => (next) => (action) => {
    if (wrapperAction.match(action)) {
      const fn = getUndoableFn(action.payload.name);

      if (!fn) {
        throw new Error(
          `undoable action '${action.payload.name}' not found in registry. This should never happen`
        );
      }

      const args = action.payload.args;
      const retv = next(fn.action(...args) as any);
      const signatures = consumeHistorySignatures();

      if (action.payload.undoable) {
        const undo = signatures.undo.map((sig) => sig.payload);
        const redo = signatures.redo.map((sig) => sig.payload);

        if (undo.length < 1) {
          t3dev().log.error(
            `Action '${fn.id}' called as undoable, but it has not defined any onUndo() actions. This history entry will not be recorded.`
          );
        }

        if (redo.length < 1) {
          t3dev().log.error(
            `Action '${fn.id}' called as undoable, but it has not defined any onRedo() actions. This history entry will not be recorded.`
          );
        }

        if (redo.length > 0 && undo.length > 0) {
          store.dispatch(
            createHistory({
              undo: signatures.undo.map((sig) => sig.payload),
              redo: signatures.redo.map((sig) => sig.payload),
            })
          );
        }
      }

      return retv;
    }

    return next(action);
  };
