import type { RootReducer } from 'types';
import type { PrunedSheetsReducerState, SheetsReducerState } from './types';

export const pruneSheets = (state: SheetsReducerState) => {
  if (!state) return;

  const pruned = {} as PrunedSheetsReducerState;
  // return the sheets state only if there is at least one sheet
  // otherwise save space in the JSON by returning nothing
  if (state.ids.length > 0) {
    pruned.byId = state.byId;
    pruned.ids = state.ids;

    // return everything except .selected, which is session-only
    return pruned;
  }

  return;
};

export function getSheetRows(sheet: string) {
  return (state: RootReducer) => {
    return state.modelReducer.sheets.byId[sheet].rows;
  };
}

export function getSheetColumns(sheet: string) {
  return (state: RootReducer) => {
    return state.modelReducer.sheets.byId[sheet].columns;
  };
}

export function getSheetEntries(sheet: string) {
  return (state: RootReducer) => {
    return state.modelReducer.sheets.byId[sheet].entries;
  };
}

export function getFirstRowEntries(sheetId: string) {
  return (state: RootReducer) => {
    const sheet = state.modelReducer.sheets.byId[sheetId];
    return sheet.entries[sheet.rows[0]];
  };
}

export function getSheetValues(sheet: string) {
  return (state: RootReducer) => {
    return state.modelReducer.sheets.byId[sheet].values;
  };
}

export function getSheetSelectionsBySheetId(sheetId: string) {
  return (state: RootReducer) => {
    return state.modelReducer.sheets.selected[sheetId];
  };
}

export function getSheetSelectedRowsBySheetId(sheet: string) {
  return (state: RootReducer) => {
    const sheetSelections = getSheetSelectionsBySheetId(sheet)(state);

    return sheetSelections?.byId;
  };
}

export function getSheetRowsLength(sheet: string) {
  return (state: RootReducer) => {
    return state.modelReducer.sheets.byId[sheet].rows.length;
  };
}

export function getSheetColumnsLength(sheet: string) {
  return (state: RootReducer) => {
    return state.modelReducer.sheets.byId[sheet].columns.length;
  };
}

export function isCellSelected(props: {
  sheet: string;
  row: string;
  column: string;
}) {
  const { sheet, row, column } = props;
  return (state: RootReducer) => {
    return (
      state.modelReducer.sheets.selected[sheet]?.byId[row]?.[column] || false
    );
  };
}

export function getAnchor(sheet: string) {
  return (state: RootReducer) => {
    const selected = state.modelReducer.sheets.selected[sheet];
    if (selected == null) return null;
    const current = selected.ranges[selected.ranges.length - 1];
    if (current == null) return null;
    const anchor = current[0];
    return anchor;
  };
}

export function isCellAnchor(props: {
  sheet: string;
  row: string;
  column: string;
}) {
  const { sheet, row, column } = props;
  return (state: RootReducer) => {
    const anchor = getAnchor(sheet)(state);
    if (anchor == null) return false;
    return anchor[0] == row && anchor[1] == column;
  };
}
