import { ModelReducer } from 'types';
import { initialBaseModelState } from './base/initial-state';
import {
  initialExpressionState,
  initialPropSelectState,
  initialPropSizesState,
} from './properties';
import { initialModelFilesState } from './files';
import { initialTableFilterState } from './table/filter/initial-state';
import { initialTableSortState } from './table/sort';
import { initialTableViewState } from './table/view';
import { initialTableSettingsState } from './table/settings';
import { initialUrlFlyoutState } from './urlFlyout';
import { initialMptContextMenuState } from './mptContextMenu';
import { initialMptPopoutEditorState } from './mptPopoutEditor';
import { initialMptRearrangeState } from './mptRearrange';
import { initialHotkeyMapState } from './hotkeyMap';
import { initialSearchResultState } from './modelSearchResults';
import { initialNotesEditorState } from './notesEditor/reducer';
import { initialEdgeColumnsState } from './edgeColumns';
import { initialModelLinksState } from './modelLinks';
import { initialGridSettingsState } from './gridSettings';
import { initialState as schema } from './schema/reducer';
import { initialState as sheets } from './sheets/reducer';
import { initialState as history } from './history/reducer';

export const initialModelState: ModelReducer = {
  base: initialBaseModelState,
  edgeColumns: initialEdgeColumnsState,
  edges: {},
  expressions: initialExpressionState,
  files: initialModelFilesState,
  filters: {},
  filterSort: [],
  gridSettings: initialGridSettingsState,
  history,
  hotkeyMap: initialHotkeyMapState,
  modelCalcs: {},
  modelLinks: initialModelLinksState,
  modelProps: {},
  mptContextMenu: initialMptContextMenuState,
  mptPopoutEditor: initialMptPopoutEditorState,
  mptRearrange: initialMptRearrangeState,
  nodes: {},
  notes: [],
  notesEditor: initialNotesEditorState,
  paths: {},
  propSchemas: {},
  propSelect: initialPropSelectState,
  propSizes: initialPropSizesState,
  propValues: {},
  quickPropertyViewers: {},
  schema,
  scripts: {},
  searchResults: initialSearchResultState,
  sets: {},
  shapes: {},
  sheets,
  tableFilter: initialTableFilterState,
  tableSettings: initialTableSettingsState,
  tableSort: initialTableSortState,
  tableView: initialTableViewState,
  urlFlyout: initialUrlFlyoutState,
};
