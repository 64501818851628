import { Reducer } from 'redux';
import { SEARCH, isPayloadAction } from 'modules/common';
import { MetraAction, SearchReducer } from 'types';

export const initialSearchState: SearchReducer = {
  searchField: '',
  searchText: '',
  displaySearchResults: false,
  pagination: {
    count: 0,
    numberOfRows: 0,
    rowCount: 0,
    startIndex: 0,
    total: 0,
  },
  expressionEnabled: false,
};
/*
 * search-spec reducer. The search spec stores a user-defined search
 * consisting of a field to search in and text to search for.
 */
export const searchReducer: Reducer<
  SearchReducer,
  MetraAction<Partial<SearchReducer>, any, void>
> = (state = initialSearchState, action): SearchReducer => {
  if (!isPayloadAction(action)) {
    return state;
  }

  switch (action.type) {
    case SEARCH.CLEAR:
    case SEARCH.DISPLAY:
    case SEARCH.EXPRESSION_ENABLED:
    case SEARCH.FIELD:
    case SEARCH.TEXT: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case SEARCH.TAG: {
      const tags: string[] = [];
      if (state.searchField === 'tags') {
        state.searchText
          .split(',')
          .map((tagText) => tagText.trim())
          .filter((tagText) => tagText !== '')
          .forEach((tagText) => tags.push(tagText));
      }
      if (!tags.includes(action.payload.searchText as string)) {
        tags.push(action.payload.searchText as string);
      }
      return {
        ...state,
        searchField: 'tags',
        searchText: tags.join(', '),
      };
    }
    case SEARCH.UPDATE.PAGINATION: {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload.pagination,
        },
      };
    }
    case SEARCH.UPDATE.NUMBER_OF_ROWS: {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload,
        },
      };
    }
    default:
      break;
  }
  return state;
};
