import { RECENTS, isPayloadAction } from 'modules/common';
import { MetraAction, RecentsReducer } from 'types';

export const initialRecentsState: RecentsReducer = {
  files: {
    pagination: {
      count: 0,
      numberOfRows: 50,
      rowCount: 0,
      startIndex: 0,
      total: 0,
    },
  },
  models: {
    pagination: {
      count: 0,
      numberOfRows: 50,
      rowCount: 0,
      startIndex: 0,
      total: 0,
    },
  },
};

export const recentsReducer = (
  state = initialRecentsState,
  action: MetraAction<Partial<RecentsReducer>, any, void>
): RecentsReducer => {
  if (!isPayloadAction(action)) {
    return state;
  }

  switch (action.type) {
    case RECENTS.UPDATE.FILES.PAGINATION: {
      return {
        ...state,
        files: {
          pagination: {
            ...state.files.pagination,
            ...action.payload,
          },
        },
      };
    }
    case RECENTS.UPDATE.MODELS.PAGINATION: {
      return {
        ...state,
        models: {
          pagination: {
            ...state.models.pagination,
            ...action.payload,
          },
        },
      };
    }
    default:
      break;
  }
  return state;
};
