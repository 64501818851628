import cloneDeep from 'clone-deep';
import { removeFromArray } from 'utils/utils-extra';
import { GRID, SHAPE } from 'utils/constants-extra';
import { propValuesUpdateReducer } from '../properties';
import {
  ModelEdge,
  ModelProp,
  ModelPropertySchema,
  ModelPropertyValueUpdate,
  ModelReducer,
  ModelSet,
  ShapeConfig,
  ModelCalc,
  ModelImage,
  ModelNode,
  NodeConfig,
  EdgeConfig,
  DrawingConfig,
  TextConfig,
} from 'types';
import { isNode } from 'modules/model/shape/shape-helpers';

/**
 * manages the deletion of edges from edge and node reducers
 * @param state - modelState.
 * @param edges - edges being deleted.
 * @returns updated state.
 */
export const handleEdgeDelete = (
  state: ModelReducer,
  edges: ModelEdge[]
): ModelReducer => {
  const newState = cloneDeep(state);

  edges.forEach((edge) => {
    //for each deleted edge, we need to update the attached nodes, removing this
    //edge from them

    //update in-node
    const inNode = { ...newState.nodes[edge.inNodeId] };
    inNode.edgeIds = inNode.edgeIds.filter((edgeId) => edgeId !== edge.id);
    newState.nodes[inNode.id] = inNode;
    const inShape = { ...newState.shapes[inNode.id] };
    if (isNode(inShape)) {
      inShape.edges = inNode.edgeIds;
      newState.shapes[inShape.id] = inShape;
    }

    //update out-node
    const outNode = { ...newState.nodes[edge.outNodeId] };
    outNode.edgeIds = outNode.edgeIds.filter((edgeId) => edgeId !== edge.id);
    newState.nodes[outNode.id] = outNode;
    const outShape = { ...newState.shapes[outNode.id] };
    if (isNode(outShape)) {
      outShape.edges = outNode.edgeIds;
      newState.shapes[outShape.id] = outShape;
    }

    // remove this edge from its sets
    edge.setIds.forEach((setId) => {
      const set = newState.sets[setId];
      set.edgeIds = set.edgeIds.filter((edgeId) => edgeId !== edge.id);
      // clean up empty sets
      if (set.nodeIds.length < 1 && set.edgeIds.length < 1) {
        delete newState.sets[setId];
        delete newState.propValues[setId];
        delete newState.expressions.values[setId];
        delete newState.expressions.errors[setId];

        newState.tableSort.entities.sets = [
          ...newState.tableSort.entities.sets.filter((id) => id !== setId),
        ];
      }
    });

    // prune edge sorts
    newState.tableSort.entities.edges =
      newState.tableSort.entities.edges.filter((id) => id !== edge.id);

    // remove edge from any categories and associated sheet data
    for (const catId in newState.schema?.categories.byId || {}) {
      const category = newState.schema.categories.byId[catId];
      if (category.membersById[edge.id]) {
        delete category.membersById[edge.id];
        category.members = category.members.filter((m) => m !== edge.id);

        const sheet = newState.sheets.byId[category.sheet];
        if (!sheet) continue;
        sheet.rows = sheet.rows.filter((r) => r !== edge.id);
        const entry = sheet.entries[edge.id] || {};
        delete sheet.entries[edge.id];
        for (const colId in entry) {
          delete sheet.values[entry[colId]];
        }
      }
    }

    //remove property data, shape, and then delete the edge
    delete newState.expressions.errors[edge.id];
    delete newState.expressions.values[edge.id];
    delete newState.propValues[edge.id];
    delete newState.shapes[edge.id];
    delete newState.edges[edge.id];
  });

  return newState;
};

export const handleModelPropDelete = (
  state: ModelReducer,
  modelProps: ModelProp[]
) => {
  const newState = cloneDeep(state);
  modelProps.forEach((modelProp) => {
    const id = modelProp.id;
    removeFromArray(newState.tableSort.entities.modelProps, id);
    delete newState.expressions.errors[id];
    delete newState.expressions.values[id];
    delete newState.propValues[id];
    delete newState.modelProps[id];
  });
  return newState;
};

export const handleModelCalcDelete = (
  state: ModelReducer,
  modelCalcs: ModelCalc[]
) => {
  const newState = cloneDeep(state);
  modelCalcs.forEach((modelCalc) => {
    const id = modelCalc.id;
    removeFromArray(newState.tableSort.entities.modelCalcs, id);
    delete newState.expressions.errors[id];
    delete newState.expressions.values[id];
    delete newState.propValues[id];
    delete newState.modelCalcs[id];
  });
  return newState;
};

/**
 * manages the deletion of nodes from node and edge reducers
 * @param state - modelState.
 * @param nodes - nodes being deleted.
 * @returns updated state.
 */
export const handleNodeDelete = (state: ModelReducer, nodes: ModelNode[]) => {
  let newState = cloneDeep(state);

  const edgeIds = new Set<UUID>();
  nodes.forEach((node) => node.edgeIds.forEach((id) => edgeIds.add(id)));
  const edges = [...edgeIds]
    .map((id) => newState.edges[id])
    .filter((edge) => edge); // remove undefined edges (already deleted)
  newState = handleEdgeDelete(newState, edges);

  // prune edge sorts
  newState.tableSort.entities.edges = newState.tableSort.entities.edges.filter(
    (id) => !edgeIds.has(id)
  );

  nodes.forEach((node) => {
    // remove this node from its sets
    node.setIds.forEach((setId) => {
      const set = newState.sets[setId];
      if (set && set.nodeIds) {
        set.nodeIds = set.nodeIds.filter((nodeId) => nodeId !== node.id);
        // clean up empty sets
        if (set.nodeIds.length < 1 && set.edgeIds.length < 1) {
          delete newState.sets[setId];
          delete newState.propValues[setId];
          delete newState.expressions.values[setId];
          delete newState.expressions.errors[setId];

          const collapsedSetShapeId = newState.base.collapsed.sets[setId];
          if (collapsedSetShapeId) {
            delete newState.base.collapsed.sets[setId];
            delete newState.base.collapsed.shapes[collapsedSetShapeId];
            delete newState.shapes[collapsedSetShapeId];
          }

          newState.tableSort.entities.sets = [
            ...newState.tableSort.entities.sets.filter((id) => id !== setId),
          ];
        }
      }
    });

    // prune node sorts
    newState.tableSort.entities.nodes =
      newState.tableSort.entities.nodes.filter((id) => id !== node.id);

    // remove node from any categories and associated sheet data
    for (const catId in newState.schema?.categories.byId || {}) {
      const category = newState.schema.categories.byId[catId];
      if (category.membersById[node.id]) {
        delete category.membersById[node.id];
        category.members = category.members.filter((m) => m !== node.id);

        const sheet = newState.sheets.byId[category.sheet];
        if (!sheet) continue;
        sheet.rows = sheet.rows.filter((r) => r !== node.id);
        const entry = sheet.entries[node.id] || {};
        delete sheet.entries[node.id];
        for (const colId in entry) {
          delete sheet.values[entry[colId]];
        }
      }
    }

    //remove any property data, shape, and then the node
    delete newState.expressions.errors[node.id];
    delete newState.expressions.values[node.id];
    delete newState.propValues[node.id];
    delete newState.shapes[node.id];
    delete newState.nodes[node.id];
  });

  return newState;
};

export const handleDrawingDelete = (
  state: ModelReducer,
  drawings: DrawingConfig[]
) => {
  let newState = cloneDeep(state);

  drawings.forEach((drawing) => {
    delete newState.shapes[drawing.id];
  });

  return newState;
};

export const handleTextShapesDelete = (
  state: ModelReducer,
  textShapes: TextConfig[]
) => {
  let newState = cloneDeep(state);

  textShapes.forEach((text) => {
    delete newState.shapes[text.id];
  });

  return newState;
};

/**
 * manages the deletion of paths from reducers
 * @param state - modelState.
 * @param pathIds - paths being deleted.
 * @returns updated state.
 */
export const handlePathDelete = (state: ModelReducer, pathIds: UUID[]) => {
  let newState = cloneDeep(state);

  pathIds.forEach((pathId) => {
    // prune path sorts
    newState.tableSort.entities.paths =
      newState.tableSort.entities.paths.filter((id) => id !== pathId);

    //remove any property data
    delete newState.expressions.errors[pathId];
    delete newState.expressions.values[pathId];
    delete newState.propValues[pathId];
    delete newState.paths[pathId];
  });

  return newState;
};

export const handleSetDelete = (state: ModelReducer, sets: ModelSet[]) => {
  const newState = cloneDeep(state);

  // remove this set from its nodes' and edges' data
  sets.forEach((set) => {
    set.nodeIds
      .map((nid) => newState.nodes[nid])
      .forEach((node) => {
        node.setIds = node.setIds.filter((sid) => sid !== set.id);
      });

    set.edgeIds
      .map((eid) => newState.edges[eid])
      .forEach((edge) => {
        edge.setIds = edge.setIds.filter((sid) => sid !== set.id);
      });

    // Prune set sorts
    newState.tableSort.entities.sets = newState.tableSort.entities.sets.filter(
      (id) => id !== set.id
    );

    // Remove any property data, and then the set itself
    delete newState.expressions.errors[set.id];
    delete newState.expressions.values[set.id];
    delete newState.propValues[set.id];
    delete newState.sets[set.id];
  });

  return newState;
};

/**
 * manages the deletion of schemas from schema and value property reducers
 * @param  state - modelState.
 * @param  schemas - schemas being deleted.
 * @returns  updated state.
 */
export const handleSchemaDelete = (
  state: ModelReducer,
  schemas: ModelPropertySchema[]
) => {
  const newState = cloneDeep(state);

  schemas.forEach((schema) => {
    Object.values(newState[schema.category]).forEach((entity) => {
      //remove the value entry from the entity's property values and calculation data
      [
        newState.propValues,
        newState.expressions.values,
        newState.expressions.errors,
      ].forEach((struct) => {
        const values = struct[entity.id];
        if (values) {
          struct[entity.id] = { ...values };
          delete struct[entity.id][schema.id];
        }
      });
    });

    // if nodes and edges were displaying this
    // prop as their label, revert their label back to their Name
    for (const uuid in newState.shapes) {
      const shape = newState.shapes[uuid];
      if (shape.type === SHAPE.NODE) {
        const node = shape as NodeConfig;
        if (node.labelContent === schema.id) {
          node.labelContent = GRID.NAME_COLUMN.NODES;
        }
      } else if (shape.type === SHAPE.EDGE) {
        const edge = shape as EdgeConfig;
        if (edge.labelContent === schema.id) {
          edge.labelContent = GRID.NAME_COLUMN.EDGES;
        }
      }
    }

    // prune schema sorts
    newState.tableSort.schemas[schema.category] = newState.tableSort.schemas[
      schema.category
    ].filter((id) => id !== schema.id);

    //delete the schema
    delete newState.propSchemas[schema.id];
  });

  return newState;
};

export const handleBackgroundImageDelete = (
  state: ModelReducer,
  images: ModelImage[]
) => {
  const newState = cloneDeep(state);
  images.forEach((image) => {
    delete newState.shapes[image.id];
    newState.tableSort.entities.nodes = [
      ...newState.tableSort.entities.nodes.filter((id) => id !== image.id),
    ];
  });

  return newState;
};

/**
 * manages the deletion of shapes from shape, node, and edge reducers
 * @param state - modelState.
 * @param shapes - shapes being deleted.
 * @returns updated state.
 */
export const handlePixiDelete = (
  state: ModelReducer,
  shapes: ShapeConfig[]
) => {
  let newState = { ...state };
  newState.shapes = { ...state.shapes };

  //determine which shapes are edges, nodes, or ephemeral shapes like selectors
  const edges = shapes
    .filter((shape) => shape.type === SHAPE.EDGE)
    .map((shape) => state.edges[shape.id]);
  const nodes = shapes
    .filter((shape) => shape.type === SHAPE.NODE)
    .map((shape) => state.nodes[shape.id]);
  const other = shapes.filter(
    (shape) => shape.type !== SHAPE.NODE && shape.type !== SHAPE.EDGE
  );
  //always delete edges before nodes
  newState = handleEdgeDelete(newState, edges);
  newState = handleNodeDelete(newState, nodes);

  other.forEach((shape) => {
    //delete the selector
    delete newState.shapes[shape.id];
  });

  return newState;
};

export const handlePropertyUpdate = (
  state: ModelReducer,
  values: ModelPropertyValueUpdate[]
) => {
  let newState = { ...state };
  newState.propValues = propValuesUpdateReducer(state.propValues, values);
  return newState;
};
