import { EDGE_COLUMNS } from 'utils/constants';
import { Reducer } from 'redux';
import { EdgeColumns, MetraAction, ModelPropertySchema, UUID } from 'types';
import { isPayloadAction } from 'modules/common';
import { v4 as uuid } from 'uuid';

export const sourceUUID = uuid();
export const targetUUID = uuid();

export const initialEdgeColumnsState = {
  source: sourceUUID,
  target: targetUUID,
};

export const initEdgeColumns = (edgeColumns: EdgeColumns) => {
  return {
    type: EDGE_COLUMNS.INIT,
    payload: edgeColumns,
  };
};

/*******************
 * Reducer
 *******************/

export const edgeColumnsReducer: Reducer<
  EdgeColumns,
  MetraAction<any, any, any>
> = (state = initialEdgeColumnsState, action) => {
  if (!isPayloadAction(action)) {
    return state;
  }
  switch (action.type) {
    case EDGE_COLUMNS.INIT:
      return action.payload;
    default:
      return state;
  }
};
