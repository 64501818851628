import { ARCHIVE } from 'modules/common';
import difference from 'lodash/difference';
import { getIdsOnPage } from 'utils/utils';

export const getSelectedOnPage = (state, collection) => {
  const { selected, pagination } = state.archiveReducer;
  const ids = state.collectionReducer[collection]?.ids || [];
  const fileIdsOnPage = getIdsOnPage(ids, pagination);
  return fileIdsOnPage.filter((element) => selected.includes(element));
};

export const handleSelected = (file, collection) => (dispatch, getState) => {
  const state = getState();
  const selected = state.archiveReducer.selected
    ? state.archiveReducer.selected
    : [];
  let selectedCp = [...selected];
  const fileIndex = selectedCp.indexOf(file.id);
  const fileNotPreviouslySelected = fileIndex === -1;
  fileNotPreviouslySelected
    ? selectedCp.push(file.id)
    : selectedCp.splice(fileIndex, 1);
  dispatch(setArchivedSelected(selectedCp, collection));
};

export const setArchivedSelected =
  (toBeSelectedIds, collection) => async (dispatch, getState) => {
    await dispatch({
      type: ARCHIVE.UPDATE.SELECTED,
      payload: toBeSelectedIds,
    });
    const state = getState();
    const { pagination, selected } = state.archiveReducer;
    const ids = state.collectionReducer[collection]?.ids || [];
    dispatch(setSelectedIdNotOnPage(ids, pagination, selected));
  };

export const setSelectedIdNotOnPage =
  (ids, pagination, selectedIds) => (dispatch) => {
    const selectedIdNotOnPage =
      difference(selectedIds, getIdsOnPage(ids, pagination)).length > 0;
    dispatch({
      type: ARCHIVE.SELECTED_ID_NOT_ON_PAGE,
      payload: selectedIdNotOnPage,
    });
  };

/**
 * Selects all archives displayed within the parameters of the paginated users table
 */
export const selectPage = (collection) => (dispatch, getState) => {
  const state = getState();
  if (getPageIsSelected(state, collection)) {
    dispatch(resetSelected(collection));
  } else {
    const { pagination, selected } = state.archiveReducer;

    const ids = state.collectionReducer[collection]?.ids || [];
    const itemsOnPage = getIdsOnPage(ids, pagination, collection);
    const unselectedIdsOnPage = itemsOnPage.filter(
      (id) => !selected.includes(id)
    );
    dispatch(
      setArchivedSelected(unselectedIdsOnPage.concat(selected), collection)
    );
  }
};

export const resetSelected = (collection) => async (dispatch, getState) => {
  await dispatch({
    type: ARCHIVE.SELECT_NONE,
  });
  const state = getState();
  const { pagination, selected } = state.archiveReducer;

  const ids = state.collectionReducer[collection]?.ids ?? [];
  dispatch(setSelectedIdNotOnPage(ids, pagination, selected, collection));
};

export const getPageIsSelected = (state, collection) => {
  let pagination = state.archiveReducer.pagination;
  let ids = state.collectionReducer[collection]?.ids || [];

  return (
    getIdsOnPage(ids, pagination, collection).length ===
    getSelectedOnPage(state, collection).length
  );
};

/**
 * Selects all archived files displayed within the parameters of the paginated table
 */
export const selectAllArchived = (collection) => (dispatch, getState) => {
  const state = getState();
  if (getPageIsSelected(state, collection)) {
    dispatch(resetSelected(collection));
  } else {
    const { pagination, selected } = state.archiveReducer;

    const ids = state.collectionReducer[collection]?.ids || [];
    const archivedOnPage = getIdsOnPage(ids, pagination);
    const unselectedArchivedOnPage = archivedOnPage.filter(
      (fileId) => !selected.includes(fileId)
    );
    dispatch(
      setArchivedSelected(unselectedArchivedOnPage.concat(selected), collection)
    );
  }
};
