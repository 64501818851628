import { isPayloadAction } from 'modules/common';
import { GUILD } from 'modules/common/constants';
import { Reducer } from 'redux';
import { GuildReducer, MetraAction } from 'types';

export const SCHEMA_NAME = 'guilds';

export const initialState: GuildReducer = {
  guildId: null,
  loaded: false,
  error: false,
};

export const guildReducer: Reducer<GuildReducer, MetraAction<any, any, any>> = (
  state = initialState,
  action
): GuildReducer => {
  if (!isPayloadAction(action)) return state;
  switch (action.type) {
    case GUILD.SET_GUILD:
      return {
        ...state,
        ...action.payload,
      };
    case GUILD.SET_LOADED:
      // don't bother to set it to the same thing
      if (state.loaded === action.payload.loaded) return state;
      return {
        ...state,
        ...action.payload,
      };
    case GUILD.GET_FAILURE:
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
};

export default guildReducer;
