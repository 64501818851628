/*eslint no-extend-native: "off" */
import cloneDeep from 'clone-deep';
import { isNone } from 'helpers/utils';

const SetSymbol: unique symbol = Symbol('set');
const ObjSymbol: unique symbol = Symbol('object');
const PromiseSymbol: unique symbol = Symbol('promise');

declare global {
  interface Promise<T> {
    [PromiseSymbol]: true;
  }

  interface PromiseConstructor {
    isPromise<U, T extends Promise<U>>(value: any | T): value is Promise<U>;
  }

  interface Set<T> {
    [SetSymbol]: true;
    addAll(arr: T[]): Set<T>;
    concat(arr: T[]): Set<T>;
    map<V>(
      callback: (item: T, index: number, arr: readonly T[]) => V,
      thisArg?: any
    ): V[];
    reduce<V>(
      callback: (acc: V, item: T, index: number, arr: readonly T[]) => V,
      init: V
    ): V;
  }

  interface SetConstructor {
    isSet<T>(value: any | Set<T>): value is Set<T>;
  }

  interface Array<T> {
    /**
     * a mutative variant of `concat`
     */
    add(values: T[]): T[];
    /**
     * a mutative variant of `push`
     */
    addItem(value: T): T[];
    clone(): T[];
    insert(values: T[], index: number): T[];
    insertItem(value: T, index: number): T[];
    findReplace(
      value: T,
      predicate: (value: T, index: number, obj: T[]) => unknown,
      thisArg?: any
    ): Option<T>;
    first(): T;
    last(): T;
    remove(value: T): Option<T>;
    removeAt(index: number): Option<T>;
    removeBy(
      compare: (value: T, index: number, arr: readonly T[]) => boolean
    ): Option<T>;
    replace(oldValue: T, newValue: T): Option<T>;
    replaceAt(value: T, index: number): Option<T>;
    take(num: number, startIndex?: number): T[];
    zip<U>(values: U[]): [T, U][];
  }

  interface Object {
    [ObjSymbol]: true;
  }

  interface ObjectConstructor {
    add<T extends RecordOf<T>>(obj: T, key: KeyOf<T>, value: ValueOf<T>): T;
    clone<T>(obj: T): T;
    deepMerge<T extends AnyObject<T>>(a: T, b: DeepOption<T>): T;
    filter<T extends RecordOf<T>>(
      obj: T,
      callback: (
        item: KeyValuePair<T>,
        index: number,
        arr: KeyValuePair<T>[]
      ) => boolean,
      thisArg?: any
    ): T;
    find<T extends RecordOf<T>>(
      obj: T,
      callback: (
        item: KeyValuePair<T>,
        index: number,
        arr: KeyValuePair<T>[]
      ) => boolean,
      thisArg?: any
    ): Option<KeyValuePair<T>>;
    forEach<T extends RecordOf<T>>(
      obj: T,
      callback: (
        item: KeyValuePair<T>,
        index: number,
        arr: readonly KeyValuePair<T>[]
      ) => void,
      thisArg?: any
    ): void;
    isObject<T = Object>(value: any | T): value is T;
    isRecord<T extends RecordOf<T>>(value: any | T): value is RecordOf<T>;
    map<Returns, T extends RecordOf<T>>(
      obj: T,
      callback: (
        item: KeyValuePair<T>,
        index: number,
        arr: readonly KeyValuePair<T>[],
        thisArg?: any
      ) => Returns
    ): Returns[];
    merge<A extends Object, B extends Object>(a: A, b: B): A & B;
    overlay<A>(a: A, b: A | Partial<A>): A;
    reduce<Returns, T extends RecordOf<T>>(
      obj: T,
      callback: (
        acc: Returns,
        item: KeyValuePair<T>,
        index: number,
        arr: readonly KeyValuePair<T>[]
      ) => Returns,
      init: Returns
    ): Returns;
  }
}

Promise.prototype[PromiseSymbol] = true;
Promise.isPromise = function <U, T extends Promise<U>>(
  value: any | T
): value is Promise<U> {
  if (!!value && PromiseSymbol in value && value?.[PromiseSymbol]) return true;
  return false;
};

Set.prototype[SetSymbol] = true;
Set.prototype.addAll = function <T>(arr: T[]): Set<T> {
  for (let i = 0; i < arr.length; i++) {
    this.add(arr[i]);
  }
  return this;
};
Set.prototype.concat = function <T>(arr: T[]): Set<T> {
  return new Set<T>(Array.from(this.values()).concat(arr));
};
Set.prototype.map = function <T, V>(
  callback: (item: T, index: number, arr: readonly T[]) => V,
  thisArg?: any
): V[] {
  return Array.from(this.values()).map(callback, thisArg);
};
Set.prototype.reduce = function <T, V>(
  callback: (acc: V, item: T, index: number, arr: readonly T[]) => V,
  init: V
): V {
  return Array.from(this.values()).reduce(callback, init);
};

Set.isSet = function <T>(value: any | Set<T>): value is Set<T> {
  if (Object.isObject(value) && SetSymbol in value && value?.[SetSymbol])
    return true;
  return false;
};

/**
 * a mutative variant of `concat`
 */
Array.prototype.add = function <T>(values: T[]): T[] {
  this.splice(this.length, 0, ...values);
  return this;
};

/**
 * a mutative variant of `push`
 */
Array.prototype.addItem = function <T>(value: T): T[] {
  this.splice(this.length, 0, value);
  return this;
};

Array.prototype.clone = function () {
  return [].concat(this as any);
};

Array.prototype.findReplace = function <T>(
  value: T,
  predicate: (value: T, index: number, obj: T[]) => unknown,
  thisArg?: any
): Option<T> {
  const index = this.findIndex(predicate, thisArg);
  if (index === -1) return null;
  return this.replaceAt(value, index);
};

Array.prototype.first = function <T>(): T {
  return this.at(0);
};

Array.prototype.last = function <T>(): T {
  return this.at(-1);
};

Array.prototype.insert = function <T>(values: T[], index: number): T[] {
  this.splice(index, 0, ...values);
  return this;
};

Array.prototype.insertItem = function <T>(value: T, index: number): T[] {
  this.splice(index, 0, ...[value]);
  return this;
};

Array.prototype.remove = function <T>(value: T): Option<T> {
  const index = this.indexOf(value);
  if (index === -1) return null;
  const oldValue = this.removeAt(index);
  return oldValue;
};

Array.prototype.removeAt = function <T>(index: number): Option<T> {
  const removedValues = this.splice(index, 1);
  return removedValues[0];
};

Array.prototype.removeBy = function <T>(
  compare: (value: T, index: number, arr: readonly T[]) => boolean
): Option<T> {
  const index = this.findIndex(compare, this);

  if (index >= 0) {
    return this.removeAt(index);
  } else {
    return null;
  }
};

Array.prototype.replaceAt = function <T>(value: T, index: number): Option<T> {
  const oldValues = this.splice(index, 1, value);
  return oldValues[0];
};

Array.prototype.replace = function <T>(oldValue: T, newValue: T): Option<T> {
  const index = this.indexOf(oldValue);
  if (index === -1) return null;
  return this.replaceAt(newValue, index);
};

Array.prototype.take = function <T>(num: number, startIndex = 0): T[] {
  return this.slice(startIndex, num);
};

Array.prototype.zip = function <T, U>(values: U[]): [T, U][] {
  if (this.length > values.length)
    throw new Error('array `values` must at least as long as this array');
  return this.map((item, index) => [item, values[index]]);
};

Object.prototype[ObjSymbol] = true;

Object.add = function <T extends RecordOf<T>>(
  obj: T,
  key: KeyOf<T>,
  value: ValueOf<T>
): T {
  obj[key] = value;
  return obj;
};

// create a fall-back in case structuredClone is not defined (i.e., it should be)
const _cloneFunc = window.structuredClone ? window.structuredClone : cloneDeep;

Object.clone = function <T>(obj: T): T {
  let result!: T;

  // just in-case the structured clone fails, clone-deep usually will succeed;
  // NOTE: any functions attached to the object that are non-attomic, will be
  // lost
  try {
    result = _cloneFunc(obj as T);
  } catch {
    result = cloneDeep(obj as T);
  } finally {
    return result as any;
  }
};

Object.deepMerge = function <T extends AnyObject<T>>(
  objA: T,
  objB: DeepOption<T>
): T {
  if (objA !== undefined) {
    if (Array.isArray(objB)) {
      if (Array.isArray(objA)) {
        return objA.concat(objB) as T;
      } else {
        return objB as T;
      }
    } else if (Set.isSet(objB)) {
      if (Set.isSet(objA)) {
        objB.forEach((value) => Set.isSet(objA) && objA.add(value as T));
        return objA;
      } else {
        return objB as T;
      }
    } else if (Object.isRecord(objB)) {
      if (Object.isRecord(objA)) {
        const state = Object.reduce(
          objA,
          (state, [key, value]) => {
            if (key in state) {
              // const val = (state as RecordOf<any>)[key as KeyOf<any>];
              const val = state[key];
              const mergedVal = Object.deepMerge(
                value,
                val as DeepOption<ValueOf<T>>
              );
              Object.add(state as T, key, mergedVal);
            } else {
              Object.add(state as T, key, value);
            }
            return state;
            // use a shallow-clone for speed
          },
          { ...objB }
        );
        return { ...objA, ...state };
      } else {
        return objB as T;
      }
    } else if (typeof objB === 'string') {
      return objB;
    } else if (typeof objB === 'number') {
      return objB;
    } else {
      if (objB !== undefined) {
        return objB;
      } else {
        return objA;
      }
    }
  } else if (objB !== undefined) {
    return objB as T;
  } else {
    return undefined as unknown as T;
  }
};

Object.filter = function <T extends RecordOf<T>>(
  obj: T,
  callback: (
    item: KeyValuePair<T>,
    index: number,
    arr: KeyValuePair<T>[]
  ) => boolean,
  thisArg?: any
): T {
  if (isNone(obj)) return obj;
  const filtered = (
    Object.entries<T>(Object.clone(obj as any)) as KeyValuePair<T>[]
  ).filter(callback, thisArg);
  return Object.fromEntries(filtered) as T;
};

Object.find = function <T extends RecordOf<T>>(
  obj: T,
  callback: (
    item: KeyValuePair<T>,
    index: number,
    arr: KeyValuePair<T>[]
  ) => boolean,
  thisArg?: any
): Option<KeyValuePair<T>> {
  return (
    Object.entries<T>(Object.clone(obj as any)) as KeyValuePair<T>[]
  ).find(callback, thisArg);
};

Object.forEach = function <T extends RecordOf<T>>(
  obj: T,
  callback: (
    item: KeyValuePair<T>,
    index: number,
    arr: readonly KeyValuePair<T>[]
  ) => void,
  thisArg?: any
): void {
  (Object.entries<T>(Object.clone(obj as any)) as KeyValuePair<T>[]).forEach(
    callback,
    thisArg
  );
};

Object.isObject = function <T = Object>(value: any | T): value is T {
  if (value === undefined || value === null) return false;
  if (value instanceof Object && ObjSymbol in value && value[ObjSymbol])
    return true;
  if (typeof value === 'object') return true;
  return false;
};

Object.isRecord = <T extends RecordOf<T>>(
  value: any | T
): value is RecordOf<T> => {
  if (Object.isObject(value) && Object.keys(value).length) return true;
  return false;
};

Object.merge = function merge<A extends Object, B extends Object>(
  a: A,
  b: B
): A & B {
  Object.assign(a, b);
  return a as A & B;
};

Object.map = function <Returns, T extends RecordOf<T>>(
  obj: T,
  callback: (
    item: KeyValuePair<T>,
    index: number,
    arr: KeyValuePair<T>[],
    thisArg?: any
  ) => Returns
): Returns[] {
  return (Object.entries<T>(Object.clone(obj as any)) as KeyValuePair<T>[]).map(
    callback
  );
};

Object.overlay = function overlay<A>(a: A, b: A | Partial<A>): A {
  Object.forEach(b as A, ([k, v]) => {
    a[k] = v;
  });
  return a;
};

Object.reduce = function <Returns, T extends RecordOf<T>>(
  obj: T,
  callback: (
    acc: Returns,
    item: KeyValuePair<T>,
    index: number,
    arr: readonly KeyValuePair<T>[]
  ) => Returns,
  init: Returns
): Returns {
  if (!obj) return init;
  return (
    Object.entries<T>(Object.clone(obj as any)) as KeyValuePair<T>[]
  ).reduce<Returns>(callback, init);
};

/**
 * check if a given static or class method is a polyfill extension
 */
export function isExtension(symbol: any): boolean {
  switch (symbol) {
    case Promise.isPromise:
    case Set.isSet:
    case Set.prototype.addAll:
    case Set.prototype.concat:
    case Set.prototype.map:
    case Set.prototype.reduce:
    case Array.prototype.add:
    case Array.prototype.addItem:
    case Array.prototype.clone:
    case Array.prototype.findReplace:
    case Array.prototype.first:
    case Array.prototype.last:
    case Array.prototype.insert:
    case Array.prototype.insertItem:
    case Array.prototype.remove:
    case Array.prototype.removeAt:
    case Array.prototype.removeBy:
    case Array.prototype.replace:
    case Array.prototype.replaceAt:
    case Array.prototype.take:
    case Array.prototype.zip:
    case Object.add:
    case Object.clone:
    case Object.deepMerge:
    case Object.filter:
    case Object.find:
    case Object.forEach:
    case Object.isObject:
    case Object.isRecord:
    case Object.map:
    case Object.merge:
    case Object.overlay:
    case Object.reduce:
      return true;
    default:
      return false;
  }
}

// if (FeatureFlags.DEBUG_TOOLS) {
//   window.__dbg = {};
// }
