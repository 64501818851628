import { isSome } from 'helpers/utils';
import { MPTCell, RootReducer } from 'types';

export function getEntity(state: RootReducer, id: string) {
  const model = state.modelReducer;

  const entity =
    model.nodes[id] ||
    model.edges[id] ||
    model.sets[id] ||
    model.modelProps[id] ||
    model.modelCalcs[id] ||
    model.propSchemas[id] ||
    model.paths[id];

  return entity;
}

export function getType(state: RootReducer, id: string) {
  const model = state.modelReducer;

  const type: string =
    (model.nodes[id] && 'nodes') ||
    (model.edges[id] && 'edges') ||
    (model.sets[id] && 'sets') ||
    (model.modelCalcs[id] && 'modelCalcs') ||
    (model.modelProps[id] && 'modelProps') ||
    (model.paths[id] && 'paths') ||
    'unknown';

  return type;
}

export function isGraphShape(type: string) {
  return type === 'nodes' || type === 'edges' || type === 'sets';
}

export function isFilteredOut(state: RootReducer, id: string) {
  const filter = state.modelReducer.tableFilter.entities;
  return filter.length > 0 && !filter.includes(id);
}

export const nameSchemas: Record<string, string> = {
  nodes: 'name/column/NODES',
  edges: 'name/column/EDGES',
  sets: 'name/column/SETS',
  modelProps: 'name/column/MODEL_PROPS',
  modelCalcs: 'name/column/MODEL_CALCS',
  paths: 'name/column/PATHS',
};

export const nameRows: Record<string, string> = {
  nodes: 'name/row/NODES',
  edges: 'name/row/EDGES',
  sets: 'name/row/SETS',
  modelProps: 'name/row/MODEL_PROPS',
  modelCalcs: 'name/row/MODEL_CALCS',
  paths: 'name/row/PATHS',
};

export function getSearchDomain(state: RootReducer) {
  const model = state.modelReducer;

  const ids = Object.keys(model.nodes)
    .concat(Object.keys(model.edges))
    .concat(Object.keys(model.sets))
    .concat(Object.keys(model.modelProps))
    .concat(Object.keys(model.modelCalcs))
    .concat(Object.keys(model.paths));

  const domain: Record<string, MPTCell[]> = {};

  ids.forEach((id) => {
    const type = getType(state, id);
    if (isGraphShape(type) && isFilteredOut(state, id)) return;

    const row: MPTCell[] = [];
    domain[id] = row;

    // add name column for this row
    row.push([type, id, nameSchemas[type]]);

    // add any prop values.
    // to save memory, we ignore columns with no prop value
    const values = model.propValues[id];
    if (isSome(values)) {
      for (const sid in values) {
        const category = model.propSchemas[sid].category;
        row.push([category, id, sid]);
      }
    }
  });

  // add a row for all schema labels
  const schemas = Object.keys(model.propSchemas);
  const row: MPTCell[] = [];
  domain['header-labels'] = row;
  schemas.forEach((id) => {
    const category = model.propSchemas[id].category;
    row.push([category, nameRows[category], id]);
  });

  return domain;
}
