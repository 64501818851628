import cloneDeep from 'lodash/cloneDeep';
import { MODEL, PROPERTY_SELECT } from 'utils/constants';
import { compareArrays } from 'utils/utils';

/**
 * UTILTIES
 */

let lastSelector;
let lastState;

export const selectorState = (state) => {
  return {};
};

export const selector = (state) => {
  if (lastSelector) {
    if (
      lastState.modelReducer.tableView === state.modelReducer.tableView &&
      lastState.modelReducer.propSelect === state.modelReducer.propSelect
    ) {
      return lastSelector;
    }
  }

  lastState = state;
  const {
    tableView: { type, rows, cols },
    propSelect: { byCoords, byIds, byCells, shiftAnchor, editing, bySchemaIds },
  } = state.modelReducer;

  const selection = {
    shiftAnchor,
    editing,
    byIds,
    byCoords,
    byCells,
    bySchemaIds,
    offset: {
      row: rows[type],
      col: cols[type],
    },
  };

  /**
   * determine a cell is selected by entity & schema ids.
   */
  selection.findByIds = (rowId, columnId) => {
    return !!selection.byIds[`${rowId}@${columnId}`];
  };

  /**
   * return a set of cells based on entity & schema ids.
   */
  selection.allByIds = () => {
    return Object.keys(selection.byIds).map((key) => [type, ...key.split('@')]);
  };

  /**
   * return a set of cells that match the given rowId/entityId
   */
  selection.getRowById = (rowId) => {
    return selection.allByIds().filter((cell) => cell[1] === rowId);
  };

  /**
   * test to see if a cell is selected by row & column number
   */
  selection.findByCoords = (row, column) => {
    return selection.byCoords[row] && selection.byCoords[row][column];
  };

  /**
   * return a set of cells by type, row and column [type, row, column]
   */
  selection.all = () => {
    const allPairs = [];
    Object.entries(selection.byCoords).forEach((entry) => {
      const [row, cols] = entry;
      for (const col in cols) {
        allPairs.push([type, row, col]);
      }
    });
    return allPairs;
  };

  selection.length = Object.keys(selection.byIds).length;

  lastSelector = selection;
  return selection;
};

/*******************
 * Action Types
 *******************/

/*******************
 * Action Creators
 *******************/

/**
 * selection = [
 *  ['tabType', 'rowId', 'columnId'],
 *  ['tabType', 'rowId', 'columnId']
 * ]
 */
const propertyCreator =
  (action) =>
  (selection = []) => ({
    type: action,
    payload: selection,
  });

/**
 * Adds a new selection of cells to list of selections
 * @return {import('types/common').MetraActionFunc<[string[]], string[]>}
 */
export const selectCells = propertyCreator(PROPERTY_SELECT.SET.CELLS);

/**
 * Removes the given cells from all selections
 * @param {Array} cells - array of deselected cell selections.
 * @param {Object} ids - selections byId to deselect.
 * @param {Object} coords - selections byCoords to deselect.
 */
export const removeSelection = (
  deselection = { cells: [], ids: {}, coords: {} }
) => ({
  type: PROPERTY_SELECT.REMOVE,
  payload: deselection,
});

/**
 * Clears all selections
 */
export const clearSelection = () => (dispatch) => {
  return dispatch({ type: PROPERTY_SELECT.CLEAR });
};

/**
 * Sets the cells in the active (IE most recent) selection
 * @param {Array} cells - array of active cell selections.
 * @param {Object} ids - selections byId to select.
 * @param {Object} coords - selections byCoords to select.
 */
export const setActiveSelection = (
  selection = { cells: [], tableItems: { entities: [], schemas: [] } }
) => ({
  type: PROPERTY_SELECT.SET.ACTIVE,
  payload: selection,
});

export const updateCoords = () => (dispatch, getState) => {
  const { base, propSelect } = getState().modelReducer;
  const { tableItems } = base;
  const { byCells } = propSelect;

  const [coords] = mapCellsToCoordsAndIds(byCells, tableItems);
  dispatch(setCoords(coords));
};

export const mapCellsToCoordsAndIds = (cells, tableItems) => {
  const coords = {};
  const ids = {};
  cells.forEach((cell) => {
    cell.forEach(([type, rowId, columnId]) => {
      const row = tableItems.entities[type].indexOf(rowId) + 1;
      const col = tableItems.schemas[type].indexOf(columnId) + 1;
      if (row === -1 || col === -1) {
        // dont allow invalid cells to be selected
        return;
      }
      ids[`${rowId}@${columnId}`] = true;
      coords[row] = { ...coords[row], [col]: true };
    });
  });
  return [coords, ids];
};

export const getSchemaIds = (ids) => {
  const cellAddresses = Object.keys(ids);
  const schemaIds = [];
  cellAddresses.forEach((address) => {
    const [rowId, columnId] = address.split('@');
    const propertyIsSchemaName = rowId.startsWith('name/row');
    if (propertyIsSchemaName) schemaIds.push(columnId);
  });
  return schemaIds;
};

export const setCoords = (coords) => ({
  type: PROPERTY_SELECT.SET.COORDS,
  payload: coords,
});

/**
 * set the shiftAnchor.
 * @param {Array} anchor - shiftAnchor's coordinates in array form.
 */
export const setAnchor = (anchor = []) => ({
  type: PROPERTY_SELECT.SET.ANCHOR,
  payload: anchor,
});

export const stepAnchorDown = () => ({
  type: PROPERTY_SELECT.STEP_ANCHOR.DOWN,
});

export const stepAnchorLeft = () => ({
  type: PROPERTY_SELECT.STEP_ANCHOR.LEFT,
});

export const stepAnchorRight = () => ({
  type: PROPERTY_SELECT.STEP_ANCHOR.RIGHT,
});

export const stepAnchorUp = () => ({
  type: PROPERTY_SELECT.STEP_ANCHOR.UP,
});

export const setDropAnchor = (
  dropAnchor,
  anchorRow,
  needNewRowAnchor = false
) => ({
  type: PROPERTY_SELECT.SET.DROP_ANCHOR,
  payload: { ids: dropAnchor, anchorRow, needNewRowAnchor },
});

/**
 * Establishes the cell being edited and the uncommitted edit-value.
 * @param {Object} cell - cell being edited.
 * @param {string} value - current edit-value of the cell (unsaved value).
 */
export const setEditing = (cell = null, value = '') => ({
  type: PROPERTY_SELECT.SET.EDITING,
  payload: { cell, value },
});

/**
 * prop select initial state
 * @type {import('types').PropSelect}
 */
export const initialPropSelectState = {
  byCoords: {},
  byIds: {},
  byCells: [],
  shiftAnchor: null,
  dropAnchor: { ids: {}, rowAnchor: 0, needNewRowAnchor: false },
  editing: { cell: null, value: '' },
  bySchemaIds: [],
};

/*******************
 * Reducer
 *******************/

/**
 *
 * @param {import('types').PropSelect} initialState
 * @param {import('types').MetraSimpleAction<unknown>} action
 * @returns {import('types').PropSelect}
 */
export const propSelectReducer = (state = initialPropSelectState, action) => {
  switch (action.type) {
    case PROPERTY_SELECT.SET.CELLS: {
      const newState = cloneDeep(state);
      newState.byCells = [action.payload];
      return newState;
    }

    case PROPERTY_SELECT.SET.SELECTION: {
      const newState = cloneDeep(state);
      const { cells, tableItems } = action.payload;
      newState.byCells = [...newState.byCells, [...cells]];

      const [coords, ids] = mapCellsToCoordsAndIds(
        newState.byCells,
        tableItems
      );
      const schemaIds = getSchemaIds(ids);

      newState.bySchemaIds = schemaIds;
      newState.byIds = ids;
      newState.byCoords = coords;
      return newState;
    }

    case PROPERTY_SELECT.SET.COORDS: {
      const newState = cloneDeep(state);
      newState.byCoords = action.payload;
      return newState;
    }

    case PROPERTY_SELECT.SET.FILTER_MAPPING: {
      const newState = cloneDeep(state);
      newState.filterMapping = action.payload;
      return newState;
    }

    case PROPERTY_SELECT.SET.ACTIVE: {
      const newState = cloneDeep(state);
      const { cells, tableItems } = action.payload;
      newState.byCells = cells;
      const [coords, ids] = mapCellsToCoordsAndIds(
        newState.byCells,
        tableItems
      );
      const schemaIds = getSchemaIds(ids);

      newState.bySchemaIds = schemaIds;
      newState.byIds = ids;
      newState.byCoords = coords;

      return newState;
    }

    case PROPERTY_SELECT.SET.ANCHOR: {
      const newState = cloneDeep(state);
      newState.shiftAnchor = action.payload;
      return newState;
    }

    case PROPERTY_SELECT.SET.DROP_ANCHOR: {
      return {
        ...state,
        dropAnchor: cloneDeep(action.payload),
      };
    }

    case PROPERTY_SELECT.STEP_ANCHOR.DOWN: {
      const newState = cloneDeep(state);
      newState.shiftAnchor[0] = newState.shiftAnchor[0] + 1;
      return newState;
    }

    case PROPERTY_SELECT.STEP_ANCHOR.LEFT: {
      const newState = cloneDeep(state);
      newState.shiftAnchor[1] = newState.shiftAnchor[1] - 1;
      return newState;
    }

    case PROPERTY_SELECT.STEP_ANCHOR.RIGHT: {
      const newState = cloneDeep(state);
      newState.shiftAnchor[1] = newState.shiftAnchor[1] + 1;
      return newState;
    }

    case PROPERTY_SELECT.STEP_ANCHOR.UP: {
      const newState = cloneDeep(state);
      newState.shiftAnchor[0] = newState.shiftAnchor[0] - 1;
      return newState;
    }

    case PROPERTY_SELECT.SET.EDITING: {
      const newState = cloneDeep(state);
      newState.editing = action.payload;
      return newState;
    }

    case PROPERTY_SELECT.REMOVE: {
      const newState = cloneDeep(state);
      const { cells: deselected, tableItems } = action.payload;
      const keptSelections = [];
      // remove cells entries based on deselection cells
      newState.byCells.forEach((selection) => {
        const remainingCells = selection.filter((selectedCell) =>
          // for every cell, remove it if it matches any of the deselected cells
          // i.e if every cell doesn't fail to match the deselected cells
          deselected.every(
            (deselectedCell) => !compareArrays(deselectedCell, selectedCell)
          )
        );
        if (remainingCells.length > 0) keptSelections.push(remainingCells);
      });
      // replace cells with the pruned selections
      newState.byCells = keptSelections;

      const [coords, ids] = mapCellsToCoordsAndIds(
        newState.byCells,
        tableItems
      );
      const schemaIds = getSchemaIds(ids);

      newState.bySchemaIds = schemaIds;
      newState.byIds = ids;
      newState.byCoords = coords;

      return newState;
    }

    case PROPERTY_SELECT.CLEAR: {
      return {
        ...state,
        byCoords: {},
        byIds: {},
        byCells: [],
        bySchemaIds: [],
      };
    }

    case MODEL.INIT:
      return initialPropSelectState;
    default:
      return state;
  }
};

export default propSelectReducer;
