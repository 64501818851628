import { URL_FLYOUT } from 'modules/common';
import { URLFlyoutReducer, MetraSimpleAction } from 'types';

export const initialUrlFlyoutState: URLFlyoutReducer = {
  id: '',
  visible: false,
  links: [],
  x: 0,
  y: 0,
};

export const urlFlyoutReducer = (
  state = initialUrlFlyoutState,
  action: MetraSimpleAction<Partial<URLFlyoutReducer>>
): URLFlyoutReducer => {
  switch (action.type) {
    case URL_FLYOUT.SHOW: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case URL_FLYOUT.HIDE: {
      // ignore attempts to close an out-of-date URLFlyout
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};
