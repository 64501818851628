import {
  Assets,
  Graphics,
  Rectangle,
  Sprite as PSprite,
  Texture,
} from 'pixi.js';
import type { EdgeConfig, ModelEdge, ModelReducer, ShapeConfig } from 'types';
import type { ModelEngine } from 'engine/engine';
import type { Entity } from 'ecs/Entity';
import type { EcsInstance } from 'ecs/EcsInstance';
import { getLabel } from 'modules/model/selectors';
import { colorParse } from 'utils/utils';
import { getEdgeAnchorId } from 'modules/model/shape/edge';
import {
  Arrowhead,
  Color,
  ShapeUUID,
  Style,
  Scale,
  Related,
  Segment,
  Selectable,
  Graphic,
  Sprite,
  Bindings,
  Bindable,
  Visible,
  Waypoints,
  Position,
  MinScaledSize,
  Edge,
  Moveable,
  MetraLayer,
  Order,
  Alpha,
  Asset,
  LabelRef,
  SetColors,
  Width,
  Segments,
  PendingEdge,
  Bounds,
  Muted,
  Evaluateable,
  NeedsEvaluation,
  ActiveSetColor,
  SegmentSprite,
  Displayables,
  SpriteEdge,
  DarkMode,
  Hidden,
  Rotation,
  DisplayableConfig,
  Interactive,
} from 'engine/components';
import { Vector2 } from 'utils/vector';
import {
  correctRectilinearVectors,
  getRectilinearVectors,
} from 'helpers/engine';
import { EVENT, EventModeType } from 'utils/constants-extra';
import {
  ASSET_TYPE,
  ECS_GROUP,
  ECS_TAG,
  EDGE_STYLE,
  HEX_COLOR,
  HEX_STRING,
  LAYER,
  SIZING,
} from 'utils/constants';
import { allSome, isNone, isOk, isSome } from 'helpers/utils';
import { fixEdgeAsset } from 'helpers/assets';
import { buildLabel } from './common';
import { buildWaypoints } from './common';

export function buildSegment(
  owner: Entity,
  ecs: EcsInstance,
  ownerShapeUUID: ShapeUUID,
  index: number,
  style: Style,
  arrowhead: Arrowhead,
  color: Color,
  scale: Scale,
  from: Vector2,
  to: Vector2,
  asset: Asset,
  orderValue: number,
  first = false,
  last = false
): Result<Entity> {
  const darkMode = color.value === HEX_COLOR.EDGE_DEFAULT;
  return ecs
    .create()
    .addWith(() => {
      const shapeUUID = new ShapeUUID();
      shapeUUID.value = ownerShapeUUID.value;
      return shapeUUID;
    })
    .addWith(() => {
      const related = new Related();
      related.value = owner;
      return related;
    })
    .addWith(() => {
      const segment = new Segment();
      segment.value = index;
      segment.from.val = from;
      segment.to.val = to;
      segment.first.val = first;
      segment.last.val = last;
      return segment;
    })
    .addMaybe(
      ownerShapeUUID.value === ECS_TAG.PENDING_EDGE
        ? undefined
        : new Selectable()
    )
    .add(new Interactive())
    .addWith(() => {
      const displayables = new Displayables();
      return displayables;
    })
    .addMaybeWith((builder) => {
      if (asset.value === 'solidLine') {
        return;
      }

      const graphic = new Graphic();
      graphic.asset = new Graphics();
      graphic.asset.zIndex = orderValue;
      const displayables = builder.get(Displayables);
      displayables.val = [
        {
          textureName: null,
          asset: graphic.asset,
          layer: LAYER.EDGE,
        },
      ];
      return graphic;
    })
    .addMaybeWith((_builder) => {
      if (asset.value !== 'solidLine') {
        return;
      }

      const segmentSprite = new SegmentSprite();
      const texture: Texture = Assets.cache.get('segment');
      const sprite = new PSprite(texture);
      sprite.visible = false;
      sprite.tint = darkMode ? HEX_COLOR.WHITE : HEX_COLOR.EDGE_DEFAULT;
      segmentSprite.val = sprite;
      return segmentSprite;
    })
    .addMaybeWith((builder) => {
      if (!last) return undefined;
      const sprite = new Sprite();
      const spriteTexture: Texture = Assets.cache.get(arrowhead.value);
      const pixiSprite = new PSprite(spriteTexture);
      pixiSprite.tint = color.value;
      sprite.asset = pixiSprite;
      sprite.asset.eventMode = EventModeType.STATIC;
      const arrowScale = 0.5 + 0.125 * 4;
      sprite.asset.scale.set(
        scale.value.x * SIZING.EDGE_ARROW_SCALE * arrowScale,
        scale.value.y * SIZING.EDGE_ARROW_SCALE * arrowScale
      );
      sprite.asset.position.set(to.x, to.y);
      sprite.asset.zIndex = orderValue;
      return sprite;
    })
    .addMaybeWith((builder) => {
      const segmentSprite = builder.getMaybe(SegmentSprite);
      const arrowSprite = builder.getMaybe(Sprite);
      const sprites: DisplayableConfig[] = [];

      if (segmentSprite) {
        sprites.push({
          textureName: 'segment',
          asset: segmentSprite.val,
          layer: LAYER.EDGE,
        });
      }
      if (arrowSprite) {
        sprites.push({
          textureName: arrowhead.value,
          asset: arrowSprite.asset,
          layer: LAYER.EDGE_ARROW,
        });
      }
      if (sprites.length > 0) {
        const displayables = builder.get(Displayables);
        displayables.val = [...displayables.val, ...sprites];
        return displayables;
      }

      return;
    })
    .addMaybeWith((builder) => {
      if (!last) return undefined;
      const bindings = new Bindings();
      bindings.bindingName = builder.get(ShapeUUID).value;
      bindings.component = Sprite;
      bindings.events = [
        EVENT.CLICK,
        EVENT.MOUSEDOWN,
        EVENT.MOUSEMOVE,
        EVENT.MOUSEOVER,
        EVENT.MOUSEUP,
        EVENT.MOUSEUPOUTSIDE,
        EVENT.RIGHTDOWN,
        EVENT.RIGHTUP,
      ];
      return bindings;
    })
    .add(new Bindable())
    .add(new Visible())
    .build();
}

export function buildRectilinearSegment(
  owner: Entity,
  ecs: EcsInstance,
  ownerShapeUUID: ShapeUUID,
  index: number,
  style: Style,
  arrowhead: Arrowhead,
  color: Color,
  scale: Scale,
  from: Vector2,
  to: Vector2,
  priorSegment: Option<Result<Entity>>,
  asset: Asset,
  orderValue: number,
  first = false,
  last = false
): [Option<Result<Entity>>, Option<Result<Entity>>] {
  let [p1, p2, p3] = getRectilinearVectors(from, to);
  if (isSome(priorSegment) && isOk(priorSegment)) {
    // even though the systems are not aware of it yet, the prior segment
    // exists and has accessible data
    const segment = ecs.getComponentOfType(priorSegment, Segment);
    if (isNone(segment)) return [null, null];
    [p1, p2, p3] = correctRectilinearVectors(
      segment.from.val,
      segment.to.val,
      p1,
      p2,
      p3
    );
  }
  const seg1 = buildSegment(
    owner,
    ecs,
    ownerShapeUUID,
    index,
    style,
    arrowhead,
    color,
    scale,
    p1,
    p2,
    asset,
    orderValue,
    first,
    last ? !last : last
  );
  const seg2 = buildSegment(
    owner,
    ecs,
    ownerShapeUUID,
    index + 1,
    style,
    arrowhead,
    color,
    scale,
    p2,
    p3,
    asset,
    orderValue,
    first ? !first : first,
    last
  );

  return [seg1, seg2];
}

export function buildEdgeSegments(
  ecs: EcsInstance,
  owner: Entity,
  ownerShapeUUID: ShapeUUID,
  waypoints: Waypoints,
  style: Style,
  arrowhead: Arrowhead,
  color: Color,
  scale: Scale,
  asset: Asset,
  orderValue: number,
  fromPos: Vector2,
  toPos: Vector2
): Entity[] {
  const segments: Entity[] = [];
  const waypointsLength = waypoints.values.length;
  let pos1!: Option<Position>, pos2!: Option<Position>;
  if (style.value === EDGE_STYLE.RECTILINEAR) {
    if (waypointsLength) {
      pos1 = ecs.getComponentOfType(waypoints.values[0], Position);
      if (isNone(pos1)) return [];
      // we have defined waypoints
      let [segment1, segment2] = buildRectilinearSegment(
        owner,
        ecs,
        ownerShapeUUID,
        0,
        style,
        arrowhead,
        color,
        scale,
        fromPos,
        pos1.value,
        null,
        asset,
        orderValue,
        true,
        false
      );
      isSome(segment1) && isOk(segment1) && segments.push(segment1);
      isSome(segment2) && isOk(segment2) && segments.push(segment2);
      for (let i = 1; i < waypointsLength; i++) {
        pos1 = ecs.getComponentOfType(waypoints.values[i - 1], Position);
        if (isNone(pos1)) return [];
        pos2 = ecs.getComponentOfType(waypoints.values[i], Position);
        if (isNone(pos2)) return [];
        [segment1, segment2] = buildRectilinearSegment(
          owner,
          ecs,
          ownerShapeUUID,
          i * 2,
          style,
          arrowhead,
          color,
          scale,
          pos1.value,
          pos2.value,
          segment2,
          asset,
          orderValue,
          false,
          false
        );
        isSome(segment1) && isOk(segment1) && segments.push(segment1);
        isSome(segment2) && isOk(segment2) && segments.push(segment2);
      }
      pos2 = ecs.getComponentOfType(
        waypoints.values[waypointsLength - 1],
        Position
      );
      if (isNone(pos2)) return [];
      [segment1, segment2] = buildRectilinearSegment(
        owner,
        ecs,
        ownerShapeUUID,
        waypointsLength * 2,
        style,
        arrowhead,
        color,
        scale,
        pos2.value,
        toPos,
        segment2,
        asset,
        orderValue,
        false,
        true
      );
      isSome(segment1) && isOk(segment1) && segments.push(segment1);
      isSome(segment2) && isOk(segment2) && segments.push(segment2);
    } else {
      // we have no defined waypoints
      const [segment1, segment2] = buildRectilinearSegment(
        owner,
        ecs,
        ownerShapeUUID,
        0,
        style,
        arrowhead,
        color,
        scale,
        fromPos,
        toPos,
        null,
        asset,
        orderValue,
        true,
        true
      );
      isSome(segment1) && isOk(segment1) && segments.push(segment1);
      isSome(segment2) && isOk(segment2) && segments.push(segment2);
    }
  } else {
    if (waypointsLength && style.value === EDGE_STYLE.LINEAR) {
      pos1 = ecs.getComponentOfType(waypoints.values[0], Position);
      if (isNone(pos1)) return [];
      // we have defined waypoints
      let segment = buildSegment(
        owner,
        ecs,
        ownerShapeUUID,
        0,
        style,
        arrowhead,
        color,
        scale,
        fromPos,
        pos1.value,
        asset,
        orderValue,
        true,
        false
      );
      isOk(segment) && segments.push(segment);
      for (let i = 1; i < waypointsLength; i++) {
        pos1 = ecs.getComponentOfType(waypoints.values[i - 1], Position);
        if (isNone(pos1)) return [];
        pos2 = ecs.getComponentOfType(waypoints.values[i], Position);
        if (isNone(pos2)) return [];
        segment = buildSegment(
          owner,
          ecs,
          ownerShapeUUID,
          i,
          style,
          arrowhead,
          color,
          scale,
          pos1.value,
          pos2.value,
          asset,
          orderValue,
          false,
          false
        );
        isOk(segment) && segments.push(segment);
      }
      pos2 = ecs.getComponentOfType(
        waypoints.values[waypointsLength - 1],
        Position
      );
      if (isNone(pos2)) return [];
      segment = buildSegment(
        owner,
        ecs,
        ownerShapeUUID,
        waypointsLength,
        style,
        arrowhead,
        color,
        scale,
        pos2.value,
        toPos,
        asset,
        orderValue,
        false,
        true
      );
      isOk(segment) && segments.push(segment);
    } else {
      // no waypoints, so build only a single segment
      const segment = buildSegment(
        owner,
        ecs,
        ownerShapeUUID,
        0,
        style,
        arrowhead,
        color,
        scale,
        fromPos,
        toPos,
        asset,
        orderValue,
        true,
        true
      );
      isOk(segment) && segments.push(segment);
    }
  }
  return segments;
}

export function buildEdge(
  ecs: EcsInstance,
  config: EdgeConfig,
  modelEdge: ModelEdge,
  model: ModelReducer,
  showEdgeLabels: boolean,
  hiddenSetsMap: Set<string>,
  engine: ModelEngine
): Result<Entity, Error> {
  const {
    sets,
    edges,
    base: { allSetsAlpha, allEdgesAlpha, darkMode, labelSize },
  } = model;
  return ecs
    .create()
    .init((builder) => {
      const inNode = model.shapes[config.to];
      const outNode = model.shapes[config.from];
      if (!inNode || !outNode) {
        throw new Error('Edges must have endpoints!');
      }
      builder.setData('inNode', inNode);
      builder.setData('outNode', outNode);
      return builder;
    })
    .addWith(() => {
      const shapeUUID = new ShapeUUID();
      shapeUUID.value = config.id;
      return shapeUUID;
    })
    .addWith(() => {
      const minScaledSize = new MinScaledSize();
      minScaledSize.value = 1.0;
      return minScaledSize;
    })
    .addWith((builder) => {
      // Determine the edge-sprite position.  It should be positioned
      // halfway between the nodes it connects.
      const position = new Position();
      const fromPos = Vector2.fromPoint(
        builder.getData<ShapeConfig>('outNode').pos
      );
      builder.setData('fromPos', fromPos);
      const toPos = Vector2.fromPoint(
        builder.getData<ShapeConfig>('inNode').pos
      );
      builder.setData('toPos', toPos);
      position.value = fromPos.add(toPos).divScalar(2);
      return position;
    })
    .addWith((builder) => {
      const edge = new Edge();
      edge.inNodeId = builder.getData<ShapeConfig>('inNode').id;
      edge.outNodeId = builder.getData<ShapeConfig>('outNode').id;
      edge.inAnchorId = getEdgeAnchorId(edge.inNodeId, model);
      edge.outAnchorId = getEdgeAnchorId(edge.outNodeId, model);
      return edge;
    })
    .add(new Selectable())
    .add(new Moveable())
    .addWith(() => {
      const layer = new MetraLayer();
      layer.value = config.layer;
      return layer;
    })
    .addWith(() => {
      const order: Order = new Order();
      order.value = config.order;
      return order;
    })
    .addWith((builder) => {
      const alphaComp = new Alpha();
      let alpha = config.alpha;

      const setIds = edges[builder.get(ShapeUUID).value]?.setIds ?? [];
      // apply set alphas multiplicatively for each set that owns this shape
      setIds.forEach((sid) => {
        alpha *= sets[sid].alpha;
      });

      // apply the "All Sets" alpha multiplicatively, if this belongs to a set
      if (setIds.length > 0) alpha *= allSetsAlpha;

      alpha *= allEdgesAlpha;

      alphaComp.value = alpha;
      return alphaComp;
    })
    .addWith((builder) => {
      const displayables = new Displayables();
      return displayables;
    })
    .addWith((builder) => {
      const graphic = new Graphic();
      graphic.asset = new Graphics();
      builder.get(Displayables).val = [
        {
          textureName: null,
          asset: graphic.asset,
          layer: config.layer,
        },
      ];
      return graphic;
    })
    .addWith(() => {
      const asset = new Asset();
      asset.assetType.val = ASSET_TYPE.PRESET;
      asset.value = fixEdgeAsset(config.asset);
      return asset;
    })
    .addMaybeWith((_builder) => {
      if (config.asset !== 'solidLine') {
        return;
      }

      return new SpriteEdge();
    })
    .addMaybeWith(() => {
      if (
        ![HEX_STRING.EDGE_DEFAULT, HEX_STRING.EDGE_LIGHT].includes(config.color)
      )
        return;
      return new DarkMode();
    })
    .addWith(() => {
      const color = new Color();
      color.value = colorParse(config.color);
      return color;
    })
    .addWith(() => {
      const arrowhead = new Arrowhead();
      arrowhead.value = config.arrowhead || 'curvedArrowhead';
      return arrowhead;
    })
    .addMaybe(config.visible ? new Visible() : undefined)
    .addMaybe(config.hidden ? new Hidden() : undefined)
    .addWith(() => {
      const scale = new Scale();
      scale.value = Vector2.fromPoint(config.scale).divScalar(3);
      return scale;
    })
    .addWith((builder) => {
      const labelRef = new LabelRef();
      labelRef.position.val = config.labelPosition;
      labelRef.content.val = config.labelContent;
      const maybeLabel = buildLabel(
        ecs,
        getLabel(modelEdge, model.expressions, 'edges'),
        builder.getEntity(),
        config,
        ECS_GROUP.EDGE_LABELS,
        builder.get(Order),
        showEdgeLabels,
        false,
        labelSize,
        darkMode,
        true // is edge
      );
      if (isOk(maybeLabel)) {
        labelRef.value = maybeLabel;
        const edgeLabelRotation = new Rotation();
        edgeLabelRotation.value = 0;
        ecs.addComponent(labelRef.value, edgeLabelRotation);
      }
      return labelRef;
    })
    .add(new ActiveSetColor())
    .addWith((builder) => {
      const setIds = modelEdge.setIds;
      builder.setData('setIds', setIds);
      // Only need the top Color for edges
      // The "top" color would be the viewable color closest to the end
      const setColors = new SetColors();
      setColors.values = [];
      for (let i = setIds.length; i--; ) {
        const setId = setIds[i];
        if (!hiddenSetsMap.has(setId)) {
          setColors.values = [colorParse(sets[setId].color)];
          break;
        }
      }
      return setColors;
    })
    .addWith(() => {
      const width = new Width();
      width.value = config.width;
      return width;
    })
    .addWith(() => {
      const style = new Style();
      style.value = config.style;
      return style;
    })
    .addWith((builder) => {
      const waypoints = new Waypoints();
      waypoints.values = buildWaypoints(
        ecs,
        builder.getEntity(),
        config,
        builder.get(Order)
      );
      return waypoints;
    })
    .addWith((builder) => {
      const segments = new Segments();
      segments.values = buildEdgeSegments(
        ecs,
        builder.getEntity(),
        builder.get(ShapeUUID),
        builder.get(Waypoints),
        builder.get(Style),
        builder.get(Arrowhead),
        builder.get(Color),
        builder.get(Scale),
        builder.get(Asset),
        builder.get(Order).val,
        builder.getData<Vector2>('fromPos'),
        builder.getData<Vector2>('toPos')
      );
      if (segments.values.length === 0)
        throw new Error('Edges must have at least 1 Segment!');
      return segments;
    })
    .add(new Evaluateable())
    .add(new NeedsEvaluation())
    .also((builder) => {
      const setIds = builder.getData<string[]>('setIds');
      for (let i = setIds.length; i--; ) {
        builder.group(setIds[i]);
      }
      return builder;
    })
    .tag(config.id)
    .group(ECS_GROUP.EDGES)
    .build();
}

export function buildPendingEdge(
  ecs: EcsInstance,
  _engine: ModelEngine,
  fromId: string,
  fromPos: Vector2,
  toPos: Vector2,
  styleType: number = EDGE_STYLE.DEFAULT,
  darkMode: boolean = false,
  orderValue: number = 0
): void {
  ecs
    .create()
    .add(new PendingEdge())
    .add(new Visible())
    .add(new SpriteEdge())
    .addWith(() => {
      const shapeUUID = new ShapeUUID();
      shapeUUID.value = ECS_TAG.PENDING_EDGE;
      return shapeUUID;
    })
    .addWith((builder) => {
      const edge = new Edge();
      edge.outAnchorId = fromId;
      edge.inAnchorId = 'pending-edge-target';
      return edge;
    })
    .addWith(() => {
      const order = new Order();
      order.value = orderValue;
      return order;
    })
    .addWith(() => {
      const layer = new MetraLayer();
      layer.value = LAYER.EDGE;
      return layer;
    })
    .addWith(() => {
      const scale = new Scale();
      scale.value = new Vector2(0.33, 0.33);
      return scale;
    })
    .addWith(() => {
      const width = new Width();
      width.value = 4;
      return width;
    })
    .addWith(() => {
      const color = new Color();
      color.value = darkMode ? HEX_COLOR.EDGE_LIGHT : HEX_COLOR.EDGE_DEFAULT;
      return color;
    })
    .addWith(() => {
      const position = new Position();
      position.value = toPos;
      return position;
    })
    .addWith(() => {
      const asset = new Asset();
      asset.assetType.val = ASSET_TYPE.PRESET;
      asset.value = 'solidLine';
      return asset;
    })
    .addWith(() => {
      const alpha = new Alpha();
      alpha.value = 1.0;
      return alpha;
    })
    .addWith(() => {
      const arrowhead = new Arrowhead();
      arrowhead.value = 'curvedArrowhead';
      return arrowhead;
    })
    .add(new ActiveSetColor())
    .addWith(() => {
      const setColors = new SetColors();
      setColors.values = [];
      return setColors;
    })
    .addWith(() => {
      const style = new Style();
      style.value = styleType;
      return style;
    })
    .addWith(() => {
      const waypoints = new Waypoints();
      waypoints.values = [];
      return waypoints;
    })
    .addWith((builder) => {
      const segments = new Segments();
      segments.values = [];

      const asset = new Asset();
      asset.value = 'solidLine';

      if (builder.get(Style).value === EDGE_STYLE.RECTILINEAR) {
        const [segment1, segment2] = buildRectilinearSegment(
          builder.getEntity(),
          ecs,
          builder.get(ShapeUUID),
          0,
          builder.get(Style),
          builder.get(Arrowhead),
          builder.get(Color),
          builder.get(Scale),
          fromPos,
          toPos,
          null,
          asset,
          orderValue,
          true,
          true
        );
        isSome(segment1) && isOk(segment1) && segments.values.push(segment1);
        isSome(segment2) && isOk(segment2) && segments.values.push(segment2);
      } else {
        const segment = buildSegment(
          builder.getEntity(),
          ecs,
          builder.get(ShapeUUID),
          0,
          builder.get(Style),
          builder.get(Arrowhead),
          builder.get(Color),
          builder.get(Scale),
          fromPos,
          toPos,
          asset,
          orderValue,
          true,
          true
        );
        isOk(segment) && segments.values.push(segment);
      }
      return segments;
    })
    .addWith((builder) => {
      const bounds = new Bounds();
      const width = builder.get(Width);
      bounds.value = new Rectangle(toPos.x, toPos.y, width.value, width.value);
      return bounds;
    })
    .add(new Visible())
    .tagWith((builder) => {
      return builder.get(ShapeUUID).value;
    })
    .build();
}

export function rebuildEdgeSegments(
  ecs: EcsInstance,
  entity: Entity,
  spriteEdges = true
): void {
  const retrieved = ecs.retrieve(entity, [
    Arrowhead,
    Color,
    Edge,
    Order,
    Segments,
    Scale,
    Style,
    ShapeUUID,
    Waypoints,
    Asset,
    Visible,
  ]);
  if (!allSome(retrieved)) return;
  const [
    arrowhead,
    color,
    edge,
    order,
    segments,
    scale,
    style,
    shapeUUID,
    waypoints,
    asset,
    visible,
  ] = retrieved;

  // whenever possible, we avoid rebuilding sprite edges
  if (!spriteEdges && ecs.hasComponent(entity, SpriteEdge.type)) {
    return;
  }

  const inEnt = ecs.tagManager.getEntityByTag(edge.inAnchorId);
  const outEnt = ecs.tagManager.getEntityByTag(edge.outAnchorId);
  if (!inEnt || !outEnt) return;
  const fromPos = ecs.getComponentOfType(outEnt, Position);
  const toPos = ecs.getComponentOfType(inEnt, Position);
  if (!fromPos || !toPos) return;

  const muted = ecs.getComponentOfType(segments.values[0], Muted);
  ecs.deleteEntities(segments.values);

  const segmentEntities = buildEdgeSegments(
    ecs,
    entity,
    shapeUUID,
    waypoints,
    style,
    arrowhead,
    color,
    scale,
    asset,
    order.val,
    fromPos.value,
    toPos.value
  );
  segments.values = segmentEntities;

  // segments should match edge's current visibility
  for (const segment of segmentEntities) {
    const sprite = ecs.getComponent(segment, Sprite);
    if (sprite) {
      sprite.asset.visible = !!visible;
    }
  }

  ecs.resolveAll(segmentEntities);
  ecs.update(segments);

  if (muted) {
    segments.values.forEach((segment) => {
      const mutedSegment = new Muted();
      mutedSegment.alpha.val = muted.alpha.val;
      ecs.addComponent(segment, mutedSegment);
      ecs.resolveById(segment.id);
    });
  }
}
