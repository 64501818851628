import { t3dev } from 't3dev';
import { CoreAtoms } from './sub-atoms/core';
import { SelectionAtoms } from './sub-atoms/selection';
import { StyleAtoms } from './sub-atoms/style';
import { isNone } from 'helpers/utils';
import { ShapeUUID } from 'engine/components';

import type { Sys2 } from './sys2';
import type { MetraDispatch } from 'types';
import type { EntityId } from '.';
import { ContextMenuAtoms } from './sub-atoms/context-menu';
import { DebugAtoms } from './sub-atoms/debug';
import { CollisionAtoms } from './sub-atoms/collision';
import { TextAtoms } from './sub-atoms/text';
import { EdgeAtoms } from './sub-atoms/edge';

let singleton: Atoms;
export function useAtoms() {
  singleton ||= new Atoms();
  return singleton;
}

export function getAtoms() {
  singleton ||= new Atoms();
  return singleton;
}

export class Atoms {
  private _sys2: Option<Sys2>;
  private _dispatch: Option<MetraDispatch>;
  loaded = false;

  core: CoreAtoms;
  debug: DebugAtoms;
  selection: SelectionAtoms;
  style: StyleAtoms;
  contextMenu: ContextMenuAtoms;
  collision: CollisionAtoms;
  text: TextAtoms;
  edge: EdgeAtoms;

  constructor() {
    this.core = new CoreAtoms(this);
    this.debug = new DebugAtoms(this);
    this.selection = new SelectionAtoms(this);
    this.style = new StyleAtoms(this);
    this.contextMenu = new ContextMenuAtoms(this);
    this.collision = new CollisionAtoms(this);
    this.text = new TextAtoms(this);
    this.edge = new EdgeAtoms(this);
  }

  load(sys2: Sys2, dispatch: MetraDispatch) {
    this._sys2 = sys2;
    this._dispatch = dispatch;
    this.loaded = true;
    return this;
  }

  init() {
    this.selection.init();
    this.debug.init();
    this.style.init();
    this.contextMenu.init();
    this.collision.init();
    this.text.init();
  }

  get state() {
    return this.sys2.state;
  }

  get sys2() {
    if (isNone(this._sys2)) {
      t3dev().log.error('Tried to access Sys2 in Atoms before Atoms is loaded');
      return null as unknown as Sys2;
    }

    return this._sys2;
  }

  get dispatch() {
    if (isNone(this._dispatch)) {
      t3dev().log.error(
        'Tried to access dispatch in Atoms before Atoms is loaded'
      );
      return null as unknown as MetraDispatch;
    }

    return this._dispatch;
  }

  toUuids(eids: Set<EntityId>) {
    const uuids = new Set<UUID>();
    for (const eid of eids) {
      uuids.add(this.sys2.assert(eid, ShapeUUID).val);
    }
    return [...uuids];
  }
}
