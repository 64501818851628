import { MODEL_LINKS } from 'modules/common';
import type { MetraSimpleAction } from 'types';
import type { ModelLinksDataReducer } from 'types/modules/modelLinksData/reducer';

export const initialModelLinksDataState: ModelLinksDataReducer = {
  byId: {},
};

export const modelLinksDataReducer = (
  state = initialModelLinksDataState,
  action: MetraSimpleAction<any>
): ModelLinksDataReducer => {
  const { type, payload } = action;

  if (type === MODEL_LINKS.SET && payload.byId) {
    return {
      byId: payload.byId,
    };
  }

  return state;
};
