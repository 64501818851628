import { MPT_CONTEXT_MENU } from 'modules/common/constants';
import { MetraActionFunc, MptContextArgs, UUID } from 'types';

export const showMPTContextMenu: MetraActionFunc<
  [MptContextArgs],
  MptContextArgs
> = (payload) => {
  return {
    type: MPT_CONTEXT_MENU.SHOW,
    payload: payload,
  };
};

export const hideMPTContextMenu: MetraActionFunc = () => {
  return { type: MPT_CONTEXT_MENU.HIDE };
};
