import type {
  ShapeConfig,
  EdgeConfig,
  NodeConfig,
  CollapsedConfig,
  BackgroundConfig,
  AssetType,
  DrawingConfig,
  TextConfig,
} from 'types';
import { SHAPE } from 'utils/constants-extra';

export function isNode(shapeConfig: ShapeConfig): shapeConfig is NodeConfig {
  if (!shapeConfig) return false;
  if (
    shapeConfig.type === SHAPE.NODE &&
    'edges' in shapeConfig &&
    'asset' in shapeConfig
  )
    return true;
  return false;
}

export function isDrawing(
  shapeConfig: ShapeConfig
): shapeConfig is DrawingConfig {
  if (!shapeConfig) return false;
  if (
    shapeConfig.type === SHAPE.DRAWING &&
    'strokeColor' in shapeConfig &&
    'strokeAlpha' in shapeConfig
  )
    return true;
  return false;
}

export function isText(shapeConfig: ShapeConfig): shapeConfig is TextConfig {
  if (!shapeConfig) return false;
  if (
    shapeConfig.type === SHAPE.TEXT &&
    'fontSize' in shapeConfig &&
    'text' in shapeConfig
  )
    return true;
  return false;
}

export function isEdge(shapeConfig: ShapeConfig): shapeConfig is EdgeConfig {
  if (
    shapeConfig.type === SHAPE.EDGE &&
    'arrowhead' in shapeConfig &&
    'asset' in shapeConfig &&
    'from' in shapeConfig &&
    'to' in shapeConfig &&
    'width' in shapeConfig &&
    'style' in shapeConfig &&
    'waypoints' in shapeConfig
  )
    return true;
  return false;
}

export function isCollapsedSet(
  shapeConfig: ShapeConfig
): shapeConfig is CollapsedConfig {
  if (
    shapeConfig.type === SHAPE.COLLAPSED_SET &&
    'setId' in shapeConfig &&
    'startPos' in shapeConfig
  )
    return true;
  return false;
}

export function isBackground(
  shapeConfig: ShapeConfig
): shapeConfig is BackgroundConfig {
  if (shapeConfig.type === SHAPE.BACKGROUND && 'asset' in shapeConfig)
    return true;
  return false;
}

export function hasAsset<T extends ShapeConfig | Partial<ShapeConfig>>(
  shapeConfig: T
): shapeConfig is T & { asset: string } {
  if ('asset' in shapeConfig) return true;
  return false;
}

export function hasAssetType<T extends ShapeConfig>(
  shapeConfig: T
): shapeConfig is T & { assetType: AssetType } {
  if ('assetType' in shapeConfig) return true;
  return false;
}

export function hasAssets<T extends ShapeConfig>(
  shapeConfig: T
): shapeConfig is T & { asset: string; assetType: AssetType } {
  if (hasAsset(shapeConfig) && hasAssetType(shapeConfig)) return true;
  return false;
}
