import React from 'react';
import { MetraMedia } from 'types/modules/entities';
import BrokenLinkIcon from './BrokenLinkIcon';
import {
  isMetraFileDownloadURL,
  isMetraFolderURL,
  isMetraModelURL,
} from 'utils/url-builders';
import { findValidUrlsOrNoteLinks, mergeTextsAndUrls } from 'utils/utils';
import { ImmutableObject } from 'seamless-immutable';
import { extractSearchText } from 'modules/model/modelLinks/helpers';

type PartialMedia =
  | Partial<Record<string, MetraMedia>>
  | Partial<ImmutableObject<Record<string, MetraMedia>>>;

export const getMediaName = (
  precedingElementName: string,
  url: string,
  allMedia: PartialMedia
) => {
  const { pathname, search } = new URL(url);
  const urlElements = pathname.split('/');
  const index = urlElements.indexOf(precedingElementName);
  const mediaId = urlElements[index + 1];

  if (
    !!mediaId &&
    precedingElementName === 'files' &&
    index === urlElements.length - 1
  ) {
    return 'files';
  }

  let mediaName = allMedia?.[mediaId]?.name;
  if (mediaName && search) {
    const [searchText, _isAdvanced] = extractSearchText(search);
    mediaName = mediaName + ` (search: ${searchText})`;
  }

  return mediaName ?? url;
};

export const getDownloadFileName = (url: string, allMedia: PartialMedia) => {
  return getMediaName('media', url, allMedia);
};

export const getFolderURL = (url: string, allMedia: PartialMedia) => {
  return getMediaName('files', url, allMedia);
};

export const getModelFileName = (url: string, allMedia: PartialMedia) => {
  return getMediaName('models', url, allMedia);
};

export const isMetraDownloadUrl = (url: string) => {
  return isMetraFileDownloadURL(url);
};

export function getDisplayText(url: string, allMedia: PartialMedia) {
  return isMetraFileDownloadURL(url)
    ? getDownloadFileName(url, allMedia)
    : isMetraModelURL(url)
    ? getModelFileName(url, allMedia)
    : isMetraFolderURL(url)
    ? getFolderURL(url, allMedia)
    : null;
}

export const displayUrl = (url: string, allMedia: PartialMedia) => {
  const displayText = getDisplayText(url, allMedia);

  // If a Metra file and unable to find the file name,
  // original URL is returned indicating the file was not found
  if (displayText === url) {
    return <BrokenLinkIcon link={url} />;
  }

  if (displayText) {
    return (
      <a href={url} onClick={(e) => e.preventDefault()}>
        {displayText}
      </a>
    );
  } else {
    return url;
  }
};

export function getTextParts(urls: string[], value: string) {
  // we used to do value.split(regex-that-idenfies-urls) but that had problems
  // with the localhost url's.  Replacing it with this workable but less slick
  // and easily-reused logic.
  const texts = [];
  let searchIndex = 0;
  for (let i = 0; i < urls.length; i++) {
    const nextIndex = value.indexOf(urls[i], searchIndex);
    texts.push(value.substring(searchIndex, nextIndex));
    searchIndex = nextIndex + urls[i].length;
  }
  texts.push(value.substring(searchIndex));

  return texts;
}

export function getTextUrlMixDisplayValue(
  value: string,
  allMedia: PartialMedia
) {
  const urls = findValidUrlsOrNoteLinks(value);
  const texts = getTextParts(urls, value);
  const mappedUrls = urls.map((url) => {
    return getDisplayText(url, allMedia) || url;
  });
  const merged = mergeTextsAndUrls(texts, mappedUrls);

  return merged.join('');
}

/**
 * When text is interspersed with urls, find and styles the urls.
 * Then, return a list of components that mixes the plain text with the styled urls.
 * @param value
 * @returns Urls styled and mixed within plain text
 */
export function textUrlMix(
  value: string,
  allMedia: PartialMedia
): Array<React.ReactElement> {
  const urls = findValidUrlsOrNoteLinks(value);
  const texts = getTextParts(urls, value);
  const merged = mergeTextsAndUrls(texts, urls);

  return merged.map((item, i) =>
    i % 2 === 0 ? (
      <span key={`${i}-${item}`}>{item}</span>
    ) : (
      <span key={`${i}-${item}`} className="mod-url">
        {displayUrl(item, allMedia)}
      </span>
    )
  );
}
