import { Component } from './component';
import { t3dev } from 't3dev';
import { Query } from './query';
import { dispatcher, globalGetState } from 'utils/utils-extra';
import { Atoms, useAtoms } from './atoms';

import type { Entity, EntityId, Signature } from 'ecs/Entity';
import type { ComponentClass } from './types';
import type { ModelEngine } from 'engine/engine';
import type {
  ComponentTuple,
  GetStateFunc,
  MetraDispatch,
  OrderedComponentTuple,
  OrderedOptionComponentTuple,
  RootReducer,
} from 'types';

let sys2: Option<Sys2>;
export function getSys2(): Sys2 {
  if (sys2 == null) {
    throw new Error('tried to get sys2 before initialization');
  }
  return sys2;
}

export class Sys2 {
  engine: ModelEngine;
  dispatch: MetraDispatch;
  systems: Set<Sys2System> = new Set();
  queries: Query[] = [];
  dirtyComponents: Component[] = [];
  toDelete: Set<EntityId> = new Set();
  atoms: Atoms;
  getState: GetStateFunc<RootReducer>;

  private initialized = false;

  constructor(engine: ModelEngine, getState = globalGetState) {
    sys2 = this;
    this.engine = engine;
    this.getState = getState;
    this.dispatch = dispatcher;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    this.atoms = useAtoms().load(this, dispatcher);
  }

  findQueries(
    included: ComponentClass[] = [],
    excluded: ComponentClass[] = [],
    optional: ComponentClass[] = []
  ) {
    loop: for (const query of this.queries) {
      for (const i of included) {
        if (!query.included.has(i.type)) continue loop;
      }

      for (const e of excluded) {
        if (!query.excluded.has(e.type)) continue loop;
      }

      for (const o of optional) {
        if (!query.optional.has(o.type)) continue loop;
      }

      t3dev().log.log(query._stack);
    }
  }

  get state() {
    return this.getState();
  }

  get components() {
    return this.engine.ecs.componentManager;
  }

  get entities() {
    return this.engine.ecs.entityManager;
  }

  // this is only used for testing right now
  // we will do a full clean-up when ecs2 is complete
  // and sys2 actually has entities and components to manage
  cleanUp() {
    for (const system of this.systems) {
      system.deregister?.();
    }

    this.toDelete.clear();
    this.systems.clear();
    this.queries = [];
    this.initialized = false;
  }

  register<S extends Sys2System>(system: Registerable): S {
    const registered = system.register(this);
    this.systems.add(registered);
    registered.init?.(this.state);
    return registered as S;
  }

  deregister(system: Sys2System) {
    system.deregister?.();
    return this.systems.delete(system);
  }

  // todo: merge queries with matching signatures
  query(...components: ComponentClass[]) {
    if (this.initialized) {
      t3dev().log.error(
        'Do not create a query after initialization! Queries should be created once in constructor() or init() and saved for later reference.'
      );
    }

    const q = new Query(components);
    this.queries.push(q);

    for (const entity of this.entities.entities) {
      if (q.match(entity.signature)) {
        q.add(entity);
      }
    }

    return q;
  }

  // Get all entities matching the given signature
  // NOTE: This is much slower than using a query!
  // Always use a query when available!
  match(included: ComponentClass[], excluded: ComponentClass[] = []) {
    const q = new Query(included, excluded);
    for (const entity of this.entities.entities) {
      if (q.match(entity.signature)) {
        q.add(entity);
      }
    }

    return q.all;
  }

  onEntityChanged(entity: Entity, component: Component | typeof Component) {
    // entities continue to exist until the end of the frame
    // and some of our behavior trees mutate entities after deleting them
    // we want to ignore these changes
    if (this.toDelete.has(entity.id)) return;

    for (const query of this.queries) {
      if (query.match(entity.signature)) {
        const added = query.add(entity, component);

        // if the entity has already been added, we try a change
        // because this may be a toggleable component
        if (!added) {
          query.change(entity.id, component);
        }
      } else {
        query.remove(entity.id, component);
      }
    }
  }

  deleteEntity(eid: EntityId) {
    this.onEntityDeleted(eid);
  }

  onEntityDeleted(eid: EntityId) {
    this.toDelete.add(eid);
    for (const query of this.queries) {
      query.remove(eid);
    }
  }

  // callback passed directly to components
  onComponentChanged = (component: Component) => {
    this.dirtyComponents.push(component);
    for (const query of this.queries) {
      query.change(component.owner, component);
    }
  };

  load() {
    for (let q of this.queries) {
      q.load();
    }
  }

  init() {
    this.atoms.init();
    this.initialized = true;
  }

  update() {
    const nextQueries = [];
    for (let q of this.queries) {
      if (q.detached) continue;
      q.tick();
      nextQueries.push(q);
    }

    this.queries = nextQueries;

    // run all queries before clearing
    // this way we can have callbacks that reference other query's data
    for (let q of this.queries) {
      q.clear();
    }

    for (let component of this.dirtyComponents) {
      component.dirty = false;
    }

    for (const deleted of this.toDelete) {
      const entity = this.entities.getEntity(deleted) as Entity;
      const ecs = this.engine.ecs;

      // nullable because we want sys2 to run without
      // ecs instance in tests.
      // in the future we'll remove these entirely
      ecs.systemManager?.deleteEntity(entity);
      ecs.tagManager?.deleteEntity(entity);
      ecs.groupManager?.deleteEntity(entity);
      this.components.deleteEntity(entity);
      this.entities.deleteEntity(entity);
    }

    this.dirtyComponents = [];
    this.toDelete.clear();
  }

  getComponents(eid: EntityId) {
    return this.components.getAllEntityComponentsById(eid);
  }

  //HELPERS

  assert<C extends typeof Component>(eid: EntityId, component: C) {
    const result = this.components.getComponentById(eid, component);
    if (!result) {
      t3dev().log.error(
        `assertion failed: component ${component.name} for entity ${eid} does not exist`
      );
    }
    return result as InstanceType<C>;
  }

  assertAll<C extends ComponentTuple>(eid: EntityId, components: [...C]) {
    const results = [];
    for (const component of components) {
      results.push(this.assert(eid, component));
    }

    return results as OrderedComponentTuple<C>;
  }

  maybe<C extends typeof Component>(eid: EntityId, component: C) {
    return this.components.getComponentById(eid, component) as Option<
      InstanceType<C>
    >;
  }

  ensure<C extends Component>(eid: EntityId, fallback: C) {
    const result = this.components.getComponentByTypeAndId(
      eid,
      fallback.type
    ) as Option<C>;
    if (result) {
      return result;
    }

    this.addComponent(eid, fallback);
    return fallback;
  }

  maybeAll<C extends ComponentTuple>(eid: EntityId, components: [...C]) {
    const results = [];
    for (const component of components) {
      results.push(this.maybe(eid, component));
    }

    return results as OrderedOptionComponentTuple<C>;
  }

  has(eid: EntityId, component: typeof Component) {
    return this.components.hasComponentById(eid, component.type);
  }

  hasAll(eid: EntityId, components: (typeof Component)[]) {
    for (const component of components) {
      if (!this.has(eid, component)) {
        return false;
      }
    }

    return true;
  }

  hasAny(eid: EntityId, components: (typeof Component)[]) {
    for (const component of components) {
      if (this.has(eid, component)) {
        return true;
      }
    }

    return false;
  }

  createEntity(components: Component[] = []): EntityId {
    const eid = this.entities.create().id;
    this.addComponents(eid, components);
    return eid;
  }

  // eventually we will remove the entity object in favor of ECS typed array storage
  // and will access that data via getters like this one
  // but for now reusing an entity reference is faster than repeated getter calls
  entitySignature(eid: EntityId) {
    const entity = this.entities.getEntity(eid);
    if (!entity) {
      t3dev().log.error(
        `cannot get entity signature: entity ${eid} does not exist`
      );
      return undefined as unknown as Signature;
    }

    return entity.signature;
  }

  addComponent(eid: EntityId, component: Component) {
    const entity = this.entities.getEntity(eid);
    if (!entity) {
      t3dev().log.error(
        `cannot add component ${component}: entity ${eid} does not exist`
      );
      return undefined as unknown as EntityId;
    }

    component.onDirty = this.onComponentChanged;
    component.dirty = false;
    this.components.addComponentById(eid, component);

    if (!entity.signature.has(component.type)) {
      entity.signature.add(component.type);
      this.onEntityChanged(entity, component);
    }

    return eid;
  }

  addComponents(eid: EntityId, components: Component[]) {
    for (const component of components) {
      this.addComponent(eid, component);
    }

    return eid;
  }

  removeComponent(eid: EntityId, component: Component | typeof Component) {
    const entity = this.entities.getEntity(eid);
    if (!entity) {
      t3dev().log.error(
        `could not remove component from entity ${eid}: entity does not exist`
      );
      return;
    }

    // eventually we will pass around ComponentClass instead
    // which can be an instance OR a Class and provides static functions like `.type`
    // but EcsInstance uses this signature so we stick with it for now
    if (component instanceof Component) {
      this.components.removeComponent(component);
    } else {
      this.components.removeComponentTypeById(eid, component);
    }

    if (entity.signature.has(component.type)) {
      entity.signature.delete(component.type);
      this.onEntityChanged(entity, component);
    }
  }

  removeComponents(
    eid: EntityId,
    components: (Component | typeof Component)[]
  ) {
    for (const component of components) {
      this.removeComponent(eid, component);
    }
  }
}

export interface Registerable {
  register: (sys2: Sys2) => Sys2System;
}

export interface Sys2System {
  deregister?: () => void;
  init?: (state: RootReducer) => void;
}
