import { v4 as uuid } from 'uuid';
import {
  reducerFromHandlers,
  generateDefaultActionHandlers,
} from 'modules/common';
import { NODE } from 'utils/constants';

/*******************
 * Action Creators
 *******************/

/**
 * @param {import('types').ModelNode} node
 * @returns {import('types').MetraSimpleAction<import('types').ModelNode[]>} action
 */
export const addNode = (node) => addNodes([node]);
/**
 * @param {import('types').ModelNode[]} node
 * @returns {import('types').MetraSimpleAction<import('types').ModelNode[]>} action
 */
export const addNodes = (nodes) => ({
  type: NODE.CREATE,
  payload: nodes,
});

/**
 * Makes an action for creating new node data.
 * @param {Partial<import('types').ModelNode>} props - a complete or partial edge def
 * @return {import('types').ModelNode} A edge creation action
 */
export const createNode = ({
  label = '',
  id = uuid(),
  edgeIds = [],
  setIds = [],
} = {}) => {
  edgeIds ||= [];
  label ||= '';
  setIds ||= [];
  id ||= uuid();
  return {
    id,
    label,
    edgeIds,
    setIds,
  };
};

/**
 * Makes an action for updating a node
 *
 * @param {import('types').ModelNode} node
 * @returns {import('types').MetraSimpleAction<import('types').ModelNode[]>} action
 */
export const updateNode = (updatedNode) => updateNodes([updatedNode]);
/**
 * Makes an action for updating nodes
 *
 * @param {import('types').ModelNode[]} nodes
 * @returns {import('types').MetraSimpleAction<import('types').ModelNode[]>} action
 */
export const updateNodes = (nodes) => ({
  type: NODE.UPDATE,
  payload: nodes,
});

/**
 * Makes an action for deleting a node
 *
 * @param {import('types').UUID } id - the uuid of the node
 * @return {import('types').MetraSimpleAction<import('types').UUID[]>} A node delete action
 */
export const deleteNode = (node) => deleteNodes([node]);
/**
 * Makes an action for deleting nodes
 *
 * @param {import('types').UUID[]} id - the uuid of the node
 * @return {import('types').MetraSimpleAction<import('types').UUID[]>} A node delete action
 */
export const deleteNodes = (nodes) => ({
  type: NODE.DELETE,
  payload: nodes,
});

/**
 * intiailizes the reducer with node data.
 * @param {Record<UUID, import('types').MetraNode>} nodes - nodes to set the reducer to.
 */
export const initNodes = (nodes) => ({
  type: NODE.INITIALIZE,
  payload: nodes,
});

/*******************
 * Reducers
 *******************/

export const initNodeReducer = (state, nodes = {}) => nodes;

//Make the reducer
export const nodeReducer = reducerFromHandlers(
  generateDefaultActionHandlers(NODE, {
    [NODE.INITIALIZE]: initNodeReducer,
    [NODE.DELETE]: (state) => state, //this is taken care of by the higherOrderReducer
  })
);
