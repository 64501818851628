import type { LaneParse, QualifiedRef } from 'types';

export interface MetraNode extends math.MathNode {
  root: QualifiedRef;
}

export function makeMetraNode(node: math.MathNode, root: QualifiedRef) {
  const returnNode: MetraNode = node as any;
  returnNode.root = root;
  return returnNode;
}

export interface MetraMathNode extends math.MathNode {
  exp: QualifiedRef;
  root: QualifiedRef;
  name?: QualifiedRef | string;
}

export function makeMetraMathNode(
  node: math.MathNode,
  exp: QualifiedRef,
  root: QualifiedRef,
  name?: QualifiedRef | string
) {
  const returnNode: MetraMathNode = node as any;
  returnNode.root = root;
  returnNode.exp = exp;
  returnNode.name = name;
  return returnNode;
}

export declare interface MetraMathArrayNode extends math.ArrayNode {
  lane: LaneParse;
  root: QualifiedRef;
}

export function makeMetraMathArrayNode(
  node: math.ArrayNode,
  lane: LaneParse,
  root: QualifiedRef
) {
  const returnNode: MetraMathArrayNode = node as any;
  returnNode.root = root;
  returnNode.lane = lane;
  return returnNode;
}

export declare interface MetraMathAccessorNode extends math.AccessorNode {
  object: MetraMathNode;
  root: QualifiedRef;
}

export function makeMetraMathAccessorNode(
  node: math.AccessorNode,
  object: MetraMathNode,
  root: QualifiedRef
): MetraMathAccessorNode {
  const returnNode: MetraMathAccessorNode = node as any;
  returnNode.root = root;
  returnNode.object = object;
  return returnNode;
}
