import { debounce } from 'lodash';
import {
  MetraAction,
  ModelExpressions,
  GetStateFunc,
  InterpreterOptions,
  MetraSimpleAction,
  ModelPropertyValueUpdate,
  TableSort,
  UpdatedExpressionsResults,
  PropertyExpressionUpdate,
  PasteMapperResult,
  InterpreterWorker,
  ThunkAction,
  MetraDispatch,
  ModelReducer,
} from 'types';
import { EXPRESSIONS } from 'modules/common/constants';
import { TIMEOUTS } from 'utils/constants';
import { setPropertyValues } from 'modules/model/properties/values';
import { Interpreter } from 'interpreter/interpreter';
import { defaultWorkers, renamerWorkers } from 'interpreter/workers/workers';
import { pasteMapper } from 'interpreter/workers/pasteMapper';
import { logError } from 'utils/utils-extra';
import cloneDeep from 'lodash/cloneDeep';
import {
  setHyperlinksExpression,
  setModelLinks,
} from 'modules/model/modelLinks';
import { remember } from 'modules/model/base/actions';

let lastEvaluatedSort: Option<TableSort> = null;

function evaluateExpressions<Options>(
  state: ModelReducer,
  options: Partial<InterpreterOptions<Options>> = {}
): UpdatedExpressionsResults<ModelPropertyValueUpdate[]> {
  let propValues: Option<ModelPropertyValueUpdate[]>;
  let rebuildCache = options.rebuildCache || false;

  if (options.sort && options.sort.oldSort !== options.sort.newSort) {
    const renamer = new Interpreter<ModelPropertyValueUpdate[], Options>(
      renamerWorkers,
      options
    );
    const results = renamer.interpret(state, options);
    state = results.state;
    propValues = results.extra;
    rebuildCache = true;
  }

  const extendedOptions = {
    ...options,
    rebuildCache,
  };
  const interpreter = new Interpreter<ModelPropertyValueUpdate[], Options>(
    defaultWorkers,
    extendedOptions
  );
  const results = interpreter.interpret(state, extendedOptions);

  return { results, propValues };
}

export function updateExpressionsSync<O>(
  dispatch: MetraDispatch,
  getState: GetStateFunc,
  options: Partial<InterpreterOptions<O>>
) {
  const extendedOptions = {
    dispatch,
    ...options,
  };
  const state = getState();
  const results = evaluateExpressions<O>(
    cloneDeep(state.modelReducer),
    extendedOptions
  );
  const {
    results: {
      state: { expressions },
    },
    propValues,
  } = results;

  dispatch({
    type: EXPRESSIONS.UPDATE,
    payload: expressions,
  });

  if (propValues) dispatch(setPropertyValues(propValues));

  dispatch(setModelLinks());
  dispatch(setHyperlinksExpression());

  // remember how things were sorted now so that next time we need to
  // re-evaluate we can figure out where the cell-references should point
  if (options.sort?.newSort) lastEvaluatedSort = options.sort.newSort;
  dispatch(setCalculationsIsRunning(false));
}

const debouncedUpdateExpressions = debounce(
  updateExpressionsSync<any>,
  TIMEOUTS.PERCEPTION
);

export function setCalculationsIsRunning(payload: boolean) {
  return {
    type: EXPRESSIONS.IS_RUNNING,
    payload,
  };
}

export function updateExpressions<O>(
  options: Partial<InterpreterOptions<O>>
): ThunkAction<void> {
  return (dispatch, getState) => {
    dispatch(setCalculationsIsRunning(true));
    debouncedUpdateExpressions(dispatch, getState, options);
  };
}

/**
 * Wrap this action around another action to update expressions
 * This action will check for changes in sort order and update cell references
 *
 * Set sync = true to prevent the update from being debounced. Mostly useful
 * for testing
 * @param action - the action being dispatched
 * @param sync - whether to execute synchronously (true) or debounced (false)
 * @returns a metra thunk action
 */
export function withUpdatedExpressions<P1, P2, O>(
  action: MetraAction<P1, P2, void> | ThunkAction<void>,
  sync = false,
  options: Partial<InterpreterOptions<O>> = {}
): ThunkAction<void> {
  return (dispatch, getState) => {
    // we need to know what the cell-references referred to before-update so
    // we can update them correctly.  but the modelReducer.tableSort may not
    // reflect the basis of the cell-references in the current state of the
    // expressions if we've debounced-postponed updating the expressions.
    // (tableSort may reflect a newer state of affairs than the
    // cell-references do).
    if (!lastEvaluatedSort)
      lastEvaluatedSort = getState().modelReducer.tableSort;
    const oldSort = lastEvaluatedSort;
    if (typeof action === 'function') {
      dispatch(action);
    } else {
      dispatch(action);
    }
    const newSort = getState().modelReducer.tableSort;
    if (sync) {
      updateExpressionsSync<O>(dispatch, getState, {
        sort: { oldSort, newSort },
      });
    } else {
      dispatch(
        updateExpressions({
          rebuildCache: true,
          sort: { oldSort, newSort },
          ...options,
        })
      );
    }
  };
}

export function initExpressions(
  expressions: ModelExpressions
): MetraSimpleAction<ModelExpressions> {
  return {
    type: EXPRESSIONS.INITIALIZE,
    payload: expressions,
  };
}

/**
 * attempts to re-map the given values returning the mapped results
 */
export function mapPastedValues<
  Results extends PasteMapperResult[],
  Options extends PropertyExpressionUpdate[]
>(options: InterpreterOptions<Options>): ThunkAction<Option<Results>> {
  return (_dispatch, getState) => {
    try {
      const interpreter = new Interpreter<Results, Options>(
        [pasteMapper],
        options
      );

      const result = interpreter.interpret(getState().modelReducer, options);
      return result.extra;
    } catch (e) {
      logError(e, 'ERROR WHILE INTERPRETING:');
      throw new Error(`failure to map values for ${pasteMapper}! `);
    }
  };
}

export function mapValues<Results, Options>(
  mappers: InterpreterWorker<Results, Options>[],
  options?: InterpreterOptions<Options>
): ThunkAction<Option<Results>> {
  return (_dispatch, getState) => {
    try {
      const interpreter = new Interpreter<Results, Options>(mappers, options);
      let interpreterModel: ModelReducer = getState().modelReducer;
      if (options?.extraOpts && (options.extraOpts as any).modelState) {
        interpreterModel = (options.extraOpts as any).modelState;
      }
      const results = interpreter.interpret(interpreterModel, options);
      return results.extra;
    } catch (e: any) {
      logError(e, 'ERROR WHILE INTERPRETING:');
      throw new Error(`failure to map values for ${mappers}! `);
    }
  };
}
