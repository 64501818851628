import {
  generateDefaultActionHandlers,
  reducerFromHandlers,
} from 'modules/common/genericReducer';
import { UUID } from 'types/common';
import { ModelPropertySchema } from 'types/index';
import { PROPERTY_SCHEMA } from 'utils/constants';

export const initReducer = (
  _state: Record<UUID, ModelPropertySchema>,
  schemas = {}
) => schemas;

//Make the reducer
export const schemasReducer = reducerFromHandlers<
  Record<UUID, ModelPropertySchema>
>(
  generateDefaultActionHandlers(PROPERTY_SCHEMA, {
    [PROPERTY_SCHEMA.INITIALIZE]: initReducer,
    [PROPERTY_SCHEMA.DELETE]: (state) => state,
  })
);
