import { buildMediaTagURL } from 'utils/url-builders';
import {
  apiGet,
  apiPost,
  UPDATE_RECENT_TAGS,
  ENTITIES,
  isApiErrorAction,
  MEDIA,
  NO_OP,
} from 'modules/common';
import { MESSAGES } from 'modules/ui/messages';
import {
  FetchedTagsResult,
  GetStateFunc,
  MetraTag,
  MetraThunkDispatch,
  NormalizedResult,
  ThunkActionFunc,
} from 'types';
import { isNone } from 'helpers/utils';
import { makeNormalizedResult, makeReadSuccess } from 'modules/entities/utils';
import { asyncSleep } from 'utils/utils';
import { searchProjects } from 'modules/project/project';
import {
  addSearchTag,
  clearSearch,
  setDisplaySearchResults,
  setSearchPaginationRows,
} from 'modules/search/actions';

export const createTags: ThunkActionFunc<
  [tagList: string[], mediaId: Numberish],
  Promise<void>
> = (tagList, mediaId) => {
  return async (dispatch, getState) => {
    let promises = tagList.map((tag) => {
      return dispatch(
        apiPost<NormalizedResult<'tags'>>({
          entity: `media/${mediaId}/tags/append`,
          body: JSON.stringify({
            name: tag,
          }),
          headers: { 'Content-Type': 'application/json' },
          types: [ENTITIES.ACTION_SUCCESS, MEDIA.UPLOAD_FAILURE],
          meta: { schema: 'tags', mutation: ENTITIES.MUTATE_CREATE },
        })
      );
    });
    await Promise.all(promises);
    // give some time to process on backend
    await asyncSleep(500);
    // they should be ready
    const fetches = await dispatch(fetchMediaTags(mediaId));
    if (isNone(fetches)) return;
    let mutableMedia = Object.clone(
      getState().entityReducer.media[mediaId].asMutable({ deep: true })
    );
    const { tags } = fetches;
    mutableMedia.tags = tags;
    dispatch(
      makeReadSuccess(makeNormalizedResult(mutableMedia, 'media'), 'media')
    );
  };
};

export const deleteTagFromMedia: ThunkActionFunc<
  [Numberish, Numberish],
  Promise<void>
> = (id, mediaId) => {
  return async (dispatch, getState) => {
    const tag = getState().entityReducer.tags[id];

    await dispatch(
      apiPost({
        entity: `media/${mediaId}/tags/remove`,
        body: JSON.stringify({
          name: tag.name,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
        types: [ENTITIES.ACTION_SUCCESS, MEDIA.UPDATE_FAILURE],
        error: MESSAGES.ERROR.UPDATE,
        meta: { schema: 'tags' },
      })
    );
    const results = await dispatch(fetchMediaTags(mediaId));
    if (isNone(results)) return;

    let mutableMedia = Object.clone(
      getState().entityReducer.media[mediaId].asMutable({ deep: true })
    );
    const { tags } = results;
    mutableMedia.tags = tags;
    await dispatch(
      makeReadSuccess(makeNormalizedResult(mutableMedia, 'media'), 'media')
    );

    const state = getState();
    const mediaRecords = state.entityReducer.media.asMutable();
    const mediaType = mediaRecords[mediaId]?.media_type;
    const tagIds = [
      ...new Set(
        Object.values(mediaRecords)
          .filter((media) => media.media_type === mediaType)
          .reduce<Numberish[]>((acc, record) => {
            const tags = record.getIn(['tags']).asMutable();

            return [...acc, ...tags];
          }, [])
      ),
    ];

    dispatch({
      type: UPDATE_RECENT_TAGS,
      payload: tagIds,
    });
  };
};

export const getTagSearch: ThunkActionFunc<
  [string, (tags: MetraTag[]) => void],
  Promise<void>
> = (search, callback) => {
  return async (dispatch) => {
    const response = await dispatch(
      apiGet<NormalizedResult<'tags'>>({
        entity: 'tags',
        params: { search: search.toLowerCase() },
        types: [ENTITIES.ACTION_SUCCESS, MEDIA.GET_FAILURE],
        meta: { schema: 'tags' },
      })
    );
    if (isApiErrorAction(response)) return;
    callback(Object.values(response.payload.entities.tags));
  };
};

export const fetchMediaTags: ThunkActionFunc<
  [Numberish, Option<string>],
  Promise<Option<FetchedTagsResult>>
> =
  (mediaId, nextUrl = null) =>
  async (dispatch) => {
    const url = nextUrl || buildMediaTagURL(mediaId); // `/api${buildGuildUrl()}/media/${mediaId}/tags`;
    const results = await dispatch(
      apiGet<NormalizedResult<'tags' | 'guilds'>>({
        explicit: url,
        types: [NO_OP.SUCCESS, NO_OP.FAILURE],
      })
    );
    if (isApiErrorAction(results)) return null;

    const tagResults: FetchedTagsResult = {
      entities: results.payload.entities,
      tags: results.payload.results,
      next: results.payload.next,
    };

    return tagResults;
  };

export const loadMediaTags: ThunkActionFunc<
  [Numberish, Option<string>],
  Promise<void>
> = (mediaId, nextUrl = null) => {
  return async (dispatch, getState) => {
    const results = await dispatch(fetchMediaTags(mediaId, nextUrl));
    if (isNone(results)) return;
    const { tags, next } = results;

    // don't bother updating if there are no tags
    if (tags.length < 1) return;

    let mutableMedia = getState().entityReducer.media[mediaId].asMutable({
      deep: true,
    });
    mutableMedia.tags.add(tags);
    mutableMedia.next = next;
    dispatch(
      makeReadSuccess(makeNormalizedResult(mutableMedia, 'media'), 'media')
    );
  };
};

export const searchProjectTags =
  (tagName: string) =>
  async (dispatch: MetraThunkDispatch, getState: GetStateFunc) => {
    dispatch(addSearchTag(tagName));
    await dispatch(
      searchProjects({
        [getState().searchReducer.searchField]:
          getState().searchReducer.searchText,
      })
    );
  };

export const doTagSearch =
  (tag: MetraTag) => (dispatch: MetraThunkDispatch) => {
    dispatch(clearSearch());
    dispatch(setDisplaySearchResults(true));
    dispatch(setSearchPaginationRows({ numberOfRows: 50 }));
    dispatch(addSearchTag(tag.name));
  };
