import {
  AnimatedSprite,
  ITextStyle,
  Sprite as PSprite,
  TextStyle,
  Text as PText,
  Texture,
  BaseTexture,
  Resource,
  IAutoDetectOptions,
} from 'pixi.js';
import { EventEmitter as EventEmitter3 } from 'eventemitter3';
import { Asset, GIFSprite, Label } from 'engine/components';
import { EventManager } from 'metra-events';
import {
  DEFAULT_ASSETS,
  DEFAULT_NODE_PLACEHOLDER,
  VALID_EDGE_ASSETS,
  getAssetUrl,
} from 'modules/model/assets';
import { ENGINE, EventModeType } from 'utils/constants-extra';
import type { ModelEngine } from 'engine/engine';
import { buildLoadAsset } from 'engine/factories/utils';
import { HEX_COLOR } from 'utils/constants';

export function fixEdgeAsset(asset: string): string {
  return VALID_EDGE_ASSETS.includes(asset) ? asset : 'solidLine';
}

export function updateOrCreateLabel(
  labelText: string,
  textStyle: Partial<ITextStyle> = {},
  darkMode: boolean,
  label = new Label(),
  autoDestroy = false,
  [_engine] = EventManager.emit<[ModelEngine]>(ENGINE.GET.SELF)
): [label: Label, oldAsset: PText] {
  const oldAsset = label.asset;
  if (autoDestroy && oldAsset) oldAsset.destroy(true);

  const colors = darkMode
    ? { stroke: HEX_COLOR.BLACK, fill: HEX_COLOR.WHITE }
    : { stroke: HEX_COLOR.WHITE, fill: HEX_COLOR.BLACK };

  label.value = labelText;
  // build style
  const newStyle = new TextStyle({
    fill: colors.fill,
    fontFamily: 'Roboto,sans-serif',
    fontSize: 14, // .797em
    fontWeight: 'bold',
    lineJoin: 'round',
    stroke: colors.stroke,
    strokeThickness: 5,
    ...textStyle,
  });

  // BitmapFont.from('MetraLabelFont', {

  // });

  // create new text and remove old, destroying it
  const text = new PText(labelText, newStyle);
  label.asset = text;
  label.asset.resolution = 3;
  label.asset.anchor.set(0.5, 0.5);

  return [label, oldAsset];
}

export function updateOrCreateGIFSprite(
  asset: Asset,
  sprite = new GIFSprite(),
  autoDestroy = false,
  [engine] = EventManager.emit<[ModelEngine]>(ENGINE.GET.SELF)
): [
  gifSprite: GIFSprite,
  oldAsset: PSprite | AnimatedSprite,
  loading: boolean
] {
  let loading = false;
  const oldAsset = sprite.asset.val;
  const assetUrl = getAssetUrl(asset);
  if (autoDestroy && oldAsset) {
    if ('textures' in oldAsset) {
      // since `AnimatedSprite` does not properly destroy its texture array
      // we need to do it manually
      oldAsset.textures.forEach((texture) =>
        (texture as EventEmitter3).off(
          'update',
          (oldAsset as any)._onUpdateTexture,
          oldAsset
        )
      );
    }
    oldAsset.destroy({
      baseTexture: false,
      children: true,
      texture: true,
    });
  }
  // build sprite
  if (DEFAULT_ASSETS.includes(assetUrl)) {
    const baseTexture = engine.getDefaultTexture(assetUrl);
    if (!baseTexture) {
      throw new Error('default asset not in cache!');
    }
    sprite.asset.val = new PSprite(new Texture(baseTexture));
    sprite.textureUrl.val = assetUrl;
    if (oldAsset) {
      sprite.asset.val.alpha = oldAsset.alpha;
      sprite.asset.val.zIndex = oldAsset.zIndex;
    }
    sprite.baseWidth.val = baseTexture.width;
    sprite.baseHeight.val = baseTexture.height;
  } else if (engine.assetAvailable(assetUrl)) {
    const metraAsset = engine.getAsset(assetUrl);
    if (!metraAsset || metraAsset.message !== 'success') {
      throw new Error(`passed asset "${assetUrl}" not actually in cache!`);
    } else if (metraAsset.isGIF) {
      const anim = new AnimatedSprite(
        metraAsset.asset.textures.map(
          (bt) => new Texture(bt as BaseTexture<Resource, IAutoDetectOptions>)
        ),
        true
      );
      anim.animationSpeed = metraAsset.asset.animationSpeed;
      sprite.asset.val = anim;
      sprite.textureUrl.val = assetUrl;
      if (oldAsset) {
        sprite.asset.val.alpha = oldAsset.alpha;
        sprite.asset.val.zIndex = oldAsset.zIndex;
      }
      sprite.baseWidth.val = metraAsset.width;
      sprite.baseHeight.val = metraAsset.height;
    } else {
      sprite.asset.val = new PSprite(
        new Texture(
          metraAsset.asset as BaseTexture<Resource, IAutoDetectOptions>
        )
      );
      sprite.textureUrl.val = assetUrl;
      if (oldAsset) {
        sprite.asset.val.alpha = oldAsset.alpha;
        sprite.asset.val.zIndex = oldAsset.zIndex;
      }
      sprite.baseWidth.val = metraAsset.width;
      sprite.baseHeight.val = metraAsset.height;
    }
  } else {
    loading = true;
    // display placeholder
    const texture = engine.getDefaultTexture(DEFAULT_NODE_PLACEHOLDER);
    if (!texture) {
      throw new Error('placeholder not in cache!');
    }
    sprite.asset.val = new PSprite(new Texture(texture));
    if (oldAsset) {
      sprite.asset.val.alpha = oldAsset.alpha;
      sprite.asset.val.zIndex = oldAsset.zIndex;
    }
    sprite.baseWidth.val = texture.width;
    sprite.baseHeight.val = texture.height;

    buildLoadAsset(engine.ecs, assetUrl, assetUrl);
  }

  sprite.asset.val.eventMode = EventModeType.STATIC;
  return [sprite, oldAsset, loading];
}

const GRAYSCALE_MARKER_SUFFIX = ':Tenet3:grayscale';

export function createGrayscaleGifSpriteURL(url: string) {
  return `${url}${GRAYSCALE_MARKER_SUFFIX}`;
}
