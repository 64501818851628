import assetCircle from './circle.png';
import assetSquare from './square.png';
import assetTriangle from './triangle.png';
import assetEdgeHandle from './edge-handle.png';
import assetSegment from './segment.png';
import collapsibleIcon from './collapsible-icon.png';
import imgPlaceholder from './image-regular.png';
import solidLine from './edgeStyle_solid.png';
import dottedLine from './edgeStyle_dots.png';
import shortDashLine from './edgeStyle_shortDash.png';
import longDashLine from './edgeStyle_longDash.png';
import sqDashLine from './edgeStyle_sqDash.png';
import sqLongDashLine from './edgeStyle_sqLongDash.png';
import circlePlusArrowWithArrowhead from './arrowWithArrowhead_circlePlus.png';
import curvedArrowheadFilledDotArrowWithArrowhead from './arrowWithArrowhead_curvedArrowheadFilledDot.png';
import unfilledArrowArrowWithArrowhead from './arrowWithArrowhead_unfilledArrow.png';
import filledDotArrowWithArrowhead from './arrowWithArrowhead_filledDot.png';
import curvedArrowheadArrowWithArrowhead from './arrowWithArrowhead_curvedArrowhead.png';
import filledArrowArrowWithArrowhead from './arrowWithArrowhead_filledArrow.png';
import filledDiamondArrowWithArrowhead from './arrowWithArrowhead_filledDiamond.png';
import unfilledDiamondArrowWithArrowhead from './arrowWithArrowhead_unfilledDiamond.png';
import circlePlusArrowhead from './arrowhead_circlePlus.png';
import curvedArrowheadFilledDotArrowhead from './arrowhead_curvedArrowheadFilledDot.png';
import unfilledArrowArrowhead from './arrowhead_unfilledArrow.png';
import filledDotArrowhead from './arrowhead_filledDot.png';
import curvedArrowheadArrowhead from './arrowhead_curvedArrowhead.png';
import filledArrowArrowhead from './arrowhead_filledArrow.png';
import filledDiamondArrowhead from './arrowhead_filledDiamond.png';
import unfilledDiamondArrowhead from './arrowhead_unfilledDiamond.png';
import none from './none.png';

/** @type {import('types').PixiAsset[]} */
const assets = [
  { name: 'default', url: assetCircle },
  { name: 'square', url: assetSquare },
  { name: 'triangle', url: assetTriangle },
  { name: 'edgeHandle', url: assetEdgeHandle },
  { name: 'segment', url: assetSegment },
  { name: 'collapsibleIcon', url: collapsibleIcon },
  { name: 'imgPlaceholder', url: imgPlaceholder },
  { name: 'solidLine', url: solidLine },
  { name: 'dottedLine', url: dottedLine },
  { name: 'shortDashLine', url: shortDashLine },
  { name: 'longDashLine', url: longDashLine },
  { name: 'sqDashLine', url: sqDashLine },
  { name: 'sqLongDashLine', url: sqLongDashLine },
  { name: 'circlePlusArrowWithArrowhead', url: circlePlusArrowWithArrowhead },
  {
    name: 'curvedArrowheadFilledDotArrowWithArrowhead',
    url: curvedArrowheadFilledDotArrowWithArrowhead,
  },
  {
    name: 'unfilledArrowArrowWithArrowhead',
    url: unfilledArrowArrowWithArrowhead,
  },
  { name: 'filledDotArrowWithArrowhead', url: filledDotArrowWithArrowhead },
  {
    name: 'curvedArrowheadArrowWithArrowhead',
    url: curvedArrowheadArrowWithArrowhead,
  },
  { name: 'filledArrowArrowWithArrowhead', url: filledArrowArrowWithArrowhead },
  {
    name: 'filledDiamondArrowWithArrowhead',
    url: filledDiamondArrowWithArrowhead,
  },
  {
    name: 'unfilledDiamondArrowWithArrowhead',
    url: unfilledDiamondArrowWithArrowhead,
  },
  { name: 'circlePlus', url: circlePlusArrowhead },
  {
    name: 'curvedArrowheadFilledDot',
    url: curvedArrowheadFilledDotArrowhead,
  },
  { name: 'unfilledArrow', url: unfilledArrowArrowhead },
  { name: 'none', url: none },
  { name: 'filledDot', url: filledDotArrowhead },
  { name: 'curvedArrowhead', url: curvedArrowheadArrowhead },
  { name: 'filledArrow', url: filledArrowArrowhead },
  { name: 'filledDiamond', url: filledDiamondArrowhead },
  { name: 'unfilledDiamond', url: unfilledDiamondArrowhead },
];

export default assets;
