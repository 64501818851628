// import Cookies from 'js-cookie';
// import { GifReader } from 'omggif';
// import { Texture } from 'pixi.js';
// import { AnimatedGIF } from '@pixi/gif';
import { Injectable, LazyLoadedResult, MetraAsset, ShapeConfig } from 'types';
import type { ModelLoader } from 'engine/loader';
import type { ModelEngine } from 'engine/engine';
import { EventManager } from 'metra-events';
import { ENGINE } from 'utils/constants-extra';
import { t3dev } from 't3dev';
import { isNone } from 'helpers/utils';
import { hasAsset } from './shape/shape-helpers';
import { getEntityAssetURLs } from 'utils/url-builders';
import { ThumbnailMode } from 'engine/constants';
import type { Asset } from 'engine/components';
// import { logError } from 'utils/utils-extra';

/**
 * all default assets
 */
export const DEFAULT_ASSETS = [
  'default',
  'edgeHandle',
  'circlePlus',
  'curvedArrowhead',
  'curvedArrowheadFilledDot',
  'filledArrow',
  'filledDiamond',
  'filledDot',
  'unfilledArrow',
  'unfilledDiamond',
  'square',
  'triangle',
  'segment',
  'solidLine',
  'dottedLine',
  'shortDashLine',
  'longDashLine',
  'sqDashLine',
  'sqLongDashLine',
  'imgPlaceholder',
];

export const DEFAULT_NODE_PLACEHOLDER = 'imgPlaceholder';
export const DEFAULT_EDGE_PLACEHOLDER = 'solidLine';

const DEFAULT_NODE_ASSETS: Record<string, boolean> = {
  default: true,
  square: true,
  triangle: true,
};

export function isDefaultNodeAsset(asset: string) {
  return DEFAULT_NODE_ASSETS[asset];
}

export const VALID_EDGE_ASSETS = [
  'solidLine',
  'dottedLine',
  'shortDashLine',
  'longDashLine',
  'sqDashLine',
  'sqLongDashLine',
];

export function getModelLoader(loader?: ModelLoader): ModelLoader {
  let _loader: ModelLoader;
  if (isNone(loader)) {
    const [engine] = EventManager.emit<[ModelEngine]>(ENGINE.GET.SELF);
    if (engine.destroyed)
      throw new Error('UNSAFE ACCESS TO DESTROYED ENGINE LOADER!');
    _loader = engine.loader;
  } else {
    _loader = loader;
  }
  return _loader;
}

/**
 * checks if the asset is in the current texture cache
 * @param asset - asset name to check.
 * @returns true if found, false if not.
 */
export function isAssetInTextureCache(
  asset: string,
  loader: ModelLoader = getModelLoader()
): boolean {
  return loader.assetInCache(asset);
}

/**
 * Removes texture associated with asset in TextureCache.
 * Replaces with default node texture.
 * @param asset - shape asset url that failed
 */
export async function fallbackBadAsset(
  asset: string,
  loader: ModelLoader = getModelLoader()
): Promise<void> {
  t3dev().log.log(`fallback to "default" on asset "${asset}"`);
  await loader.assets.unload(asset);
  loader.assets.add(asset, 'default');
  const foo = await loader.assets.load(asset);
  t3dev().log.log('?', { foo });
}

/**
 * creates url from blobData to create a texture to be added to the TextureCache,
 * keyed with respective asset
 * @param response - response from request for asset
 * @param asset - url of the file
 * @param [loader]- current model loader
 * @returns  resolve when asset successfully loaded. reject on error processing texture
 */
export async function pendingAssetToTextureCache(
  response: Response,
  asset: string,
  loader: ModelLoader = getModelLoader()
): Promise<void> {
  if (loader.assetInCache(asset)) return;
  // const blob = await response.blob();
  // const url = URL.createObjectURL(blob);
  // const rawbuff = await response.arrayBuffer();
  // const decoder = new ImageDecoder({
  //   type: 'image/png',
  //   data: rawbuff,
  // });
  // const texture = Texture.from(url);
  // loader.addTextureAsset(asset, texture);
  // Texture.addToCache(texture, asset);
  // return new Promise((resolve, reject) => {
  //   texture.baseTexture.on('update', () => {
  //     // check for updates as they come in.
  //     // resolve once an update contains a valid texture
  //     if (texture.baseTexture.valid) {
  //       resolve();
  //     }
  //   });
  //   texture.baseTexture.on('error', () => {
  //     reject();
  //   });
  // });
}

/**
 * creates url from blobData to create a texture to be added to the TextureCache keyed
 * with respective asset
 * @param asset - the url of the asset to be loaded into pixi
 * @param [isBackground] - flag for if image is a background
 * @param [isThumbnail]- flag for if image is a thumbnail
 * @param [loader] - current model loader
 * @param [injectables]
 * @returns a promise that resolves with the result of processing the image
 */
export async function loadAssetToTextureCache(
  asset: string,
  isBackground = false,
  isThumbnail = false,
  loader: ModelLoader = getModelLoader(),
  injectables: Injectable = {}
): Promise<LazyLoadedResult> {
  const injected = {
    pendingAssetToTextureCache,
    pendingGifAssetToFrameCache,
    ...injectables,
  };
  let [assetURL, smallURL, largeURL] = getEntityAssetURLs(asset);
  // let assetURL = asset;
  // const thumbSize = isBackground ? 'large' : 'small';
  // if (isThumbnail) assetURL = `${assetURL}?size_maximum=${thumbSize}`;

  let url = isBackground
    ? isThumbnail
      ? largeURL
      : assetURL
    : isThumbnail
    ? smallURL
    : assetURL;
  loader.assets.add([asset, url], url);
  const resources = await loader.assets.load<MetraAsset>(url);
  const message = resources.message;
  t3dev().log.log('what is this:', {
    asset,
    assetURL,
    smallURL,
    largeURL,
    resources,
    message,
  });
  // if (response.status === 200) {
  //   try {
  //     if (response.headers.get('Content-Type') === 'image/gif') {
  //       await injected.pendingGifAssetToFrameCache(response, asset, loader);
  //     } else {
  //       await injected.pendingAssetToTextureCache(response, asset, loader);
  //     }
  //     message = 'success';
  //   } catch (error: any) {
  //     t3dev().log.error('ERROR ADDING MODEL ASSET TO CACHE:', error.toString());
  //     message = 'badAsset';
  //   }
  // } else {
  //   try {
  //     const json = await response.json();
  //     message = json.error?.includes('quarantined') ? 'quarantined' : 'error';
  //   } catch (error: any) {
  //     t3dev().log.error(
  //       'ERROR READING ASSET RESPONSE API ERROR MESSAGE',
  //       error.toString()
  //     );
  //     message = 'badAsset';
  //   }
  // }
  // if (message === 'badAsset') {
  //   await fallbackBadAsset(asset, loader);
  // }

  return {
    asset,
    message,
  };
}

export function assetIsGIF(
  asset: string,
  loader: ModelLoader = getModelLoader()
): boolean {
  return loader.assetIsGif(asset);
}

/**
 * attempts to processess a GIF, caching it in the `FrameCache` if
 * successful. Handles Disposal Type 1 and 2 GIFs.
 * @param response
 * @param asset
 */
export async function pendingGifAssetToFrameCache(
  response: Response,
  asset: string,
  loader: ModelLoader = getModelLoader()
): Promise<void> {
  if (loader.assetInCache(asset)) return;
  try {
    // const rawBuffer = await response.arrayBuffer();
    // const gif = AnimatedGIF.fromBuffer(rawBuffer, { loop: true });
    // loader.addGifAsset(asset, {
    //   // animationSpeed: 16,
    //   gif,
    //   width: gif.width,
    //   height: gif.height,
    // });
    // const buffer = new Uint8Array(rawBuffer);
    // const gif = new GifReader(buffer);
    // const numFrames = gif.numFrames();
    // // the image is 24bit RGBA color, so height * width * 4 8-bit
    // // segments will give us an array of proper size.
    // let patchData = new Uint8Array(gif.width * gif.height * 4);
    // const textures = [];
    // for (let i = 0; i < numFrames; i++) {
    //   if (gif.frameInfo(i).disposal === 1) {
    //     // type 1 re-use the previous buffer to patch in the new pixel
    //     // data.
    //     gif.decodeAndBlitFrameRGBA(i, patchData);
    //   } else {
    //     // non-type 1 i.e., type 0 & 2, need new buffers for each frame
    //     patchData = new Uint8Array(gif.width * gif.height * 4);
    //     gif.decodeAndBlitFrameRGBA(i, patchData);
    //   }
    //   textures.push(
    //     Texture.fromBuffer(
    //       // since the textures use the buffer as reference data, we need
    //       // to clone the array so that each texture remains different
    //       // i.e., they dont all equal the last frame drawn ;)
    //       new Uint8Array(patchData),
    //       gif.width,
    //       gif.height
    //     )
    //   );
    // }
    // loader.addGifAsset(asset, {
    //   // typical frame rate is 60fps i.e, 16ms, so divide
    //   // that by the frame delay. GIF delays are not in
    //   // milliseconds, so we need to multiply by 10
    //   animationSpeed: 16 / (gif.frameInfo(0).delay * 10),
    //   textures,
    //   width: gif.width,
    //   height: gif.height,
    // } as FrameCacheEntry);
    // loader.setAssetAsGif(asset);
    // loader.assets.cache.set(asset, {
    //   // typical frame rate is 60fps i.e, 16ms, so divide
    //   // that by the frame delay. GIF delays are not in
    //   // milliseconds, so we need to multiply by 10
    //   animationSpeed: 16 / (gif.frameInfo(0).delay * 10),
    //   textures,
    //   width: gif.width,
    //   height: gif.height,
    // });
    // await addGifToCache(asset, {
    //   // typical frame rate is 60fps i.e, 16ms, so divide
    //   // that by the frame delay. GIF delays are not in
    //   // milliseconds, so we need to multiply by 10
    //   animationSpeed: 16 / (gif.frameInfo(0).delay * 10),
    //   textures,
    //   width: gif.width,
    //   height: gif.height,
    // });
    // window[FrameCache][asset] = {
    //   // typical frame rate is 60fps i.e, 16ms, so divide
    //   // that by the frame delay. GIF delays are not in
    //   // milliseconds, so we need to multiply by 10
    //   animationSpeed: 16 / (gif.frameInfo(0).delay * 10),
    //   textures,
    //   width: gif.width,
    //   height: gif.height,
    // };
  } catch (e) {
    t3dev().log.error('ERROR ADDING ASSET TO GIF CACHE:', e);
    throw e;
  }
}

/**
 * Filter the list of all shapeConfigs down to shapeConfigs of assets with an certain error message
 * @param shapeConfigs
 * @param assetsWithMessage
 */
export function getShapeConfigsWithMessage(
  assetsWithMessage: Set<String>,
  shapeConfigs: ShapeConfig[]
): ShapeConfig[] {
  return shapeConfigs.filter(
    (config) => hasAsset(config) && assetsWithMessage.has(config.asset)
  );
}

/**
 * Create a set of all assets that returned from the server with a specific error message.
 * Assumes that files in the same model can not have the same name
 * @param {Array<Object>} results Response from server when trying to load asset
 * @param {string} message Error message from loading asset
 */
export function getAssetsWithMessage(
  results: PromiseSettledResult<LazyLoadedResult>[],
  message: string
): Set<string> {
  const uniqueAssets = new Set<string>();
  results.forEach((result) => {
    if (result.status === 'fulfilled') {
      result.value.message === message && uniqueAssets.add(result.value.asset);
    }
  });
  return uniqueAssets;
}

/**
 * loads the given asset if not in texture cache and prepares an update
 * to all corresponding shape records
 * @param selectedIds - the shapes to update
 * @param asset - the media file to update to
 * @param shapeConfigRecords - current shape records
 * @param isBackground - the media file is for a Background
 * @returns returns a void promise
 */
export async function loadAssetAndPrepShapes(
  selectedIds: string[],
  asset: string,
  shapeConfigRecords: Record<string, ShapeConfig>,
  isBackground: boolean = false,
  loader: ModelLoader = getModelLoader(),
  injectables: Record<string, unknown> = {}
): Promise<void> {
  const injected = {
    isAssetInTextureCache,
    assetIsGIF,
    loadAssetToTextureCache,
    ...injectables,
  };

  const updated: ShapeConfig[] = [];
  try {
    // attempt to load new texture / GIF asset if not already
    // cached
    if (
      !injected.isAssetInTextureCache(asset, loader) &&
      !injected.assetIsGIF(asset)
    ) {
      const _returned = await injected.loadAssetToTextureCache(
        asset,
        isBackground,
        false,
        loader,
        injectables
      );
    }
  } catch (e) {
    t3dev().log.error('ERROR DURING LOAD AND PREP SHAPES', e);
    return;
  }

  // const defaults = ['default', 'square', 'triangle'];
  // for (let i = selectedIds.length; i--; ) {
  //   const config = shapeConfigRecords[selectedIds[i]];
  //   if (
  //     hasAsset(config) &&
  //     (config.type === SHAPE.NODE || config.type === SHAPE.BACKGROUND)
  //   ) {
  //     updated.push(config);
  //     config.asset = asset;
  //     config.color = defaults.includes(config.asset) ? '#33387b' : '#ffffff';
  //   }
  // }
  // EventManager.emit(ENGINE.UPDATE.SHAPES.ASSETS.FINALIZE, updated);
}

const GRAYSCALE_MARKER_SUFFIX = ':Tenet3:grayscale';

export function getAssetUrl(asset: Asset): string {
  // ensure we don't try to use a default asset as a URL base
  if (DEFAULT_ASSETS.includes(asset.value)) return asset.value;
  const assetUrl = new URL(asset.value);
  if (asset.grayscale.val) {
    return `${assetUrl.href}${GRAYSCALE_MARKER_SUFFIX}`;
  }
  return assetUrl.href;
}
