import './loadingbar.scss';
import cn from 'classnames/dedupe';

/**
 * A general purpose thin loading bar to be used throughout the application
 * @return {JSX.Element}
 */
export declare interface LoadingBarProps {
  className?: string;
  isLoading?: boolean;
  message?: string;
  children?: React.ReactNode;
}

export const LoadingBar: React.FC<LoadingBarProps> = ({
  className,
  isLoading = true,
  message,
  children,
}) => {
  const cname = cn(className, 'loader-container');
  return isLoading ? (
    <div className={cname}>
      <div className="loader" />
      {message || children}
    </div>
  ) : (
    <div className="inactive-loader" />
  );
};
