import { v4 as uuid } from 'uuid';
import {
  generateDefaultActionHandlers,
  reducerFromHandlers,
} from 'modules/common/genericReducer';
import { captureMessage } from '@sentry/browser';
import { EDGE } from 'utils/constants';

/* ******************
 * Action Creators
 *******************/

/**
 * @param {import('types').ModelEdge} edge
 * @returns {import('types').MetraSimpleAction<import('types').ModelEdge[]>} action
 */
export const addEdge = (edge) => addEdges([edge]);
/**
 * @param {import('types').ModelEdge[]} edges
 * @returns {import('types').MetraSimpleAction<import('types').ModelEdge[]>} action
 */
export const addEdges = (edges) => ({
  type: EDGE.CREATE,
  payload: edges,
});

/**
 * Makes an action for creating new edges.
 *
 * @param {Partial<import('types').ModelEdge>} props - a complete or partial edge def
 * @return {import('types').ModelEdge} A edge creation action
 */
export const createEdge = ({
  inNodeId,
  outNodeId,
  label = '',
  setIds = [],
  id = uuid(),
}) => {
  if (!inNodeId || !outNodeId) {
    let errMessage = `Invalid node identifiers: (${outNodeId}, ${inNodeId})`;
    captureMessage(errMessage);
    throw errMessage;
  }

  label ||= '';
  setIds ||= [];
  id ||= uuid();

  return {
    id,
    inNodeId,
    outNodeId,
    label,
    setIds,
  };
};

/**
 * Makes an action for updating an edge. Currently on only the label
 * may be updated.
 *
 * @param {import('types').ModelEdge} edge - the updated edge
 * @return {import('types').MetraSimpleAction<import('types').ModelEdge[]>} An edge update action
 */
export const updateEdge = (edge) => updateEdges([edge]);

/**
 * Makes an action for updating edges *
 * @param {import('types').ModelEdge[]} edge - the updated edge
 * @return {import('types').MetraSimpleAction<import('types').ModelEdge[]>} An edge update action
 */
export const updateEdges = (edges) => ({
  type: EDGE.UPDATE,
  payload: edges,
});

/**
 * Makes an action for deleting an edge
 *
 * @param {import('types').UUID} id - the uuid of the node
 * @return {import('types').MetraSimpleAction<import('types').UUID[]>} A node delete action
 */
export const deleteEdge = (edge) => deleteEdges([edge]);

/**
 * Makes an action for deleting edges
 *
 * @param {import('types').UUID[]} id - the uuid of the node
 * @return {import('types').MetraSimpleAction<import('types').UUID[]>} A node delete action
 */
export const deleteEdges = (edges) => ({
  type: EDGE.DELETE,
  payload: edges,
});

/**
 * intiailizes the reducer with edge data.
 * @param {import('types'.MetraSimpleAction<
 *   Record<import('types').UUID, import('types').ModelEdge>
 * >)} edges - edges to set the reducer to.
 */
export const initEdges = (edges) => ({
  type: EDGE.INITIALIZE,
  payload: edges,
});

/*******************
 * Reducers
 *******************/

export const initEdgeReducer = (_state, edges = {}) => edges;

//Make the reducer
export const edgeReducer = reducerFromHandlers(
  generateDefaultActionHandlers(EDGE, {
    [EDGE.INITIALIZE]: initEdgeReducer,
    [EDGE.DELETE]: (state) => state, //this is taken care of by the higherOrderReducer
  })
);
