import { Reducer } from 'redux';
import { MODEL } from 'utils/constants';
import {
  LinkedSearchResults,
  MetraSimpleAction,
  SearchResults,
  ThunkActionFunc,
} from 'types';
import { CellResult } from 'modules/basicSearch/engine';

export const initialSearchResultState: SearchResults = {
  results: [],
  nodes: 0,
  edges: 0,
  sets: 0,
  modelProps: 0,
  modelCalcs: 0,
  paths: 0,
  linked: 0,
  total: 0,
  linkedResults: {},
};

export const initSearchResults = (
  searchResults: SearchResults = initialSearchResultState
): MetraSimpleAction<SearchResults> => ({
  type: MODEL.SEARCH_RESULTS.INIT,
  payload: searchResults,
});

export const setModelSearchResults: ThunkActionFunc<
  [CellResult[], Option<LinkedSearchResults>]
> =
  (cellResults: CellResult[], linkedResults: Option<LinkedSearchResults>) =>
  (dispatch, getState) => {
    linkedResults ||= {};

    let results: SearchResults = {
      results: [...cellResults],
      linkedResults: { ...linkedResults },
      nodes: 0,
      edges: 0,
      sets: 0,
      modelProps: 0,
      modelCalcs: 0,
      paths: 0,
      linked: 0,
      total: 0,
    };

    const resultSets: Record<string, Set<string>> = {
      nodes: new Set(),
      edges: new Set(),
      sets: new Set(),
      modelProps: new Set(),
      modelCalcs: new Set(),
      paths: new Set(),
    };

    for (let i = 0; i < cellResults.length; i++) {
      const [type, rowId] = cellResults[i];
      resultSets[type].add(rowId);
    }

    results.nodes = resultSets.nodes.size;
    results.edges = resultSets.edges.size;
    results.sets = resultSets.sets.size;
    results.modelProps = resultSets.modelProps.size;
    results.modelCalcs = resultSets.modelCalcs.size;
    results.paths = resultSets.paths.size;
    results.linked = Object.values(linkedResults).reduce((sum, { count }) => {
      return sum + count;
    }, 0);

    results.total =
      results.nodes +
      results.edges +
      results.sets +
      results.modelProps +
      results.modelCalcs +
      results.paths +
      results.linked;

    return dispatch({
      type: MODEL.SEARCH_RESULTS.SET,
      payload: results,
    });
  };

export const clearModelSearchResults = (): MetraSimpleAction<SearchResults> => {
  return {
    type: MODEL.SEARCH_RESULTS.CLEAR,
    payload: initialSearchResultState,
  };
};

/*******************
 * Reducer
 *******************/

export const searchResultReducer: Reducer<
  SearchResults,
  MetraSimpleAction<SearchResults>
> = (state = Object.clone(initialSearchResultState), action) => {
  switch (action.type) {
    case MODEL.SEARCH_RESULTS.INIT:
      return action.payload;
    case MODEL.SEARCH_RESULTS.SET:
      return {
        ...action.payload,
      };
    case MODEL.SEARCH_RESULTS.CLEAR:
      return action.payload;
    default:
      return state;
  }
};
