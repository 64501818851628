import {
  ActionHandler,
  generateDefaultActionHandlers,
  reducerFromHandlers,
} from 'modules/common/genericReducer';
import { ModelPath } from 'types';
import { PATH } from 'utils/constants';

export const initPathReducer: ActionHandler<Record<UUID, ModelPath>> = (
  _state,
  paths
) => paths;

//Make the reducer
export const pathReducer = reducerFromHandlers(
  generateDefaultActionHandlers(PATH, {
    [PATH.INITIALIZE]: initPathReducer,
    [PATH.DELETE]: (state) => state, //this is taken care of by the higherOrderReducer
  })
);
