import type { Entity } from 'ecs/Entity';
import { Node, Zoom } from 'engine/components';
import type { ModelEngine } from 'engine/engine';
import { CircleRange } from 'engine/range';
import type {
  Rectangle,
  Text as PText,
  Application,
  ICanvas,
  IRenderer,
} from 'pixi.js';
import type {
  ExpressionString,
  FileEntry,
  FolderEntry,
  MediaEntry,
  MetraMedia,
  RootReducer,
} from 'types';
import {
  EXTRA_GRID_LINES,
  MEDIA_TYPE,
  MIN_GRID_SIZE,
  TEXT_RESOLUTION,
} from 'utils/constants';
import { Vector2 } from 'utils/vector';

/*
 *various helper and safety methods to validate data is safe
 */

export function isOk<T, E extends Error>(
  result: Result<T, E>
): result is Ok<T> {
  if (result instanceof Error) return false;
  return true;
}

export function isErr<T, E extends Error>(
  result: Result<T, E>
): result is Err<E> {
  if (isOk(result)) return false;
  return true;
}

export function isSome<T>(option: Option<T>): option is Some<T> {
  if (
    option ||
    typeof option === 'number' ||
    typeof option === 'boolean' ||
    typeof option === 'string'
  ) {
    return true;
  }
  return false;
}

export function isNone<T>(option: Option<T>): option is None {
  if (isSome(option)) {
    return false;
  } else {
    return true;
  }
}

export function numberishEquals(
  a: Option<Numberish>,
  b: Option<Numberish>,
  testInt = false
): boolean {
  if (!a || !b) {
    return false;
  }

  let c!: number, d!: number;
  if (typeof a === 'string') {
    c = testInt ? Number.parseInt(a) : Number.parseFloat(a);
  } else if (typeof a === 'number') {
    c = a;
  } else {
    return false;
  }

  if (typeof b === 'string') {
    d = testInt ? Number.parseInt(b) : Number.parseFloat(b);
  } else if (typeof b === 'number') {
    d = b;
  } else {
    return false;
  }

  return c === d;
}

/**
 * checks if an object is media. This includes any folders, models,
 * and other media types to be displayed in the Library
 * @param {any} record object to be checked
 * @returns {boolean} is record a media type to be displayed in the library
 */
export function isMedia(record: any): record is MetraMedia {
  const types = Object.values(MEDIA_TYPE);
  return types.includes(record.media_type);
}

/**
 * checks if an object is of type 'FILE'
 * @param {any} file object to be checked
 * @returns {boolean} is file a 'FILE' media_type
 */
export function isFile(file: any): boolean {
  return file?.media_type === MEDIA_TYPE.FILE;
}

/**
 * checks if an object is of type 'MODEL'
 * @param {any} file object to be checked
 * @returns {boolean} is file a 'MODEL' media_type
 */
export function isModel(file: any): boolean {
  return file?.media_type === MEDIA_TYPE.MODEL;
}

/**
 * checks if an object is of type 'FOLDER'
 * @param {any} media object to be checked
 * @returns {boolean} is file a 'FOLDER' media_type
 */
export function isFolder(media: any): boolean {
  if (media?.media_type === MEDIA_TYPE.FOLDER) return true;
  return false;
}

export const parseFileType = (file: any): string => {
  if (!isMedia(file)) return 'PROJECT'; //NOTE: as we extend this function, this will change
  if (isFolder(file)) return 'FOLDER';
  const split = file.name.split('.');
  const fileExt = split[split.length - 1];
  return split.length > 1 && !!fileExt
    ? split.pop()?.trim().toUpperCase() ?? 'UNKNOWN'
    : 'UNKNOWN';
};

export function allSome<
  T,
  U extends T[],
  V extends OrderedSomeTuple<T, U, Tuple<T, U>>
>(tuple: V | OrderedOptionTuple<T, U, Tuple<T, U>>): tuple is V {
  if (tuple.every((item) => isSome(item))) return true;
  else return false;
}

export function allNone<
  T,
  U extends T[],
  V extends OrderedNoneTuple<T, U, Tuple<T, U>>
>(tuple: V | OrderedOptionTuple<T, U, Tuple<T, U>>): tuple is V {
  if (tuple.every((item) => isNone(item))) return true;
  return false;
}

export function anySome<T extends Tuple<T>, N extends Tuple<N>>(
  tuple: any | OrderedOptionTuple<T>
): tuple is OrderedOptionTuple<T> {
  if (Array.isArray(tuple) && tuple.some((item) => isSome(item))) return true;
  return false;
}

export function anyNone<T extends Tuple<T>>(
  tuple: OrderedSomeTuple<T> | any
): tuple is OrderedOptionTuple<T> {
  if (Array.isArray(tuple) && tuple.some((item) => isNone(item))) return true;
  return false;
}

export function noneNone<T extends Tuple<T>>(
  tuple: OrderedOptionTuple<T>
): tuple is OrderedSomeTuple<T> {
  if (tuple.some((item) => isNone(item))) return false;
  return true;
}

export function noneSome<T extends Tuple<T>>(
  tuple: OrderedOptionTuple<T>
): tuple is OrderedNoneTuple<T> {
  if (tuple.some((item) => isSome(item))) return false;
  return true;
}

export function isNumber<T extends Numberish>(value: any | T): value is number {
  if (
    isSome(value) &&
    typeof value !== 'string' &&
    !Number.isNaN(value) &&
    Number.isFinite(value)
  )
    return true;
  return false;
}

export function isExpressionString(
  value: string | ExpressionString
): value is ExpressionString {
  if (value.startsWith('=')) return true;
  return false;
}

export function isNumeric<T extends Numberish>(value: any | T): value is T {
  if (
    value !== null &&
    value !== '' &&
    (typeof value === 'string' || typeof value === 'number') &&
    !Number.isNaN(Number(value))
  )
    return true;
  return false;
}

export function isMediaEntry(value: File | MediaEntry): value is MediaEntry {
  if ('isFileEntry' in value && value.isFileEntry) return true;
  return false;
}

export function isStandardFile(value: File | MediaEntry): value is File {
  if (isMediaEntry(value)) return false;
  return true;
}

export function isFolderEntry(value: MediaEntry): value is FolderEntry {
  if (value.isFolder) return true;
  return false;
}

export function isFileEntry(value: MediaEntry): value is FileEntry {
  if (value.isFolder) return false;
  return true;
}

export function isString(value: any): value is string {
  if (typeof value === 'string') return true;
  return false;
}

export function isStringish<T extends Stringish & { toString(): string }>(
  value: any | T
): value is T {
  if (
    typeof value === 'string' ||
    typeof value === 'number' ||
    !!value?.toString
  )
    return true;
  return false;
}

/**
 * forcibly nullifies a property so you can prevent memory leaks
 */
export function nullify(thing: any, prop: string) {
  thing[prop] = null;
}

/**
 * forcibly retype something
 * `WARNING`: do not use unless you are `ABSOLUTELY` certain of the underlying type
 */
export function isActually<T = any>(thing: any): T {
  return thing as T;
}

/**
 * tests if something is a type by forcibly retyping it in the test
 * NOTE: IT WILL ALWAYS TEST TRUE
 * `WARNING`: do not use unless you are `ABSOLUTELY` certain of the underlying type
 */
export function testIsActually<T = any>(thing: any): thing is T {
  if (isActually(thing)) return true;
  else return false;
}

export function intersection<T = any>(a: T[], b: T[]): T[] {
  let map = new Set(b);
  return a.filter((x) => map.has(x));
}

export function pathElementAfter(
  pathElement: string,
  urlPath: string
): Option<string> {
  const urlSplit = urlPath.split('/');
  const index = urlSplit.indexOf(pathElement);
  let value = null;
  if (index >= 0) {
    value = urlSplit[index + 1];
  }
  return value;
}

/**********************
Grid utility functions
**********************/

export function getZoomAdjustedGridSettings(
  rawGridSettings: { width: number; height: number },
  scale: { x: number; y: number }
): { width: number; height: number } {
  const adjustedZoomSettings = {
    width: Math.abs(rawGridSettings.width),
    height: Math.abs(rawGridSettings.height),
  };

  if (adjustedZoomSettings.width <= MIN_GRID_SIZE) {
    adjustedZoomSettings.width = MIN_GRID_SIZE;
  }

  if (adjustedZoomSettings.height <= MIN_GRID_SIZE) {
    adjustedZoomSettings.height = MIN_GRID_SIZE;
  }

  while (
    (adjustedZoomSettings.width * scale.x) / Math.abs(rawGridSettings.width) <=
    0.5
  ) {
    adjustedZoomSettings.width = adjustedZoomSettings.width * 2;
  }

  while (
    (adjustedZoomSettings.height * scale.y) /
      Math.abs(rawGridSettings.height) <=
    0.5
  ) {
    adjustedZoomSettings.height = adjustedZoomSettings.height * 2;
  }

  return adjustedZoomSettings;
}

export function getTotalNumberOfVerticalGridLines(
  screen: Rectangle,
  zoom: Vector2,
  gridColumnSize: number
) {
  const screenWidthCoords = screen.width / zoom.x;

  const numVerticalLines =
    zoom.x < 1
      ? Math.round(screenWidthCoords / gridColumnSize / zoom.x) +
        EXTRA_GRID_LINES
      : Math.round((screenWidthCoords / gridColumnSize) * zoom.x) +
        EXTRA_GRID_LINES;

  return numVerticalLines;
}

export function getTotalNumberOfHorizontalGridLines(
  screen: Rectangle,
  zoom: Vector2,
  gridRowSize: number
) {
  const screenWidthCoords = screen.height / zoom.y;

  const numVerticalLines =
    zoom.x < 1
      ? Math.round(screenWidthCoords / gridRowSize / zoom.x) + EXTRA_GRID_LINES
      : Math.round((screenWidthCoords / gridRowSize) * zoom.x) +
        EXTRA_GRID_LINES;

  return numVerticalLines;
}

export function getSnapPosition(
  zoom: Vector2,
  initialGridSettings: { width: number; height: number },
  mouse: Vector2
) {
  const gridSettings = getZoomAdjustedGridSettings(
    {
      width: initialGridSettings.width,
      height: initialGridSettings.height,
    },
    zoom
  );

  const numHorizontalLinesFromYOriginToMouseClickPos = Math.round(
    mouse.y / gridSettings.height
  );
  const numVerticalLinesFromXOriginToMouseClickPos = Math.round(
    mouse.x / gridSettings.width
  );

  const newXPos =
    numVerticalLinesFromXOriginToMouseClickPos * gridSettings.width;
  const newYPos =
    numHorizontalLinesFromYOriginToMouseClickPos * gridSettings.height;

  return new Vector2(newXPos, newYPos);
}

export function getZoom(engine: ModelEngine) {
  const zooms = engine.ecs.getComponentsByType(Zoom);
  const defaultZoom = new Vector2(1, 1);

  if (zooms && zooms.count === 1) {
    const zoom = zooms.first;
    const zoomValue = zoom?.val ? zoom.val : defaultZoom;

    return zoomValue;
  }

  return defaultZoom;
}

export function getPlacementPosition(
  engine: ModelEngine,
  mouse: Vector2,
  state: RootReducer
) {
  let placementPosition = mouse;

  if (state.appReducer.grid.snap) {
    const zoom = getZoom(engine);
    placementPosition = getSnapPosition(
      zoom,
      state.modelReducer.gridSettings,
      mouse
    );
  }

  const hasCollision = getHasNodeCollision(placementPosition, engine);

  if (hasCollision) {
    return null;
  }

  return placementPosition;
}

export function getHasNodeCollision(
  placementPosition: Vector2,
  engine: ModelEngine
) {
  const results = engine.sys2.atoms.collision.queryPoint(placementPosition);
  for (const eid of results) {
    if (engine.sys2.has(eid, Node)) {
      return true;
    }
  }

  return false;
}

/***************************
 End Grid utility functions
***************************/

type GlRenderer = IRenderer<ICanvas> & { gl: WebGL2RenderingContext };

export function getTextResolution(text: PText, app: Application) {
  const gl = (app.renderer as GlRenderer).gl;
  const maxTextureSize = gl.getParameter(gl?.MAX_TEXTURE_SIZE) as number;
  const textRes = TEXT_RESOLUTION;
  const scaledWidth = text.width * textRes;
  const scaledHeight = text.height * textRes;

  // check if either dimension exceeds the max texture size
  if (scaledWidth > maxTextureSize || scaledHeight > maxTextureSize) {
    // Calculate the ratio to fit the max texture size
    const widthRatio = maxTextureSize / scaledWidth;
    const heightRatio = maxTextureSize / scaledHeight;

    // Use the smaller ratio to ensure both dimensions fit
    const scaleRatio = Math.min(widthRatio, heightRatio);

    // return the updated resolution
    return scaleRatio * textRes;
  }

  // Texture is within the limits, return the default resolution
  return textRes;
}
