import Cookies from 'js-cookie';
import { Reducer } from 'redux';
import {
  ApiError,
  ApiRequest,
  MetraApiAction,
  NormalizedResult,
  MetraApiErrorAction,
  DetailError,
  MetraApiResponseAction,
  ToastFunc,
  MetraAction,
  MetraApiActionObjectReducer,
  RequestError,
  MAPISuccessAction,
  MAPIFailureAction,
  MetraSimpleAction,
  MetraVoidAction,
  MetraPayloadAction,
  MetraBasicAction,
  ThunkAction,
  MetraRequestError,
  MetraApiError,
} from 'types';
import { OrgContext } from 'utils/OrganizationContext';
import { UrlFor, UrlForExplicit } from 'utils/urlFor';
import { buildOrgUrl, buildGuildUrl } from 'utils/url-builders';
import {
  API_CORE_CANCEL,
  TOASTS,
  AUTH_LOGOUT,
  AUTH_FAILURE_PKI,
  AUTH_FAILURE_MFA,
  API_CORE_REQUEST,
  API_CORE_FAILURE,
  API_CORE_SUCCESS,
  HTTP_NO_CONTENT,
} from './constants';
import { ENTITIES } from './constants-extra';
import { createToastMessage } from 'modules/ui/toasts';
import { MESSAGES } from 'modules/ui/messages';
import { isValidEntityKey } from 'modules/entities/utils';
import { isNone, isSome } from 'helpers/utils';

/*
  HELPERS
 */

export function isPayloadAction<
  Payload,
  ApiPayload,
  ErrorType,
  Action extends MetraPayloadAction<Payload, ApiPayload, ErrorType>
>(action: Action | MetraVoidAction): action is Action {
  if ('payload' in action) {
    return true;
  }
  return false;
}

export const isVoidAction = <
  Payload,
  ApiPayload,
  ErrorType,
  Action extends MetraVoidAction
>(
  action:
    | Action
    | (
        | MetraSimpleAction<Payload>
        | MetraApiResponseAction<ApiPayload>
        | MetraRequestError
        | MetraApiError<ErrorType>
      )
): action is Action => {
  if ('payload' in action) {
    return false;
  } else {
    return true;
  }
};

export function isApiAction<
  Payload,
  ApiPayload,
  ErrorType,
  Action extends MetraApiAction<ApiPayload, ErrorType>
>(
  action: Action | (MetraVoidAction | MetraSimpleAction<Payload>)
): action is Action {
  if ('payload' in action) {
    if ('meta' in action) {
      return true;
    }
  }
  return false;
}

export function isBasicAction<
  Payload,
  ApiPayload,
  ErrorType,
  Action extends MetraBasicAction<Payload>
>(
  action:
    | Action
    | (
        | MetraApiResponseAction<ApiPayload>
        | MetraRequestError
        | MetraApiError<ErrorType>
      )
): action is Action {
  if ('meta' in action) {
    return false;
  }
  return true;
}

export function isSimpleAction<
  Payload,
  ApiPayload,
  ErrorType,
  Action extends MetraSimpleAction<Payload>
>(
  action:
    | Action
    | (
        | MetraVoidAction
        | MetraApiResponseAction<ApiPayload>
        | MetraRequestError
        | MetraApiError<ErrorType>
      )
): action is Action {
  if ('payload' in action) {
    if ('meta' in action) {
      return false;
    }
    return true;
  }
  return false;
}

export function isApiErrorAction<
  Payload,
  ApiPayload,
  ErrorType,
  Action extends MetraApiErrorAction<ErrorType>
>(
  action:
    | Action
    | (
        | MetraVoidAction
        | MetraApiResponseAction<ApiPayload>
        | MetraSimpleAction<Payload>
      )
): action is Action {
  if ('payload' in action) {
    if ('meta' in action) {
      if ('error' in action) {
        return true;
      }
    }
    return false;
  }
  return false;
}

export function isApiResponseAction<
  Payload,
  ApiPayload,
  ErrorType,
  Action extends MetraApiResponseAction<ApiPayload>
>(
  action:
    | Action
    | (
        | MetraVoidAction
        | MetraSimpleAction<Payload>
        | MetraRequestError
        | MetraApiError<ErrorType>
      )
): action is Action {
  if ('payload' in action) {
    if ('meta' in action) {
      if ('error' in action) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  }
  return false;
}

export function isApiError<ErrorType, Action extends MetraApiError<ErrorType>>(
  action: Action | MetraRequestError
): action is Action {
  if (action.payload.name === 'ApiError') {
    return true;
  } else {
    return false;
  }
}

export function isRequestError<ErrorType, Action extends MetraRequestError>(
  action: Action | MetraApiError<ErrorType>
): action is Action {
  if (action.payload.name === 'RequestError') {
    return true;
  } else {
    return false;
  }
}

const toastMessage =
  <ToastFuncData>(
    type: string,
    result: ToastFuncData,
    toast: string | ToastFunc<ToastFuncData> | undefined,
    undo?: () => void
  ): ThunkAction<void> =>
  (dispatch) => {
    if (!toast) return;
    let message = '';
    if (typeof toast === 'function') {
      message = toast(result);
    } else {
      message = toast;
    }
    dispatch(createToastMessage(type, message, undefined, undo));
  };

/**
 * fetchIntercept intercepts a fetch request passing the response to the
 * callback. The callback should return a valid response object
 * @param callback - the function that receives the cloned response
 * @return a function that matches the fetch function
 */
export const fetchIntercept = (
  callback: (_response: Response) => Promise<Response>,
  fetch: (_input: RequestInfo | URL, _init?: RequestInit) => Promise<Response>
): typeof fetch => {
  return async (
    input: RequestInfo | URL,
    init?: RequestInit
  ): Promise<Response> => {
    const response = await fetch(input, init);
    return await callback(response);
  };
};

export const apiCore =
  <Payload, ErrorType = void>(
    options: ApiRequest<Payload, ErrorType>,
    method: 'GET' | 'HEAD' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'OPTIONS'
  ): ThunkAction<Promise<MetraApiAction<Payload, ErrorType>>> =>
  async (_dispatch, getState) => {
    const {
      entity,
      record,
      params,
      headers,
      body,
      explicit,
      meta,
      guildCName,
    } = options;

    const state = getState();

    let allHeaders: Record<string, string> = {};

    if (!options.forceDisableAuth) {
      const token = Cookies.get('csrftoken');
      token && (allHeaders['X-CSRFToken'] = token);
    }
    if (headers) {
      Object.forEach(headers, ([key, value]) => {
        allHeaders[key] = value;
      });
    }
    // set fetcher, to a passed fetcher OR fallback to global fetch (default)
    let fetcher: typeof fetch = options.fetcher as typeof fetch;
    fetcher ??= global.fetch;

    let prefix!: string;
    // If the guild is excluded, use the org url
    // If there is a context, use it
    // If there is a guildCName, use it with the org Url
    // If there is neither, use the org url
    if (options?.meta?.excludeGuild) {
      prefix = buildOrgUrl();
    } else if (guildCName) {
      prefix = `${buildOrgUrl()}/guild/${guildCName}`;
    } else if (OrgContext.guild) {
      prefix = buildGuildUrl();
    } else {
      prefix = buildOrgUrl();
    }

    let successFunction: Option<MAPISuccessAction<Payload, ErrorType>>;
    successFunction ??= options.successType;
    if (
      Object.isObject<MAPISuccessAction<Payload, ErrorType>>(options.types[0])
    ) {
      successFunction = options.types[0];
    }

    let failureFunction: Option<MAPIFailureAction<Payload, ErrorType>>;
    failureFunction ??= options.failureType;
    if (
      Object.isObject<MAPIFailureAction<Payload, ErrorType>>(options.types[1])
    ) {
      failureFunction = options.types[1];
    }

    const url = explicit
      ? UrlForExplicit(explicit, record?.toString(), params as any)
      : UrlFor(`${prefix}/${entity}`, record?.toString(), params as any);

    let successType!: string;
    let failureType!: string;
    if (isSome(successFunction)) {
      successType = successFunction.type;
    } else {
      successType = options.types[0] as string;
    }
    if (isSome(failureFunction)) {
      failureType = failureFunction.type;
    } else {
      failureType = options.types[1] as string;
    }

    let payload!: Payload | RequestError | ApiError<ErrorType>;
    let error!: true | false;
    let type!: string;
    let responseContent = {} as Payload | ErrorType;
    let response: Option<Response> = null;

    try {
      response = await fetcher(url, {
        method,
        headers: allHeaders,
        ...(body ? { body } : {}),
        mode: 'cors',
        ...(options.signal ? { signal: options.signal } : {}),
        credentials: options.forceDisableAuth ? 'omit' : 'include',
      });
    } catch (error: any) {
      return {
        error: true as const,
        meta: {
          ...meta,
        },
        payload: {
          name: 'RequestError',
          message: error.message,
          status: 400,
          statusText: 'Bad Request',
        },
        type: failureType,
      };
    }

    try {
      error = false as const;
      if (isSome(response) && response.ok) {
        if (isSome(successFunction)) {
          payload = (await successFunction.payload(
            options,
            state,
            response
          )) as Payload;
          type = successType;
        } else {
          type = successType;
          if (
            // only deserialize the json if there is actual content
            // and that content is deserializeable to json
            (response.headers.get('content-length')?.length ?? -1 > 0) &&
            response.headers.get('content-type') === 'application/json' &&
            response.status !== HTTP_NO_CONTENT
          ) {
            responseContent = await response.json();
          } else if (
            (response.headers.get('content-length')?.length ?? -1 > 0) &&
            response.headers.get('content-type') === 'text/html' &&
            response.status !== HTTP_NO_CONTENT
          ) {
            responseContent = (await response.text()) as Payload;
          } else if (response.headers.get('content-length')?.length ?? -1 > 0) {
            responseContent = (await response.blob()) as Payload;
          }
          payload = responseContent as Payload;
        }
        error = false as const;
      } else if (isNone(response) || !response.ok) {
        error = true as const;
        type = failureType;

        // non success
        if (isSome(failureFunction)) {
          const errorPayload = await failureFunction.payload(
            options,
            state,
            response
          );
          payload = {
            name: 'ApiError',
            status: response.status,
            statusText: response.statusText,
            message: `${response.status} - ${response.statusText}`,
            response: errorPayload,
          };
        } else {
          if (
            // only deserialize the json if there is actual content
            // and that content is deserializeable to json
            (response.headers.get('content-length')?.length ?? -1 > 0) &&
            response.headers.get('content-type') === 'application/json'
          ) {
            responseContent = await response.json();
          }

          payload = {
            name: 'ApiError',
            status: response.status,
            statusText: response.statusText,
            message: `${response.status} - ${response.statusText}`,
            response: responseContent as ErrorType,
          };
        }
      } else {
        error = true as const;
        type = failureType;
      }
    } catch (e: any) {
      error = true as const;
      type = failureType;
      payload = {
        message: e.message,
        name: 'RequestError',
        status: 422,
        statusText: 'Unprocessable Content',
      };
    } finally {
      return {
        type,
        payload,
        meta: {
          ...meta,
        },
        ...(error ? { error } : {}),
      } as MetraApiAction<Payload, ErrorType>;
    }
  };

export function handleApiError<ApiPayload, ErrorType>(
  error: ApiError<ErrorType>,
  type: string,
  request: ApiRequest<ApiPayload, ErrorType>,
  result: MetraApiErrorAction<ErrorType>
): ThunkAction<MetraApiErrorAction<ErrorType>> {
  return (dispatch) => {
    if (error.status === 403) {
      // user was trying to logout and got back a 403.
      if (request.entity === 'auth/caclogin/') {
        dispatch(toastMessage(TOASTS.ERROR, result, MESSAGES.ERROR.CAC));
        return dispatch({
          type: AUTH_FAILURE_PKI,
          payload: error,
          meta: {
            ...request.meta,
          },
          error: true as const,
        });
      } else if (error.status === 403 && request.userCheck) {
        return dispatch({
          type,
          payload: error,
          meta: {
            ...request.meta,
          },
          error: true as const,
        });
      } else if (
        (error.response as unknown as DetailError)?.detail ===
        'You do not have permission to perform this action: not yet verified with a multi-factor option'
      ) {
        dispatch(toastMessage(TOASTS.ERROR, result, MESSAGES.ERROR.MFA));
        return dispatch({
          type: AUTH_FAILURE_MFA,
          payload: error,
          meta: {
            ...request.meta,
          },
          error: true as const,
        });
      }
    }

    if (
      error.status === 401 &&
      !request.explicit &&
      !(
        (request.entity && request.entity.includes('totp/_verify')) ||
        (request.entity === 'users' && request.record === '_current') ||
        (request.entity === '_meta' && request.record === undefined)
      )
    ) {
      dispatch(toastMessage(TOASTS.ERROR, result, MESSAGES.ERROR.SESSION));
      return dispatch({
        type: AUTH_LOGOUT,
        meta: {
          ...request.meta,
          enableReplace: false,
        },
        payload: error,
        error: true as const,
      });
    }

    if (
      (error.status === 400 && error.statusText !== 'Bad Request') ||
      error.status === 415
    ) {
      return dispatch({
        type,
        meta: {
          ...request.meta,
        },
        payload: {
          ...error,
          message: error.statusText,
        },
        error: true as const,
      } as any);
    }
    // this was some general specified request error
    dispatch(toastMessage(TOASTS.ERROR, result, request.error));

    // it was some other error
    return dispatch({
      type,
      // we have specify true because typescript is not -that- smart ^_^
      error: true as const,
      payload: { ...error },
      meta: {
        ...request.meta,
      },
    });
  };
}

export const handleRequestErrors =
  <Payload, ErrorType>(
    error: RequestError,
    type: string,
    request: ApiRequest<Payload, ErrorType>
  ): ThunkAction<MetraApiErrorAction<ErrorType>> =>
  (dispatch) => {
    if (request?.signal?.aborted) {
      // RSAA groups "AbortError" with general errors. Also, each browser has a different error message.
      // Instead, check whether the original signal was aborted to identify this as the error source.
      // Toast not shown from errors that occur when there is an abort signal.
      // Request was likely replaced with a new one. There is no errant behavior to warn about.
      return dispatch({
        type: API_CORE_CANCEL,
        meta: {
          ...request.meta,
        },
        payload: error,
        error: true as const,
      });
    } else {
      // it was some other internal error
      return dispatch({
        type,
        meta: {
          ...request.meta,
          enableReplace: false,
        },
        payload: error,
        error: true as const,
      });
    }
  };

export const handleErrors =
  <ApiPayload, ErrorType>(
    response: MetraApiErrorAction<ErrorType>,
    request: ApiRequest<ApiPayload, ErrorType>
  ): ThunkAction<MetraApiErrorAction<ErrorType>> =>
  (dispatch) => {
    // test the data type to let TypeScript narrow what the type is
    // if (response.payload.name === 'InternalError') return response;
    if (
      response.payload &&
      typeof response.payload === 'object' &&
      'name' in response.payload &&
      response.payload.name === 'ApiError'
    ) {
      // response is an ApiError
      return dispatch(
        handleApiError(response.payload, response.type, request, response)
      );
    } else {
      return dispatch(
        handleRequestErrors(response.payload, response.type, request)
      );
    }
  };

export const genericApiProcessor =
  <Payload, ErrorType>(
    method: 'GET' | 'HEAD' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'OPTIONS',
    request: ApiRequest<Payload, ErrorType>
  ): ThunkAction<Promise<MetraApiAction<Payload, ErrorType>>> =>
  async (dispatch) => {
    dispatch({
      type: API_CORE_REQUEST,
      payload: {
        ...request,
        body: '<removed>',
      },
    });

    // redux-api-middleware overrides (abuses) the core Dispatch type,
    // so we need to cast the call and the response because `ValidAction`
    // is not exported
    const response = await dispatch(apiCore(request, method));

    if (isApiErrorAction(response)) {
      dispatch({
        type: API_CORE_FAILURE,
        payload: {
          ...request,
          body: '<removed>',
          error: response,
        },
      });

      return dispatch(handleErrors(response, request));
    } else {
      dispatch({
        type: API_CORE_SUCCESS,
        payload: {
          ...request,
          body: '<removed>',
        },
      });
    }

    dispatch(
      toastMessage(TOASTS.SUCCESS, response, request.success, request.undo)
    );
    dispatch(toastMessage(TOASTS.INFO, response, request.info));
    dispatch(toastMessage(TOASTS.WARNING, response, request.warning));

    const returnValue: MetraApiAction<Payload, ErrorType> = dispatch(response);
    return returnValue;
  };

/**
 * Given an object mapping action types to functions, and an initial state, creates
 * a reducer. The reducer checks if an action's type is in the map, and if so, calls
 * the associated function, passing the state and the action. The state of this
 * reducer is then the response from that function.
 *
 * @param actionObject - an object mapping action types to functions
 * @param initialState - the initial state of the reducer
 * @returns a generic metra api reducer
 */
export function genericApiReducer<State, Payload, ApiPayload, ErrorType>(
  actionObject: MetraApiActionObjectReducer<
    State,
    Payload,
    ApiPayload,
    ErrorType
  >,
  initialState: State
): Reducer<State, MetraAction<Payload, ApiPayload, ErrorType>> {
  return (state = initialState, action) => {
    if (action.type in actionObject) {
      return actionObject[action.type](state, action);
    } else {
      return state;
    }
  };
}

/*
  ACTIONS
 */

/**
 * performs a get request
 * @param options - ApiRequest options
 * @returns a MetraApiAction of the reuqested Payload type
 */
export const apiGet =
  <ApiPayload = NormalizedResult, ErrorType = ApiPayload>(
    options: ApiRequest<ApiPayload, ErrorType>
  ): ThunkAction<Promise<MetraApiAction<ApiPayload, ErrorType>>> =>
  async (dispatch) => {
    return dispatch(
      genericApiProcessor<ApiPayload, ErrorType>('GET', {
        ...options,
        meta: {
          mutation: ENTITIES.MUTATE_READ,
          schema: options.schema
            ? options.schema
            : isValidEntityKey(options.entity)
            ? options.entity
            : undefined,
          collection: options.collection,
          record: options.record,
          ...options.meta,
        },
      })
    );
  };

/**
 * performs a patch request
 * @param options - ApiRequest options
 * @returns a MetraApiAction of the reuqested Payload type
 */
export const apiPatch =
  <ApiPayload = NormalizedResult, ErrorType = ApiPayload>(
    options: ApiRequest<ApiPayload, ErrorType>
  ): ThunkAction<Promise<MetraApiAction<ApiPayload, ErrorType>>> =>
  async (dispatch) =>
    dispatch(
      genericApiProcessor<ApiPayload, ErrorType>('PATCH', {
        ...options,
        meta: {
          mutation: ENTITIES.MUTATE_UPDATE_ONE,
          schema: options.schema
            ? options.schema
            : isValidEntityKey(options.entity)
            ? options.entity
            : undefined,
          collection: options.collection,
          record: options.record,
          ...options.meta,
        },
      })
    );

/**
 * performs a post request
 * @param options - ApiRequest options
 * @returns a MetraApiAction of the reuqested Payload type
 */
export const apiPost =
  <ApiPayload = NormalizedResult, ErrorType = ApiPayload>(
    options: ApiRequest<ApiPayload, ErrorType>
  ): ThunkAction<Promise<MetraApiAction<ApiPayload, ErrorType>>> =>
  async (dispatch) =>
    dispatch(
      genericApiProcessor<ApiPayload, ErrorType>('POST', {
        ...options,
        meta: {
          mutation: ENTITIES.MUTATE_CREATE,
          schema: options.schema
            ? options.schema
            : isValidEntityKey(options.entity)
            ? options.entity
            : undefined,
          collection: options.collection,
          record: options.record,
          ...options.meta,
        },
      })
    );

/**
 * performs a put request
 * @param options - ApiRequest options
 * @returns a MetraApiAction of the reuqested Payload type
 */
export const apiPut =
  <ApiPayload = NormalizedResult, ErrorType = ApiPayload>(
    options: ApiRequest<ApiPayload, ErrorType>
  ): ThunkAction<Promise<MetraApiAction<ApiPayload, ErrorType>>> =>
  async (dispatch) =>
    dispatch(
      genericApiProcessor<ApiPayload, ErrorType>('PUT', {
        ...options,
        meta: {
          mutation: ENTITIES.MUTATE_UPDATE_ONE,
          schema: options.schema
            ? options.schema
            : isValidEntityKey(options.entity)
            ? options.entity
            : undefined,
          collection: options.collection,
          record: options.record,
          ...options.meta,
        },
      })
    );

/**
 * performs a delete request
 * @param options - ApiRequest options
 * @returns a MetraApiAction of the reuqested Payload type
 */
export const apiDelete =
  <ApiPayload = NormalizedResult, ErrorType = ApiPayload>(
    options: ApiRequest<ApiPayload, ErrorType>
  ): ThunkAction<Promise<MetraApiAction<ApiPayload, ErrorType>>> =>
  async (dispatch) =>
    dispatch(
      genericApiProcessor<ApiPayload, ErrorType>('DELETE', {
        ...options,
        meta: {
          mutation: ENTITIES.MUTATE_DELETE,
          schema: options.schema
            ? options.schema
            : isValidEntityKey(options.entity)
            ? options.entity
            : undefined,
          collection: options.collection,
          record: options.record,
          ...options.meta,
        },
      })
    );

/**
 * cancels a request
 * @param options - ApiRequest options
 * @returns a MetraApiAction of the reuqested Payload type
 */
export const apiCancel =
  <ApiPayload = NormalizedResult, ErrorType = ApiPayload>(
    options: ApiRequest<ApiPayload, ErrorType>
  ): ThunkAction<Promise<MetraApiAction<ApiPayload, ErrorType>>> =>
  async (dispatch) =>
    dispatch(
      genericApiProcessor<ApiPayload, ErrorType>('DELETE', {
        ...options,
      })
    );
