import { ENTITIES, PROJECTS, apiGet, apiPatch, apiPost } from 'modules/common';
import {
  ApiRequest,
  CollectionKey,
  MetraApiAction,
  MetraProject,
  NormalizedResult,
  ProjectCreationFormModel,
  StandardError,
  ThunkAction,
  ThunkActionFunc,
} from 'types';
import { SCHEMA_NAME } from './project';
import { MESSAGES } from 'modules/ui/messages';

/**
 * createProject issues the POST create action against nucleo for the passed
 * project
 */
export const createNewProject: ThunkActionFunc<
  [
    project: ProjectCreationFormModel,
    collection?: CollectionKey,
    reload?: boolean
  ],
  Promise<MetraApiAction<MetraProject, StandardError>>
> =
  (project, collection = 'projects', reload = true) =>
  async (dispatch) => {
    if (reload) {
      dispatch({
        type: PROJECTS.LOADED,
        payload: false,
      });
    }
    const result = await dispatch(
      apiPost<MetraProject, StandardError>({
        entity: SCHEMA_NAME,
        collection,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          name: project.name.value,
          description: project.description.value,
          plaintext_description: project.plaintext_description.value,
          guild_permissions: project.guild_permissions.value,
        }),
        types: [ENTITIES.ACTION_SUCCESS, PROJECTS.CREATE_FAILURE],
        error: MESSAGES.ERROR.CREATE.PROJECT,
        success: MESSAGES.SUCCESS.CREATE.PROJECT,
      })
    );
    if (result.type === ENTITIES.ACTION_SUCCESS) {
      dispatch({
        type: PROJECTS.LOADED,
        payload: true,
      });
    }
    return result;
  };

/**
 * gets a project with the specified id
 * @param id - project id
 * @param [options] - request options
 */
export const getProject: ThunkActionFunc<
  [id: Numberish, options?: Partial<ApiRequest<any, any>>],
  Promise<MetraApiAction<NormalizedResult<'projects'>>>
> =
  (id, options = {}) =>
  async (dispatch) => {
    dispatch({
      type: PROJECTS.LOADED,
      payload: false,
    });
    const project = await dispatch(
      apiGet<NormalizedResult<'projects'>>({
        entity: `${SCHEMA_NAME}/${id}`,
        collection: 'projects',
        types: [ENTITIES.ACTION_SUCCESS, PROJECTS.GET_FAILURE],
        meta: {
          schema: SCHEMA_NAME,
        },
        signal: options.signal,
        ...options,
      })
    );
    if (project.type === ENTITIES.ACTION_SUCCESS) {
      dispatch({
        type: PROJECTS.LOADED,
        payload: true,
      });
    }
    return project;
  };

/**
 *
 * @param id - project id
 * @param payload - changes to the project
 * @param guildCName - guild of project
 * @returns the patch update thunk
 */
export function updateProject(
  id: Numberish,
  payload: Partial<MetraProject>,
  guildCName = ''
): ThunkAction<Promise<MetraApiAction<MetraProject>>> {
  return async (dispatch) => {
    return dispatch(
      apiPatch<MetraProject>({
        entity: SCHEMA_NAME,
        record: `${id}`,
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
        },
        types: [ENTITIES.ACTION_SUCCESS, PROJECTS.UPDATE_FAILURE],
        error: MESSAGES.ERROR.UPDATE,
        success: MESSAGES.SUCCESS.UPDATE,
        guildCName,
      })
    );
  };
}

export function updatePermission(
  id: Numberish,
  payload: Partial<MetraProject>,
  guildCName = ''
): ThunkAction<Promise<MetraApiAction<MetraProject>>> {
  return async (dispatch) => {
    return dispatch(
      apiPatch<MetraProject>({
        entity: SCHEMA_NAME,
        record: `${id}/permissions`,
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
        },
        types: [ENTITIES.ACTION_SUCCESS, PROJECTS.UPDATE_FAILURE],
        error: MESSAGES.ERROR.UPDATE,
        success: MESSAGES.SUCCESS.UPDATE,
        guildCName,
      })
    );
  };
}
