import { MPT_REARRANGE } from 'modules/common';
import { MetraSimpleAction, MPTRearrangeReducer } from 'types';

export const initialMptRearrangeState: MPTRearrangeReducer = {
  dimension: '',
  sourcePos: null,
  targetPos: null,
  phase: '',
};

export const mptRearrangeReducer = (
  state = initialMptRearrangeState,
  action: MetraSimpleAction<Partial<MPTRearrangeReducer>>
): MPTRearrangeReducer => {
  const { type } = action;
  switch (type) {
    case MPT_REARRANGE.DRAG: {
      const payload = action.payload;
      return {
        ...state,
        targetPos: payload.targetPos as number,
      };
    }
    case MPT_REARRANGE.END: {
      return {
        ...state,
        phase: 'over',
      };
    }
    case MPT_REARRANGE.RESET: {
      return initialMptRearrangeState;
    }
    case MPT_REARRANGE.START: {
      const payload = action.payload;
      return {
        dimension: payload.dimension as 'schemas' | 'entities',
        sourcePos: payload.sourcePos as number,
        targetPos: payload.targetPos as number,
        phase: 'afoot',
      };
    }
    default:
      return state;
  }
};
