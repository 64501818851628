import { clamp, cloneDeep } from 'lodash';
import {
  CollapsedConfig,
  EdgeConfig,
  NodeConfig,
  ShapeConfig,
  ModelEdge,
  ModelNode,
  ModelSet,
  TransitionData,
  BoundingMetaData,
  BoundsPkg,
  PanelOffsets,
  ModelReducer,
  TableSettings,
  PointLike,
  ThunkActionFunc,
} from 'types';
import type { EcsInstance } from 'ecs/EcsInstance';
import type { Entity } from 'ecs/Entity';
import { updateShapesByConfig } from 'modules/model/pixi';
import { TABLE } from 'modules/common';
import {
  Edge,
  Renderable,
  Node,
  Asset,
  Position,
  CollapsedSet,
  Order,
  Arrowhead,
  Segment,
  Style,
  Waypoints,
  Waypoint,
  MetraLayer,
  Selected,
} from 'engine/components';
import type { ModelEngine } from 'engine/engine';
import type { ModelCamera } from 'engine/camera';
import { isNone, isSome } from './utils';
import { BinaryHeap } from 'utils/BinaryHeap';
import { EDGE_STYLE, LAYER, LAYOUT, MODEL, ZOOM } from 'utils/constants';
import { ENGINE, SHAPE } from 'utils/constants-extra';
import { Vector2 } from 'utils/vector';
import { colorParse } from 'utils/utils';
import { dispatcher } from 'utils/utils-extra';
import { canEdgeBeVisible, determineEdgeVisibility } from './edges/visibility';
import { rebuildEdgeSegments } from 'engine/factories/edge';
import { hasAsset } from 'modules/model/shape/shape-helpers';
import { t3dev } from 't3dev';

export const MODEL_FILES_WIDTH = 350;

export function updateGifSpriteAsset(
  engine: ModelEngine,
  entity: Entity,
  config: Partial<ShapeConfig>
): void {
  // if no asset, or asset is undefined/null
  if (!hasAsset(config) || !config.asset) return;
  const asset = engine.ecs.getComponent(entity, Asset);
  if (!asset) return;

  // trigger asset update
  asset.value = config.asset;
  engine.ecs.update(asset);
}

/**
 * toggles the renderability of an entity by adding/removing `Renderable` component
 * @param entity entity to modify
 * @param renderable the current visibility
 * @param ecs current ecs instance
 */
export function toggleRenderable(
  entity: Entity,
  renderable: boolean,
  ecs: EcsInstance
): void {
  if (renderable && !ecs.hasComponent(entity, Renderable.type)) {
    ecs.addComponent(entity, new Renderable());
    ecs.resolve(entity);
  } else if (!renderable && ecs.hasComponent(entity, Renderable.type)) {
    ecs.removeComponentType(entity, Renderable);
    ecs.resolve(entity);
  }
}

/**
 * toggle Renderable Components for as sets are toggled collapsed
 * @param shapes updated state of the shapes
 * @param ecs
 */
export function toggleShapesRenderable(
  shapes: ShapeConfig[],
  ecs: EcsInstance
): void {
  for (let i = shapes.length; i--; ) {
    const shape = shapes[i];
    const entity = ecs.tagManager.getEntityByTag(shape.id);
    if (!entity) continue;
    toggleRenderable(entity, shape.visible, ecs);
  }
}

export function updateEdgeAfterSetColorChange(
  nodeData: ModelNode,
  ecs: EcsInstance
): void {
  for (let i = nodeData.edgeIds.length; i--; ) {
    const edgeEntity = ecs.tagManager.getEntityByTag(nodeData.edgeIds[i]);
    if (!edgeEntity) continue;
    ecs.updateByEntity(edgeEntity);
  }
}

/**
 * Determine which set colors should be visible around node
 * @param nodeSetIds
 * @param hiddenSetsMap
 * @param sets
 * @returns
 */
export const getNodeSetColors = (
  nodeSetIds: Array<UUID>,
  hiddenSetsMap: Set<UUID>,
  sets: Record<UUID, ModelSet>
): Array<number> => {
  return nodeSetIds.reduce(
    (setColors, setId) =>
      hiddenSetsMap.has(setId)
        ? setColors
        : setColors.concat([colorParse(sets[setId].color)]),
    [] as number[]
  );
};

/**
 * Determine the top most visible set color for an edge
 * @param edgeSetIds
 * @param hiddenSets
 * @param sets
 * @returns
 */
export const getEdgeSetColors = (
  edgeSetIds: Array<UUID>,
  hiddenSetsMap: Set<UUID>,
  sets: Record<string, ModelSet>
): Array<number> => {
  return edgeSetIds.reduce(
    (setColors, setId) =>
      hiddenSetsMap.has(setId) ? setColors : [colorParse(sets[setId].color)],
    [] as number[]
  );
};

/**
 * Determine whether the collapsed shape's color is shown
 * @param collapsed
 * @param hiddenSetsMap
 * @returns
 */
export const getCollapsedSetColors = (
  collapsed: CollapsedConfig,
  hiddenSetsMap: Set<UUID>
): Array<number> =>
  !hiddenSetsMap.has(collapsed.setId) ? [colorParse(collapsed.color)] : [];

/**
 * derives the alpha value for an entity
 * @param ecs current ecs instance
 * @param entity entity to modify
 * @param baseAlpha starting alpha value
 * @param uuid uuid of entity
 * @param model current model
 * @returns the new alpha value number
 */
export function deriveAlpha(
  ecs: EcsInstance,
  entity: Entity,
  baseAlpha: number,
  uuid: string,
  model: ModelReducer
): number {
  const {
    sets,
    nodes,
    edges,
    base: { allSetsAlpha, allNodesAlpha, allEdgesAlpha },
  } = model;
  let alpha = baseAlpha;

  // apply the "All Nodes/Edges" alphas depending on this shape type
  if (ecs.hasComponent(entity, Node.type)) {
    const setIds = nodes[uuid]?.setIds ?? [];
    // apply set alphas multiplicatively for each set that owns this shape
    setIds.forEach((sid) => {
      alpha *= sets[sid].alpha;
    });

    // apply the "All Sets" alpha multiplicatively, if this belongs to a set
    if (setIds.length > 0) alpha *= allSetsAlpha;

    alpha *= allNodesAlpha;
  }
  if (ecs.hasComponent(entity, Edge.type)) {
    const setIds = edges[uuid]?.setIds ?? [];
    // apply set alphas multiplicatively for each set that owns this shape
    setIds.forEach((sid) => {
      alpha *= sets[sid].alpha;
    });

    // apply the "All Sets" alpha multiplicatively, if this belongs to a set
    if (setIds.length > 0) alpha *= allSetsAlpha;

    alpha *= allEdgesAlpha;
  }
  if (ecs.hasComponent(entity, CollapsedSet.type)) {
    alpha = baseAlpha * allSetsAlpha;
  }

  return clamp(alpha, 0.05, 1.0);
}

export function ecsChangeOrder(
  direction: string,
  model: ModelReducer,
  ecs: EcsInstance
): boolean {
  const modelState = cloneDeep(model);
  const shapes = modelState.shapes as Record<string, ShapeConfig>;
  const selected = modelState.base.selected.shapes as string[];
  const updated: ShapeConfig[] = [];

  // sort shapes by their display layer
  const layers: Record<string, ShapeConfig[]> = {};
  for (const shape of Object.values(shapes)) {
    layers[shape.layer] = layers[shape.layer] || [];
    layers[shape.layer].push(shape);
  }

  // change order of selected shapes
  for (let i = selected.length; i--; ) {
    const shape = shapes[selected[i]];
    const newOrders = {
      // less than 0 ensures it will be be pop'd first
      [MODEL.SEND_LAYER_TO_BACK]: -1,
      // +-1.5 ensure intent of forward/backward motion is preserved
      // by placing it between two integers
      [MODEL.SEND_LAYER_BACKWARD]: shape.order - 1.5,
      [MODEL.SEND_LAYER_FORWARD]: shape.order + 1.5,
      // greater than length ensures it will be pop'd last
      [MODEL.SEND_LAYER_TO_FRONT]: layers[shape.layer].length + 1,
    };
    shape.order = newOrders[direction];
  }

  // compact shape orders to fit in range [0, layer.length - 1]
  for (const layer of Object.values(layers)) {
    // construct the binary heap for efficient sorting
    const heap = new BinaryHeap((s: ShapeConfig) => s.order);

    // for each item in this layer, add it to the heap
    for (let i = layer.length; i--; ) {
      const shape = layer[i];
      heap.push(shape);
    }

    // for this heap, go through and update all the orders if they changed
    // when they change, we'll use the index so that it stays bounded
    // within the layer's length i.e., 0 to layer.length
    for (let i = 0; i < layer.length; i++) {
      // pop each element out of the heap and check to see if its
      // order has been updated
      const shape: ShapeConfig = heap.pop();
      if (!shape) continue;
      if (shape.order !== i) {
        // the order has changed for this shape, so update it
        shape.order = i;
        updated.push(shape);

        const entity = ecs.tagManager.getEntityByTag(shape.id);
        if (!entity) continue;
        const order = ecs.getComponent(entity, Order) as Order;
        if (!order) continue;
        order.value = shape.order;
        ecs.update(order);
      }
    }
  }

  dispatcher(updateShapesByConfig(updated));
  return true;
}

export function changeLayer(
  direction: string,
  model: ModelReducer,
  engine: ModelEngine,
  ecs: EcsInstance
): boolean {
  const newLayer = {
    [MODEL.SEND_SHAPE_TO_BACKGROUND]: LAYER.BACKGROUND,
    [MODEL.SEND_SHAPE_TO_FOREGROUND]: LAYER.FOREGROUND,
  };
  const shapes = model.shapes;
  const selected = model.base.selected.shapes;
  const updated: ShapeConfig[] = [];

  selected.forEach((shape) => {
    if (shapes[shape].type === SHAPE.BACKGROUND) {
      const entity = ecs.tagManager.getEntityByTag(shape);
      if (!entity) return false;

      shapes[shape].layer = newLayer[direction];
      updated.push(shapes[shape]);

      const layer = ecs.getComponent(entity, MetraLayer);

      if (isSome(layer)) {
        layer.value = newLayer[direction];
        ecs.update(layer);
      }

      // engine needs to move this image to a different container
      // someday we should move this logic to a system
      engine.dirty = true;

      // Always display the moved background on top of new layer
      ecsChangeOrder(MODEL.SEND_LAYER_TO_FRONT, model, ecs);
    }
  });
  dispatcher(updateShapesByConfig(updated));
  return true;
}

/**
 * Determine the portion of the canvas that appears to be its center,
 * when part of it is covered by panels
 * @param screenCenter - center of the screen (not the world)
 * @param panelOffsets - portion of canvas covered by panels
 * @returns apparent center
 */
export const getPerceivedCenter = (
  screenCenter: PointLike,
  panelOffsets: PanelOffsets
): Vector2 =>
  new Vector2(
    screenCenter.x - panelOffsets.panelOffsetX / 2,
    screenCenter.y - panelOffsets.panelOffsetY / 2
  );

/**
 * Determine how much the model canvas is covered by the horizontal or vertical props and files panels
 * @param showPropsPanel whether the props panel is open
 * @param showMptGridPopout whether the MPT is undocked
 * @param showImageFilesPanel whether the image-files panel is open
 * @param tableSettings props panel current settings
 * @returns panel offsets in height and width directions
 */
export function getPanelOffsets(
  showPropsPanel: boolean,
  showMptGridPopout: boolean,
  showImageFilesPanel: boolean,
  tableSettings: TableSettings
): PanelOffsets {
  let panelOffsetX = 0;
  let panelOffsetY = LAYOUT.MODEL_PADDING_Y;
  if (showPropsPanel && !showMptGridPopout) {
    if (tableSettings.orientation === TABLE.ORIENTATION.VERTICAL) {
      panelOffsetX += tableSettings.width;
    } else if (tableSettings.orientation === TABLE.ORIENTATION.HORIZONTAL) {
      panelOffsetY += tableSettings.height;
    }
  }
  if (showImageFilesPanel) panelOffsetX += MODEL_FILES_WIDTH;
  return { panelOffsetX, panelOffsetY };
}

/**
 * Determine location and size data for selection
 * @param boundsPackages list of all relevant bounding data
 * @returns meta data about the bounding data
 */
export const getBoundingMetaData = (
  boundsPackages: BoundsPkg[]
): BoundingMetaData => {
  let minXPkg = boundsPackages[0];
  let maxXPkg = boundsPackages[0];
  let minYPkg = boundsPackages[0];
  let maxYPkg = boundsPackages[0];

  for (let i = boundsPackages.length; i--; ) {
    const boundsPkg = boundsPackages[i];
    minXPkg =
      boundsPkg.bounds.value.left +
        boundsPkg.position.value.x -
        boundsPkg.bounds.value.width / 2 <
      minXPkg.bounds.value.left +
        minXPkg.position.value.x -
        minXPkg.bounds.value.width / 2
        ? boundsPkg
        : minXPkg;
    maxXPkg =
      boundsPkg.bounds.value.right +
        boundsPkg.position.value.x -
        boundsPkg.bounds.value.width / 2 >
      maxXPkg.bounds.value.right +
        maxXPkg.position.value.x -
        maxXPkg.bounds.value.width / 2
        ? boundsPkg
        : maxXPkg;
    minYPkg =
      boundsPkg.bounds.value.top +
        boundsPkg.position.value.y -
        boundsPkg.bounds.value.height / 2 <
      minYPkg.bounds.value.top +
        minYPkg.position.value.y -
        minYPkg.bounds.value.height / 2
        ? boundsPkg
        : minYPkg;
    maxYPkg =
      boundsPkg.bounds.value.bottom +
        boundsPkg.position.value.y -
        boundsPkg.bounds.value.height / 2 >
      maxYPkg.bounds.value.bottom +
        maxYPkg.position.value.y -
        maxYPkg.bounds.value.height / 2
        ? boundsPkg
        : maxYPkg;
  }

  // use the shape's extents information to calculate the min-max
  // of all their extent information, within safe JS number boundaries
  const minX =
    minXPkg.bounds.value.left +
    minXPkg.position.value.x -
    minXPkg.bounds.value.width / 2;
  const maxX =
    maxXPkg.bounds.value.right +
    maxXPkg.position.value.x -
    maxXPkg.bounds.value.width / 2;
  const minY =
    minYPkg.bounds.value.top +
    minYPkg.position.value.y -
    minYPkg.bounds.value.height / 2;
  const maxY =
    maxYPkg.bounds.value.bottom +
    maxYPkg.position.value.y -
    maxYPkg.bounds.value.height / 2;

  // determine our range of values
  const rangeX = Math.abs(maxX - minX);
  const rangeY = Math.abs(maxY - minY);
  const min = new Vector2(minX, minY);
  const max = new Vector2(maxX, maxY);
  const range = new Vector2(rangeX, rangeY);

  const center = range.divScalar(2).add(min);

  return { center, max, min, range, minX, minY, maxX, maxY, rangeX, rangeY };
};

/**
 * Determine the scale required to fit the entities of interest into the visible canvas portion
 * @param panelOffsets amount canvas is obstructed by panels
 * @param boundingMetaData location data for model entities of interest
 * @param scaledViewport camera viewport adjusted based on model scale
 * @returns the appropriate model scale
 */
export const getConstrainedScale = (
  panelOffsets: PanelOffsets,
  boundingMetaData: BoundingMetaData,
  scaledViewport: Vector2
): number => {
  /*
    Our height and width need adjustments due to scaling.

    In addition, we're also giving ourselves room so toolbars
    don't cover elements.

    Ex. ModelToolbarPanel is 50px wide, so we subtract 100px
    from the scaled viewport width.
    This means if we have a 1000px wide viewport, our elements will
    only take up 900px of width, giving 50px of space on both sides.

    When the properties and/or files table is active, we just subtract
    their widths as well.
  */

  const { panelOffsetX, panelOffsetY } = panelOffsets;
  const { rangeX, rangeY } = boundingMetaData;

  const panel = new Vector2(
    LAYOUT.MODEL_PADDING_X + panelOffsetX,
    LAYOUT.MODEL_PADDING_Y + panelOffsetY
  );
  const adjusted = scaledViewport.sub(panel);
  /*
    determine delta scaling for each cardinal direction
    clamp it if the range of values is less than a 1:1 scaled window
  */
  const deltaScale = adjusted
    .div(new Vector2(rangeX, rangeY))
    .clamp(ZOOM.LOWER_BOUNDS, 2.0);

  // we need to use the scaling based on which is smallest (i.e., widest)
  // so that all visible values will be seen
  const widestScale = deltaScale.y < deltaScale.x ? deltaScale.y : deltaScale.x;

  // Ensure new scale isn't less than ZOOM.LOWER_BOUNDS (1%)
  return widestScale > ZOOM.LOWER_BOUNDS ? widestScale : ZOOM.LOWER_BOUNDS;
};

/**
 * Update the camera to zoom on the entities, keeping them centered in the
 * visible portion of the model canvas.
 * @param panelOffsets portion of canvas obscured by open panels
 * @param boundingMetaData entity location data
 * @param nextScale scale needed to keep entities in view
 * @param camera model camera
 * @modifies the camera position and scale
 */
export function doConstrainedZoom(
  panelOffsets: PanelOffsets,
  boundingMetaData: BoundingMetaData,
  nextScale: number,
  camera: ModelCamera
): void {
  const { panelOffsetX, panelOffsetY } = panelOffsets;
  const { minX, minY, rangeX, rangeY } = boundingMetaData;

  // calculate the center of the range of values
  // this will be our new camera position
  const newPos = new Vector2(panelOffsetX, panelOffsetY)
    .divScalar(nextScale)
    .addXY(rangeX, rangeY)
    .divScalar(2)
    .addXY(minX, minY);

  camera.update({
    x: newPos.x,
    y: newPos.y,
    scale: {
      x: nextScale,
      y: nextScale,
    },
  });
}

/**
 * determines the combined style of selected entities
 * @param selectedIds - the uuids of the selected entities
 * @param ecs - the current ecs instance
 * @return the overall style string
 */
export function styleOfSelected(
  selectedIds: string[],
  ecs: EcsInstance
): string {
  if (selectedIds.length === 0) return 'mixed';
  const selectedIdsCount = selectedIds.length;
  const lastEntity = ecs.getEntityByTag(selectedIds[selectedIdsCount - 1]);
  if (isNone(lastEntity)) return 'mixed';
  const lastAsset = ecs.getComponentOfType(lastEntity, Asset);
  if (isNone(lastAsset)) return 'mixed';

  for (let i = selectedIdsCount - 1; i--; ) {
    const entity = ecs.tagManager.getEntityByTag(selectedIds[i]);
    if (!entity) continue;
    const asset = ecs.getComponentOfType(entity, Asset);
    if (!asset) continue;
    if (asset.value !== lastAsset.value) return 'mixed';
  }

  return lastAsset.value;
}

/**
 * determines the combined style of selected edge-entities arrowheads
 * @param selectedIds - the uuids of the selected edge-entities
 * @param ecs - the current ecs instance
 * @return the overall style string
 */
export function arrowheadStyleOfSelected(
  selectedIds: string[],
  ecs: EcsInstance
): string {
  if (selectedIds.length === 0) return 'mixed';
  const selectedIdsCount = selectedIds.length;
  const lastEntity = ecs.tagManager.getEntityByTag(
    selectedIds[selectedIdsCount - 1]
  );
  if (isNone(lastEntity)) return 'mixed';
  const lastArrowhead = ecs.getComponentOfType(lastEntity, Arrowhead);
  if (isNone(lastArrowhead)) return 'mixed';

  for (let i = selectedIdsCount - 1; i--; ) {
    const entity = ecs.tagManager.getEntityByTag(selectedIds[i]);
    if (!entity) continue;
    const arrowhead = ecs.getComponentOfType(entity, Arrowhead);
    if (!arrowhead) continue;
    if (arrowhead.value !== lastArrowhead.value) return 'mixed';
  }

  return lastArrowhead.value;
}

/**
 * determines the combined style of selected edge-entities arrowheads
 * @param selectedIds - the uuids of the selected edge-entities
 * @param ecs - the current ecs instance
 * @return the overall style string
 */
export function waypointStyleOfSelected(
  selectedIds: string[],
  ecs: EcsInstance
): number {
  if (selectedIds.length === 0) return EDGE_STYLE.MIXED;
  const selectedIdsCount = selectedIds.length;
  const lastEntity = ecs.tagManager.getEntityByTag(
    selectedIds[selectedIdsCount - 1]
  );
  if (isNone(lastEntity)) return EDGE_STYLE.MIXED;
  const lastStyle = ecs.getComponentOfType(lastEntity, Style);
  if (isNone(lastStyle)) return EDGE_STYLE.MIXED;

  for (let i = selectedIdsCount - 1; i--; ) {
    const entity = ecs.tagManager.getEntityByTag(selectedIds[i]);
    if (isNone(entity)) continue;
    const style = ecs.getComponentOfType(entity, Style);
    if (isNone(style)) continue;
    if (style.value !== lastStyle.value) return EDGE_STYLE.MIXED;
  }

  return lastStyle.value;
}

export function setSegment(
  ecs: EcsInstance,
  entity: Entity,
  newFrom: Vector2,
  newTo: Vector2
): void {
  // update segment
  const segment = ecs.getComponentOfType(entity, Segment);
  if (isNone(segment)) return;
  segment.from.val = newFrom;
  segment.to.val = newTo;
  ecs.update(segment);
}

export function reverseWaypoints(
  ecs: EcsInstance,
  entity: Entity,
  config: EdgeConfig,
  edge: Edge
): EdgeConfig {
  // first we retrieve the endpoints
  const fromEntity = ecs.tagManager.getEntityByTag(edge.outAnchorId);
  const toEntity = ecs.tagManager.getEntityByTag(edge.inAnchorId);
  if (!fromEntity || !toEntity) return config;
  // then we retrieve their positions
  const fromPos = ecs.getComponentOfType(fromEntity, Position);
  const toPos = ecs.getComponentOfType(toEntity, Position);
  if (!fromPos || !toPos) return config;

  //reverse the waypoints
  config.waypoints.reverse();

  const waypoints = ecs.getComponentOfType(entity, Waypoints);
  if (isNone(waypoints)) return config;
  waypoints.values.reverse();
  const length = waypoints.values.length;
  for (let i = length; i--; ) {
    const waypoint = ecs.getComponentOfType(waypoints.values[i], Waypoint);
    if (isNone(waypoint)) continue;
    waypoint.value = i;
    ecs.update(waypoint);
  }
  ecs.update(waypoints);
  rebuildEdgeSegments(ecs, entity);

  return config;
}

export function modifyEdgeConnections(
  nodeId: string,
  edgeId: string,
  include: boolean,
  updatedNodeStates: Record<string, ModelNode>,
  updatedNodeShapes: Record<string, ShapeConfig>,
  model: ModelReducer
) {
  const updatedNodeStatesCopy = cloneDeep(updatedNodeStates);
  const updatedNodeShapesCopy = cloneDeep(updatedNodeShapes);

  let nodeState = updatedNodeStatesCopy[nodeId];
  let nodeShape = updatedNodeShapesCopy[nodeId] as NodeConfig;

  if (!nodeState) {
    nodeState = model.nodes[nodeId];
    updatedNodeStatesCopy[nodeId] = nodeState;
  }

  if (!nodeShape) {
    nodeShape = model.shapes[nodeId] as NodeConfig;
    updatedNodeShapesCopy[nodeId] = nodeShape;
  }

  if (include) {
    nodeState.edgeIds.push(edgeId);
    nodeShape.edges.push(edgeId);
  } else {
    nodeState.edgeIds = nodeState.edgeIds.filter(
      (existingId) => existingId !== edgeId
    );
    nodeShape.edges = nodeShape.edges.filter(
      (existingId) => existingId !== edgeId
    );
  }
}

/**
 * attempts to build `TransitionData` for a given UUID
 */
// export function uuidToTransitionData(
//   uuid: UUID,
//   data?: Partial<TransitionData>
// ): TransitionData {
//   const ecs: Option<EcsInstance> =
//     data?.ecs ?? Actions?.engine?.ecs ?? undefined;
//   const model: Option<ModelReducer> =
//     data?.model ?? globalGetState().modelReducer;
//   const targetEntity: Option<Entity> =
//     ecs?.getEntityByTag(uuid) ?? data?.targetEntity ?? undefined;
//   const setData: Option<ModelSet> =
//     data?.setData ?? model.sets?.[uuid] ?? undefined;
//   const transitionData = {
//     ...data,
//     ecs,
//     model,
//     setData,
//     targetEntity,
//   } as TransitionData;
//   return transitionData;
// }

/**
 * checks a line segment "contains" a given point
 * based on algorithm here: https://stackoverflow.com/a/13741803
 * @param segment - the edge segment to test against
 * @param point - a 2d vector to use as the point
 * @param fuzziness - the allowed radial fuzzyiness of the check
 * @returns true if "contained" along the line segment, otherwise false
 */
export function segmentContainsPoint(
  segment: Segment,
  point: Vector2,
  fuzziness = 7 // fuzziness radius
): boolean {
  let left!: Vector2, right!: Vector2;
  // are we left to right, or right to left?
  if (segment.from.val.x <= segment.to.val.x) {
    left = segment.from.val;
    right = segment.to.val;
  } else {
    left = segment.to.val;
    right = segment.from.val;
  }

  // point is out of the bounds of our fuzziness factor
  if (point.x + fuzziness < left.x || right.x < point.x - fuzziness) {
    return false;
  } else if (
    point.y + fuzziness < Math.min(left.y, right.y) ||
    Math.max(left.y, right.y) < point.y - fuzziness
  ) {
    return false;
  }

  const delta = right.sub(left);

  // if the line is straight
  if (delta.x === 0 || delta.y === 0) return true;

  const slope = delta.y / delta.x;
  const offset = left.y - left.x * slope;
  const y = point.x * slope + offset;

  const contains = point.y - fuzziness <= y && y <= point.y + fuzziness;

  return contains;
}

export const shouldEdgeBeVisible: ThunkActionFunc<
  [edgeConfig: EdgeConfig | ModelEdge],
  boolean
> =
  (edgeConfig) =>
  (_, getState, { emit }) => {
    const [engine] = emit<[ModelEngine]>(ENGINE.GET.SELF);
    if (isNone(engine)) return false;
    const targetEntity = engine.ecs.getEntityByTag(edgeConfig.id);
    if (isNone(targetEntity)) return false;
    return determineEdgeVisibility({
      targetEntity,
      model: getState().modelReducer,
      ecs: engine.ecs,
    } as TransitionData);
  };

/**
 * Returns whether an edge can be visible (e.g., an edge connecting two nodes
 * that were expressly hidden cannot be made visible).
 * (ignores whether the edge has been expressly hidden by the user)
 * @param data the current transition data
 */
export const isEdgePresentable: ThunkActionFunc<
  [edgeConfig: EdgeConfig | ModelEdge],
  boolean
> =
  (edgeConfig) =>
  (_, getState, { emit }) => {
    const [engine] = emit<[ModelEngine]>(ENGINE.GET.SELF);
    if (isNone(engine)) return false;
    const targetEntity = engine.ecs.getEntityByTag(edgeConfig.id);
    if (isNone(targetEntity)) return false;
    return canEdgeBeVisible({
      targetEntity,
      model: getState().modelReducer,
      ecs: engine.ecs,
    } as TransitionData);
  };

export function toggleSelected(
  entity: Entity,
  selected: boolean,
  ecs: EcsInstance
): void {
  if (selected && !ecs.hasComponentOfType(entity, Selected)) {
    ecs.addComponent(entity, new Selected());
    ecs.resolve(entity);
  } else if (!selected && ecs.hasComponentOfType(entity, Selected)) {
    ecs.removeComponentType(entity, Selected);
    ecs.resolve(entity);
  }
}

export function removeAllSelected(ecs: EcsInstance) {
  const selected = ecs.getComponentsByType(Selected);
  if (!selected) return;

  for (let sel of selected) {
    ecs.removeComponent(sel);
    ecs.resolveById(sel.owner);
  }
}

export function toggleSelectedIds(
  ecs: EcsInstance,
  ids: UUID[],
  selected: boolean
): void {
  for (let id of ids) {
    const entity = ecs.getEntityByTag(id);
    if (!entity) {
      t3dev().log.error(
        `could not set selection on shape ${id}: entity does not exist`
      );
      continue;
    }
    toggleSelected(entity, selected, ecs);
  }
}
