import { isSome } from 'helpers/utils';
import { NOTES } from 'modules/common';

/*******************
 * Action Types
 *******************/

/*******************
 * Action Creators
 *******************/

export const appendNote = (noteId: string) => ({
  type: NOTES.APPEND_NOTE,
  payload: noteId,
});

export const setNotesOrder = (notes: string[]) => ({
  type: NOTES.SET_ORDER,
  payload: notes,
});

export interface NotesCollectionAction {
  type: string;
  payload: string | string[];
}

/*******************
 * Reducer
 *******************/

export const notesCollectionReducer = (
  state: string[],
  action: NotesCollectionAction
) => {
  switch (action.type) {
    case NOTES.APPEND_NOTE: {
      // Don't let the same note-id in twice.  Not even if they are diffent
      // types - right now nucleo is using string-id's for notes, but
      // number-id's for media, and they should represent the same value
      if (!Array.isArray(action.payload)) {
        const payloadNoteId = action.payload;
        if (!state.some((stateNoteId) => stateNoteId === payloadNoteId)) {
          // we don't already have this note id, go ahead and add it.
          return [...state, action.payload];
        }
      }
      return state;
    }
    case NOTES.SET_ORDER: {
      return action.payload;
    }
    default: {
      return isSome(state) ? state : [];
    }
  }
};
