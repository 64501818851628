import Matomo from 'react-piwik';
import { MATOMO_ENABLED, MATOMO_SITE_ID, MATOMO_URL } from './matomoSettings';
import { History } from 'history';

/**
 * Configures Matomo analytics and adds a listener
 * to the browser history to trigger analytics.
 * Matomo reporting is enabled by config retrieved from Nucleo.
 * The Matomo server url and site identifier can be configured via nucleo.
 */
export const initMatomo = (history: History, userEmail: string): void => {
  if (MATOMO_ENABLED()) {
    const matomo = new Matomo({
      url: MATOMO_URL(),
      siteId: MATOMO_SITE_ID(),
      // trackErrors: true,
      // By overriding the default filenames we can bypass adblockers.
      // See https://forum.matomo.org/t/wontfix-custom-name-for-piwik-js/1477/3
      jsFilename: 'js/',
      phpFilename: 'js/',
    });

    // Ensures that all url's are lowercase when sending to Matomo.
    // Prevents Matomo from reporting org/EXAMPLE and org/example as
    // two different pages
    history.listen((location) => {
      const lowercaseUrl = location.pathname.toLowerCase();
      Matomo.push(['setCustomUrl', lowercaseUrl]);
      Matomo.push(['trackPageView']);
    });

    //Send heartbeat every 15 seconds
    //this allows for better recording of time
    //on page
    //see https://developer.matomo.org/guides/tracking-javascript-guide
    Matomo.push(['enableHeartBeatTimer']);
    setMatomoUserId(userEmail);
  }
};

/**
 * Sets the user id in the matomo tracker link. This groups
 * tracking together by the user id. Setting the id to null
 * will clear the user id and record subsequent visits as
 * new visitors.
 *
 * If MATOMO_ENABLED settings is falsey, no action performed.
 *
 * See https://matomo.org/docs/user-id/
 */
export const setMatomoUserId = (userEmail: string): void => {
  if (!MATOMO_ENABLED()) return;

  if (userEmail) {
    Matomo.push(['setUserId', userEmail]);
  } else {
    Matomo.push(['resetUserId']);
  }
};

/**
 * Records a tracking event, which is a useful way to measure
 * interactions such as menu or media clicks.
 *
 * If MATOMO_ENABLED settings is falsey, no action performed.
 *
 * category - category of the event, used for grouping. An example would be 'ModelEditor' or 'ProjectLibrary'.
 * action - action of the event, used for grouping. An example would be 'MenuBar::New' or 'MenuBar::Save'.
 * name - (optional) name of the event. This is typically used to identify the specific file, project or model that is used. An example would be the model uuid.
 * value - (optional) numeric value of the event.
 *
 * See https://matomo.org/docs/event-tracking/
 */
export const trackEvent = (
  category: string,
  action: string,
  name?: string,
  value?: number
): void => {
  if (!MATOMO_ENABLED()) return;

  //Remove undefined args
  let args = ['trackEvent', category, action, name, value].filter(
    (x) => x !== undefined
  );

  Matomo.push(args);
};

export default initMatomo;
