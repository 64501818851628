import {
  AnyNameableMathNode,
  BaseModelEntityType,
  MappedTableSort,
  ModelEntityTypes,
  ModelReducer,
  ModelSchemaCategories,
  NodePrefixType,
  PropertyRef,
  QualifiedRef,
  ResultSet,
} from 'types';
import { mathjs } from './t3math';
import type { Interpreter } from './interpreter';
import { isNone, isSome } from 'helpers/utils';
import {
  GridNameColumnValues,
  GridNameRowValues,
  NODE_TYPE_PREFIX,
  SCHEMA_CATEGORY_TYPES,
} from 'utils/constants-extra';
import { intToAlphaColumn } from 'utils/utils-extra';
import { FeatureFlags } from 'utils/features';
import {
  EXP_NAME_CELL_PARENT_IDS,
  EXP_NAME_CELL_SCHEMA_IDS,
  EXP_NODE_LETTER_PREFIXES,
  SourceTargetType,
  SOURCE_TARGET_TYPES,
  TripleType,
  TRIPLE_TYPES,
  SOURCE_TARGET_MAPS,
  NAME_CELL_PARENT_IDS,
  NAME_CELL_SCHEMA_IDS,
} from './constants';
import { isModelEdge, isModelNode, isModelPath } from 'helpers/data';
import { EventManager } from 'metra-events';
import { t3dev } from 't3dev';
import { MetraNode } from './nodes';

export function isMetraNode<T extends MetraNode>(
  mathNode: any | T
): mathNode is T {
  if ('root' in mathNode && typeof mathNode.root === 'string') return true;
  return false;
}

export function isNamedNode<T extends AnyNameableMathNode>(
  mathNode: any | T
): mathNode is T {
  if (
    mathjs.isAccessorNode(mathNode) ||
    mathjs.isAssignmentNode(mathNode) ||
    mathjs.isFunctionAssignmentNode(mathNode) ||
    mathjs.isSymbolNode(mathNode)
  )
    return true;
  return false;
}

export function isValueNode<T extends math.ConstantNode | math.AssignmentNode>(
  mathNode: any | T
): mathNode is T {
  return mathjs.isConstantNode(mathNode) || mathjs.isAssignmentNode(mathNode);
}

export function isOperatorNode<T extends math.OperatorNode>(
  mathNode: any | T
): mathNode is T {
  return mathjs.isOperatorNode(mathNode);
}

export function isString(value: string | any): value is string {
  if (isSome(value) && typeof value === 'string') return true;
  return false;
}

export function setRecord<A extends Record<UUID, Record<UUID, string>>>(
  obj: A,
  keya: KeyOf<A>,
  keyb: KeyOf<ValueOf<A>>,
  value: ValueOf<ValueOf<A>>
) {
  Object.add(obj, keya, obj[keya] || {});
  Object.add(obj[keya], keyb, value || ('' as ValueOf<ValueOf<A>>));
}

export function removeRecord<A extends Record<UUID, Record<UUID, string>>>(
  obj: A,
  keya: KeyOf<A>,
  keyb: KeyOf<ValueOf<A>>
) {
  if (!obj[keya]) return;
  delete obj[keya][keyb];
  if (Object.keys(obj[keya]).length > 0) return;
  delete obj[keya];
}

// because mathjs doesnt provide this
export function isResultSet<Entries>(
  value: any | ResultSet<Entries>
): value is ResultSet<Entries> {
  if (mathjs.isResultSet(value) || value.type === 'ResultSet') return true;
  return false;
}

export function isGridNameRowValue(
  value: any | GridNameRowValues
): value is GridNameRowValues {
  if (EXP_NAME_CELL_PARENT_IDS.includes(value)) return true;
  return false;
}

export function isGridNameColumnValue(
  value: any | GridNameColumnValues
): value is GridNameColumnValues {
  if (EXP_NAME_CELL_SCHEMA_IDS.includes(value)) return true;
  return false;
}

export function getEntityType(
  state: ModelReducer,
  entityId: UUID,
  schemaId: UUID
): ModelSchemaCategories {
  if (!isGridNameRowValue(entityId)) {
    if (entityId in state.modelCalcs) return 'modelCalcs';
    if (entityId in state.modelProps) return 'modelProps';
    if (entityId in state.nodes) return 'nodes';
    if (entityId in state.edges) return 'edges';
    if (entityId in state.paths) return 'paths';
    if (entityId in state.sets) return 'sets';
  } else if (schemaId in state.propSchemas) {
    const ent = state.propSchemas[schemaId].category;
    return ent;
  } else if (entityId in state.propValues) {
    let found = Object.reduce(
      state.propValues[entityId],
      (result, [_key, value]) => {
        if (value in state.propSchemas) {
          return state.propSchemas[value].category;
        }
        return result;
      },
      null as Option<ModelSchemaCategories>
    );
    if (isSome(found)) return found;
  }

  if (FeatureFlags.DEBUG_TOOLS) {
    console.error(
      'ERROR DETERMINING ENTITY TYPE:',
      Object.clone({
        state,
        entityId,
        schemaId,
      })
    );
  }

  throw new Error(
    `Entity ${entityId} is not of known type: modelCalc, modelProp, node, edge, set, or path`
  );
}

export function entToPrefix(
  entity?: ModelSchemaCategories
): Option<NodePrefixType> {
  return entity ? NODE_TYPE_PREFIX?.[entity] : null;
}

export function prefixToType(
  prefix: string | NodePrefixType
): Option<ModelSchemaCategories> {
  const thePrefix = prefix.toLowerCase();

  return SCHEMA_CATEGORY_TYPES.find(
    (type) => NODE_TYPE_PREFIX?.[type] === thePrefix
  );
}

export function coordsToRef(
  ent: ModelSchemaCategories,
  row: number,
  col: number
): QualifiedRef {
  if (!SCHEMA_CATEGORY_TYPES.includes(ent)) {
    throw new Error(
      'cell entity type must be one of: modelCalcs, modelProps, nodes, edges, paths, or sets'
    );
  }
  if (row < 0 || col < 0) {
    throw new Error('cell coordinates cannot be less than 0');
  }

  const ref = `${entToPrefix(ent)}${intToAlphaColumn(
    col
  )}${row}`.toLowerCase() as QualifiedRef;
  return ref;
}

// build a map of IDs to their row / column
// the stardard idsToCoords does an array search, so
// when doing repeated lookups in buildExpressions, a map is much faster
export function buildCoordMaps(state: ModelReducer) {
  const entityMap: Record<string, Record<string, number>> = {};
  for (const key in state.tableSort.entities) {
    const type = key as ModelSchemaCategories;
    const ents = state.tableSort.entities[type];
    entityMap[type] = { [NAME_CELL_PARENT_IDS[type]]: 0 };
    ents.forEach((ent, i) => (entityMap[type][ent] = i + 1));
  }

  const schemaMap: Record<string, Record<string, number>> = {};
  for (const key in state.tableSort.schemas) {
    const type = key as ModelSchemaCategories;
    const schemas = state.tableSort.schemas[type];
    schemaMap[type] = { [NAME_CELL_SCHEMA_IDS[type]]: 0 };
    schemas.forEach((schema, i) => (schemaMap[type][schema] = i + 1));
  }

  return { entityMap, schemaMap };
}

export function idsToCoords(
  state: ModelReducer,
  mappedTableSort: MappedTableSort,
  { parentId, schemaId }: PropertyRef,
  ent = getEntityType(state, parentId, schemaId),
  override = false
): [row: number, column: number] {
  let row = 0;
  let col = 0;
  if (parentId && !isGridNameRowValue(parentId)) {
    if (isSome(mappedTableSort.entityMap[ent][parentId])) {
      row = mappedTableSort.entityMap[ent][parentId];
    }
    if (row < 0 && !override) {
      console.error('itc::bad row', Object.clone({ parentId, schemaId, ent }));
      throw new Error(
        `cannot convert IDs to coordinates: parent ID not found: ${parentId}`
      );
    }
  }

  if (schemaId && !isGridNameColumnValue(schemaId)) {
    if (isSome(mappedTableSort.schemaMap[ent][schemaId])) {
      col = mappedTableSort.schemaMap[ent][schemaId];
    }
    if (col < 0 && !override) {
      console.error(
        'itc::bad column',
        Object.clone({ parentId, schemaId, ent })
      );
      throw new Error(
        `cannot convert IDs to coordinates: schema ID not found: ${schemaId}`
      );
    }
  }
  return [row, col];
}

export function idsToRef(
  state: ModelReducer,
  mappedTableSort: MappedTableSort,
  { parentId, schemaId }: PropertyRef,
  ent = getEntityType(state, parentId, schemaId),
  override = false
): QualifiedRef {
  const [row, col] = idsToCoords(
    state,
    mappedTableSort,
    { parentId, schemaId },
    ent,
    override
  );
  return coordsToRef(ent, row, col);
}

export function coordsToLocalRef(row: number, col: number): string {
  if (row < 0 || col < 0) {
    throw new Error('cell coordinates cannot be less than 0');
  }

  const ref = `${intToAlphaColumn(col)}${row}`;
  return ref.toLowerCase();
}

export function isGlobalRef(ref: string): boolean {
  if (
    EXP_NODE_LETTER_PREFIXES.includes(ref[0].toLowerCase()) &&
    ref[1] === '_' &&
    ref.length > 2
  )
    return true;

  return false;
}

export function isSourceTargetType(
  value: string | SourceTargetType
): value is SourceTargetType {
  if (SOURCE_TARGET_TYPES.includes(value as SourceTargetType)) return true;
  return false;
}

export function isTripleType(value: string | TripleType): value is TripleType {
  if (TRIPLE_TYPES.includes(value as TripleType)) return true;
  return false;
}

export function getEntityRow(
  mappedTableSort: MappedTableSort,
  entity: ModelEntityTypes,
  type: ModelSchemaCategories
): number {
  return mappedTableSort.entityMap[type][entity.id];
}

export function lookupEntity(
  modelState: ModelReducer,
  type: ModelSchemaCategories,
  entityId: string
): Option<BaseModelEntityType> {
  const entities = modelState[type];
  if (entityId in entities) {
    const entity = entities[entityId];
    return entity;
  }
  return null;
}

export function lookupEndpointEntity(
  modelState: ModelReducer,
  entity: BaseModelEntityType,
  mathNodeName: string
): [Option<ModelEntityTypes>, Option<ModelSchemaCategories>] {
  let lookupId = null;
  if (isModelEdge(entity)) {
    const prop = SOURCE_TARGET_MAPS?.[mathNodeName as SourceTargetType] ?? 'id';
    lookupId = entity?.[prop];
  } else if (isModelNode(entity)) {
    lookupId = entity.id;
  } else if (isModelPath(entity)) {
    if (mathNodeName === 'source') {
      lookupId = entity.triples[0].source;
    } else if (mathNodeName === 'target') {
      lookupId = entity.triples[entity.triples.length - 1].target;
    }
  }
  if (isNone(lookupId)) return [lookupId, null];
  try {
    const type = getEntityType(modelState, lookupId, '');
    const endpoint = modelState[type]?.[lookupId] ?? null;
    return [endpoint, type];
  } catch {
    return [undefined, undefined];
  }
}

/*
 * this allows us to always retrieve the current 'active' interpreter
 */
export function getInterpreter<R, O>(): Interpreter<R, O> {
  const [itpr] = EventManager.emit('itpr');
  if (!itpr) throw new Error('Interpreter Error: not initialized!');
  return itpr;
}
