import { MetraSimpleAction, MetraVoidAction } from 'types';
import { ActionSignatures } from './types';

export const MODEL_HISTORY = {
  CLEAR: 'model_history/CLEAR',
  CLEAR_REDO: 'model_history/CLEAR_REDO',
  CREATE: 'model_history/CREATE',
  REDO: 'model_history/REDO',
  UNDO: 'model_history/UNDO',
  UNIVERSAL: 'model_history/UNIVERSAL',
};

export function createHistory({
  undo,
  redo,
}: ActionSignatures): MetraSimpleAction<ActionSignatures> {
  return {
    type: MODEL_HISTORY.CREATE,
    payload: {
      undo,
      redo,
    },
  };
}

export function undo(): MetraVoidAction {
  return {
    type: MODEL_HISTORY.UNDO,
  };
}

export function redo(): MetraVoidAction {
  return {
    type: MODEL_HISTORY.REDO,
  };
}

export function clear(): MetraVoidAction {
  return {
    type: MODEL_HISTORY.CLEAR,
  };
}

export function clearRedo(): MetraVoidAction {
  return {
    type: MODEL_HISTORY.CLEAR_REDO,
  };
}
