import { TableSort } from 'types';
import type { Expression } from '../expression';
import type { Interpreter } from 'interpreter/interpreter';
import { GRID, SCHEMA_CATEGORY_TYPES } from 'utils/constants-extra';
import { alphaColumnToInt, intToAlphaColumn } from 'utils/utils-extra';
import { coordsToRef, entToPrefix, prefixToType } from 'interpreter/helpers';
import { DependencyNode } from 'interpreter/dependencyCache';
import { nameSchemas } from 'modules/basicSearch/helpers';

export const symbolRx = /([_$a-z]+[_$a-z0-9]*)/gi;
export const rangeRx = /([_$a-z]+[_$a-z0-9]*):([_$a-z]+[_$a-z0-9]*)/gi;
export const cellRx = /^(?<type>[cmnsep]_)?(?<col>[a-z]+)(?<row>[0-9]+)$/i;
export const colRx = /^(?<type>[cmnsep]_)?(?<col>[a-z]+)_$/i;
export const rowRx = /^(?<type>[cmnsep]_)?_(?<row>[0-9]+)$/i;

type CapturedIds = {
  columnIds: Record<UUID, string>;
  rowIds: Record<UUID, number>;
};

export function buildCellMappings<R, O>(
  oldTableSort: TableSort,
  newTableSort: TableSort,
  itpr: Interpreter<R, O>
): Record<UUID, UUID> {
  const cellIdsBefore = captureCellIds(oldTableSort);
  const cellIdMappings: Record<UUID, UUID> = {};
  SCHEMA_CATEGORY_TYPES.forEach((entityType) => {
    // create cell mappings for row 0 (prop-name row).
    let columnId = 'b';
    const prefix = entToPrefix(entityType);
    newTableSort.schemas[entityType].forEach((schemaId) => {
      const old0CellId = prefix + cellIdsBefore.columnIds[schemaId] + '0';
      const new0CellId = prefix + columnId + '0';
      Object.add(cellIdMappings, old0CellId, new0CellId);
      columnId = incrementColumnId(columnId);
    });

    // create cell mappings for column a (entity-name column).
    let rowId = 1;
    newTableSort.entities[entityType].forEach((entityId) => {
      const oldACellId = prefix + 'a' + cellIdsBefore.rowIds[entityId];
      const newACellId = prefix + 'a' + rowId;
      Object.add(cellIdMappings, oldACellId, newACellId);
      rowId++;
    });

    // create cell mappings for the rest of the table
    columnId = 'b';
    newTableSort.schemas[entityType].forEach((schemaId) => {
      let rowId = 1;
      newTableSort.entities[entityType].forEach((entityId) => {
        const oldCellId =
          prefix +
          cellIdsBefore.columnIds[schemaId] +
          cellIdsBefore.rowIds[entityId];
        const newCellId = prefix + columnId + rowId;
        Object.add(cellIdMappings, oldCellId, newCellId);
        rowId++;
      });
      columnId = incrementColumnId(columnId);
    });
  });

  return trimMappings(cellIdMappings);
}

function trimMappings(cellMappings: Record<UUID, UUID>): Record<UUID, UUID> {
  const trimmedMappings: Record<UUID, UUID> = {};

  // to save time we remove identity-mappings
  Object.keys(cellMappings).forEach((originalName) => {
    const updatedName = cellMappings[originalName];
    if (originalName !== updatedName) {
      trimmedMappings[originalName] = updatedName;
      // also add full-row, full-column mappings
      if (!originalName.includes('undefined')) {
        const originalMatch = originalName.match(
          /^(?<type>[cmnsep]_)?(?<col>[a-z]+)(?<row>[0-9]+)$/i
        );

        const updatedMatch = updatedName.match(
          /^(?<type>[cmnsep]_)?(?<col>[a-z]+)(?<row>[0-9]+)$/i
        );
        if (originalMatch && updatedMatch) {
          const [, entType, origCol, origRow] = originalMatch;
          const [, , updCol, updRow] = updatedMatch;
          trimmedMappings[entType + '_' + origRow] = entType + '_' + updRow;
          trimmedMappings[entType + origCol + '_'] = entType + updCol + '_';
        }
      }
    }
  });

  return trimmedMappings;
}

export function captureCellIds(tableSort: TableSort): CapturedIds {
  const columnIds: Record<UUID, string> = {};
  const rowIds: Record<UUID, number> = {};
  SCHEMA_CATEGORY_TYPES.forEach((entityType) => {
    let columnId = 'b';
    tableSort.schemas[entityType].forEach((schemaId) => {
      Object.add(columnIds, schemaId, columnId);
      columnId = incrementColumnId(columnId);
    });
    let rowId = 1;
    tableSort.entities[entityType].forEach((entityId) => {
      Object.add(rowIds, entityId, rowId++);
    });
  });
  return {
    columnIds: columnIds,
    rowIds: rowIds,
  };
}

export function incrementColumnId(columnId: string): string {
  const columnIdAsNumber = alphaColumnToInt(columnId);
  return intToAlphaColumn(columnIdAsNumber + 1).toLowerCase();
}

/**
 * replaces `C1:D5` with range function: `cellRange(C1, D5)`
 * these refs will be qualified in the parser along with other refs
 * NOTE / WARNING: does not appear to cover if user uses `cellRange` instead
 */
export function replaceRanges<R, O>(
  itpr: Interpreter<R, O>,
  exp: Expression<R, O>
): string {
  const value = exp.preparsed.replace(rangeRx, (range, start, end) => {
    if (!start.match(cellRx) || !end.match(cellRx)) return range;
    const s = itpr.grab(start, exp.ent);
    const e = itpr.grab(end, exp.ent);
    if (!s || !e || s.ent !== e.ent) return range;
    const deps = exp.deps;
    deps && deps.addDepRange(s, e);
    if (!s.deps || !e.deps) return range;
    const inline = `cellRange(${start}, ${end})`;
    return inline;
  });
  return value;
}

export function addColumnDependencies<R, O>(
  itpr: Interpreter<R, O>,
  exp: Expression<R, O>
) {
  for (let match of exp.preparsed.matchAll(symbolRx)) {
    const sym = match[0];
    const colRef = sym.match(colRx);
    if (!colRef || !colRef.groups) continue;

    const col = itpr.derefColumn(colRef.groups.col);
    const prefix = colRef.groups.type;
    const ent = prefix ? prefixToType(prefix.toLowerCase()) : exp.ent;
    if (!ent) {
      console.info(
        `saw undefined entity for expression: ${exp} while attempting to add column dependencies`
      );
      continue;
    }

    const tableSort = itpr.state.tableSort;
    const rowcount = tableSort.entities[ent].length;
    const startRef = coordsToRef(ent, 1, col);
    const endRef = coordsToRef(ent, rowcount, col);
    const start = itpr.grab(startRef);
    const end = itpr.grab(endRef);
    const deps = exp.deps;
    deps && start && end && deps.addDepRange(start, end);
  }
}

export function addRowDependencies<R, O>(
  itpr: Interpreter<R, O>,
  exp: Expression<R, O>
) {
  for (let match of exp.preparsed.matchAll(symbolRx)) {
    const sym = match[0];
    const rowRef = sym.match(rowRx);
    if (!rowRef || !rowRef.groups) continue;
    const row = itpr.derefRow(rowRef.groups.row);
    const prefix = rowRef.groups.type;
    const ent = prefix ? prefixToType(prefix.toLowerCase()) : exp.ent;

    if (!ent) {
      console.info(
        `saw undefined entity for expression: ${exp} while attempting to add row dependencies`
      );
      continue;
    }

    const tableSort = itpr.state.tableSort;
    const colcount = tableSort.schemas[ent].length;
    const startRef = coordsToRef(ent, row, 0);
    const endRef = coordsToRef(ent, row, colcount);
    const start = itpr.grab(startRef);
    const end = itpr.grab(endRef);
    const deps = exp.deps;
    deps && start && end && deps.addDepRange(start, end);
  }
}
