import { TABLE } from 'modules/common';
import { invalidateClipboardCellReferences } from 'helpers/copy-paste';
import { withUpdatedExpressions } from 'modules/model/properties/expressions/actions';

/**
 * ACTIONS
 */

/**
 *
 * @param {{
 *  type: import('types').TableSortKey;
 *  subtype: import('types').ModelSchemaCategories;
 *  func: string;
 *  meta: import('types').CategorySorting;
 * }} sort
 * @param {boolean} sync
 * @returns {import('types').MetraThunkAction<unknown, void, void>}
 */
export const applySort =
  (
    sort = { type: null, subtype: null, func: null, meta: null },
    sync = false
  ) =>
  (dispatch) => {
    dispatch(
      withUpdatedExpressions(
        {
          type: TABLE.SORT.APPLY,
          payload: sort,
        },
        sync
      )
    );
    dispatch(invalidateClipboardCellReferences(sort.subtype));
  };

/**
 * override and initialize the sort state as the given sort.
 * @param {object} sort - sort state.
 */
export const initSort = (sort) => ({
  type: TABLE.SORT.INIT,
  payload: sort,
});

/**
 * re-initialize the sortings with a default id-based sort
 * @returns {import('types').MetraVoidAction}
 */
export const resetSort = () => ({ type: TABLE.SORT.RESET });

/**
 * updates/adds a specific entities sort
 * @param {import('types').SyncFuncMeta} sort - the given sort for a specific item {type, id, order}
 */
export const updateEntitySort = (
  sort = { type: null, id: null, order: null }
) => updateManySort('entities', [sort]);

/**
 * updates/adds a specific schema sort
 * @param {import('types').SyncFuncMeta} sort - the given sort for a specific item {type, id, order}
 */
export const updateSchemaSort = (
  sort = { type: null, id: null, order: null }
) => updateManySort('schemas', [sort]);

/**
 * updates/adds many sortings of a specific major type (e.g., entities, schemas)
 * @param {'entities' | 'schemas'} type - Parameter description.
 * @param {import('types').CategorySorting[]} sortings - an array of all the individual sorts to apply
 * @param {boolean} [sync=false] - whether or not to update expressions synchronously vs asynchronously
 */
export const updateManySort =
  (type, sortings = [], sync = false) =>
  async (dispatch) => {
    dispatch(
      withUpdatedExpressions(
        {
          type: TABLE.SORT.UPDATE,
          payload: [type, sortings],
        },
        sync
      )
    );
    dispatch(invalidateClipboardCellReferences(type));
  };

/**
 * Coordinates to move an MPT column or row from one location to another
 * @typedef {{dimension: string, tab: string, from: number, to: number}} CellMovement
 */
/**
 *
 * @param {CellMovement} params
 * @returns {import('types/common').Action<CellMovement>}
 */
export const arrangeCells =
  ({ dimension, tab, from, to }) =>
  async (dispatch) => {
    dispatch(
      withUpdatedExpressions({
        type: TABLE.ARRANGE_CELLS,
        payload: { dimension, tab, from, to },
      })
    );
    dispatch(invalidateClipboardCellReferences(tab));
  };
