import {
  CollapsedSet,
  Label,
  Muted,
  Segment,
  Selectable,
  Selected,
  ShapeUUID,
  Waypoint,
} from 'engine/components';
import { SubAtoms } from '../sub-atoms';
import Actions from 'actions/actions';
import { isSome } from 'helpers/utils';

import type { Query } from '..';
import type { Entity, EntityId } from 'ecs/Entity';

export class SelectionAtoms extends SubAtoms {
  private _selected!: Query;
  private _selectableShapes!: Query;

  init() {
    this._selected = this.sys2.query(Selected);
    this._selectableShapes = this.sys2
      .query(Selectable, ShapeUUID)
      .without(Label, Segment, Waypoint, Muted);
  }

  // NOTE: ideally the selection atoms would
  // purely use sys2 and dispatches.
  // this is a hack where we update ecs immediately,
  // then call Actions which also updates ecs needlessly in a later frame.
  // eventually we should replace Actions calls with dispatches
  // but the logic is complicated and needs a thorough refactor
  select(eids: EntityId[], shouldCreateHistory: boolean) {
    const uuids: Set<string> = new Set();
    for (const eid of eids) {
      const uuid = this.sys2.assert(eid, ShapeUUID).val;
      const target = this.sys2.engine.ecs.getEntityByTag(uuid) as Entity;
      const selected = this.sys2.ensure(target.id, new Selected());
      this.sys2.engine.ecs.update(selected);
      uuids.add(uuid);

      // if this is a collapsed set, select the set itself
      const setUuid = this.sys2.maybe(target.id, CollapsedSet)?.setId;
      if (isSome(setUuid)) {
        uuids.add(setUuid);
      }
    }
    Actions.select([...uuids], 'both', undefined, shouldCreateHistory);
  }

  deselect(eids: EntityId[]) {
    const uuids: Set<string> = new Set();
    for (const eid of eids) {
      this.sys2.removeComponent(eid, Selected);
      this.sys2.engine.ecs.updateByEntity({ id: eid } as Entity);
      const uuid = this.sys2.assert(eid, ShapeUUID).val;
      uuids.add(uuid);

      // if this is a collapsed set, deselect the set itself
      const setUuid = this.sys2.maybe(eid, CollapsedSet)?.setId;
      if (isSome(setUuid)) {
        uuids.add(setUuid);
      }
    }
    Actions.deselect([...uuids], 'both');
  }

  deselectAll() {
    for (const eid of this._selected.all) {
      this.sys2.removeComponent(eid, Selected);
    }
    Actions.deselectAll();
  }

  selectAll() {
    const uuids: UUID[] = [];
    for (const eid of this._selectableShapes.all) {
      this.sys2.addComponent(eid, new Selected());

      const uuid = this.sys2.assert(eid, ShapeUUID).val;
      uuids.push(uuid);
    }
    Actions.selectAll(uuids);
  }

  selectExactly(eids: EntityId[], shouldCreateHistory: boolean) {
    const uuids: Set<string> = new Set();
    for (const eid of this._selected.all) {
      this.sys2.removeComponent(eid, Selected);
      this.sys2.engine.ecs.updateByEntity({ id: eid } as Entity);
    }
    for (const eid of eids) {
      const uuid = this.sys2.assert(eid, ShapeUUID).val;
      const target = this.sys2.engine.ecs.getEntityByTag(uuid) as Entity;
      const selected = this.sys2.ensure(target.id, new Selected());
      this.sys2.engine.ecs.update(selected);
      uuids.add(uuid);

      // if this is a collapsed set, select the set itself
      const setUuid = this.sys2.maybe(target.id, CollapsedSet)?.setId;
      if (isSome(setUuid)) {
        uuids.add(setUuid);
      }
    }
    Actions.selectExactly([...uuids], 'both', undefined, shouldCreateHistory);
  }
}
