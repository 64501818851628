import { Reducer } from 'redux';
import { MEDIA, isSimpleAction } from 'modules/common';
import { DownloadCacheEntry, MetraAction, ThunkActionFunc } from 'types';
import { t3dev } from 't3dev';

/*
 * downloadCache reducer. The downloadCache stores signed URLs for reuse.
 * It should always be accessed through the appropriate helper method so
 * expired URLs are properly refreshed
 */
export const downloadCacheReducer: Reducer<
  Record<string, DownloadCacheEntry>,
  MetraAction<any, any, any>
> = (state = {}, action) => {
  if (!isSimpleAction(action)) return state;
  switch (action.type) {
    case MEDIA.ADD_URL_TO_CACHE: {
      const { signedUrl } = action.payload;
      const expires = Date.now() + 60 * 60 * 1000; // 1 hour from now
      return { ...state, [action.payload.downloadUrl]: { signedUrl, expires } };
    }
    default:
      return state;
  }
};

// export default downloadCacheReducer;

export const getSignedUrl: ThunkActionFunc<
  [downloadUrl: string],
  Promise<Option<string>>
> = (downloadUrl) => async (dispatch, getState) => {
  const { downloadCache } = getState().mediaReducer;
  const entry = downloadCache?.[downloadUrl] ?? null;
  if (entry && entry.expires > Date.now()) return entry.signedUrl;
  dispatch({
    type: MEDIA.ADD_URL_TO_CACHE,
    payload: { downloadUrl, signedUrl: 'loading' },
  });

  try {
    const response = await fetch(downloadUrl, {
      method: 'get',
      credentials: 'include',
      mode: 'cors',
    });
    // local environment doesn't redirect
    const signedUrl = response.redirected ? response.url : downloadUrl;

    dispatch({
      type: MEDIA.ADD_URL_TO_CACHE,
      payload: { downloadUrl, signedUrl },
    });

    return signedUrl;
  } catch (error: any) {
    t3dev().log.error('ERROR ADDING TO DOWNLOAD CACHE', error.toString());
    return null;
  }
};
