import {
  MetraActionFunc,
  MetraMedia,
  ShapeConfig,
  ThunkActionFunc,
} from 'types';
import { MODEL } from 'utils/constants';
import { deleteFile } from 'modules/media/media';
import { remember } from 'modules/model/base/actions';
import { getMediaAssetURL } from 'utils/url-builders';
import { hasAsset } from '../shape/shape-helpers';

export const setNodeMediaFile: MetraActionFunc<
  [file: Partial<MetraMedia>],
  Partial<MetraMedia>
> = (file) => ({
  type: MODEL.SET_NODE_MEDIA_FILE,
  payload: file,
});

export const setGrid: MetraActionFunc<[showGrid: unknown], unknown> = (
  showGrid
) => ({
  type: MODEL.FILES.SET_GRID,
  payload: showGrid,
});

export const setEditMode: MetraActionFunc<[isEditing: boolean], boolean> = (
  isEditing
) => ({
  type: MODEL.FILES.SET_EDIT_MODE,
  payload: isEditing,
});

export const setSelectedFiles: ThunkActionFunc<
  [file: Partial<MetraMedia>],
  void
> = (file) => (dispatch, getState) => {
  const { editMode, selectedFiles } = getState().modelReducer.files;
  let newSelection = new Set<Numberish>(selectedFiles);
  if (editMode && file.id) {
    if (selectedFiles.has(file.id)) {
      newSelection.delete(file.id);
    } else {
      newSelection.add(file.id);
    }
  } else if (file.id) {
    newSelection = new Set([file.id]);
    dispatch({
      type: MODEL.SET_NODE_MEDIA_FILE,
      payload: file,
    });
  } else {
    newSelection = new Set<Numberish>([]);
  }

  dispatch({
    type: MODEL.FILES.SET_SELECTED_FILES,
    payload: newSelection,
  });
};

export const attemptToDeleteSelectedFiles: ThunkActionFunc<[], void> =
  () => async (dispatch, getState) => {
    const { media } = getState().entityReducer;
    const {
      shapes,
      files: { selectedFiles },
    } = getState().modelReducer;

    const assetMap = new Map<string, ShapeConfig[]>();
    Object.forEach(shapes, ([id, shape]) => {
      if (!hasAsset(shape)) return;
      assetMap.set(shape.asset, [...(assetMap.get(shape.asset) ?? []), shape]);
    });

    const shapesToRevert: ShapeConfig[] = [];

    selectedFiles.forEach((fileId) => {
      const file = media?.[fileId];
      if (!file) return;
      const asset = getMediaAssetURL(file);
      if (assetMap.has(asset)) {
        assetMap.get(asset)?.forEach((shape) => shapesToRevert.push(shape));
      }
    });

    if (shapesToRevert.length > 0) {
      dispatch({
        type: MODEL.FILES.SET_SHAPES_TO_REVERT,
        payload: shapesToRevert,
      });
      dispatch(toggleDeleteImageModal());
    } else {
      await dispatch(deleteFiles());
    }
  };

export const deleteFiles: ThunkActionFunc<[], Promise<void>> =
  () => async (dispatch, getState) => {
    const promises = [];
    const { selectedFiles } = getState().modelReducer.files;

    dispatch(setNodeMediaFile({}));

    for (let fileId of selectedFiles)
      promises.push(dispatch(deleteFile(fileId)));

    await Promise.all(promises);
    dispatch(remember());
    dispatch(setEditMode(false));
  };

export const toggleDeleteImageModal: ThunkActionFunc<[], void> =
  () => (dispatch, getState) => {
    dispatch({
      type: MODEL.FILES.TOGGLE_DELETE_IMAGE_MODAL,
      payload: !getState().modelReducer.files.showDeleteImageModal,
    });
  };
