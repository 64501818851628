import SeamlessImmutable, { isImmutable } from 'seamless-immutable';
import { ENTITIES, isApiAction } from 'modules/common';
import {
  BaseNormalizedResult,
  EntityKey,
  EntityMutationType,
  EntityReducer,
  MutableEntityReducer,
} from 'types';
import { Reducer } from 'redux';
import { isNone } from 'helpers/utils';
import { t3dev } from 't3dev';
import { updateOrAdd } from './utils';

// NOTE: only ever used here to describe the shape of incomming data
// if it is needed elsewhere, hoist it into types
// DO NOT export it from here
interface EntityReducerAction<Payload> {
  type: string;
  payload: Payload;
  meta: {
    schema?: EntityKey;
    mutation: EntityMutationType;
    record?: Numberish;
  };
}

type AnyEntityAction =
  | EntityReducerAction<MutableEntityReducer>
  | EntityReducerAction<IIdentifiable>;

function isIdAction<T extends EntityReducerAction<IIdentifiable>>(
  action: any | T
): action is T {
  if (action.payload && 'id' in action.payload) return true;
  return false;
}

export function createReducer(state: EntityReducer, action: AnyEntityAction) {
  if (!isIdAction(action)) return state;
  if (isNone(action.meta.schema)) return state;
  return updateOrAdd(
    state,
    action.meta.schema,
    action.payload.id,
    action.payload
  );
}

export function readReducer(
  state: EntityReducer,
  action: EntityReducerAction<BaseNormalizedResult>
) {
  if (isIdAction(action)) return state;
  if (isNone(action.payload.entities)) return state;
  const result = Object.reduce(
    action.payload.entities,
    (innerState, [schema, entities]) => {
      return Object.reduce(
        entities,
        (finalState, [id, entity]) => {
          return updateOrAdd(finalState, schema, id, entity);
        },
        innerState
      );
    },
    state
  );
  return result;
}

export const updateOneReducer = (
  state: EntityReducer,
  action: EntityReducerAction<IIdentifiable>
) => {
  if (!isIdAction(action)) return state;
  if (isNone(action.meta.schema)) return state;
  return updateOrAdd(
    state,
    action.meta.schema,
    action.payload.id,
    action.payload
  );
};

export const updateManyReducer = (
  state: EntityReducer,
  action: EntityReducerAction<BaseNormalizedResult>
) => {
  if (isIdAction(action)) return state;
  if (isNone(action.meta.schema)) return state;
  let update = state;
  Object.forEach(action.payload.entities, ([schemaKey, entities]) => {
    Object.forEach(entities, ([id, entity]) => {
      update = updateOrAdd(update, schemaKey, id, entity);
    });
  });

  return update;
};

export const deleteReducer = (
  state: EntityReducer,
  action: EntityReducerAction<IIdentifiable>
) => {
  if (!isIdAction(action)) return state;
  if (isNone(action.meta.schema)) return state;

  const updatedSchema = { ...state[action.meta.schema] };
  delete updatedSchema[action.meta.record || action.payload.id];

  return SeamlessImmutable({
    ...state,
    [action.meta.schema]: updatedSchema,
  });
};

/**
 */
const _initialEntityState: MutableEntityReducer = {
  admins: {},
  adminGuilds: {},
  apikey: {},
  guilds: {},
  guildMetrics: {},
  guildSearchResults: {},
  historicalfileversion: {},
  historicalmedia: {},
  historicalmodelversion: {},
  historicalproject: {},
  license: {},
  media: {},
  mediaSearchResults: {},
  orgMetrics: {},
  orgUsers: {},
  projects: {},
  projectSearchResults: {},
  users: {},
  tasks: {},
  tags: {},
  versions: {},
};

export const initialEntityState = SeamlessImmutable(_initialEntityState);

/**
 */
export const entityReducer: Reducer<
  EntityReducer,
  EntityReducerAction<MutableEntityReducer> | EntityReducerAction<IIdentifiable>
> = (state = initialEntityState, action) => {
  if (action.type !== ENTITIES.ACTION_SUCCESS) {
    return state;
  }
  if (!isImmutable(state)) {
    t3dev().log.error('NO!', { action, state });
    throw new Error("DON'T MUTATE ENTITIES! BAD DEV! NO COOKIE!!!");
  }
  if (isApiAction(action) && action.meta) {
    switch (action.meta.mutation) {
      case ENTITIES.MUTATE_CREATE:
        return createReducer(state, action);
      case ENTITIES.MUTATE_READ:
        return readReducer(state, action as any);
      case ENTITIES.MUTATE_UPDATE_ONE:
        return updateOneReducer(state, action as any);
      case ENTITIES.MUTATE_UPDATE_MANY:
        return updateManyReducer(state, action as any);
      case ENTITIES.MUTATE_DELETE:
        return deleteReducer(state, action as any);
      default:
        return state;
    }
  }

  return state;
};
