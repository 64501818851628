import type { Entity, EntityId, Signature } from 'ecs/Entity';
import type { Component } from './component';
import type { ComponentClass } from './types';
import { T3DevLogger, t3dev } from 't3dev';

type QueryEvent =
  | 'added'
  | 'removed'
  | 'created'
  | 'destroyed'
  | 'changed'
  | 'tick'
  | 'load';

export type QueryFn = (entities: Set<EntityId>) => void;

export type QueryOneFn = (eid: EntityId) => void;
export type QueryEachFn = (eid: EntityId) => void;

type QueryEventListeners = Record<QueryEvent, QueryFn[]>;

export function queryOne(callback: QueryOneFn) {
  let fn: QueryFn = (entities) => {
    if (entities.size < 1) return;
    if (entities.size > 1) {
      t3dev().log.error(
        'QueryOneFn',
        callback,
        `expected 1 but received ${entities.size} entities`
      );
      return;
    }
    const eid = [...entities][0];
    callback(eid);
  };

  return fn;
}

function toSignature(
  components: ComponentClass[],
  start: Signature = new Set()
) {
  for (const component of components) {
    start.add(component.type);
  }

  return start;
}

export class Query {
  included: Signature;
  excluded: Signature;
  optional: Signature;

  all: Set<EntityId> = new Set();

  added: Set<EntityId> = new Set();
  removed: Set<EntityId> = new Set();
  created: Set<EntityId> = new Set();
  destroyed: Set<EntityId> = new Set();
  changed: Set<EntityId> = new Set();

  tock = false;
  detached = false;

  _why: Option<number> = null;
  _whyLogger: keyof T3DevLogger = 'info';
  _stack: Error;
  _alwaysWarn = false;

  listeners: QueryEventListeners = {
    added: [],
    removed: [],
    created: [],
    destroyed: [],
    changed: [],
    tick: [],
    load: [],
  };

  detach() {
    this.detached = true;
  }

  why(id = 0, logger: keyof T3DevLogger = 'info') {
    this._why = id;
    this._whyLogger = logger;
    return this;
  }

  get shouldWarn() {
    return this._alwaysWarn || this._why != null;
  }

  _reportWhy(
    event: QueryEvent,
    eid: EntityId,
    component: Option<Component | typeof Component>
  ) {
    if (this.shouldWarn && this.tock && this.listeners[event].length > 0) {
      const trace = new Error('Event occurrence:');
      t3dev().log.warn(
        `MISSED downstream '${event}' event for ${eid} ${
          component?.componentName || ''
        }.\n Should you register your system earlier?`,
        '\n\n',
        this._stack,
        '\n\n',
        trace
      );
    }
    if (this._why == null) return;
    if (this._why > 0 && this._why !== eid) return;
    const componentText = component
      ? ` because of ${component.componentName}`
      : '';
    t3dev().log[this._whyLogger](`query ${event} ${eid}${componentText}`);
  }

  match(signature: Signature) {
    for (const i of this.included) {
      if (!signature.has(i)) return false;
    }

    for (const e of this.excluded) {
      if (signature.has(e)) return false;
    }

    return true;
  }

  constructor(
    included: ComponentClass[] = [],
    excluded: ComponentClass[] = [],
    optional: ComponentClass[] = []
  ) {
    this._stack = new Error('Query definition: ');
    this.included = toSignature(included);
    this.excluded = toSignature(excluded);
    this.optional = toSignature(optional);
  }

  add(entity: Entity, component: Option<Component | typeof Component>) {
    if (!this.all.has(entity.id)) {
      this.all.add(entity.id);
      this.added.add(entity.id);
      this._reportWhy('added', entity.id, component);
      return true;
    }

    return false;
  }

  remove(eid: EntityId, component: Option<Component | typeof Component>) {
    if (this.all.has(eid)) {
      this.all.delete(eid);
      this.changed.delete(eid);
      // for some reason (probably rebuilding)
      // segments are added and removed on the same frame
      this.added.delete(eid);
      this.removed.add(eid);

      this._reportWhy('removed', eid, component);
      return true;
    }

    return false;
  }

  change(eid: EntityId, component: Component | typeof Component) {
    if (!this.all.has(eid)) return;
    if (
      this.included.has(component.type) ||
      this.optional.has(component.type)
    ) {
      this.changed.add(eid);
      this._reportWhy('changed', eid, component);
      return true;
    }

    return false;
  }

  load() {
    for (const listener of this.listeners.load) {
      listener(this.all);
    }
  }

  with(...components: ComponentClass[]) {
    this.included = toSignature(components, this.included);
    return this;
  }

  without(...components: ComponentClass[]) {
    this.excluded = toSignature(components, this.excluded);
    return this;
  }

  maybe(...components: ComponentClass[]) {
    this.optional = toSignature(components, this.optional);
    return this;
  }

  on(event: QueryEvent | QueryEvent[], callback: QueryFn) {
    if (Array.isArray(event)) {
      this.onAll(event, callback);
    } else {
      if (event === 'created') {
        throw new Error(
          "event 'created' is disabled until we have a valid use-case. Should you use 'added' instead?"
        );
      }

      if (event === 'destroyed') {
        throw new Error(
          "event 'destroyed' is disabled until we have a valid use-case. Should you use 'removed' instead?"
        );
      }
      this.listeners[event].push(callback);
    }
    return this;
  }

  onAll(events: QueryEvent[], callback: QueryFn) {
    for (const event of events) {
      this.on(event, callback);
    }
    return this;
  }

  listen(event: QueryEvent | QueryEvent[], callback: QueryFn) {
    this.on(event, callback);
    return callback;
  }

  listenAll(events: QueryEvent[], callback: QueryFn) {
    this.onAll(events, callback);
    return callback;
  }

  off(event: QueryEvent, callback: QueryFn) {
    this.listeners[event].remove(callback);
    return this;
  }

  tick() {
    for (const listener of this.listeners.tick) {
      listener(this.all);
    }

    if (this.created.size > 0) {
      for (const listener of this.listeners.created) {
        listener(this.created);
      }
    }

    if (this.destroyed.size > 0) {
      for (const listener of this.listeners.destroyed) {
        listener(this.destroyed);
      }
    }

    if (this.added.size > 0) {
      for (const listener of this.listeners.added) {
        listener(this.added);
      }
    }

    if (this.changed.size > 0) {
      for (const listener of this.listeners.changed) {
        listener(this.changed);
      }
    }

    // had to move this to make update-qtree work
    // if we need to move this again, instead we should
    // update listeners to run in the order they are declared
    if (this.removed.size > 0) {
      for (const listener of this.listeners.removed) {
        listener(this.removed);
      }
    }

    this.tock = true;
  }

  clear() {
    this.tock = false;
    this.created.clear();
    this.destroyed.clear();
    this.added.clear();
    this.removed.clear();
    this.changed.clear();
  }

  one() {
    if (this.all.size < 1 || this.all.size > 1) {
      t3dev().log.error(
        `assertion failed: query does not contain 1 entity. Actual size: ${this.all.size}`
      );
      return undefined as unknown as number;
    }

    // use set iterator to prevent copying all data into an array
    for (const first of this.all) {
      return first;
    }

    // we will never get here
    return undefined as unknown as number;
  }

  first(): Option<number> {
    // use set iterator to prevent copying all data into an array
    for (const first of this.all) {
      return first;
    }

    return undefined;
  }
}
