/*eslint no-unused-expressions: "off"*/
import {
  LocalRef,
  ModelSchemaCategories,
  NodePrefixType,
  PropertyRef,
  QualifiedRef,
  EvaluationType,
  AnyMetraMathNode,
  EvaluatedExpression,
  MathNode,
} from 'types';
import type { DependencyNode } from './dependencyCache';
import { nullify, isNone } from 'helpers/utils';
import { UNEVALUATED } from './constants';
import { isGridNameRowValue } from './helpers';
import type { Interpreter } from './interpreter';

/**
 * Expressions are a simple object designed to hold the various metadata
 * associated with a specific cell in the MPT. They are generally static,
 * but defined progressively. EG `this.parsed` isn't defined until the
 * expression reaches the parser, but once set, its value never changes.
 *
 * Most fields are set by the Interpretter before any workers are called.
 * Each worker generally reads the field set by the previous worker and uses
 * the data to set its own field. IE the parser generates `this.parsed` from
 * `this.preparsed` and the evaluater generates `this.evaluated` from
 * `this.parsed`
 */
export class Expression<Results = unknown, Options = unknown> {
  private _destroyed: boolean;
  itpr?: Interpreter;
  row: number;
  col: number;
  ids: PropertyRef;
  unsatisfiedDeps: number;
  status: EvaluationType;
  evaluated: Option<EvaluatedExpression>;
  errors: string[];
  // these are not guaranteed to be available when first accessed
  namesUpdated: Option<string>;
  parsed: Option<AnyMetraMathNode>;
  localCellReferences: MathNode[] = [];

  value!: string;
  _reference!: QualifiedRef;
  localReference!: LocalRef;
  prefix!: NodePrefixType;
  preparsed!: string;
  ent!: ModelSchemaCategories;

  constructor() {
    // defined first
    this._reference = '' as QualifiedRef;
    this.localReference = '' as LocalRef;
    this.prefix = '' as NodePrefixType;
    this.ent = '' as ModelSchemaCategories;
    this.row = -1;
    this.col = -1;
    this.ids = { parentId: '', schemaId: '' };
    this.unsatisfiedDeps = -0;

    //defined second (in order)
    this.status = UNEVALUATED;
    this.evaluated = null;
    this.errors = [];
    this._destroyed = false;
  }

  destroy() {
    if (this._destroyed) return;
    nullify(this, '_itpr');
    nullify(this, 'parsed');
    this._destroyed = true;
  }

  get isExpression(): true {
    return true;
  }

  get ref(): QualifiedRef {
    return this._reference;
  }

  get unevaluated(): boolean {
    return this.status === UNEVALUATED;
  }

  get hasErrors(): boolean {
    return this.errors.length > 0;
  }

  get isPlain(): boolean {
    // it is a prop value row, so it cannot be an equation
    if (isGridNameRowValue(this.ids.parentId)) {
      return true;
    }
    if (isNone(this.value)) return true;
    return !this.value.startsWith('=');
  }

  get deps(): DependencyNode<Results, Options> {
    if (isNone(this.itpr)) {
      throw new Error(
        'expression tried to reference Interpreter, but it has not been set'
      );
    }
    return this.itpr.getDeps(this._reference);
  }

  setReference(ref: QualifiedRef) {
    this._reference = ref;
  }
}
