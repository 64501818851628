// Settings file for Megalith

import { globalGetState } from './utils-extra';

export const NODE_ENV = process.env.NODE_ENV;

export const PROXY = process.env.REACT_APP_DEV_PROXY;

/**
 * whether we are in a development environment
 */
export const isDev = NODE_ENV === 'development';

/**
 * whether we are in a production environment
 */
export const isProd = NODE_ENV === 'production';

/*******
 * API *
 *******/

/**
 * If set, Megalith will use this base URI for downloads instead of
 * API_URL.
 * @constant
 * @default 'http://localhost:8000/api'
 */
export const TRUE_API_URI =
  process.env.REACT_APP_TRUE_API_URI || 'http://localhost:8000/api';

/**
 * The base URI to use for interacting with the Nucleo backend.
 * @constant
 * @default '/api'
 */
export const API_URL = process.env.REACT_APP_API_URL || '/api';

/**
 * Base directory for local development with backend
 * @constant
 * @default 'http://localhost:8000/'
 */
export const DEV_PROXY =
  process.env.REACT_APP_DEV_PROXY || 'http://localhost:8000/';

/**
 * If set, TRUE_API_URI should be used for the download base URI
 * instead of API_URL.
 * TODO Seems redundant if TRUE_API_URI is set though -- dwc
 * @constant
 * @default false
 */
export const USE_DIRECT_DOWNLOAD =
  process.env.REACT_APP_USE_DIRECT_DOWNLOAD === 'true';

/** Use this URL for downloading files
 * The URL format thing will ensure that the path is absolute. See
 * https://stackoverflow.com/a/44547904
 * @constant
 */
export let DOWNLOAD_URL = USE_DIRECT_DOWNLOAD ? TRUE_API_URI : API_URL;
DOWNLOAD_URL = new URL(DOWNLOAD_URL, window.location.href).href;

export const SENTRY_ENABLED = () =>
  globalGetState()?.configReducer.sentryEnabled === true;
export const SENTRY_DSN = () =>
  globalGetState()?.configReducer.sentryMegalithDsn || '';
export const RELEASE_VERSION = process.env.REACT_APP_RELEASE_VERSION;
export const SENTRY_ENV = process.env.REACT_APP_SENTRY_ENV;

export const CHROME_VERSION = process.env.REACT_APP_CHROME_VERSION;
export const FIREFOX_VERSION = process.env.REACT_APP_FIREFOX_VERSION;
export const ACTIVITY_PING_SECONDS =
  (process.env as any).REACT_APP_SESSION_INACTIVITY_EXPIRY_SECONDS - 60;
