import { v4 as uuid } from 'uuid';
import {
  generateDefaultActionHandlers,
  reducerFromHandlers,
} from 'modules/common';
import { SET } from 'utils/constants';
/*******************
 * Action Types
 *******************/

/*******************
 * Action Creators
 *******************/

/**
 * add a set to the state store.
 * @param {import('types').ModelSet} sets
 * @returns {import('types').MetraSimpleAction<import('types').ModelSet[]>} sets
 */
export const addSet = (set) => addSets([set]);
/**
 * add sets to the state store.
 * @param {import('types').ModelSet[]} sets
 * @returns{import('types').MetraSimpleAction<import('types').ModelSet[]>} sets
 */
export const addSets = (sets) => ({
  type: SET.CREATE,
  payload: sets,
});

/**
 * Creates a set
 * @param {Partial<import('types').ModelSet>} params ModelSet Properties
 * @returns {import('types').ModelSet}
 */
export const createSet = ({
  id = uuid(),
  label = '',
  color = '#000000',
  nodeIds = [],
  edgeIds = [],
  alpha = 1,
  visible = true,
  collapsed = false,
} = {}) => ({
  id,
  nodeIds,
  edgeIds,
  label,
  color,
  alpha,
  visible,
  collapsed,
});

/**
 * Makes an action for updating an set.
 *
 * @param {import('types').ModelSet} set - the set with updates made. We'll pass this on.
 * @return {import('types').MetraSimpleAction<
 * import('types').ModelSet[]
 * >} A set update action
 */
export const updateSet = (set) => updateSets([set]);

/**
 * Makes an action for updating sets.
 *
 * @param {import('types').ModelSet[]} sets - the sets to update
 * @return {import('types').MetraSimpleAction<
 * import('types').ModelSet[]
 * >} A set update action
 */
export const updateSets = (sets) => ({
  type: SET.UPDATE,
  payload: sets,
});

/**
 * Makes an action for deleting a set.
 *
 * @param {import('types').ModelSet} set - the set to delete
 * @return {import('types').MetraSimpleAction<
 * import('types').ModelSet[]
 * >} A set update action
 */
export const deleteSet = (set) => deleteSets([set]);

/**
 * Makes an action for deleting sets.
 *
 * @param {import('types').ModelSet[]} sets - the sets to delete
 * @return {import('types').MetraSimpleAction<
 * import('types').ModelSet[]
 * >} A set update action
 */
export const deleteSets = (sets) => ({
  type: SET.DELETE,
  payload: sets,
});

/**
 * initializes the reducer with node data.
 * @param {Record<import('types').UUID, import('types').ModelSet>} sets - sets to set the reducer to.
 * @returns {import('types').MetraSimpleAction<Record<import('types').UUID, import('types').ModelSet>>}
 */
export const initSets = (sets) => ({
  type: SET.INITIALIZE,
  payload: sets,
});

/*******************
 * Reducers
 *******************/

export const initSetsReducer = (state, sets = {}) => sets;

//Make the reducer
export const setReducer = reducerFromHandlers(
  generateDefaultActionHandlers(SET, {
    [SET.INITIALIZE]: initSetsReducer,
  })
);
