import { SELECTION_FILTER } from 'utils/constants';
import cloneDeep from 'clone-deep';
import { v4 as uuid } from 'uuid';
import { Reducer } from 'redux';
import { Filter, MetraAction, MetraVoidAction, UUID } from 'types';
import { isPayloadAction, isVoidAction } from 'modules/common';

export const initSelectionFilter = (filters = {}) => ({
  type: SELECTION_FILTER.INIT,
  payload: filters,
});

export const makeSelectionFilter = (
  name: string,
  alpha: number,
  entities: string[],
  hideUnpopulated: boolean = false,
  schemas: string[] = []
) => {
  const id = uuid();

  // Only allow "Hide Empty Columns" to be saved if we have no Columns included in the filter
  const filterUnpopulated = schemas.length === 0 ? hideUnpopulated : false;

  return {
    id,
    name,
    alpha,
    entities,
    filterUnpopulated,
    schemas,
  };
};

interface newFilter {
  [key: string]: object;
}

export const addSelectionFilter = (filter: Filter) => {
  const key = filter.id;
  const newFilter: newFilter = {};
  newFilter[key] = filter;

  return {
    type: SELECTION_FILTER.ADD_FILTER,
    payload: newFilter,
  };
};

export const deleteSelectionFilter = (
  filters: Record<string, Filter>,
  filterId: UUID
) => {
  delete filters[filterId];
  return {
    type: SELECTION_FILTER.DELETE_FILTER,
    payload: filters,
  };
};

export const deleteShapesFromFilters = (
  filters: Record<string, Filter>,
  shapeIds: UUID[]
) => {
  // Index shapeIds for faster lookup
  const shapeIdsIndex: Record<string, boolean> = {};
  shapeIds.forEach((id) => {
    shapeIdsIndex[id] = true;
  });

  // Create a copy of the original filters with updated entities
  const updatedFilters: Record<string, Filter> = {};
  for (const filterId of Object.keys(filters)) {
    const filter = filters[filterId];

    // Remove deleted entities
    const updatedEntities = filter.entities.filter(
      (uuid) => !shapeIdsIndex[uuid]
    );

    // Check if the updated filter has entities - filters without entities will be removed
    if (updatedEntities.length > 0) {
      const updatedFilter: Filter = { ...filter, entities: updatedEntities };
      updatedFilters[filterId] = updatedFilter;
    }
  }
  return {
    type: SELECTION_FILTER.DELETE_SHAPES_FROM_FILTERS,
    payload: updatedFilters,
  };
};

/*******************
 * Reducer
 *******************/

export const selectionFilterReducer: Reducer<
  Record<UUID, Filter>,
  MetraAction<any, any, any>
> = (state = {}, action) => {
  if (!isPayloadAction(action)) {
    return state;
  }
  switch (action.type) {
    case SELECTION_FILTER.INIT:
      return action.payload;
    case SELECTION_FILTER.ADD_FILTER:
      const newState = cloneDeep(state);
      return {
        ...newState,
        ...(action.payload as object),
      };
    case SELECTION_FILTER.DELETE_FILTER:
      return {
        ...(action.payload as object),
      };
    case SELECTION_FILTER.DELETE_SHAPES_FROM_FILTERS:
      return {
        ...(action.payload as object),
      };
    default:
      return state;
  }
};
