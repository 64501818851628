export const SCHEMA_NAME = 'media';
export const SCHEMA_NAME_FILESIZE = 'media/filesize';

export const ONE_MB_BINARY_SIZE = Math.pow(2, 20);
export const ONE_GB_BINARY_SIZE = Math.pow(2, 30);
export const ONE_TB_BINARY_SIZE = Math.pow(2, 40);
export const MIN_FILE_SIZE = 1;
export const MAX_NON_MULTI_PART_SIZE = ONE_MB_BINARY_SIZE * 100; // 100 MB
export const UPLOAD_CHUNK_SIZE = ONE_MB_BINARY_SIZE * 5; // 5 MB (minimum MP upload size)
export const MAX_MODEL_FILE_SIZE = ONE_GB_BINARY_SIZE * 4; // 4GB // 4_000_000_000; // 4GB
export const MAX_FILE_SIZE = ONE_TB_BINARY_SIZE * 4; // 4TB

// most common browsers support a default of 6 max concurrent connections to a server
// We only use 3 to mitigate upload timeouts
export const MAX_CONCURRENT_CONNECTIONS = 3;

export enum MPUploadStatus {
  ABORTED = 'ABORTED',
  CONFLICT = 'CONFLICT',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  IN_PROCESS = 'IN_PROCESS',
}

export enum XhrStatus {
  ABORT,
  ERROR,
  NONE,
  SUCCESS,
  TIMEOUT,
}
