import type { Interpreter } from 'interpreter/interpreter';
import { PARSED } from 'interpreter/constants';
import { parse, mathjs } from 'interpreter/t3math';
import { t3dev } from 't3dev';

const relativeRowRx = /^_(?<row>[0-9]+)$/i;
/**
 * essentually the same as parser, but without the need to morph names
 * parser replaces `source` and `target` for edge expressions with calls to
 * the entity(n__{node-row-number}) function.  But if we did this while
 * renaming those would become permanent, so avoid those replacements during
 * renaming.
 */
export function renameParser<R, O>(itpr: Interpreter<R, O>): Interpreter<R, O> {
  const cacheState = itpr.dependencyCache.frozen;

  itpr.expressionList.forEach((exp) => {
    // if this is plain text, skip parsing
    if (exp.isPlain) {
      exp.parsed = new mathjs.ConstantNode(exp.preparsed);
    } else {
      // parse the expression
      try {
        exp.parsed = parse(exp.preparsed.slice(1).trim());
      } catch (e: any) {
        exp.errors.push(`Syntax Error: ${e.message}`);
        t3dev().log.error('EXPRESSION SYNTAX ERROR', e.message);
        return;
      }

      exp.parsed = exp.parsed.transform((node) => {
        if (!mathjs.isSymbolNode(node)) return node;

        // qualify any relative reference
        // B4 becomes n_b4 on Nodes tab, e_b4 on Edges, etc
        // First look for relative row-references, like _4
        if (node.name.match(relativeRowRx)) {
          const replacement = new mathjs.SymbolNode(
            exp.ent[0] + '_' + node.name
          );
          exp.localCellReferences.push(replacement);
          return replacement;
        }

        // Not a row-reference; keep looking for simple-references, like b4
        const qualified = itpr.qualifyRef(node.name, exp.ent);

        if (!qualified) return node;

        // we need to remember if the reference was a fully-qualified reference
        // or a relative reference; at the end of the whole shebang we need to
        // keep relative references relative, and fully-qualified references
        // fully-qualified
        if (node.name.toLowerCase() === qualified) return node;
        const replacement = new mathjs.SymbolNode(qualified);
        exp.localCellReferences.push(replacement);
        return replacement;
      });
    }

    exp.status = PARSED;
    // if cache started frozen, refreeze
    itpr.dependencyCache.frozen = cacheState;
  });

  return itpr;
}
