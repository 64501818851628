import type { AdminReducer } from 'types';
import type { Reducer } from 'redux';
import { ADMIN } from 'modules/common/constants';

export const initialAdminState: AdminReducer = {
  showAddOrgUserModal: false,
  showSearchForUsersModal: false,
  manageUserModal: {
    show: false,
  },
  showAddGuildUserModal: false,
  showCreateKeyModal: false,
  showDeleteKeyModal: false,
  showRenewKeyModal: false,
  showRenewedKeyInfo: false,
  membershipFlyout: {
    showGuildMembershipFlyout: false,
    user: null,
    left: 0,
    top: 0,
  },
  apikey: {
    selected: {
      ids: [],
      keys: [],
    },
    columns: [
      'ApiSelect',
      'ApiName',
      'ApiKey',
      'ApiAssignedTo',
      'ApiCreated',
      'ApiExpires',
    ],
    columnOptions: {
      ApiSelect: {
        value: 'selected',
        title: '',
        colWidth: '46px',
      },
      ApiName: {
        showSize: true,
        value: 'name',
        title: 'Key Name',
        colWidth: '210px',
        minimumWidth: 160,
        resizable: true,
      },
      ApiKey: {
        showSize: true,
        value: 'key',
        title: 'Key',
        colWidth: '160px',
        minimumWidth: 100,
        resizable: true,
      },
      ApiAssignedTo: {
        showSize: true,
        value: 'assignedTo',
        title: 'Assigned To',
        colWidth: '155px',
        minimumWidth: 100,
        resizable: true,
      },
      ApiCreated: {
        showSize: true,
        value: 'created',
        title: 'Created',
        colWidth: '125px',
        minimumWidth: 75,
        resizable: true,
      },
      ApiExpires: {
        showSize: true,
        value: 'expires',
        title: 'Expires',
        colWidth: '125px',
        minimumWidth: 75,
        resizable: true,
      },
    },
    pagination: {
      count: 0,
      numberOfRows: 10,
      rowCount: 0,
      startIndex: 0,
      total: 0,
    },
  },
};

export const adminReducer: Reducer<AdminReducer, any> = (
  state = initialAdminState,
  action
) => {
  const newState = { ...state };
  switch (action.type) {
    case ADMIN.MODAL.SHOW_ADD_ORG_USER:
      newState.showAddOrgUserModal = action.payload;
      return newState;
    case ADMIN.MODAL.SHOW_MANAGE_USER_MODAL:
      newState.manageUserModal = action.payload;
      return newState;
    case ADMIN.MODAL.SHOW_CREATE_KEY_MODAL:
      newState.showCreateKeyModal = action.payload;
      return newState;
    case ADMIN.MODAL.SHOW_DELETE_KEY_MODAL:
      newState.showDeleteKeyModal = action.payload;
      return newState;
    case ADMIN.MODAL.SHOW_RENEW_KEY_MODAL:
      newState.showRenewKeyModal = action.payload;
      return newState;
    case ADMIN.SHOW_RENEWED_KEY_INFO:
      newState.showRenewedKeyInfo = action.payload;
      return newState;
    case ADMIN.MODAL.SHOW_SEARCH_FOR_USERS_MODAL:
      newState.showSearchForUsersModal = action.payload;
      return newState;
    case ADMIN.FLYOUT.SHOW_GUILD_MEMBERSHIP_FLYOUT:
      newState.membershipFlyout.showGuildMembershipFlyout = action.payload.show;
      newState.membershipFlyout.top = action.payload.top;
      newState.membershipFlyout.left = action.payload.left;
      return newState;
    case ADMIN.FLYOUT.SET_FLYOUT_USER:
      newState.membershipFlyout.user = action.payload;
      return newState;
    case ADMIN.API_KEY.UPDATE.COLUMN_WIDTH:
      newState.apikey.columnOptions = {
        ...action.payload.columnOptions,
      };
      return newState;
    case ADMIN.API_KEY.UPDATE.SELECTED:
      newState.apikey.selected = {
        ...action.payload.selected,
        ...action.payload.keys,
      };
      return newState;
    case ADMIN.API_KEY.UPDATE.PAGINATION:
      newState.apikey.pagination = {
        ...state.apikey.pagination,
        ...action.payload.paginate,
      };
      return newState;
    default:
      return state;
  }
};
