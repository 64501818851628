import { EntityCollectionType, ThunkActionFunc } from 'types';
import { isSome } from './utils';
import { setValidClipboardCellRangeType } from 'modules/model/base/actions';

export const invalidateClipboardCellReferences: ThunkActionFunc<
  [type: Option<EntityCollectionType>]
> = (type) => {
  return async (dispatch, getState) => {
    // only invalidate the clipboard cell references if they were of the same
    // type - so if (e.g.) we re-order the Edges tab it won't affect a clipboard
    // created by the Sets tab.
    if (isSome(type)) {
      if (getState().modelReducer.base.validClipboardCellRangeType === type) {
        dispatch(setValidClipboardCellRangeType(null));
      }
    }
  };
};
