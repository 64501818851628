import type { Expression } from './expression';
import type { QualifiedRef } from 'types';
import { nullify, isNone } from 'helpers/utils';
import { makeRange } from 'utils/utils';
import { t3dev } from 't3dev';
import { coordsToRef, getInterpreter } from './helpers';

export class DependencyCache<Results = unknown, Options = unknown> {
  private _nodes: Record<QualifiedRef, DependencyNode<Results, Options>>;
  private _destroyed: boolean;
  frozen: boolean;

  constructor() {
    this._nodes = {};
    this.frozen = false;
    this._destroyed = false;

    t3dev().setValue('depCache', this);
  }

  destroy(destroyItpr = false) {
    if (this._destroyed) return;
    for (let ref in this._nodes) {
      this.getDeps(ref as QualifiedRef)?.destroy();
    }
    destroyItpr && getInterpreter().destroy(false);
    nullify(this, '_nodes');
    nullify(this, '_itpr');
    this.frozen = false;

    t3dev().setValue('depCache', null);
    this._destroyed = true;
  }

  freeze() {
    this.frozen = true;
  }

  thaw() {
    this.frozen = false;
  }

  addDeps(ref: QualifiedRef) {
    let node;
    if (ref in this._nodes) {
      node = this._nodes[ref];
    } else {
      node = new DependencyNode<Results, Options>();
    }
    node.ref = ref;
    this._nodes[ref] = node;
  }

  getDeps(ref: QualifiedRef): DependencyNode<Results, Options> {
    this._nodes[ref] ||= new DependencyNode<Results, Options>();
    return this._nodes[ref];
  }

  getExp(ref: QualifiedRef): Option<Expression<Results, Options>> {
    return getInterpreter().getExp(ref);
  }

  populateDependencies(ref: QualifiedRef) {
    const deps = this.getDeps(ref);
    if (!deps) return;
    if (deps.populated) return;
    const itpr = getInterpreter();
    deps.depRanges.forEach((range) => {
      const { start: startRef, end: endRef } = range;
      const start = this.getExp(startRef);
      const end = this.getExp(endRef);
      if (!start || !end) return;
      const rows = makeRange(start.row, end.row, true);
      const cols = makeRange(start.col, end.col, true);
      for (let r = 0; r < rows.length; r++) {
        for (let c = 0; c < cols.length; c++) {
          const ref = coordsToRef(start.ent, rows[r], cols[c]);
          const exp = itpr.grab(ref);
          if (isNone(exp)) continue;
          deps.addDependency(exp, { force: true });
        }
      }
    });
    deps.populated = true;
  }
}

let __DEPENDENCY_CACHE__: Option<DependencyCache<any, any>> =
  new DependencyCache<any, any>();

/**
 * forcibly rebuild the cache, destroying any prior cache
 */
export function rebuildCache<R, O>(): void {
  if (__DEPENDENCY_CACHE__) __DEPENDENCY_CACHE__.destroy(false);
  __DEPENDENCY_CACHE__ = new DependencyCache<R, O>();
}

/**
 * forcibly nullify the cache
 */
export function nullifyCache(): void {
  __DEPENDENCY_CACHE__ = null;
}

export function getDependencyCache<
  R,
  O,
  D extends DependencyCache<R, O> = DependencyCache<R, O>
>(): D {
  if (!__DEPENDENCY_CACHE__) rebuildCache();
  return __DEPENDENCY_CACHE__ as D;
}

export class DependencyNode<Results = unknown, Options = unknown> {
  private _dependencyRefs: QualifiedRef[];
  // private _dependencyList: DependencyNode<Results, Options>[];
  private _dependantRefs: QualifiedRef[];
  private _destroyed: boolean;
  // private _dependantList: DependencyNode<Results, Options>[];
  depRanges: {
    start: QualifiedRef; //Expression<Results, Options>;
    end: QualifiedRef; //Expression<Results, Options>;
  }[];

  populated: boolean;

  private _reference!: QualifiedRef;

  dependencies: Record<QualifiedRef, QualifiedRef>;
  dependants: Record<QualifiedRef, QualifiedRef>;

  constructor() {
    this.dependencies = {};
    this._dependencyRefs = [];
    this.dependants = {};
    this._dependantRefs = [];
    this.depRanges = [];
    this.populated = false;
    this._destroyed = false;
  }

  destroy() {
    if (this._destroyed) return;
    nullify(this, '_dependencyRefs');
    nullify(this, '_dependantRefs');
    nullify(this, 'depRanges');
    nullify(this, 'dependencies');
    nullify(this, 'dependants');
    nullifyCache();
    this._destroyed = true;
  }

  private get _cache(): DependencyCache<Results, Options> {
    return getDependencyCache();
  }

  set ref(value: QualifiedRef) {
    this._reference = value;
  }

  get ref(): QualifiedRef {
    return this._reference;
  }

  get exp(): Option<Expression<Results, Options>> {
    return this._cache.getExp(this._reference);
  }

  get dependencyLength(): number {
    return this._dependencyRefs.length;
  }

  clear() {
    this._dependencyRefs.forEach((ref) => {
      const dep = this._cache.getDeps(ref);
      dep && dep._removeDependant(this.exp);
    });

    this.dependencies = {};
    this._dependencyRefs = [];
    this.depRanges = [];
    this.populated = false;
  }

  private _addDependency(
    dep: DependencyNode<Results, Options>,
    opt: { force?: boolean } = {}
  ) {
    if (this.dependencies[dep.ref]) return;
    this.dependencies[dep.ref] = dep.ref;
    this._dependencyRefs.push(dep.ref);
    dep._addDependant(this, opt);
  }

  addDependency(
    exp: Expression<Results, Options>,
    opt: { force?: boolean } = {}
  ) {
    if (this._cache.frozen && !opt.force) return;
    if (this.dependencies[exp.ref]) return;
    // get dependency node
    const dep = this._cache.getDeps(exp.ref);
    dep && this._addDependency(dep, opt);
  }

  _removeDependant(dep: Option<Expression<Results, Options>>) {
    if (!dep || !this.dependants[dep.ref]) return;
    delete this.dependants[dep.ref];
    if (dep.deps) {
      const listIndex = this._dependantRefs.indexOf(dep.ref);
      if (listIndex > -1) this._dependantRefs.splice(listIndex, 1);
    }
  }

  private _addDependant(
    dep: DependencyNode<Results, Options>,
    opt: { force?: boolean } = {}
  ) {
    if (this.dependants[dep.ref]) return;
    this.dependants[dep.ref] = dep.ref;
    this._dependantRefs.push(dep.ref);
    dep._addDependency(this, opt);
  }

  addDependant(
    exp: Expression<Results, Options>,
    opt: { force?: boolean } = {}
  ) {
    if (this._cache.frozen && !opt.force) return;
    if (this.dependants[exp.ref]) return;
    // get dependency node
    const dep = this._cache.getDeps(exp.ref);
    dep && this._addDependant(dep, opt);
  }

  addDepRange(
    start: Expression<Results, Options>,
    end: Expression<Results, Options>
  ) {
    if (this._cache.frozen) return;
    if (
      this.depRanges.find(
        (range) => range.start === start.ref && range.end === end.ref
      )
    )
      return;

    this.depRanges.push({ start: start.ref, end: end.ref });
  }

  get allDependants() {
    return this._allDependants();
  }

  _allDependants(): DependencyNode<Results, Options>[] {
    const seen = new Set<QualifiedRef>();
    const toCollect = [...this._dependantRefs];
    const list: DependencyNode<Results, Options>[] = [];
    for (let i = 0; i < toCollect.length; i++) {
      const ref = toCollect[i];
      const dep = this._cache.getDeps(ref);
      if (isNone(dep) || seen.has(dep.ref)) continue;
      seen.add(dep.ref);

      list.push(dep);

      const depDeps = dep._dependantRefs;
      if (depDeps.length) {
        toCollect.add(depDeps.filter((dref) => !seen.has(dref)));
      }
    }
    return list;
  }

  _allDependencies(): DependencyNode<Results, Options>[] {
    const seen = new Set<QualifiedRef>();
    const toCollect = [...this._dependencyRefs];
    const list: DependencyNode<Results, Options>[] = [];
    for (let i = 0; i < toCollect.length; i++) {
      const ref = toCollect[i];
      const dep = this._cache.getDeps(ref);
      if (isNone(dep) || seen.has(dep.ref)) continue;
      seen.add(dep.ref);
      list.push(dep);

      const depDeps = dep._dependencyRefs;
      if (depDeps.length) {
        toCollect.add(depDeps.filter((dref) => !seen.has(dref)));
      }
    }
    return list;
  }

  get allDependencies() {
    return this._allDependencies();
  }

  get dependencyRefs() {
    return this._dependencyRefs;
  }

  get dependencyList(): DependencyNode<Results, Options>[] {
    let list: DependencyNode<Results, Options>[] = [];
    this._dependencyRefs.forEach((ref) => {
      const node = this._cache.getDeps(ref);
      if (node) list.push(node);
    });

    return list;
  }

  get dependantList(): DependencyNode<Results, Options>[] {
    let list: DependencyNode<Results, Options>[] = [];
    this._dependantRefs.forEach((ref) => {
      const node = this._cache.getDeps(ref);
      if (node) list.push(node);
    });
    // return this._dependantList;

    return list;
  }

  get dependantRefs() {
    return this._dependantRefs;
  }
}
