import { t3dev } from 't3dev';
import { isSome } from './utils';
import { XhrStatus } from 'modules/media/constants';

const newlineRegEx = /[\r\n]+/;

export function makeResponse(
  xhr: XMLHttpRequest,
  headerString: Option<string>
): Response {
  let headers = new Headers();
  if (headerString) {
    //split on newline
    headerString
      .trim()
      .split(newlineRegEx)
      .forEach((headerLine) => {
        const parts = headerLine.split(': ');
        const header = parts.shift();
        // since the tail end of the headerLine may have also been split
        // we want to re-join anything that may have been split using
        // the original split character
        const value = parts.join(': ');
        if (header && value) headers.set(header, value);
      });
  }
  // t3dev().log.log('mr::', {
  //   headerString,
  //   headers: Array.from(headers.entries()).map(([k, v]) => `${k}:${v}\n`),
  // });
  return new Response(xhr.response, {
    status: xhr.status,
    headers,
    statusText: xhr.statusText,
  });
}

export function makeResponseHandler(xhr: XMLHttpRequest): Promise<Response> {
  return new Promise<Response>((resolve, reject) => {
    let status = XhrStatus.NONE;
    let headerString: Option<string> = null;

    xhr.addEventListener('error', (_event) => {
      status = XhrStatus.ERROR;
    });

    xhr.addEventListener('load', (_event) => {
      status = XhrStatus.SUCCESS;
    });

    xhr.addEventListener('abort', (_event) => {
      status = XhrStatus.ABORT;
    });

    xhr.addEventListener('timeout', (_event) => {
      status = XhrStatus.TIMEOUT;
    });

    xhr.addEventListener('readystatechange', (event) => {
      if (xhr.readyState === xhr.HEADERS_RECEIVED) {
        headerString = xhr.getAllResponseHeaders();
      }
    });

    xhr.upload.addEventListener('loadend', (ev) => {});

    xhr.addEventListener('loadend', (_event) => {
      switch (status) {
        case XhrStatus.SUCCESS: {
          resolve(makeResponse(xhr, headerString));
          break;
        }
        case XhrStatus.ERROR: {
          reject(
            makeResponse({
              status: xhr.status || 599,
              response: xhr.response || 'error',
              statusText: xhr.statusText || 'Unknown Server Error',
            } as XMLHttpRequest)
          );
          break;
        }
        case XhrStatus.ABORT: {
          reject(
            makeResponse({
              status: 499,
              response: 'cancelled',
              statusText: 'Request Cancelled',
            } as XMLHttpRequest)
          );
          break;
        }
        case XhrStatus.TIMEOUT: {
          reject(
            makeResponse({
              status: 408,
              response: 'timeout',
              statusText: 'Request Timeout',
            } as XMLHttpRequest)
          );
          break;
        }
        case XhrStatus.NONE:
        default:
          reject(
            makeResponse({
              status: xhr.status || 498,
              response: xhr.response || 'unknown',
              statusText: xhr.statusText || 'Unknown Status',
            } as XMLHttpRequest)
          );
          break;
      }
    });
  });
}

export function makeSend(
  xhr: XMLHttpRequest,
  options?: RequestInit | undefined
) {
  function send(body?: BodyInit | XMLHttpRequestBodyInit): Promise<Response> {
    // type BodyInit = ReadableStream | XMLHttpRequestBodyInit;
    let innerBody: null | undefined | XMLHttpRequestBodyInit | BodyInit = body;
    innerBody ||= options?.body;

    // type XMLHttpRequestBodyInit = Blob | BufferSource | FormData | URLSearchParams | string;
    let sendableBody: XMLHttpRequestBodyInit | Document | null | undefined =
      undefined;
    if (innerBody) {
      if (innerBody instanceof ReadableStream) {
        // until we have a use case for readable streams, just use null
        sendableBody = null;
      } else {
        sendableBody = innerBody;
      }
    } else {
      sendableBody = innerBody;
    }

    const responseHandler = makeResponseHandler(xhr);

    xhr.send(sendableBody);

    return responseHandler;
  }
  return send;
}

export function makeXhr(
  url: RequestInfo | URL,
  options?: RequestInit | undefined
): [
  send: (body?: BodyInit | XMLHttpRequestBodyInit) => Promise<Response>,
  xhr: XMLHttpRequest
] {
  const xhr = new XMLHttpRequest();

  let method = options?.method;
  method ||= 'GET';

  if (url instanceof URL) {
    xhr.open(method, url.href, true);
  } else if (url instanceof Request) {
    xhr.open(method, url.url, true);
  } else {
    xhr.open(method, url, true);
  }

  if (options?.mode === 'cors') {
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
  }

  if (options?.credentials === 'include') {
    xhr.withCredentials = true;
  }

  // Headers can be multiple things by standard
  // so we cover them all
  const headers = options?.headers;
  if (Object.isRecord<Record<string, string>>(headers)) {
    Object.forEach(headers, ([key, value]) => {
      xhr.setRequestHeader(key, value);
    });
  } else if (headers instanceof Headers) {
    headers.forEach((value, key) => {
      xhr.setRequestHeader(key, value);
    });
  } else if (isSome(headers) && Object.keys(headers).length > 0) {
    headers.forEach(([key, value]) => {
      xhr.setRequestHeader(key, value);
    });
  }

  return [makeSend(xhr, options), xhr];
}

interface MakeFetcherArgs {
  onAbort: () => void;
  onProgress: (event: ProgressEvent) => void;
  onUploaded: (event: Event) => void;
  signal: AbortSignal;
}

export function makeFetcher(args?: Partial<MakeFetcherArgs>) {
  return async (
    url: RequestInfo | URL,
    options?: RequestInit | undefined
  ): Promise<Response> => {
    const [send, xhr] = makeXhr(url, options);

    if (isSome(args)) {
      if (isSome(args.signal))
        args.signal.addEventListener('abort', async () => {
          xhr.abort();
          args.onAbort?.();
        });

      if (isSome(args.onProgress))
        xhr.upload.addEventListener('progress', args.onProgress);

      if (isSome(args.onUploaded))
        xhr.addEventListener('loadend', args.onUploaded);
    }

    return send();
  };
}
