import { isNode } from './shape/shape-helpers';
import { ModelReducer } from 'types';

/**
 * checks if only default nodes are selected
 * @param allShapes each key is a shape ID. Each Value is a Shape Object
 * @param selectedShapeIds
 * @returns true if only default nodes are selected
 */
export function checkDefaultSelected(
  allShapes: ModelReducer['shapes'],
  selectedShapeIds: UUID[]
): boolean {
  if (!selectedShapeIds.length) return false;
  for (const selectedShapeId of selectedShapeIds) {
    const selectedShape = allShapes[selectedShapeId];
    if (isNode(selectedShape) && selectedShape.asset !== 'default')
      return false;
  }
  return true;
}
