import { MPT_REARRANGE } from 'modules/common';
import { MetraActionFunc } from 'types';

export const dragMPTRearrange: MetraActionFunc<
  [number],
  { targetPos: number }
> = (targetPos) => ({
  type: MPT_REARRANGE.DRAG,
  payload: { targetPos },
});

export const endMPTRearrange: MetraActionFunc = () => ({
  type: MPT_REARRANGE.END,
});

export const resetMPTRearrange: MetraActionFunc = () => ({
  type: MPT_REARRANGE.RESET,
});

export const startMPTRearrange: MetraActionFunc<
  [number, 'schemas' | 'entities'],
  { sourcePos: number; targetPos: number; dimension: 'schemas' | 'entities' }
> = (pos, dimension) => ({
  type: MPT_REARRANGE.START,
  payload: { sourcePos: pos, targetPos: pos, dimension },
});
