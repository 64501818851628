export * from './constants-extra';

/**
 * ADMIN
 */
export const ADMIN = {
  MODAL: {
    SHOW_ADD_ORG_USER: 'admin/modal/SHOW_ADD_ORG_USER',
    SHOW_MANAGE_USER_MODAL: 'admin/modal/SHOW_MANAGE_USER',
    SHOW_DELETE_KEY_MODAL: 'admin/modal/SHOW_DELETE_KEY',
    SHOW_CREATE_KEY_MODAL: 'admin/modal/SHOW_CREATE_KEY',
    SHOW_RENEW_KEY_MODAL: 'admin/modal/SHOW_RENEW_KEY',
    SHOW_SEARCH_FOR_USERS_MODAL: 'admin/modal/SHOW_SEARCH_FOR_USERS',
  },
  FLYOUT: {
    SHOW_GUILD_MEMBERSHIP_FLYOUT: 'admin/flyout/SHOW_GUILD_MEMBERSHIP_FLYOUT',
    SET_FLYOUT_USER: 'admin/flyout/SET_FLYOUT_USER',
  },
  API_KEY: {
    ERROR: {
      CREATE_FAILURE: 'admin/apikey/create/FAILURE',
      DELETE_FAILURE: 'admin/apikey/delete/FAILURE',
      RENEW_FAILURE: 'admin/apikey/renew/FAILURE',
      GET_FAILURE: 'admin/apikey/get/FAILURE',
      PUT_FAILURE: 'admin/apikey/put/FAILURE',
    },
    SUCCESS: {
      CREATE_SUCCESS: 'admin/apikey/create/SUCCESS',
      GET_SUCCESS: 'admin/apikey/get/SUCCESS',
    },
    LOADING: 'admin/apikey/LOADING',
    UPDATE: {
      COLUMN_WIDTH: 'admin/apikey/update/COLUMN_WIDTH',
      LOADING: 'admin/apikey/update/LOADING',
      PAGINATION: 'admin/apikey/update/PAGINATION',
      SELECTED: 'admin/apikey/update/SELECTED',
      SELECTED_ID_NOT_ON_PAGE: 'admin/apikey/update/SELECTED_ID_NOT_ON_PAGE',
    },
  },
  ASSIGN_TO_USER: 'admin/ASSIGN_TO_USER',
  SHOW_RENEWED_KEY_INFO: 'admin/modal/SHOW_RENEWED_KEY_INFO',
};

/**
 * API
 */
export const HTTP_NO_CONTENT = 204;
export const API_CORE_REQUEST = 'api/core/REQUEST';
export const API_CORE_SUCCESS = 'api/core/SUCCESS';
export const API_CORE_FAILURE = 'api/core/FAILURE';
export const API_CORE_CANCEL = 'api/core/CANCEL';

/**
 * APP
 * @enum{string}
 */
export const APP = {
  MODAL: {
    SHOW: 'app/modal/SHOW',
    HIDE: 'app/modal/HIDE',
  },
  TOOLBAR: {
    SETTINGS: 'app/toolbar/SETTINGS',
  },
  GRID: {
    DISPLAY: 'app/grid/display',
    SNAP: 'app/grid/snap',
  },
};

/**
 * AUTH
 */

export const SESSION_TOKEN = 'auth/session/TOKEN';
export const AUTH_AUTHORIZED = 'auth/AUTHORIZED';
export const AUTH_LOGOUT = 'auth/LOGOUT';
export const AUTH_EMAIL_SUCCESS = 'auth/email/SUCCESS';
export const AUTH_PKI_SUCCESS = 'auth/pki/SUCCESS';
export const AUTH_FAILURE_PKI = 'auth/FAILURE_PKI';
export const AUTH_FAILURE_MFA = 'auth/FAILURE_MFA';
export const AUTH_FAILURE_CREDS = 'auth/FAILURE_CREDS';
export const AUTH_FORCING_LOGOUT = 'auth/FORCING_LOGOUT';
export const AUTH_FULL_RESET = 'auth/FULL_RESET';
export const AUTH_TENANT_STATUS = 'auth/TENANT';
export const AUTH_TENANT_SETTINGS = 'auth/TENANT_SETTINGS';
export const MFA_VERIFY_SUCCESS = 'mfa/verify/SUCCESS';
export const MFA_VERIFY_FAILURE = 'mfa/verify/FAILURE';

export const CONFIG = {
  GET_FAILURE: 'config/retrieve/FAILURE',
  SET_CONFIG: 'app/config/SET',
};

/**
 * EXPRESSION REDUCER
 */
export const EXPRESSIONS = {
  INITIALIZE: 'model/expressions/INITIALIZE',
  UPDATE: 'model/expressions/UPDATE',
  SET_HYPERLINKS: 'model/expressions/SET_HYPERLINKS',
  IS_RUNNING: 'model/expressions/IS_RUNNING',
};

/**
 * LOADER
 */

export const LOADER = {
  LOADING: 'loader/LOADING',
  LOADED: 'loader/LOADED',
  FAILED: 'loader/FAILED',
};

/**
 * DRAW
 */

export const DRAW_INIT = 'draw/INIT';
export const DRAW_RENDERER_RESIZE = 'draw/renderer/RESIZE';
export const DRAW_TOOL_SELECT = 'draw/tool/SELECT';
export const DRAW_NODES_DELETE = 'draw/nodes/DELETE';
export const DRAW_STATS_TOGGLE = 'draw/stats/TOGGLE';
export const DRAW_GRAPH_DEFAULT = 'draw/graph/DEFAULT';
export const DRAW_GRAPH_SAVED = 'draw/graph/SAVED';
export const DRAW_GRAPH_LOADED = 'draw/graph/LOADED';

/**
 * GRAPH
 */

export const GRAPH_ADD_REQUEST = 'graph/ADD_REQUEST';
export const GRAPH_ADD_SUCCESS = 'graph/ADD_SUCCESS';
export const GRAPH_ADD_FAILURE = 'graph/ADD_FAILURE';
export const GRAPH_LOADING_REQUEST = 'graph/LOADING_REQUEST';
export const GRAPH_LOADING_SUCCESS = 'graph/LOADING_SUCCESS';
export const GRAPH_LOADING_FAILURE = 'graph/LOADING_FAILURE';
export const GRAPHS_GET_REQUEST = 'graphs/GET_REQUEST';
export const GRAPHS_GET_SUCCESS = 'graphs/GET_SUCCESS';
export const GRAPHS_GET_FAILURE = 'graphs/GET_FAILURE';
export const VERTICES_GET_REQUEST = 'vertices/GET_REQUEST';
export const VERTICES_GET_SUCCESS = 'vertices/GET_SUCCESS';
export const VERTICES_GET_FAILURE = 'vertices/GET_FAILURE';
export const EDGES_GET_REQUEST = 'edges/GET_REQUEST';
export const EDGES_GET_SUCCESS = 'edges/GET_SUCCESS';
export const EDGES_GET_FAILURE = 'edges/GET_FAILURE';
export const EDGE_ADD_REQUEST = 'edges/ADD_REQUEST';
export const EDGE_ADD_SUCCESS = 'edges/ADD_SUCCESS';
export const EDGE_ADD_FAILURE = 'edges/ADD_FAILURE';
export const VERTEX_ADD_REQUEST = 'vertex/ADD_REQUEST';
export const VERTEX_ADD_SUCCESS = 'vertex/ADD_SUCCESS';
export const VERTEX_ADD_FAILURE = 'vertex/ADD_FAILURE';

export const LICENSE = {
  GET_FAILURE: 'license/GET_FAILURE',
};

/**
 * HISTORY
 */
export const MEDIA_HISTORY = {
  GET_SUCCESS: 'media/GET_SUCCESS',
};

export const NO_OP = {
  SUCCESS: 'no/op/SUCCESS',
  FAILURE: 'no/op/FAILURE',
};

/**
 * PROJECTS
 */

export const PROJECTS = {
  GET_SUCCESS: 'projects/get/SUCCESS',
  GET_FAILURE: 'projects/get/FAILURE',
  CARD_VIEW: 'projects/CARD_VIEW',
  CREATE_SUCCESS: 'projects/create/SUCCESS',
  CREATE_FAILURE: 'projects/create/FAILURE',
  SELECTED: 'projects/SELECTED',
  LOADED: 'projects/LOADED',
  FILTER_TAGS: 'projects/filter/TAGS',
  DELETE_FAILURE: 'projects/delete/FAILURE',
  RESTORE_FAILURE: 'projects/restore/FAILURE',
  SHOW_LICENSE_ERROR_MODAL: 'projects/licenseErrorModal/SHOW',
  SORT_BY: 'projects/SORT_BY',
  UPDATE_FAILURE: 'projects/update/FAILURE',
};

export const RECENTS = {
  UPDATE: {
    FILES: {
      PAGINATION: '/recents/UPDATE/FILES/PAGINATION',
    },
    MODELS: {
      PAGINATION: '/recents/UPDATE/MODELS/PAGINATION',
    },
  },
};

export const SEARCH = {
  CLEAR: 'search/CLEAR',
  DISPLAY: 'search/DISPLAY',
  EXPRESSION_ENABLED: '/search/EXPRESSION_ENABLED',
  FIELD: 'search/FIELD',
  TAG: 'search/TAG',
  TEXT: '/search/TEXT',
  UPDATE: {
    PAGINATION: '/search/UPDATE/PAGINATION',
    NUMBER_OF_ROWS: '/search/UPDATE/NUMBER_OF_ROWS',
  },
};

/**
 * TOASTS
 */

export const TOASTS = {
  ERROR: 'common/toasts/ERROR',
  INFO: 'common/toasts/INFO',
  WARNING: 'common/toasts/WARNING',
  DISMISS: 'common/toasts/DISMISS',
  SUCCESS: 'common/toasts/SUCCESS',
};

/**
 * @readonly
 * @const {string}
 */
export const TASKS = {
  GET_SUCCESS: 'tasks/downloads/GET_SUCCESS',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
  IN_PROCESS: 'IN_PROCESS',
  PENDING: 'PENDING',
};

/**
 * USERS
 */

export const USERS = {
  ADDING: 'users/ADDING',
  CURRENT: 'users/CURRENT',
  FETCH_USERS: 'users/FETCH_USERS',
  GET_FAILURE: 'users/get/FAILURE',
  GET_SUCCESS: 'users/get/SUCCESS',
  INIT_GUILD_USERS: 'users/init/GUILD',
  MODAL: {
    SHOW_ADD_GUILD_USER: 'users/show/ADD_TO_GUILD',
  },
  UPDATE_SUCCESS: 'users/update/SUCCESS',
  UPDATE_FAILURE: 'users/update/FAILURE',
  PASSWORD_CHANGE_SUCCESS: 'user/password/change/SUCCESS',
  PASSWORD_CHANGE_FAILURE: 'user/password/change/FAILURE',
  PATCH_FAILURE: 'users/patch/FAILURE',
  SHOW_LICENSE_ERROR_MODAL: 'user/licenseErrorModal/SHOW',
  UPDATE: {
    APPLYING: 'users/UPDATE/APPLYING',
    COLUMN_WIDTH: 'users/UPDATE/COLUMN_WIDTH',
    FILTER_BY_ENABLED: 'users/UPDATE/FILTER_BY_SELECTED',
    GENERIC: 'users/UPDATE/',
    LOADED: 'users/UPDATE/LOADED',
    LOADING: 'users/UPDATE/LOADING',
    PAGINATION: 'users/UPDATE/PAGINATION',
    SCROLLED_RIGHT: 'users/UPDATE/SCROLLED_RIGHT',
    SELECTED: 'users/UPDATE/SELECTED',
    SELECTED_ID_NOT_ON_PAGE: 'users/UPDATE/SELECTED_ID_NOT_ON_PAGE',
    SORT: 'users/UPDATE/SORT',
  },
};

/**
 * CONTACT
 */

export const CONTACT = {
  UPLOAD_SUCCESS: 'contact/upload/SUCCESS',
  UPLOAD_FAILURE: 'contact/upload/FAILURE',
};

/*
 * ARCHIVE
 */
export const ARCHIVE = {
  CLEAR: 'archive/CLEAR',
  TOGGLE_SELECT: 'archive/toggle/SELECT',
  GET_FAILURE: '/archive/get/FAILURE',
  GET_SUCCESS: '/archive/get/SUCCESS',
  RESTORE: 'archive/RESTORE',
  SELECT_MANY: 'archive/select/ALL',
  SELECT_NONE: 'archive/select/NONE',
  SET_SORT: 'archive/set/SORT',
  SCROLLED_RIGHT: 'archive/SCROLLED_RIGHT',
  UPDATE: {
    PAGINATION: 'archive/UPDATE/PAGINATION',
    COLUMN_WIDTH: 'archive/UPDATE/COLUMN_WIDTH',
    SELECTED: 'archive/UPDATE/SELECTED',
  },
  SELECTED_ID_NOT_ON_PAGE: 'archive/SELECTED_ID_NOT_ON_PAGE',
};
/**
 * PAGINATION
 */

export const PAGINATION = {
  CLEAR_RESULTS: 'pagination/CLEAR_RESULTS',
  LOAD_MORE: 'pagination/LOAD_MORE',
};

/**
 * @const {string}
 */
export const TABLE = {
  ARRANGE_CELLS: 'table/ARRANGE_CELLS',
  SETTINGS: {
    INIT: 'table/dimensions/INIT',
    UPDATE: {
      HEIGHT: 'table/dimensions/update/HEIGHT',
      ORIENTATION: 'table/dimensions/update/ORIENTATION',
      SHOW_QUICK_RESIZE: 'table/dimensions/update/SHOW_QUICK_RESIZE',
      WIDTH: 'table/dimensions/update/WIDTH',
      YAML: 'table/dimensions/update/YAML',
    },
  },
  FILTER: {
    CLEAR: 'table/filter/CLEAR',
    CLEAR_SCHEMAS: 'table/filter/CLEAR_SCHEMAS',
    TOGGLE_ALL: 'table/filter/TOGGLE_ALL',
    TOGGLE_SHAPE_SELECTION: 'table/filter/TOGGLE_SHAPE_SELECTION',
    UPDATE: 'table/filter/UPDATE',
  },
  ORIENTATION: {
    VERTICAL: 'table/orientation/VERTICAL',
    HORIZONTAL: 'table/orientation/HORIZONTAL',
  },
  SELECT: {
    CREATE: 'table/view/SELECT',
    UPDATE: 'table/view/UPDATE',
    CLEAR: 'table/view/CLEAR',
  },
  SORT: {
    APPLY: 'table/sort/APPLY',
    INIT: 'table/sort/INIT',
    RESET: 'table/sort/RESET',
    UPDATE: 'table/sort/UPDATE',
  },
  VIEWPORT: {
    INIT: 'table/view/INIT',
    UPDATE: {
      COLS: 'table/view/update/COLS',
      COLMAX: 'table/view/update/COLMAX',
      ROWMAX: 'table/view/update/ROWMAX',
      ROWS: 'table/view/update/ROWS',
      TAB: 'table/view/update/TAB',
      TYPE: 'table/view/update/TYPE',
    },
  },
};

export const REGISTRATION = {
  API: {
    DEVICE: {
      SUCCESS: 'registration/api/device/SUCCESS',
      FAILURE: 'registration/api/device/FAILURE',
    },
    PASSWORD: {
      SUCCESS: 'registration/api/password/SUCCESS',
      FAILURE: 'registration/api/password/FAILURE',
    },
    TOTP: {
      SUCCESS: 'registration/api/totp/SUCCESS',
      FAILURE: 'registration/api/totp/FAILURE',
    },
    USER: {
      SUCCESS: 'registration/api/user/SUCCESS',
      FAILURE: 'registration/api/user/FAILURE',
    },
  },
  NEXT: 'registration/NEXT',
  PREV: 'registration/PREV',
  SET: {
    DEVICE: 'registration/set/DEVICE',
    TOKEN: 'registration/set/TOKEN',
    SUBMITERROR: 'registration/set/SUBMITERROR',
  },
  VALIDATE: {
    PASSWORD: 'registration/validate/PASSWORD',
  },
};

export const PROPERTY_SIZE = {
  UPDATE: {
    ROW: 'model/propertySize/ROW',
    COLUMN: 'model/propertySize/COLUMN',
    ALL_CELLS: 'model/propertySize/ALL_CELLS',
  },
  INITIALIZE: 'model/propertySize/INITIALIZE',
};

export const SESSION = {
  SAVE_STATE: 'session/SAVE_STATE',
  MODIFY: {
    TABLE: 'session/MODIFY/TABLE',
  },
};

export const QPV = {
  OPEN: 'model/quickPropertyViewer/OPEN',
  CLOSE: 'model/quickPropertyViewer/CLOSE',
};

export const WINDOW = {
  OPEN: 'model/window/OPEN',
  CLOSE: 'model/window/CLOSE',
  CLOSE_ALL: 'model/window/CLOSE_ALL',
  EDIT: 'model/window/EDIT',
  TYPE: {
    GRAPH_LAYOUT: 'GraphLayout',
    PATH_DISCOVERY: 'PathDiscovery',
    QPV: 'QuickPropertyViewer',
    HOTKEYMAP: 'HotkeyMap',
    MPT_POPOUT_EDITOR: 'MPTPopoutEditor',
    MODEL_STYLE_PANEL: 'ModelStylePanel',
    TEXT_SETTER: 'TextSetter',
    TINY_PROPERTY_SETTER: 'TinyPropertySetter',
    K_NEIGHBORHOOD: 'KNeighborhood',
  },
};

export const NOTES = {
  APPEND_NOTE: 'model/notesCollection/APPEND',
  SET_ORDER: 'model/notesCollection/SET_ORDER',
};

export const NOTES_EDITOR = {
  CACHE: {
    UPDATE: 'model/notesEditor/cache/UPDATE',
  },
  CLEAR_SHEET_STATE: 'model/notesEditor/CLEAR_SHEET_STATE',
  CLEAN: 'model/notesEditor/CLEAN',
  DIRTY: 'model/notesEditor/DIRTY',
  HEADER: {
    CLEAR: 'model/notesEditor/header/CLEAR',
    GO_TO: 'model/notesEditor/header/GO_TO',
  },
  INIT: 'model/notesEditor/INIT',
  RENAME_FILE: 'model/notesEditor/RENAME_FILE',
  REMOVE_SHEET: 'model/notesEditor/REMOVE_SHEET',
  SET_ACTIVE_FILE: 'model/notesEditor/SET_ACTIVE_FILE',
  SET_SCROLLED_RIGHT_COUNT: 'model/notesEditor/SET_SCROLLED_RIGHT_COUNT',
  SET_SELECTION_RANGE: 'model/notesEditor/SET_SELECTION_RANGE',
  SET_SHEET_STATE: 'model/notesEditor/SET_SHEET_STATE',
  SHOW_NOTES_OUTLINE_FLYOUT: 'model/notesEditor/show/NOTES_OUTLINE_FLYOUT',
  HIDE_NOTES_OUTLINE_FLYOUT: 'model/notesEditor/hide/NOTES_OUTLINE_FLYOUT',
  UPLOAD: {
    START: 'model/notesEditor/saving/START',
    FINISH: {
      FAILED: 'model/notesEditor/saving/finish/FAILED',
      SUCCEEDED: 'model/notesEditor/saving/finish/SUCCEEDED',
    },
  },
};

export const MPT_CONTEXT_MENU = {
  SHOW: 'model/mptContextMenu/SHOW',
  HIDE: 'model/mptContextMenu/HIDE',
};

export const MODEL_LINKS = {
  INITIALIZE: 'model/links/INITIALIZE',
  SET: 'model/links/SET',
};

export const GRID_SETTINGS = {
  SET: 'model/gridSettings/SET',
};

export const MPT_POPOUT_EDITOR = {
  WIGGLE: {
    START: 'model/mptPopoutEditor/wiggle/START',
    STOP: 'model/mptPopoutEditor/wiggle/STOP',
  },
};

export const MPT_REARRANGE = {
  DRAG: 'model/mptRearrange/DRAG',
  END: 'model/mptRearrange/END',
  RESET: 'mode/mptRearrange/RESET',
  START: 'model/mptRearrange/START',
};

export const URL_FLYOUT = {
  SHOW: 'model/urlFlyout/SHOW',
  HIDE: 'model/urlFlyout/HIDE',
};

/**
 * GUILDS
 */
export const GUILD = {
  CLEAR_ERRORS: 'guild/create/CLEAR',
  CREATE_FAILURE: 'guild/create/FAILURE',
  GET_SUCCESS: 'guild/get/SUCCESS',
  GET_FAILURE: 'guild/get/FAILURE',
  PATCH_FAILURE: 'guild/patch/FAILURE',
  SET_GUILD: 'guild/set/GUILD',
  SET_LOADED: 'guild/set/LOADED',
};

export const CREATE_GUILD = {
  SHOW_GUILD_MODAL: 'guild/modal/SHOW',
  SHOW_LICENSE_ERROR_MODAL: 'guild/licenseErrorModal/SHOW',
};

export const TOGGLE_GUILD = {
  SHOW_TOGGLE_MODAL: 'guild/toggle/modal/show',
};

export const LINK_FILE = {
  SET_INSERT_LOCATION: 'fileLink/insertLocation/SET',
  SET_RESUME_EDITING: 'fileLink/editing/RESUME',
  SET_RESUME_EDIT_LOCATION: 'fileLink/editing/LOCATION',
  SET_TARGET_CELL: 'fileLink/targetCell/SET',
};
