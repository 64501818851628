import { CollapsedSet, Edge, Node, ShapeUUID } from 'engine/components';
import { isNone, isSome } from 'helpers/utils';
import { ModelReducer, ModelSet, TransitionData } from 'types';
import { globalGetState } from 'utils/utils-extra';

/**
 * Checks if every member of a set is completely contained within another set
 * @param containedSetId - target set to check
 * @param containingSetId - potential containing set
 * @param [modelState] - current model state
 * @returns
 */
export function setIsContained(
  containedSetId: string,
  containingSetId: string,
  modelState: ModelReducer = globalGetState().modelReducer
): boolean {
  const containingSet = modelState.sets?.[containingSetId];
  const containedSet = modelState.sets?.[containedSetId];

  if (
    isNone(containedSet) ||
    (containedSet?.nodeIds.length === 0 && containedSet?.edgeIds.length === 0)
  ) {
    return false;
  }

  const containingNodeIdsMap = new Set(containingSet.nodeIds);
  if (!containedSet.nodeIds.every((nodeId) => containingNodeIdsMap.has(nodeId)))
    return false;

  const containingEdgeIdsMap = new Set(containingSet.edgeIds);
  if (!containedSet.edgeIds.every((edgeId) => containingEdgeIdsMap.has(edgeId)))
    return false;

  return true;
}

/**
 * get the sets this set is a part of
 */
export function getContainingSets(
  setId: UUID,
  modelState: ModelReducer
): ModelSet[] {
  return Object.values(modelState.sets).filter((set) =>
    setIsContained(setId, set.id, modelState)
  );
}

/**
 * get the sets of the targetEntity
 * @param data - transition data
 */
export function getEntitySets(data: TransitionData): string[] {
  const { ecs, model, targetEntity } = data;
  let sets: string[] = [];
  const [uuid, node, edge, collapsedSet] = ecs.retrieve(targetEntity, [
    ShapeUUID,
    Node,
    Edge,
    CollapsedSet,
  ]);
  if (!uuid) return sets;
  if (isSome(node)) {
    sets = model.nodes?.[uuid.value].setIds ?? sets;
  } else if (isSome(edge)) {
    sets = model.edges?.[uuid.value].setIds ?? sets;
  } else if (isSome(collapsedSet)) {
    const set = model.sets?.[collapsedSet.setId];
    if (isNone(set)) return sets;
    sets = getContainingSets(set.id, model).map((set) => set.id);
  }
  return sets;
}

/**
 * recursively determines if checkSetId is the top-most containing set, if not
 * returns the top-most containing set Id
 * @param querySetId - set id to determine its top-most containing set;
 * @param  targetSetIds - ids of all sets in the model to test against
 * @returns  id of the top-most containing set
 */
export function topContainerSet(
  querySetId: string,
  targetSetIds: readonly string[],
  modelState: ModelReducer = globalGetState().modelReducer
): string {
  let topContainer = querySetId;
  const collapsedSetIdCount = targetSetIds.length;
  for (let i = 0; i < collapsedSetIdCount; i++) {
    const collapsedSetId = targetSetIds[i];
    if (
      querySetId !== collapsedSetId &&
      setIsContained(topContainer, collapsedSetId, modelState)
    ) {
      topContainer = collapsedSetId;
    }
  }
  return topContainer;
}
