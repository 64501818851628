import { GID, gid, newestGid } from '../gid';
import {
  addCategoryMembers,
  createCategory,
  createProperty,
  deleteCategory,
  deleteProperty,
  renameCategory,
  renameProperty,
} from '../schema/actions';
import type { ThunkActionFunc } from 'types';
import { createAction } from '@reduxjs/toolkit';
import { remember } from '../base/actions';
import { SheetSelections } from './types';
import { onRedo, onUndo, undoableAction } from '../history/undoable-action';

// add a new selection without clearing previous (cmd-click)
export const addSheetSelection = createAction<{
  sheet: GID;
  row: string;
  column: string;
}>('model/sheet/selection/ADD');

// replace existing selection with the new selection (click)
export const replaceSheetSelection = createAction<{
  sheet: GID;
  row: string;
  column: string;
}>('model/sheet/selection/REPLACE');

// replace existing selection with a different selection state (undo/redo)
export const _replaceSheetSelectionState = createAction<{
  sheet: GID;
  sheetSelections: SheetSelections;
}>('model/sheet/selection/REPLACE_STATE');
export const replaceSheetSelectionState = undoableAction(
  _replaceSheetSelectionState
);

// clear all sheets selections entirely (e.g. when selecting something else on the on canvas)
export const clearAllSheetsSelection = createAction(
  'model/sheet/selection/CLEAR_ALL_SHEETS_SELECTIONS'
);

// clear selection entirely (ESC)
export const clearSheetSelection = createAction<{ sheet: GID }>(
  'model/sheet/selection/CLEAR'
);

// remove a selected cell without clearing others (cmd-click)
export const removeSheetSelection = createAction<{
  sheet: GID;
  row: string;
  column: string;
}>('model/sheet/selection/REMOVE');

// extend the most recent selection to the specified cell (shift-click)
export const extendSheetSelection = createAction<{
  sheet: GID;
  row: string;
  column: string;
}>('model/sheet/selection/EXTEND');

export const extrudeSheetSelection = createAction<{
  sheet: GID;
  row: string;
  column: string;
}>('model/sheet/selection/EXTRUDE');

export const createCategorySheet: ThunkActionFunc<[string], GID> =
  (name) => (dispatch, _getState) => {
    dispatch(createSheet({ name, type: 'category' }));
    const sheet = newestGid();
    dispatch(createCategory({ name, sheet }));
    const categoryId = newestGid();
    dispatch(addRows({ sheet, rows: [categoryId] }));
    dispatch(remember());
    return sheet;
  };

export const setCategorySheetValue: ThunkActionFunc<
  [string, string, string, string],
  string
> = (sheet, row, column, value) => (dispatch, getState) => {
  const { schema } = getState().modelReducer;
  dispatch(setValue({ sheet, row, column, value }));

  // if this is the category row (IE first row)
  // then we want to set the property name for this column as well
  if (schema.categories.bySheet[sheet] === row) {
    dispatch(renameProperty({ property: column, name: value }));
  }

  const valueId =
    getState().modelReducer.sheets.byId[sheet].entries[row][column];
  return valueId;
};

const _addCategorySheetProperty: ThunkActionFunc<
  [string, string, Option<string>]
> = (sheet, name, id) => (dispatch, getState) => {
  const { schema } = getState().modelReducer;
  const category = schema.categories.bySheet[sheet];

  // create property on the schema side
  const propid = id || gid();
  dispatch(createProperty({ name, category, id: propid }));

  // add the property as a column
  dispatch(addColumns({ sheet, columns: [propid] }));

  // set the property name as a row value. Name row is keyed by category ID
  dispatch(setCategorySheetValue(sheet, category, propid, name));

  onUndo(deleteSheetColumns({ sheet, columns: [propid] }));
  onRedo(addCategorySheetProperty(sheet, name, propid));
};
export const addCategorySheetProperty = undoableAction(
  _addCategorySheetProperty
);

export const _deleteSheetColumns: ThunkActionFunc<
  [{ sheet: string; columns: string[] }]
> =
  ({ sheet, columns }) =>
  (dispatch, getState) => {
    const category = getState().modelReducer.schema.categories.bySheet[sheet];

    if (category) {
      for (const property of columns) {
        dispatch(deleteProperty({ property }));
      }
    }

    dispatch(removeColumns({ sheet, columns }));
  };
export const deleteSheetColumns = undoableAction(_deleteSheetColumns);

export const addCategorySheetRows: ThunkActionFunc<[GID, string[]]> =
  (sheet, entryIds) => (dispatch, getState) => {
    const { schema } = getState().modelReducer;
    const category = schema.categories.bySheet[sheet];
    dispatch(addCategoryMembers({ category, members: entryIds }));
    dispatch(addRows({ sheet, rows: entryIds }));
    dispatch(remember());
  };

export const renameSheet: ThunkActionFunc<[string, string]> =
  (sheet, name) => (dispatch, getState) => {
    const {
      sheets,
      schema: { categories },
    } = getState().modelReducer;
    if (sheets.byId[sheet].type === 'category') {
      dispatch(renameCategory({ category: categories.bySheet[sheet], name }));
    }
    dispatch(_renameSheet({ sheet, name }));
    dispatch(remember());
  };

export const deleteSheet: ThunkActionFunc<[string]> =
  (sheet) => (dispatch, getState) => {
    const {
      sheets,
      schema: { categories },
    } = getState().modelReducer;
    if (sheets.byId[sheet].type === 'category') {
      dispatch(deleteCategory({ category: categories.bySheet[sheet] }));
    }
    dispatch(_deleteSheet({ sheet }));
    dispatch(remember());
  };

export const createSheet = createAction<{ name: string; type: string }>(
  'model/sheet/CREATE'
);
export const _deleteSheet = createAction<{ sheet: GID }>('model/sheet/DELETE');
export const _renameSheet = createAction<{ sheet: GID; name: string }>(
  'model/sheet/RENAME'
);

export const setEvaluated = createAction<{
  sheet: GID;
  valueId: GID;
  evaluated: string;
}>('model/sheet/value/EXPRESSION');
export const setError = createAction<{
  sheet: GID;
  valueId: GID;
  error: string;
}>('model/sheet/value/ERROR');

export const setValue = createAction<{
  sheet: GID;
  row: GID;
  column: GID;
  value: string;
}>('model/sheet/value/SET');
export const addRows = createAction<{ sheet: GID; rows: string[] }>(
  'model/sheet/row/ADD_MANY'
);
export const addColumns = createAction<{ sheet: GID; columns: string[] }>(
  'model/sheet/column/ADD_MANY'
);

export const removeColumns = createAction<{ sheet: GID; columns: string[] }>(
  'model/sheet/column/REMOVE_MANY'
);
