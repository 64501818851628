import { EXPRESSIONS } from 'modules/common';

export const initialExpressionState = {
  values: {},
  errors: {},
  hyperlinks: {},
};

export const expressionReducer = (state = initialExpressionState, action) => {
  switch (action.type) {
    case EXPRESSIONS.INITIALIZE:
      return { ...action.payload };
    case EXPRESSIONS.UPDATE:
      return { ...action.payload };
    case EXPRESSIONS.SET_HYPERLINKS:
      return {
        ...state,
        hyperlinks: action.payload,
      };
    default:
      return state;
  }
};

export default expressionReducer;
