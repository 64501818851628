import { Middleware, configureStore } from '@reduxjs/toolkit';
import { setGlobalStore } from 'utils/utils';
import { setGlobalGetState, setDispatcher } from 'utils/utils-extra';
import {
  createActionIdMiddleware,
  createTrackableThunkMiddleware,
} from 'middleware/trackableThunks';
import { createSentryLoggingMiddleware } from 'middleware/sentry';
import { initialState, rootReducer } from 'modules';
import { EventManager } from 'metra-events/manager';
import { metraThunkMiddleware } from 'middleware/metra-thunk';
import { history } from 'middleware/history';
import { undoableMiddleware } from 'middleware/undoable';

/**
 * the Metra redux Store
 */
export const metraStore = configureStore({
  reducer: rootReducer,
  preloadedState: initialState,
  // we ignore the "default" middleware since it causes alot of problems
  // we're not ready to fix
  middleware: (_getDefaultMiddleware) => {
    if (!EventManager.isInitialized) {
      EventManager.init();
    }
    return [
      undoableMiddleware,
      metraThunkMiddleware,
      createActionIdMiddleware(),
      createTrackableThunkMiddleware(),
      createSentryLoggingMiddleware(),
      history,
    ] as Middleware[];
  },
  devTools: {
    name: 'metra-redux-devtools',
    latency: 0,
    maxAge: 50,
    trace: true,
    traceLimit: 10,
    autoPause: true,
  },
});

setGlobalStore(metraStore);
setDispatcher(metraStore.dispatch);
setGlobalGetState(metraStore.getState);

export default metraStore;
