import {
  ModelCalc,
  ModelEdge,
  ModelNode,
  ModelPath,
  ModelProp,
  ModelPropertyValueUpdate,
  ModelSet,
} from 'types';
import type { Interpreter } from 'interpreter/interpreter';
import { isSome } from 'helpers/utils';
import { isGridNameColumnValue, setRecord } from 'interpreter/helpers';
import { GRID } from 'utils/constants-extra';
import { EventManager } from 'metra-events';
import { TABLE_EVENT } from 'utils/constants';

/**
 * renameFinalizer simply saves the updated propValues to the redux state.
 */
export function renameFinalizer<R, O>(
  itpr: Interpreter<R, O>
): Interpreter<R, O> {
  const propValues: ModelPropertyValueUpdate[] = [];
  const renamedNodes: ModelNode[] = [];
  const renamedEdges: ModelEdge[] = [];
  const renamedSets: ModelSet[] = [];
  const renamedPaths: ModelPath[] = [];
  const renamedModelProps: ModelProp[] = [];
  const renamedModelCalcs: ModelCalc[] = [];

  itpr.expressionList.forEach((exp) => {
    const { parentId, schemaId } = exp.ids;
    if (isSome(exp.parsed) && exp.namesUpdated) {
      if (!isGridNameColumnValue(schemaId)) {
        setRecord(
          itpr.state.propValues,
          parentId,
          schemaId,
          '= ' + exp.parsed.toString()
        );
        propValues.push({
          parentId,
          schemaId,
          value: '= ' + exp.parsed.toString(),
        });
      } else {
        if (GRID.NAME_COLUMN.NODES === schemaId) {
          itpr.state.nodes[parentId].label = '= ' + exp.parsed.toString();
          renamedNodes.push(itpr.state.nodes[parentId]);
        } else if (GRID.NAME_COLUMN.EDGES === schemaId) {
          itpr.state.edges[parentId].label = '= ' + exp.parsed.toString();
          renamedEdges.push(itpr.state.edges[parentId]);
        } else if (GRID.NAME_COLUMN.SETS === schemaId) {
          itpr.state.sets[parentId].label = '= ' + exp.parsed.toString();
          renamedSets.push(itpr.state.sets[parentId]);
        } else if (GRID.NAME_COLUMN.PATHS === schemaId) {
          itpr.state.paths[parentId].label = '= ' + exp.parsed.toString();
          renamedPaths.push(itpr.state.paths[parentId]);
        } else if (GRID.NAME_COLUMN.MODEL_PROPS === schemaId) {
          itpr.state.modelProps[parentId].label = '= ' + exp.parsed.toString();
          renamedModelProps.push(itpr.state.modelProps[parentId]);
        } else if (GRID.NAME_COLUMN.MODEL_CALCS === schemaId) {
          itpr.state.modelCalcs[parentId].label = '= ' + exp.parsed.toString();
          renamedModelCalcs.push(itpr.state.modelCalcs[parentId]);
        }
      }
    }
  });

  if (renamedNodes.length) {
    EventManager.emit(TABLE_EVENT.NODE.LABEL.UPDATE, renamedNodes);
  }
  if (renamedEdges.length) {
    EventManager.emit(TABLE_EVENT.EDGE.LABEL.UPDATE, renamedEdges);
  }
  if (renamedSets.length) {
    EventManager.emit(TABLE_EVENT.SET.LABEL.UPDATE, renamedSets);
  }
  if (renamedPaths.length) {
    EventManager.emit(TABLE_EVENT.PATH.LABEL.UPDATE, renamedPaths);
  }
  if (renamedModelProps.length) {
    EventManager.emit(TABLE_EVENT.MODEL_PROP.LABEL.UPDATE, renamedModelProps);
  }
  if (renamedModelCalcs.length) {
    EventManager.emit(TABLE_EVENT.MODEL_CALC.LABEL.UPDATE, renamedModelCalcs);
  }

  itpr.results = propValues as R;
  return itpr;
}
