import type { Interpreter } from 'interpreter/interpreter';
import { PREPARSED } from 'interpreter/constants';
import {
  addColumnDependencies,
  addRowDependencies,
  replaceRanges,
} from './helpers';

/**
 * The preparser using string manipulation to modify expressions before
 * they get parsed by MathJS. This lets us do things that aren't supported
 * or easy to do within MathJS. Most importantly: we strip out any ranges (A1:A10)
 * or col/row refs (A_, _1) and replace them with arrays
 *
 * This is also the place where we figure out an Expression's dependencies
 */
export function preparser<Returns, Options>(
  itpr: Interpreter<Returns, Options>
): Interpreter<Returns, Options> {
  const cacheState = itpr.dependencyCache.frozen;

  itpr.expressionList.forEach((exp) => {
    if (
      itpr.dependencyCache.frozen &&
      (itpr?.modified?.includes(exp.ref) ?? false)
    ) {
      itpr.dependencyCache.thaw();
      const deps = exp.deps;
      deps && deps.clear();
    }
    exp.preparsed = exp.value;
    if (exp.isPlain) {
      exp.status = PREPARSED;
      return;
    }

    // remove trailing semi-colons so values are always returned
    exp.preparsed = exp.preparsed.replace(/;+$/, '');

    // replace cell ranges with our range function
    // EG B1:B3 becomes cellRange(B1, B3);
    // We also add the ranges as dependencies here
    exp.preparsed = replaceRanges(itpr, exp);
    // Add dependencies for row and col refs, EG B_
    addColumnDependencies(itpr, exp);
    addRowDependencies(itpr, exp);

    exp.status = PREPARSED;
    // if cache started frozen, refreeze
    itpr.dependencyCache.frozen = cacheState;
  });

  return itpr;
}
