import type { EcsInstance } from 'ecs/EcsInstance';
import type { Entity } from 'ecs/Entity';
import type { Component } from 'ecs/Component';
import { Update, Finalized, LoadAsset } from 'engine/components';
import { AssetLoadState } from 'engine/constants';

/**
 * constructs an update entity that will update the redux config for the target
 * entity
 * @param ecs - current ecs scope
 * @param target - entity to update
 * @param componentTypes - array of `Component` types to update
 * @param finalize - whether or not to attach a `Finalized` component upon creation,
                   stating the Update is ready to be committed.
 * @returns the built `Entity` if successful, otherwise returns an `Error`
 */
export function buildUpdate(
  ecs: EcsInstance,
  target: Entity,
  componentTypes: (typeof Component)[],
  finalize = false
): Result<Option<Entity>, Error> {
  // ensure this is a valid update
  if (
    !componentTypes.every((compType) =>
      ecs.hasComponentOfType(target, compType)
    )
  ) {
    return null;
  }
  return ecs
    .create()
    .addWith(() => {
      const update = new Update();
      update.components = componentTypes;
      update.target = target;
      return update;
    })
    .addMaybe(finalize ? new Finalized() : undefined)
    .build();
}

export function buildLoadAsset(ecs: EcsInstance, asset: string, url?: string) {
  ecs
    .create()
    .addWith(() => {
      const loadAsset = new LoadAsset();
      loadAsset.name = asset;
      loadAsset.url = url ? url : asset;
      loadAsset.status = AssetLoadState.NONE;
      return loadAsset;
    })
    .build();
}
