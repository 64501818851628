export class FeatureFlags {
  static _ecsOverride = false;

  static get ENABLE_FOLDERS() {
    return process.env.REACT_APP_ENABLE_FOLDERS === 'true';
  }
  static get ENABLE_FOLDER_UPLOADS() {
    return process.env.REACT_APP_ENABLE_FOLDER_UPLOADS === 'true';
  }

  /**
   * enables the use of collapsing set features.
   * @returns {boolean} true if enabled, otherwise false
   */
  static get ENABLE_COLLAPSING_SETS() {
    return process.env.REACT_APP_ENABLE_COLLAPSING_SETS === 'true';
  }

  static get ENABLE_DEVELOPER_TOOLS() {
    return process.env.REACT_APP_ENABLE_DEVELOPER_TOOLS === 'true';
  }

  static get ENABLE_DEVELOPER_MODELING_TOOLS() {
    return process.env.REACT_APP_ENABLE_DEVELOPER_MODELING_TOOLS === 'true';
  }

  static get MODE_MANAGER_LOGGING() {
    return process.env.MODE_MANAGER_LOGGING === 'true';
  }

  static get DEBUG_TOOLS() {
    return process.env.REACT_APP_DEBUG_TOOLS === 'true';
  }

  static get TESTING() {
    return process.env.NODE_ENV === 'test';
  }

  static get IS_PROD() {
    return process.env.NODE_ENV === 'production';
  }

  static get CATEGORIES() {
    return process.env.REACT_APP_METRA_CATEGORIES === 'true';
  }
}
