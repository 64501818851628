/*eslint no-unused-expressions: "off"*/
import {
  CellLane,
  CellRef,
  CoordRef,
  EventUnsubFunc,
  InterpreterOptions,
  InterpreterResults,
  InterpreterWorker,
  LaneParse,
  LocalRef,
  MappedTableSort,
  MetraThunkDispatch,
  ModelReducer,
  ModelSchemaCategories,
  NodePrefixType,
  PropertyRef,
  QualifiedLane,
  QualifiedRef,
  ReferenceData,
  TableSort,
} from 'types';
import type { DependencyCache, DependencyNode } from './dependencyCache';
import { alphaColumnToInt } from 'utils/utils-extra';
import { SCHEMA_CATEGORY_TYPES } from 'utils/constants-extra';
import { nullify, isNone, isSome } from 'helpers/utils';
import { createToastMessage } from 'modules/ui/toasts';
import { TOASTS } from 'modules/common/constants';
import { Expression } from './expression';
import { getDependencyCache, rebuildCache } from './dependencyCache';
import {
  DEVMODE,
  EXP_NODE_LETTER_PREFIXES,
  NAME_CELL_PARENT_IDS,
  NAME_CELL_SCHEMA_IDS,
} from './constants';
import { mathjs } from './t3math';
import {
  isGridNameRowValue,
  isGridNameColumnValue,
  idsToRef,
  coordsToRef,
  entToPrefix,
  isGlobalRef,
  prefixToType,
  buildCoordMaps,
} from './helpers';
import './custom'; // imports custom functions
import { t3dev } from 't3dev';
import { EventManager } from 'metra-events';

export const ITPR_CELL_REGEX =
  /^(?<type>[cmnsep]_)?(?<col>[a-z]+)(?<row>[0-9]+)$/i;

/**
 * The Interpreter is the bridge between MathJS and our business logic.
 * When interpret() is called, a series of workers are called. These workers
 * (parser, evaluator, etc) do the necessary processing to turn raw strings
 * into evaluated equations. Each worker is provided a reference to the
 * interpreter, so this class is packed to the brim with useful helpers
 *
 * The Interpreter also stores a copy of our redux state for workers to access.
 * Workers can modify this state (though currently only the finalizer should
 * do so), and the edited state is ultimately returned to the reducer to be saved.
 *
 * State is also maintained between workers by modifying the Expression objects
 * representing individual cells. For more info, see expression.js
 *
 */
export class Interpreter<Results = unknown, Options = unknown> {
  private _expressions: Record<QualifiedRef, Expression<Results, Options>>;
  private _destroyed: boolean;
  scope: Record<PropertyKey, unknown>;
  workers: InterpreterWorker<Results, Options>[];
  calls: Record<PropertyKey, unknown>;
  results: Option<Results>;
  opts: Option<InterpreterOptions<Options>>;
  unsub: EventUnsubFunc;

  state!: ModelReducer;
  mappedTableSort!: MappedTableSort;
  private _cellRegex!: RegExp;
  // private _expressionList!: Expression<Results, Options>[];
  private _expressionRefs!: QualifiedRef[];
  dispatch!: MetraThunkDispatch<unknown, unknown, unknown>;
  /**
   * these are the changes the model tells us are altered
   */
  modified!: Option<QualifiedRef[]>; // Option<Expression<Results, Options>>[];
  /**
   * when changes occur, these are the only values that are saved
   * based on Kahn's Plan results
   */
  onlySave!: Option<Expression<Results, Options>>[];
  sort!: {
    newSort: TableSort;
    oldSort: TableSort;
  };

  /**
   * for debugging only
   */
  private _mathjs: Option<math.MathJsStatic>;

  /**
   * @param workers
   * @param options
   */
  constructor(
    workers: InterpreterWorker<Results, Options>[] = [],
    options: Partial<InterpreterOptions<Options>> = {}
  ) {
    // set it to something
    if (options.rebuildCache) rebuildCache<Results, Options>();
    this.scope = {};
    this._expressions = {};
    this._expressionRefs = [];
    this.workers = workers;
    this.calls = {};
    this._destroyed = false;
    // first we need to ensure there are no prior interpreters
    const [prev] = EventManager.emit('itpr') as [prev: Interpreter];
    if (prev) prev.destroy();
    this.unsub = EventManager.on('itpr', () => this, this);

    // this.modified;
    // this.onlySave;
    // this.results = Results;
    t3dev().setValue('itpr', this);

    if (DEVMODE) {
      // set the mathjs context for debugging
      this._mathjs = mathjs;
    }
  }

  /**
   * destroys the interpreter
   * @param [destroyCache=false] - destroy the dependency cache too
   */
  destroy(destroyCache = false) {
    if (this._destroyed) return;
    this._expressionRefs.forEach((ref) => {
      this.getExp(ref)?.destroy();
    });
    destroyCache && this?._cache?.destroy(false);
    this.unsub();
    nullify(this, 'workers');
    nullify(this, '_mathjs');
    nullify(this, 'scope');
    nullify(this, '_expressions');
    nullify(this, '_expressionRefs');
    nullify(this, 'calls');
    nullify(this, 'modified');
    nullify(this, 'results');
    nullify(this, 'state');
    nullify(this, 'dispatch');
    nullify(this, 'onlySave');
    nullify(this, 'sort');
    nullify(this, 'mappedTableSort');

    t3dev().setValue('itpr', null);
    this._destroyed = true;
  }

  private get _cache(): DependencyCache<Results, Options> {
    return getDependencyCache<Results, Options>();
  }

  get dependencyCache(): DependencyCache<Results, Options> {
    return this._cache;
  }

  /**
   * expects modelReducer as base state
   */
  interpret(
    state: ModelReducer,
    options: Partial<InterpreterOptions<Options>> = {}
  ): InterpreterResults<Results> {
    this.state = state;

    const mappedTableSort = buildCoordMaps(state);
    this.mappedTableSort = mappedTableSort;

    this.buildExpressions();

    if (isSome(options.dispatch)) {
      this.dispatch = options.dispatch;
    }

    if (isSome(options.values) && Array.isArray(options.values)) {
      this.modified = options.values.reduce((refs, ids) => {
        const ref = idsToRef(this.state, this.mappedTableSort, ids);
        const maybeExp = this.grab(ref);
        if (!maybeExp) return refs;
        return refs.addItem(maybeExp.ref);
      }, [] as QualifiedRef[]);
    } else {
      this.modified = [];
    }

    if (isSome(options.sort)) {
      this.sort = options.sort;
    }

    // set to blank record if empty
    this.opts ||= {} as InterpreterOptions<Options>;

    if (isSome(options.extraOpts)) {
      this.opts.extraOpts = options.extraOpts;
    }

    let lastWorker = 'none';
    try {
      this.workers.forEach((worker) => {
        lastWorker = worker.name;
        worker(this);
      });
      // if (DEVMODnE) t3dev().setValue('itpr', resultContext);
      return {
        state: this.state,
        extra: this.results,
      };
    } catch (e: any) {
      if (isSome(this.opts.dispatch)) {
        this.opts.dispatch(
          createToastMessage(TOASTS.ERROR, `Failed to parse: "${e.message}"`)
        );
      }
      console.error(
        `Iterpreter encountered error during worker (${lastWorker}) processing: `,
        e as Error
      );
      return { state: this.state, extra: null };
    }
  }

  getExp(ref: QualifiedRef): Option<Expression<Results, Options>> {
    return this._expressions[ref];
  }

  getDeps(ref: QualifiedRef): DependencyNode<Results, Options> {
    return this.dependencyCache.getDeps(ref);
  }

  /**
   * get or create (but almost always gets) an expression
   * return null if it doesn't exist
   * @param  ref - node reference name
   * @param  ent - type of reference
   * @returns
   */
  grab(
    ref: string | QualifiedRef,
    ent?: ModelSchemaCategories
  ): Option<Expression<Results, Options>> {
    const qualified = this.qualifyRef(ref, ent);
    if (isNone(qualified)) return null;
    const existing = this.fromReference(qualified);

    if (isSome(existing)) return existing;

    const [entity, row, col] = this.refToCoords(qualified);
    const allowBlank = true;
    return this.addExp(qualified, entity, row, col, allowBlank);
  }

  get expressionRefs(): QualifiedRef[] {
    return this._expressionRefs;
  }

  get expressionList(): Expression<Results, Options>[] {
    return this._expressionRefs.reduce((a, r) => {
      const exp = this.getExp(r);
      return exp ? a.addItem(exp) : a;
    }, [] as Expression<Results, Options>[]);
  }

  buildExpressions() {
    // build a map so we can quickly look up row, col by ID
    const { entityMap, schemaMap } = buildCoordMaps(this.state);

    SCHEMA_CATEGORY_TYPES.forEach((type) => {
      // add name cell
      const row = 0;
      const col = 0;
      const ref = coordsToRef(type, row, col);

      // add all entity names
      this.addExp(ref, type, row, col);
      for (
        let row = 1;
        row < this.state.tableSort.entities[type].length + 1;
        row++
      ) {
        const col = 0;
        const ref = coordsToRef(type, row, col);
        this.addExp(ref, type, row, col);
      }

      // all prop names
      for (
        let col = 0;
        col < this.state.tableSort.schemas[type].length + 1;
        col++
      ) {
        const row = 0;
        const ref = coordsToRef(type, row, col);
        this.addExp(ref, type, row, col);
      }
    });

    // only add propValues that are not blank
    // blank cells are not built so we don't run out of memory in large tables
    for (const entityId in this.state.propValues) {
      for (const schemaId in this.state.propValues[entityId]) {
        const ent = this.state.propSchemas[schemaId].category;
        const row = entityMap[ent][entityId];
        const col = schemaMap[ent][schemaId];
        const ref = coordsToRef(ent, row, col);
        this.addExp(ref, ent, row, col);
      }
    }
  }

  // assume ref is qualified
  refToCoords(
    ref: QualifiedRef
  ): [entity: ModelSchemaCategories, row: number, col: number] {
    const parsed = this._parseRef(ref.slice(2));

    if (isNone(parsed)) {
      throw new Error(`Invalid format for cell reference ${ref}`);
    }

    const ent = prefixToType(ref.slice(0, 2) as NodePrefixType);
    if (isNone(ent)) {
      throw new Error(`Invalid entity type for cell reference ${ref}`);
    }
    const row = this.derefRow(parsed.row);
    const col = this.derefColumn(parsed.col);
    return [ent, row, col];
  }

  derefColumn(colRef: string): number {
    return alphaColumnToInt(colRef.toUpperCase());
  }

  derefRow(rowRef: string) {
    return Number(rowRef);
  }

  coordsToIds(
    ent: ModelSchemaCategories,
    row: number,
    col: number,
    override = false
  ): PropertyRef {
    if (!SCHEMA_CATEGORY_TYPES.includes(ent)) {
      throw new Error(
        'cell entity type must be one of: modelCalcs, modelProps, nodes, edges, sets, paths'
      );
    }
    if ((row < 0 || col < 0) && !override) {
      throw new Error('cell coordinates cannot be less than 0');
    }
    const tableSort = this.state.tableSort;
    let parentId: Option<string>;
    if (row < 1 && ent in NAME_CELL_PARENT_IDS) {
      parentId = NAME_CELL_PARENT_IDS[ent];
    } else if (ent in tableSort.entities) {
      parentId = tableSort.entities[ent][row - 1];
    } else {
      parentId = null;
    }
    let schemaId;

    if (col < 1 && ent in NAME_CELL_SCHEMA_IDS) {
      schemaId = NAME_CELL_SCHEMA_IDS[ent];
    } else if (ent in tableSort.schemas) {
      schemaId = tableSort.schemas[ent][col - 1];
    } else {
      schemaId = null;
    }

    if (isNone(parentId) || isNone(schemaId)) {
      throw new Error('no matching parent or schema id found');
    }

    const value = this.state.propValues?.[parentId]?.[schemaId] ?? '';
    return { parentId, schemaId, value };
  }

  refToIds(ref: QualifiedRef): PropertyRef {
    const [ent, row, col] = this.refToCoords(ref);

    let parentId, schemaId;
    if (ent in this.state.tableSort.entities) {
      parentId = this.state.tableSort.entities[ent][row];
    }

    if (ent in this.state.tableSort.schemas) {
      schemaId = this.state.tableSort.schemas[ent][col];
    }

    if (isNone(parentId) || isNone(schemaId))
      throw new Error('no parent or schema id could be resolved');

    return {
      parentId,
      schemaId,
    };
  }

  get cellRegex(): RegExp {
    this._cellRegex ||= ITPR_CELL_REGEX;
    return this._cellRegex;
  }

  _parseRef(ref: string): Option<CoordRef> {
    const lref = ref.toLowerCase();
    let gotAlpha = false;
    let gotDigit = false;
    let col = '';
    let row = '';
    for (let i = 0; i < ref.length; i++) {
      const code = lref.charCodeAt(i);
      const alpha = code > 96 && code < 123;
      const digit = code > 47 && code < 58;
      if (!alpha && !digit) return null;
      if (digit && !gotAlpha) return null;
      if (alpha && gotDigit) return null;
      if (alpha) {
        gotAlpha = true;
        col += lref[i];
      }
      if (digit) {
        gotDigit = true;
        row += lref[i];
      }
    }

    if (!gotDigit || !gotAlpha) return null;

    const coords = [this.derefRow(row), this.derefColumn(col)];

    return { type: 'cell', row, col, coords };
  }

  /**
   * @param ref - node reference name
   * @param ent - type of reference
   * @returns
   */
  qualifyRef(ref: string, ent?: ModelSchemaCategories): Option<QualifiedRef> {
    let parsed;
    let global = false;

    if (ref === '') {
      return null;
    }

    if (
      EXP_NODE_LETTER_PREFIXES.includes(ref[0].toLowerCase()) &&
      ref[1] === '_' &&
      ref.length > 2
    ) {
      global = true;
      parsed = this._parseRef(ref.slice(2));
    } else {
      parsed = this._parseRef(ref);
    }

    if (!parsed) {
      return null;
    }

    if (global) return ref.toLowerCase() as QualifiedRef;

    const prefix = entToPrefix(ent);
    if (!prefix) return null;
    return `${prefix}${ref}`.toLowerCase() as QualifiedRef;
  }

  /**
   * @param ref - reference
   * @returns - reference data
   */
  refData(ref: string, ent?: ModelSchemaCategories): Option<ReferenceData> {
    let parsed;
    let global = false;
    let uppercase = false;
    let localized: LocalRef = ref as LocalRef;
    let lane = false;
    let entity: ModelSchemaCategories = ent as ModelSchemaCategories;

    if (isGlobalRef(ref)) {
      global = true;
      const maybeEnt = prefixToType(ref.slice(0, 2));
      if (isNone(maybeEnt)) return null;
      entity = maybeEnt;
      localized = ref.slice(2) as LocalRef;
    } else {
      if (isNone(entity)) {
        t3dev().log.error(
          'tried to get refData for a local cell ref, but no entity was provided'
        );
        return null;
      }
    }

    parsed = this._parseRef(localized);

    if (!parsed) {
      // even though this is a partial LaneParse (missing ent),
      // we need to cast it for the aspects we KNOW will be returned (eg row and col)
      // really we shouldn't use Partial at all for this, but that would be a larger rework
      parsed = this._parseLane(localized) as LaneParse;
      if (!parsed) {
        return null;
      }
      lane = true;
    }

    uppercase = ref[0].toUpperCase() === ref[0];

    let qualified =
      `${entity[0].toLowerCase()}_${localized.toLowerCase()}` as QualifiedRef;

    let cellref = ref as CellRef | CellLane;
    return {
      ref: cellref,
      localized,
      qualified,
      lane,
      global,
      uppercase,
      entity,
      ...parsed,
    };
  }

  grabLane(ref: string, ent?: ModelSchemaCategories): Option<LaneParse> {
    let parsed = {} as LaneParse;

    let global = false;
    if (
      EXP_NODE_LETTER_PREFIXES.includes(ref[0].toLowerCase()) &&
      ref[1] === '_' &&
      ref.length > 2
    ) {
      const lane = this._parseLane(ref.slice(2));
      if (isNone(lane)) return null;
      global = true;
      parsed = Object.overlay(parsed, lane);
    } else {
      const lane = this._parseLane(ref);
      if (isNone(lane)) return null;
      parsed = Object.overlay(parsed, lane);
    }

    if (isNone(parsed) || !Object.isRecord(parsed)) return null;

    if (global) {
      const maybeEnt = prefixToType(ref.toLowerCase().slice(0, 2));
      if (isNone(maybeEnt)) return null;
      parsed.ref = ref.toLowerCase() as QualifiedLane;
      parsed.ent = maybeEnt;
    } else {
      if (!ent || !SCHEMA_CATEGORY_TYPES.includes(ent)) {
        throw new Error(`invalid entity type: ${ent}`);
      }

      parsed.ent = ent;
      parsed.ref = `${ent[0]}_${ref.toLowerCase()}` as QualifiedLane;
    }

    return parsed;
  }

  _parseLane(ref: string): Option<Partial<LaneParse>> {
    const lower = ref.toLowerCase();
    let type: 'row' | 'column' = 'row';
    let row = '';
    let col = '';
    if (lower[0] == '_') {
      type = 'row';
      for (let i = 1; i < lower.length; i++) {
        const code = lower.charCodeAt(i);
        const digit = code > 47 && code < 58;
        if (!digit) return null;
      }
      const coords = [this.derefRow(lower.slice(1)), null];
      col = '_';
      row = ref.slice(1);
      return { type, row, col, coords, value: coords[0] as number };
    } else if (lower[lower.length - 1] === '_') {
      type = 'column';
      for (let i = 0; i < lower.length - 1; i++) {
        const code = lower.charCodeAt(i);
        const alpha = code > 96 && code < 123;
        if (!alpha) return null;
      }
      const coords = [null, this.derefColumn(lower.slice(0, -1))];
      row = '_';
      col = ref.slice(0, -1);
      return { type, row, col, coords, value: coords[1] as number };
    }

    return null;
  }

  addExp(
    ref: QualifiedRef,
    ent: ModelSchemaCategories,
    row: number,
    col: number,
    allowBlank = false
  ): Option<Expression<Results, Options>> {
    let ids;
    try {
      ids = this.coordsToIds(ent, row, col);
    } catch {
      // if the user tries to reference a cell that does not exist
      // we avoid creating an exp and treat the reference as a normal variable instead
      return;
    }
    const value = this.valueFromState(ent, ids);

    // do not build blank exps!
    // we keep this map sparse to avoid crashing the browser
    // with several GB of empty cells
    if (isNone(value) && !allowBlank) {
      return;
    }

    const exp = new Expression<Results, Options>();
    exp.itpr = this;
    exp.ent = ent;
    exp.row = row;
    exp.col = col;
    exp.setReference(ref);
    exp.localReference = exp.ref.slice(2) as LocalRef;
    exp.prefix = exp.ref.slice(0, 2) as NodePrefixType;
    exp.ids = ids;
    exp.value = value || '';
    this._expressions[ref] = exp;
    this._expressionRefs.push(ref);
    this._cache.addDeps(ref);
    return exp;
  }

  /**
   *
   * @param exp - expression being processed
   * @returns
   */
  private valueFromState(
    ent: ModelSchemaCategories,
    ids: PropertyRef
  ): Option<string> {
    const { propValues, propSchemas } = this.state;
    const { parentId, schemaId } = ids;

    // the Name cell is hard-coded
    if (isGridNameRowValue(parentId) && isGridNameColumnValue(schemaId)) {
      return 'Name';
    }
    // handle entity names (in name column)
    if (isGridNameColumnValue(schemaId) && parentId in this.state[ent]) {
      return this.state[ent][parentId].label;
    }

    // handle property names (in name row)
    if (isGridNameRowValue(parentId) && schemaId in propSchemas) {
      return propSchemas[schemaId].label;
    }

    // all other cells are property values
    return propValues?.[parentId]?.[schemaId];
  }

  fromCoords(
    ent: ModelSchemaCategories,
    row: number,
    col: number
  ): Option<Expression<Results, Options>> {
    const ref = coordsToRef(ent, row, col);
    let exp = this.getExp(ref);
    if (isSome(exp)) return exp;
  }

  /**
   * @param  ref - qualified reference
   */
  fromReference(ref: QualifiedRef): Option<Expression<Results, Options>> {
    return this.getExp(ref);
  }

  fromPropRef(
    ids: PropertyRef,
    ent?: ModelSchemaCategories,
    override = false
  ): Option<Expression<Results, Options>> {
    return this.fromReference(
      idsToRef(this.state, this.mappedTableSort, ids, ent, override)
    );
  }

  populateDependencies(ref: QualifiedRef) {
    const exp = this.getExp(ref);
    const deps = this.getDeps(ref);
    if (exp && deps) {
      this._cache.populateDependencies(ref);
      exp.unsatisfiedDeps = deps.dependencyLength;
    } else if (exp) {
      exp.unsatisfiedDeps = 0;
    }
  }
}
