import { apiGet, ARCHIVE, ENTITIES } from 'modules/common';
import { SORT } from 'utils/constants';
import { ARCHIVE_TYPE } from 'utils/constants-extra';
import { MESSAGES } from 'modules/ui/messages';
import { clearCollection } from 'modules/entities/collections';

export const clearArchives = (type) => (dispatch) => {
  dispatch(clearCollection(type));
  return { type: ARCHIVE.CLEAR };
};

export const setToNone = () => {
  return { type: ARCHIVE.CLEAR };
};

export const getProjectsArchives = (query = {}) => {
  const params = {
    ...query,
    // eslint-disable-next-line camelcase
    show_archived: true,
  };
  return apiGet({
    entity: 'projects',
    params,
    types: [ENTITIES.ACTION_SUCCESS, ARCHIVE.GET_FAILURE],
    error: MESSAGES.ERROR.GET.ARCHIVED_PROJECTS,
    meta: {
      schema: 'projects',
      collection: 'projectsArchives',
      archiveType: ARCHIVE_TYPE.PROJECTS,
    },
  });
};

export const getMediaArchives =
  (projectId = 0, query = {}) =>
  (dispatch, getState) => {
    const params = {
      ...query,
      project: projectId,
      // eslint-disable-next-line camelcase
      show_archived: true,
      // eslint-disable-next-line camelcase
      show_hidden: true,
    };

    params.limit = getState().archiveReducer.pagination.numberOfRows;

    dispatch(
      apiGet({
        entity: 'media',
        params,
        types: [ENTITIES.ACTION_SUCCESS, ARCHIVE.GET_FAILURE],
        error: MESSAGES.ERROR.GET.ARCHIVED_MEDIA,
        meta: {
          schema: 'media',
          collection: 'mediaArchives',
          archiveType: ARCHIVE_TYPE.MEDIA,
        },
      })
    );
  };

export const getIdsOnPage = (state) => {
  const { pagination } = state.archiveReducer;
  const startAt = pagination.startIndex;

  let itemsInState = null;
  if (state.archiveReducer.archiveType === ARCHIVE_TYPE.MEDIA) {
    itemsInState = state.collectionReducer.mediaArchives;
  } else if (state.archiveReducer.archiveType === ARCHIVE_TYPE.PROJECTS) {
    itemsInState = state.collectionReducer.projectsArchives;
  }

  if (itemsInState === null) {
    return [];
  }
  const itemsOnPage = itemsInState.ids.slice(
    startAt,
    startAt + pagination.numberOfRows
  );
  const idsOnPage = itemsOnPage.map((item) => item);
  return idsOnPage;
};

export const getSelectedOnPage = (state) => {
  const { selected } = state.archiveReducer;
  const fileIdsOnPage = getIdsOnPage(state);
  return fileIdsOnPage.filter((element) => selected.includes(element));
};

// export const getArchives = (cb, args) => (dispatch) => dispatch(cb(...args));

export const restore = (cb, args) => (dispatch) => dispatch(cb(...args));

export const selectMany = (selected) => ({
  type: ARCHIVE.SELECT_MANY,
  payload: selected,
});

export const selectNone = () => ({
  type: ARCHIVE.SELECT_NONE,
});

export const setSort = (sort) => ({
  type: ARCHIVE.SET_SORT,
  payload: sort,
});

export const toggleSelect = (id) => ({
  type: ARCHIVE.TOGGLE_SELECT,
  payload: id,
});

export const setPagination = (paginate) => async (dispatch) => {
  await dispatch({
    type: ARCHIVE.UPDATE.PAGINATION,
    payload: paginate,
  });
};

export const setScrolledRight = (pixels) => ({
  type: ARCHIVE.SCROLLED_RIGHT,
  payload: {
    scrolledRight: pixels,
  },
});

export const updateColumnWidths =
  (column, width, isProject = false) =>
  async (dispatch, getState) => {
    const columnOpts = getState().archiveReducer.columnOptions;
    if (isProject) {
      columnOpts[column].projectsArchivesColWidth = width;
    } else {
      columnOpts[column].colWidth = width;
    }
    dispatch({
      type: ARCHIVE.UPDATE.COLUMN_WIDTH,
      payload: { ...columnOpts },
    });
  };

export const computeRowWidth = () => (_, getState) => {
  const { columnOptions } = getState().archiveReducer;
  const colWidths = Object.values(columnOptions).map(
    (options) => options.colWidth
  );
  return colWidths.reduce(
    (accumulator, currentValue) => accumulator + parseInt(currentValue),
    0
  );
};

/**
 * @type {import('types').ArchiveReducer}
 */
export const initialState = {
  selected: [],
  sortBy: SORT.NAME_ASCENDING,
  archiveType: ARCHIVE_TYPE.NONE,
  pagination: {
    count: 0,
    numberOfRows: 10,
    rowCount: 0,
    startIndex: 0,
    total: 0,
  },
  columns: ['FileSelect', 'FileName', 'FileUser', 'FileType', 'FileDate'],
  columnOptions: {
    FileSelect: {
      value: 'selected',
      title: '',
      colWidth: '46px',
      projectsArchivesColWidth: '46px',
    },
    FileName: {
      value: 'name',
      title: 'Name',
      archives: true,
      colWidth: '360px',
      minimumWidth: 160,
      projectsArchivesColWidth: '360px',
    },
    FileUser: {
      value: 'created_by',
      title: 'Uploaded By',
      colWidth: '190px',
      minimumWidth: 150,
      projectsArchivesColWidth: '190px',
    },
    FileType: {
      value: 'type',
      title: 'Type',
      colWidth: '120px',
      minimumWidth: 60,
      projectsArchivesColWidth: '120px',
    },
    FileDate: {
      value: 'modified',
      title: 'Archive Date',
      colWidth: '180px',
      minimumWidth: 120,
      projectsArchivesColWidth: '180px',
    },
  },
  tableScroll: {
    scrolledRight: 0,
  },
};

/**
 * @type {import('redux').Reducer<import('types').ArchiveReducer>}
 */
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ENTITIES.ACTION_SUCCESS: {
      if (action.meta.archiveType) {
        return {
          ...state,
          archiveType: action.meta.archiveType,
        };
      } else {
        return state;
      }
    }
    case ARCHIVE.CLEAR: {
      return {
        ...state,
        selected: [],
        archiveType: ARCHIVE_TYPE.NONE,
      };
    }
    case ARCHIVE.SELECT_MANY: {
      return {
        ...state,
        selected: action.payload,
      };
    }
    case ARCHIVE.UPDATE.SELECTED: {
      return {
        ...state,
        selected: action.payload,
      };
    }
    case ARCHIVE.SELECT_NONE: {
      return {
        ...state,
        selected: [],
      };
    }
    case ARCHIVE.SET_SORT: {
      return {
        ...state,
        sortBy: action.payload,
      };
    }
    case ARCHIVE.TOGGLE_SELECT: {
      const selected = Array.from(state.selected);
      const index = selected.indexOf(action.payload);
      if (index < 0) {
        // selected does not contain, add
        selected.push(action.payload);
      } else {
        // selected does have, remove it
        selected.splice(index, 1);
      }
      return {
        ...state,
        selected: selected,
      };
    }
    case ARCHIVE.UPDATE.PAGINATION:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload,
        },
      };
    case ARCHIVE.UPDATE.COLUMN_WIDTH:
      return {
        ...state,
        columnOptions: { ...action.payload },
      };
    case ARCHIVE.SCROLLED_RIGHT:
      return {
        ...state,
        tableScroll: {
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

export default reducer;
