import { genericActionsForType } from 'modules/common/generic';
//Cursor SVGs
import cursorImage from 'assets/cursors/use-image-cursor-XD-26x30.svg';
import cursorNode from 'assets/cursors/Node-cursor-XD-16x16.svg';
import cursorEdge from 'assets/cursors/edge-cursor-XD-22X23.svg';
import cursorMove from 'assets/cursors/move-cursor.svg';
import cursorResizeEw from 'assets/cursors/ew-resize-cursor.svg';
import cursorPan from 'assets/cursors/grab-cursor-XD-16x14.png';
import cursorPanClicked from 'assets/cursors/grabbing-cursor-XD-16x14.svg';
import cursorDraw from 'assets/cursors/Pencil_1.svg';
import cursorResizeNesw from 'assets/cursors/nesw-resize-cursor.svg';
import cursorResizeNs from 'assets/cursors/ns-resize-cursor.svg';
import cursorResizeNwse from 'assets/cursors/nwse-resize-cursor.svg';
import { getIsMac } from './utils-extra';
export * from './constants-extra';
/*
 * App Constants
 */

export const MIN_GRID_SIZE = 16;
export const EXTRA_GRID_LINES = 2;

// TODO: this should be codified as an ecs component default
export const SELECTION_EMPHASIS = 0.8;

export const SITE_HEADER_HEIGHT = 42; //px

export const MAX_ACTIVE_UPLOADS = 3;

export const MAX_GUILD_NAME_LENGTH = 40;

// if a drag is smaller than 4px, treat it like a click
export const DRAG_FORGIVENESS = 4;

// temper variety of deltas across
// different wheel devices
export const MAX_WHEEL_DELTA = 20;

export const METRA = {
  MANIFEST: 'tenet3/metra/MANIFEST',
  PREFIX: 'tenet3/metra',

  //////////////////////////////////////////////////////////////////////////////
  // If you are modifiying VERSION, please consider whether changes are needed
  // to saveSelectionAs in saving.ts
  VERSION: '1.0.59',
  //////////////////////////////////////////////////////////////////////////////

  MODEL_CHECK_INTERVAL: 15 * 1000, //check every 15 seconds
  EXTENSION: '.metra',
  NOTE_EXTENSION: '.mnote',
  IMAGES: ['.tiff', '.bmp', '.gif', '.jpg', '.png', '.jpeg'],
};

//constants
export const ZOOM = {
  UPPER_BOUNDS: 10,
  UPPER_BOUNDS_NUMERICAL: 1000,
  LOWER_BOUNDS: 0.01,
  LOWER_BOUNDS_NUMERICAL: 1,
  RESTORE_DEFAULT_NUMERICAL: 100,
  RATE: 0.01,
  PREVIEW_UPPER: 1.5,
  PREVIEW_LOWER: 0.25,
  PREVIEW_RATE: 0.15,
  RESET: 'zoom-reset',
  SELECTION: 'zoom-selection',
};

export const MOUSE_BUTTON = {
  LEFT: 0,
  MIDDLE: 1,
  RIGHT: 2,
  BACK: 3,
  FORWARD: 4,
};

/**
 * @readonly
 * @const {string}
 */
export const ASSET_TYPE = {
  /** @type {import('types').AssetType} */
  IMAGE: 'image',
  /** @type {import('types').AssetType} */
  PRESET: 'preset',
};

export const ASSET_PRESETS = ['default', 'triangle', 'square'];

export const TOOL = {
  SELECT: 'tool/SELECT',
  FILES: 'tool/FILES',
  NODE: 'tool/NODE',
  DRAW: 'tool/DRAW',
  EDGE: 'tool/EDGE',
  MOVE: 'tool/MOVE',
  RESIZE: 'tool/RESIZE',
  PAN: 'tool/PAN',
  PAN_CLICKED: 'tool/PAN_CLICKED',
};

export const CELL = {
  MIN_WIDTH: 36,
  MAX_WIDTH: 500,
  MIN_HEIGHT: 16,
  MAX_HEIGHT: 500,
};

export const POPOUT_EDITOR = {
  WIDTH: 500,
  HEIGHT: 250,
};

export const IMAGE_DISPLAY_QUANTITY = {
  EIGHT: 8,
};

/**
 * @readonly
 * @const {string}
 */
export const WRITE_PERMISSIONS = {
  READONLY: 'READONLY',
  PROTECTED: 'PROTECTED',
  WRITE: 'WRITE',
};

export const GUILD_PERMISSIONS = {
  PROTECTED: 'PROTECTED',
  PRIVATE: 'PRIVATE',
  FULL_ACCESS: 'FULL_ACCESS',
};

/**
 * @readonly
 * @const {string}
 */
export const PROJECT_PERMISSIONS = {
  PRIVATE: 'project/PRIVATE',
  PROTECTED: 'project/PROTECTED',
  FULL_ACCESS: 'project/FULL_ACCESS',
};

/**
 * @readonly
 * @const {string}
 */
export const NODE_LAYOUT = {
  RINGS: 'Rings',
  GRID: 'Grid',
};

export const NOTE_FILE_NAME = `default${METRA.NOTE_EXTENSION}`;
/*
 * Pixi Constants
 */

/**
 * edge styles
 * @enum {number}
 */
export const EDGE_STYLE = {
  MIXED: -1,
  DEFAULT: 0,
  LINEAR: 1,
  RECTILINEAR: 2,
};

export const GRAPH = {
  VERTEX: 'graph/VERTEX',
  EDGE: 'graph/EDGE',
};

/** @const {string} */
export const SHAPE_EVENT = {
  DESTROY: 'event/shape/DESTROY',
  RENDER: 'event/shape/RENDER',
  UPDATE: 'event/shape/UPDATE',
};

/** @const {string} */
export const STAGE_EVENT = {
  REQUEST: {
    DRAW_GROUP_LENGTH: 'event/stage/request/draw/group/LENGTH',
    RELATIVEPOINT: 'event/stage/request/RELATIVEPOINT',
    CONFIG: 'event/stage/request/CONFIG',
    VIEWPORT: 'event/stage/request/VIEWPORT',
    CULL: 'event/stage/request/CULL',
  },
  ON: {
    UPDATE: 'event/stage/on/UPDATE',
  },
  UPDATE: {
    CONFIG: 'event/stage/update/CONFIG',
    SET_ZOOM: 'event/stage/update/SET_ZOOM',
    RESET_VIEW: 'event/stage/update/RESET_VIEW',
    CURSOR: 'event/stage/update/CURSOR',
    VIEWPORT: 'event/stage/update/VIEWPORT',
    EVENTS: 'event/stage/update/EVENTS',
    CONTROL_POINTS: 'event/stage/update/CONTROL_POINTS',
  },
};

/**
 *
 * @const {string} enum
 */
export const TABLE_EVENT = {
  DELETE: {
    ROWS: 'event/table/delete/ROW',
  },
  DESELECT: 'event/table/DESELECT',
  DESELECT_ALL: 'event/table/DESELECT_ALL',
  EDGE: {
    LABEL: {
      UPDATE: 'event/table/edge/label/UPDATE',
    },
  },
  MODEL_PROP: {
    LABEL: {
      UPDATE: 'event/table/modelProp/label/UPDATE',
    },
  },
  MODEL_CALC: {
    LABEL: {
      UPDATE: 'event/table/modelCalc/label/UPDATE',
    },
  },
  NODE: {
    LABEL: {
      UPDATE: 'event/table/node/label/UPDATE',
    },
  },
  SELECT: 'event/table/SELECT',
  SET: {
    LABEL: {
      UPDATE: 'event/table/set/label/UPDATE',
    },
    COLOR: {
      SHOW: 'event/table/set/color/SHOW',
      HIDE: 'event/table/set/color/HIDE',
      SHOW_ALL: 'event/table/set/color/SHOW_ALL',
      HIDE_ALL: 'event/table/set/color/HIDE_ALL',
    },
    CREATE: 'event/table/set/CREATE',
    ADD: 'event/table/set/ADD',
    REMOVE: 'event/table/SET/REMOVE',
    UPDATE: 'event/table/SET/UPDATE',
  },
  PATH: {
    LABEL: {
      UPDATE: 'event/table/path/label/UPDATE',
    },
  },
};

/** @const {string} */
export const MESSAGE = {
  EDGE_DELETE: 'message/edge/DELETE',
  NODE_DELETE: 'message/node/DELETE',
  SHAPE_RENDER: 'message/shape/RENDER',
  SHAPE_DELETE: 'message/shape/DELETE',
  SHAPE_MULTI_SELECT_ON: 'message/shape/MULTI_SELECT_ON',
  SHAPE_MULTI_SELECT_OFF: 'message/shape/SHAPE_MULTI_SELECT_OFF',
  SHAPE_GET_CONFIG: 'message/shape/get/CONFIG',
  SHAPE_GET_EXTENTS: 'message/shape/get/EXTENTS',
  SHAPE_GET_BOUNDS: 'message/shape/get/BOUNDS',
  SHAPE_GET_GRAPHIC: 'message/shape/get/GRAPHIC',
  SHAPE_MOVE_BY_DELTA: 'message/shape/move/by/DELTA',
  SHAPE_SELECT: 'message/shape/SHAPE_SELECT',
  SHAPE_SET_SELECT: 'message/shape/SHAPE_SET_SELECT',
  SHAPE_SET_VISIBLE: 'message/shape/set/VISIBLE',
};

/**
 * possible keys enum
 * @readonly
 * @const {string}
 */
export const KEY = {
  ALT: 'Alt',
  ALT_LEFT: 'AltLeft',
  ALT_RIGHT: 'AltRight',
  ARROW_DOWN: 'ArrowDown',
  ARROW_LEFT: 'ArrowLeft',
  ARROW_RIGHT: 'ArrowRight',
  ARROW_UP: 'ArrowUp',
  BACKSPACE: 'Backspace',
  B: 'b',
  C: 'c',
  CTRL: 'Control',
  CTRL_LEFT: 'ControlLeft',
  CTRL_RIGHT: 'ControlRight',
  DELETE: 'Delete',
  E: 'e',
  ENTER: 'Enter',
  ESCAPE: 'Escape',
  I: 'i',
  L: 'l',
  META: 'Meta',
  META_LEFT: 'MetaLeft',
  META_RIGHT: 'MetaRight',
  N: 'n',
  OS_LEFT: 'OSLeft',
  OS_RIGHT: 'OSRight',
  P: 'p',
  R: 'r',
  S: 's',
  T: 't',
  SHIFT: 'Shift',
  SHIFT_LEFT: 'ShiftLeft',
  SHIFT_RIGHT: 'ShiftRight',
  SPACE: ' ',
  TAB: 'Tab',
  ZERO: '0',
  NINE: '9',
  V: 'v',
  X: 'x',
  Y: 'y',
  Z: 'z',
  MODIFIER: getIsMac() ? 'Meta' : 'Control',
};

export const SOURCE = {
  OBJECT: 'event/source/OBJECT',
  STAGE: 'event/source/STAGE',
};

export const DISTANCE_AXES = ['x', 'y'];

/*
 * REDUX CONSTANTS
 */

/**
 * various model action constants
 * @const {string}
 */
export const MODEL = {
  SET_MODEL_VERSION_ID: 'model/version_id/SET',
  SET_DIRTY: 'model/SET_DIRTY',
  SET_INITIALIZED: 'model/SET_INITIALIZED',
  SET_IS_MODEL_LOADING: 'model/SET_IS_MODEL_LOADING',
  SET_IS_VERIFYING_MODEL_VERSION: 'model/SET_IS_VERIFYING_MODEL_VERSION',
  COLLAPSED: {
    CREATE: 'model/collapsed/CREATE',
    DELETE: 'model/collapsed/DELETE',
  },
  SHOW_CONTEXT_MENU: 'model/context-menu/SHOW',
  HIDE_CONTEXT_MENU: 'model/context-menu/HIDE',
  EDGE_NODES_PUSH: 'model/edge/nodes/PUSH',
  EDGE_NODES_CLEAR: 'model/edge/nodes/CLEAR',
  FILES: {
    SET_GRID: 'model/file/set/GRID',
    SET_EDIT_MODE: 'model/file/set/EDIT_MODE',
    SET_SELECTED_FILES: 'model/file/set/SELECTED_FILES',
    SET_SHAPES_TO_REVERT: 'model/file/set/SHAPES_TO_REVERT',
    TOGGLE_DELETE_IMAGE_MODAL: 'model/files/toggle/DELETE_IMAGE_MODAL',
  },
  FOCUS_MPT: 'model/mpt/FOCUS',
  GET_FAILURE: 'model/get/FAILURE',
  GET_SUCCESS: 'model/get/SUCCESS',
  GET_DATA_FAILURE: 'model/get/data/FAILURE',
  GET_DATA_SUCCESS: 'model/get/data/SUCCESS',
  GRAPH_UPDATE: 'model/graph/UPDATE',
  HISTORY_REMEMBER: 'model/history/REMEMBER',
  INCOMPATIBLE: 'model/INCOMPATIBLE',
  INIT: 'model/INIT',
  KDTREE_BUILD: 'model/kdtree/BUILD',
  KDTREE_INSERT: 'model/kdtree/INSERT',
  LOAD_FAILURE: 'model/load/FAILURE',
  LOAD_SUCCESS: 'model/load/SUCCESS',
  LOAD_CANCELLATION: 'model/load/CANCELLATION',
  MENU_LAUNCH_LOCATION: 'model/menu/LOCATION',
  MOUSE_UPDATE: 'model/mouse/UPDATE',
  MOUSE_LOCATION: 'model/mouse/LOCATION',
  MOVE_BEGIN: 'model/move/BEGIN',
  MOVE_END: 'model/move/END',
  NOT_FOUND: 'model/NOT_FOUND',
  PAN_BEGIN: 'model/pan/BEGIN',
  PAN_END: 'model/pan/END',
  OBJECT_DESELECT: 'model/object/DESELECT',
  OBJECTS_DESELECT: 'model/objects/DESELECT',
  OBJECTS_DESELECT_ALL: 'model/objects/DESELECT_ALL',
  OBJECTS_DESELECT_TYPE: 'model/objects/DESELECT_TYPE',
  OBJECTS_DESELECT_EXCEPT: 'model/objects/DESELECT_EXCEPT',
  OBJECTS_QUIESCE: 'model/objects/QUIESCE',
  OBJECTS_SELECT: 'model/objects/SELECT',
  OBJECTS_SELECTED_NODE_COUNT: 'model/objects/SELECTED_NODE_COUNT',
  OBJECTS_SELECTED_EDGE_COUNT: 'model/objects/SELECTED_EDGE_COUNT',
  OBJECTS_SELECTED_ONLY_DEFAULT_NODE:
    'model/objects/SELECTED_ONLY_DEFAULT_NODE',
  OBJECTS_UPDATE: 'model/objects/UPDATE',
  PENDING_EDGE_BEGIN: 'model/pending-edge/BEGIN',
  PENDING_EDGE_END: 'model/pending-edge/END',
  PIXI_DESTROYED: 'model/pixi/UNLOADED',
  PIXI_INIT: 'model/pixi/INIT',
  PIXI_LOADED: 'model/pixi/LOADED',
  PIXI_READY: 'model/pixi/READY',
  PIXI_RESIZE: 'model/pixi/RESIZE',
  PIXI_RESIZE_COMPLETE: 'model/pixi/RESIZE_COMPLETE',
  SAVE_FAILURE: 'model/save/FAILURE',
  STAGE_UPDATE: 'model/stage/UPDATE',
  HIDE_SET_COLOR: 'model/sets/HIDE_SET_COLOR',
  SHOW_SET_COLOR: 'model/sets/SHOW_SET_COLOR',
  HIDE_ALL_SET_COLORS: 'model/sets/HIDE_ALL_SET_COLORS',
  SHOW_ALL_SET_COLORS: 'model/sets/SHOW_ALL_SET_COLORS',
  TOOL_SET: 'model/tool/SET',
  SEND_LAYER_BACKWARD: 'model/layer/send/BACKWARD',
  SEND_LAYER_FORWARD: 'model/layer/send/FORWARD',
  SEND_LAYER_TO_BACK: 'model/layer/send/TO_BACK',
  SEND_LAYER_TO_FRONT: 'model/layer/send/TO_FRONT',
  SEND_SHAPE_TO_FOREGROUND: 'model/shape/send/TO_FOREGROUND',
  SEND_SHAPE_TO_BACKGROUND: 'model/shape/send/TO_BACKGROUND',
  SET_FILTER_OPACITY: 'model/SET_FILTER_OPACITY',
  SET_NAME: 'model/SET_NAME',
  SET_SCRIPTS: 'model/scripts/SET',
  SET_NODE_DEFAULT_LAYOUT: 'model/SET_NODE_DEFAULT_LAYOUT',
  SET_VALID_CLIPBOARD_CELL_RANGE_TYPE:
    'model/SET_VALID_CLIPBOARD_CELL_RANGE_TYPE',
  SET_COPYING: 'model/SET_COPYING',
  SET_CUTTING: 'model/SET_CUTTING',
  SET_DRAWING_STROKE_WIDTH: 'model/SET_DRAWING_STROKE_WIDTH',
  SET_DRAWING_STROKE_COLOR: 'model/SET_DRAWING_STROKE_COLOR',
  SET_DRAWING_STROKE_ALPHA: 'model/SET_DRAWING_STROKE_ALPHA',
  SET_DRAWING_FILL_COLOR: 'model/SET_DRAWING_FILL_COLOR',
  SET_DRAWING_FILL_ALPHA: 'model/SET_DRAWING_FILL_ALPHA',
  SET_DRAWING_MODE: 'model/SET_DRAWING_MODE',
  SET_LABEL_FONT_SIZE: 'model/SET_LABEL_FONT_SIZE',
  SET_PASTING: 'model/SET_PASTING',
  SET_REPLACE_NODE_FILE: 'model/SET_REPLACE_NODE_FILE',
  SET_SELECTION_EMPHASIS: 'model/SET_SELECTION_EMPHASIS',
  SET_TEXT_COLOR: 'model/SET_TEXT_COLOR',
  SET_TEXT_ALPHA: 'model/SET_TEXT_ALPHA',
  SET_TEXT_STROKE_ALPHA: 'model/SET_TEXT_STROKE_ALPHA',
  SET_TEXT_STROKE_COLOR: 'model/SET_TEXT_STROKE_COLOR',
  SET_TEXT_STROKE_THICKNESS: 'model/SET_TEXT_STROKE_THICKNESS',
  SET_TEXT_FONT_SIZE: 'model/SET_TEXT_FONT_SIZE',
  SET_IS_ON_CANVAS: 'model/SET_IS_ON_CANVAS',
  SHOW_PROPS_PANEL: 'model/show/PROPS_PANEL',
  HIDE_PROPS_PANEL: 'model/hide/PROPS_PANEL',
  TOGGLE_PROPS_PANEL: 'model/toggle/PROPS_PANEL',
  SHOW_EXITING_SAVE_MODAL: 'model/show/EXITING_SAVE_MODAL',
  SHOW_FILES_PANEL: 'model/show/FILES_PANEL',
  HIDE_FILES_PANEL: 'model/hide/FILES_PANEL',
  SET_STYLE_PANEL_ACTIVE_TYPE: 'model/panel/style/SET_ACTIVE_TYPE',
  SHOW_DEL_SET_CONFIRMATION_MODAL: 'model/show/DEL_SET_CONFIRMATION_MODAL',
  HIDE_DEL_SET_CONFIRMATION_MODAL: 'model/hide/DEL_SET_CONFIRMATION_MODAL',
  TOGGLE_FILTER_IMAGES: 'model/toggle/FILTER_IMAGES',
  TOGGLE_IMAGE_FILES_PANEL: 'model/toggle/IMAGE_FILES_PANEL',
  TOGGLE_LOCK_BACKGROUND: 'model/toggle/lock/BACKGROUND',
  TOGGLE_MAKE_BACKGROUND: 'model/toggle/make/BACKGROUND',
  TOGGLE_DARK_MODE: 'model/toggle/DARK_MODE',
  TOGGLE_GRID_MENU: 'model/toggle/GRID_MENU',
  TOGGLE_FILTER_OVERLAY: 'model/toggle/FILTER_OVERLAY',
  TOGGLE_FILTERS_FLYOUT: 'model/toggle/FILTERS_FLYOUT',
  TOGGLE_SHOW_FILES_VIEW: 'model/toggle/SHOW_FILES_VIEW',
  UPDATE_MODEL: 'model/UPDATE_MODEL',
  UPLOAD_FAILURE: 'model/upload/FAILURE',
  UPLOAD_SUCCESS: 'model/upload/SUCCESS',
  VIEWPORT_UPDATE: 'model/viewport/update',
  SET_NODE_MEDIA_FILE: 'model/panel/media_file/SET',
  SET_IS_SEARCHING: 'model/set/IS_SEARCHING',
  SET_SELECTED_FILTER: 'model/SET_SELECTED_FILTER',
  SET_SHOW_NODE_LABELS: 'model/shape/SET_SHOW_NODE_LABELS',
  SET_SHOW_NAME_MODAL: 'model/shape/SET_SHOW_NAME_MODAL',
  SET_NODE_LABELS_VISIBILITY: 'model/shape/SET_NODE_LABELS_VISIBILITY',
  SET_SHOW_EDGE_LABELS: 'model/shape/SET_SHOW_EDGE_LABELS',
  SET_SHOW_HOVER_FLYOUT: 'model/shape/SET_SHOW_HOVER_FLYOUT',
  SET_SHOW_READ_ONLY_WARNING_FLYOUT:
    'model/shape/SET_SHOW_READ_ONLY_WARNING_FLYOUT',
  SET_SHOW_SAVE_FILTER_FLYOUT: 'model/shape/SET_SHOW_SAVE_FILTER_MODAL',
  SET_SHOW_UNSAVED_CHANGES: 'model/shape/SET_SHOW_UNSAVED_CHANGES',
  SET_SHOW_LOCKED_MODEL_REFRESH: 'model/SET_SHOW_LOCKED_MODEL_REFRESH',
  SET_EDGE_LABELS_VISIBILITY: 'model/shape/SET_EDGE_LABELS_VISIBILITY',
  SET_MODEL_LIBRARY_PATH: 'model/library/SET_MODEL_LIBRARY_PATH',
  SET_MODEL_PARENT_FOLDER: 'model/library/SET_MODEL_PARENT_FOLDER',
  SET_REASSIGNING_EDGE_ID: 'model/SET_REASSINGING_EDGE_ID',
  SET_TABLE_ITEMS: 'model/SET_TABLE_ITEMS',
  SET_PROTECTED: 'model/SET_PROTECTED',
  SET_READ_ONLY: 'model/SET_READ_ONLY',
  SET_IS_OLDER_MODEL: 'model/SET_IS_OLDER_MODEL',
  SET_ACKNOWLEDGE_OLDER_MODEL: 'model/SET_ACKNOWLEDGE_OLDER_MODEL',
  SET_SCRIPTS_CONTENT: 'model/SET_SCRIPTS_CONTENT',
  SET_SCRIPTS_FILE_ID: 'model/SET_SCRIPTS_FILE_ID',
  SEARCH_RESULTS: {
    INIT: 'model/search-results/INIT',
    SET: 'model/search-results/SET',
    CLEAR: 'model/search-results/CLEAR',
  },
  SEARCH_BAR_FOCUSED: 'model/search/SEARCH_BAR_FOCUSED',
  CLOSE_MPT_GRID_POPOUT: 'model/CLOSE_MPT_GRID_POPOUT',
  TOGGLE_MPT_GRID_POPOUT: 'model/TOGGLE_MPT_GRID_POPOUT',
  CHANGE_ALL_SETS_ALPHA: 'model/CHANGE_ALL_SETS_ALPHA',
  CHANGE_ALL_NODES_ALPHA: 'model/CHANGE_ALL_NODES_ALPHA',
  CHANGE_ALL_EDGES_ALPHA: 'model/CHANGE_ALL_EDGES_ALPHA',
  TOGGLE_IMAGE_MODAL: 'model/toggle/IMAGE_MODAL',
  CHANGE_NODE_IMAGE: 'model/CHANGE_NODE_IMAGE',
  REVERT_NODE_IMAGE: 'model/REVERT_NODE_IMAGE',
  ALL_SETS_VISIBLE: 'model/ALL_SETS_VISIBLE',
  SET_RECENT_COLORS: 'model/SET_RECENT_COLORS',
  SHAPE_STYLE: 'model/NODE_SHAPE_TYPE',
  ARROWHEAD_TYPE: 'model/ARROWHEAD_TYPE',
  UPDATE_SELECTION: 'model/UPDATE_SELECTION',
  WAYPOINT_STYLE: 'model/WAYPOINT_STYLE',
  RESET: 'model/RESET',
};

export const MODEL_LOCK = {
  UPDATE: 'model/lockedModels/UPDATE',
  DELETE: 'model/lockedModels/DELETE',
  DELETE_SUCCESS: 'modelLock/DELETE/SUCCESS',
  GET_SUCCESS: 'modelLock/GET/SUCCESS',
  PUT_SUCCESS: 'modelLock/PUT/SUCCESS',
  DELETE_FAILURE: 'modelLock/DELETE/FAILURE',
  GET_FAILURE: 'modelLock/GET/FAILURE',
  PUT_FAILURE: 'modelLock/PUT/FAILURE',
  GENERAL_ERROR: 'modelLock/ERROR',
};

/** @readonly @type {['initialize'] as const} */
const _init = ['INITIALIZE'];

export const SELECTION_FILTER = {
  ADD_FILTER: 'model/selectionFilter/ADD_FILTER',
  DELETE_FILTER: 'model/selectionFilter/DELETE_FILTER',
  DELETE_SHAPES_FROM_FILTERS:
    'model/selectionFilter/DELETE_SHAPES_FROM_FILTERS',
  INIT: 'model/selectionFilter/INIT',
};

export const FILTER_SORT = {
  UPDATE: 'model/filterSort/ADD_FILTER',
  INIT: 'model/filterSort/INIT',
};

export const EDGE_COLUMNS = {
  INIT: 'model/EDGE_COLUMNS/INIT',
};

/**
 * The node action types (enum).
 */
export const NODE = genericActionsForType('model/nodes', _init);

/**
 * The modelProp action types (enum).
 */
export const MODEL_PROP = genericActionsForType('model/modelProps', _init);

/**
 * The modelCalc action types (enum).
 */
export const MODEL_CALC = genericActionsForType('model/modelCalcs', _init);

/**
 * The edge action types (enum).
 */
export const EDGE = genericActionsForType('model/edge', _init);

/**
 * The drawing action types (enum).
 */
export const DRAWING = genericActionsForType('model/DRAWING', _init);

/**
 * The text action types (enum).
 */
export const TEXT = genericActionsForType('model/TEXT', _init);

/**
 * The background action types (enum).
 */
export const BACKGROUND_IMAGE = genericActionsForType(
  'model/BACKGROUND_IMAGE',
  _init
);

/** @readonly @type {['init_shapes', 'update_properties', 'batch_update_properties'] as const} */
const _pixi = ['init_shapes', 'update_properties', 'batch_update_properties'];

/**
 * The pixi action types (enum).
 */
export const PIXI_ACTION = genericActionsForType('pixi', _pixi);

/**
 * The property value action types
 */
export const PROPERTY_VALUE = genericActionsForType(
  'model/propertyValue',
  _init
);

/**
 * The property schema action types (enum).
 */
export const PROPERTY_SCHEMA = genericActionsForType(
  'model/propertySchema',
  _init
);

/**
 * @readonly
 * @const {string}
 */
export const PROPERTY_SELECT = {
  REMOVE: 'property/select/REMOVE',
  CLEAR: 'property/select/CLEAR',
  SELECT_MULTIPLE: 'property/select/SELECT_MULTIPLE',
  SET: {
    CELLS: 'property/select/set/CELLS',
    SELECTION: 'property/select/set/SELECTION',
    FILTER_MAPPING: 'property/select/set/FILTER_MAPPING',
    COORDS: 'property/select/set/COORDS',
    ACTIVE: 'property/select/set/ACTIVE',
    ANCHOR: 'property/select/set/ANCHOR',
    DROP_ANCHOR: 'property/select/set/DROP_ANCHOR',
    EDITING: 'property/select/set/EDITING',
  },
  STEP_ANCHOR: {
    DOWN: 'property/select/STEP_ANCHOR/DOWN',
    LEFT: 'property/select/STEP_ANCHOR/LEFT',
    RIGHT: 'property/select/STEP_ANCHOR/RIGHT',
    UP: 'property/select/STEP_ANCHOR/UP',
  },
};

/**
 * The set action types (enum).
 */
export const SET = genericActionsForType('model/set', _init);

/*
 * ERRORS
 */

export const ERROR = {
  MODEL: {
    PIXI: {
      EVENT: 'error/model/pixi/EVENT',
    },
  },
};

/*
 * LAYERS
 */

/**
 * Layer enum
 * @readonly
 * @const {number} layer
 */
export const LAYER = {
  DECORATOR_UNDERLAY: 0,
  SNAP_GRID: 0.5,
  BACKGROUND: 1,
  MULTI_SELECT: 2,
  SHAPE: 2.5,
  //////////////////////////////////////////////////////////////////////////////
  // We need to keep EDGE and EDGE_ARROW separate for performance reasons.
  // It does leads to buggy display situations, where the segment and arrow of a
  // single edge could end up with the segment of a different edge in between
  // them.
  // BUT - WITHOUT these being separate, if we have 10k edges that have the same
  // render-texture on their segments, and the same render texture on their
  // arrows, we'd end up with 20k clumps of display-items.  If we separate them
  // we only end up with 2 clumps of display-items.
  EDGE: 3,
  EDGE_ARROW: 3.6,
  //////////////////////////////////////////////////////////////////////////////
  NODE: 5,
  COLLAPSED_SET: 6,
  LABEL: 7,
  FOREGROUND: 8,
  DEFAULT: 9,
  EDGE_WAYPOINT: 9.4,
  DECORATOR_OVERLAY: 10,
  CONTROL_POINT: 10.5,
  DECORATOR_HIDDEN: 11,
};

/**
 * TIMEOUTS
 */

export const TIMEOUTS = {
  AUTOSAVE: {
    LONG: 30000,
    SHORT: 3000,
  },
  INPUT: 500,
  INPUT_EXTENDED: 1000,
  PERCEPTION: 200,
  RENDER_THROTTLE: 16, // run at ~60fps,
  CULL_THROTTLE: 250,
  SCROLL_INTERVAL: 50,
  DOUBLE_CLICK: 500,
};

/*
 * LOCATIONS
 * @enum {string}
 */
export const LOCATION = {
  ADMIN: 'location/ADMIN',
  CHART: 'location/CHART',
  GUILDS: 'location/GUILDS',
  LIBRARY: 'location/LIBRARY',
  MODEL: 'location/MODEL',
  PROJECTS: 'location/PROJECTS',
  USERS: 'location/USERS',
};

/**
 * sorting definitions
 * @enum {Record<string, import('types').SortDef>}
 */
export const SORT = {
  NONE: {
    label: '',
    key: 'sort/NONE',
    query: { ordering: '' },
  },
  ACTIVITY_NEWEST: {
    label: 'Latest Activity',
    key: 'sort/activity/NEWEST',
    query: { ordering: '-activity_at' },
  },
  ACTIVITY_OLDEST: {
    label: 'Oldest Activity',
    key: 'sort/activity/OLDEST',
    query: { ordering: 'activity_at' },
  },
  GUILD_NAME_ASCENDING: {
    label: 'Guild Name Ascending',
    key: 'sort/guild/NAME_ASCENDING',
    query: { ordering: 'guild__name' }, // Two underscores
  },
  GUILD_NAME_DESCENDING: {
    label: 'Guild Name Descending',
    key: 'sort/guild/NAME_DESCENDING',
    query: { ordering: '-guild__name' }, // Two underscores
  },
  MODIFIED_NEWEST: {
    label: 'Latest Modified',
    key: 'sort/modified/NEWEST',
    query: { ordering: '-modified' },
  },
  MODIFIED_OLDEST: {
    label: 'Oldest Modified',
    key: 'sort/modified/OLDEST',
    query: { ordering: 'modified' },
  },
  NEWEST: {
    label: 'Newest',
    key: 'sort/created/NEWEST',
    query: { ordering: '-created' },
  },
  OLDEST: {
    label: 'Oldest',
    key: 'sort/created/OLDEST',
    query: { ordering: 'created' },
  },
  NAME_ASCENDING: {
    label: 'Ascending',
    key: 'sort/name/ASCENDING',
    query: { ordering: 'name' },
  },
  NAME_DESCENDING: {
    label: 'Descending',
    key: 'sort/name/DESCENDING',
    query: { ordering: '-name' },
  },
  TYPE_ASCENDING: {
    label: 'Type Descending',
    key: 'sort/type/ASCENDING',
    query: { ordering: 'type' },
  },
  TYPE_DESCENDING: {
    label: 'Type Ascending',
    key: 'sort/type/DESCENDING',
    query: { ordering: '-type' },
  },
  DELETED_NEWEST: {
    label: 'Deleted Newest',
    key: 'sort/deleted/NEWEST',
    query: { ordering: '-archived_at' },
  },
  DELETED_OLDEST: {
    label: 'Deleted Oldest',
    key: 'sort/deleted/OLDEST',
    query: { ordering: 'archived_at' },
  },
  UPLOADED_BY_ASCENDING: {
    label: 'Uploaded By Ascending',
    key: 'sort/uploaded/by/ASC',
    query: { ordering: '-created_by' },
  },
  UPLOADED_BY_DESCENDING: {
    label: 'Uploaded By Descending',
    key: 'sort/uploaded/by/DESC',
    query: { ordering: 'created_by' },
  },
};

export const SORT_DIRECTION = {
  DEFAULT: -1,
  ASCENDING: 0,
  DESCENDING: 1,
};

export const MEDIA_TYPE = {
  FOLDER: 'FOLDER',
  FILE: 'FILE',
  MODEL: 'MODEL',
};

/*
 * TRANSLATORS
 */

//take an output of parseFileType and match to a MIME type
export const MIME_MAP = {
  BMP: 'image/bmp',
  C: 'text/c',
  H: 'text/h',
  PNG: 'image/png',
  JPG: 'image/jpg',
  JPEG: 'image/jpg',
  SVG: 'image/svg',
  GIF: 'image/gif',
  PDF: 'application/pdf',
  JSON: 'application/json',
  METRA: 'metra/graph',
  CHART: 'metra/chart',
  PROJECT: 'metra/project',
  TXT: 'text/plain',
  XML: 'text/xml',
  CSV: 'text/csv',
  RTF: 'text/rtf',
  MARKDOWN: 'text/markdown',
  MD: 'text/markdown',
  MP4: 'video/mp4',
  MOV: 'video/mp4', // 'video/quicktime' won't play in html5 <video> tag
  WEBM: 'video/webm',
  UNKNOWN: 'UNKNOWN',
};

//provides a simple predictable generalized down-select of MIME_MAP types
export const MIME_SIMPLE = {
  IMAGE: 'mime/simple/image',
  [MIME_MAP.BMP]: 'mime/simple/image',
  [MIME_MAP.PNG]: 'mime/simple/image',
  [MIME_MAP.C]: 'mime/simple/file',
  [MIME_MAP.H]: 'mime/simple/file',
  [MIME_MAP.JPG]: 'mime/simple/image',
  [MIME_MAP.JPEG]: 'mime/simple/image',
  [MIME_MAP.GIF]: 'mime/simple/image',
  DOCUMENT: 'mime/simple/document',
  [MIME_MAP.PDF]: 'mime/simple/document',
  [MIME_MAP.RTF]: 'mime/simple/document',
  METRA: 'mime/simple/metra',
  [MIME_MAP.METRA]: 'mime/simple/metra',
  [MIME_MAP.CHART]: 'mime/simple/metra',
  FILE: 'mime/simple/file',
  [MIME_MAP.JSON]: 'mime/simple/file',
  [MIME_MAP.SVG]: 'mime/simple/file',
  [MIME_MAP.TXT]: 'mime/simple/file',
  [MIME_MAP.UNKNOWN]: 'mime/simple/file',
  [MIME_MAP.XML]: 'mime/simple/file',
  [MIME_MAP.CSV]: 'mime/simple/file',
  [MIME_MAP.MARKDOWN]: 'mime/simple/file',
  [MIME_MAP.MD]: 'mime/simple/file',
  VIDEO: 'mime/simple/video',
  [MIME_MAP.MP4]: 'mime/simple/video',
  [MIME_MAP.WEBM]: 'mime/simple/video',
  NONE: 'mime/simple/none',
  [MIME_MAP.PROJECT]: 'mime/simple/none',
};

export const CELL_RANGE_FORMAT = 'application/x-metra-cell-range';

// Control Point Cardinal directions for Shapes
/**
 * @type {Object<string, string>}
 */
export const CONTROL_POINTS = {
  NW: 'control/point/NW',
  N: 'control/point/N',
  NE: 'control/point/NE',
  E: 'control/point/E',
  SE: 'control/point/SE',
  S: 'control/point/S',
  SW: 'control/point/SW',
  W: 'control/point/W',
};

export const CONTROL_POINT_PADDING = 6.0;

export const DRAWING_DISTANCE_THRESHOLD = 5;

/**
 * Maps different cursor modes <string to object>. Objects which are
 * strings are handled as CSS cursor values. Objects which are objects
 * are assumed to be dictionaries of values. Object which are functions are called instead of changing the CSS.
 * Default cursor values provided for `default` and `pointer` keys.
 *
 * For details, see https://pixijs.download/dev/docs/PIXI.interaction.InteractionManager.html#cursorStyles
 */
export const CURSOR_STYLES = {
  DEFAULT: 'default',
  POINTER: 'pointer',
  NODE: `url(${cursorNode}) 10 10, crosshair`,
  IMAGE: `url(${cursorImage}) 10 10, crosshair`,
  DRAW: `url(${cursorDraw}) 2 14, default`,
  EDGE: `url(${cursorEdge}) 10 10, default`,
  MOVE: `url(${cursorMove}) 10 10, default`,
  RESIZE_NESW: `url(${cursorResizeNesw}) 10 10, default`,
  RESIZE_NWSE: `url(${cursorResizeNwse}) 10 10, default`,
  RESIZE_NS: `url(${cursorResizeNs}) 10 10, default`,
  RESIZE_EW: `url(${cursorResizeEw}) 10 10, default`,
  PAN: `url(${cursorPan}) 10 10, default`,
  PAN_CLICKED: `url(${cursorPanClicked}) 10 10, default`,
  [CONTROL_POINTS.NW]: `url(${cursorResizeNwse}) 10 10, default`,
  [CONTROL_POINTS.N]: `url(${cursorResizeNs}) 10 10, default`,
  [CONTROL_POINTS.NE]: `url(${cursorResizeNesw}) 10 10, default`,
  [CONTROL_POINTS.E]: `url(${cursorResizeEw}) 10 10, default`,
  [CONTROL_POINTS.SE]: `url(${cursorResizeNwse}) 10 10, default`,
  [CONTROL_POINTS.S]: `url(${cursorResizeNs}) 10 10, default`,
  [CONTROL_POINTS.SW]: `url(${cursorResizeNesw}) 10 10, default`,
  [CONTROL_POINTS.W]: `url(${cursorResizeEw}) 10 10, default`,
};

/**
 * Maps the tool selection to the appropriate cursor for that tool.
 * We set the cursor for the stage (background)
 * and for the draw group (objects)
 */
export const TOOL_CURSOR_MAPPING = {
  [TOOL.SELECT]: {
    stage: CURSOR_STYLES.DEFAULT,
    drawGroup: CURSOR_STYLES.DEFAULT,
  },

  [TOOL.FILES]: {
    stage: CURSOR_STYLES.IMAGE,
    drawGroup: CURSOR_STYLES.DEFAULT,
  },

  [TOOL.DRAW]: {
    stage: CURSOR_STYLES.DRAW,
    drawGroup: CURSOR_STYLES.DRAW,
  },

  [TOOL.NODE]: {
    stage: CURSOR_STYLES.NODE,
    drawGroup: CURSOR_STYLES.DEFAULT,
  },

  [TOOL.EDGE]: {
    stage: CURSOR_STYLES.EDGE,
    drawGroup: CURSOR_STYLES.EDGE,
  },

  [TOOL.PAN]: {
    stage: CURSOR_STYLES.PAN,
    drawGroup: CURSOR_STYLES.PAN,
  },

  [TOOL.PAN_CLICKED]: {
    stage: CURSOR_STYLES.PAN_CLICKED,
    drawGroup: CURSOR_STYLES.PAN_CLICKED,
  },
};

export const EDGE_NODE_RELATION = {
  SOURCE: 'edge source node',
  DESTINATION: 'edge destination node',
  ORIENTATION_DIFF: 100,
};

// TODO: remove unused sizing when legacy system removed for ENABLE_ECS_ARCH
export const SIZING = {
  EDGE: {
    WIDTH: {
      DEFAULT: 4,
      MIN: 1,
      MAX: 12,
    },
  },
  NODE: {
    X: 43,
    Y: 43,
    MIN_SCALE: 0.1, //clamp scale to minimum 10%
    MAX_SCALE: 10000.0, // effectively no limit
  },
  COLLAPSED_SET: {
    X: 50,
    Y: 50,
    SET_LINE_WIDTH: 4,
  },
  LABEL: {
    Y_OFFSET: -8,
  },
  COLLAPSIBLE_SET_BTN: {
    GRAPHIC_SCALE: 0.25,
    Y_OFFSET: 28,
  },
  NODE_CIRCLE_ASSET: {
    // assets/circle.png size is 100x100 pixels
    // actual circle diameter is 87x87 pixels
    RADIUS: 47.5,
    // placement in center of control point derived from difference in
    //circle radius to actual size of the image plus additional 0.75 to center point
    CORNER_CONTROL_POINT_OFFSET: 7.25,
    SHORT_LENGTH_CONTROL_POINT_OFFSET: 5,
    // to place the set-rings right along the border of the node circle asset
    // was determined to fit along the border without creating gaps upon scaling
    SET_RING_OFFSET: 4.75,
  },
  MULTI_SELECT: {
    CORNER_CONTROL_POINT_DIVIDEND: 12,
  },
  BACKGROUND_IMAGE: {
    CORNER_CONTROL_POINT_OFFSET: 80,
  },
  SET_MEMBERSHIP_WIDTH: {
    // FIXME: legacy! use SET_RING_WIDTH instead
    NODE_CIRCLE: 10,
    NODE_IMAGE: 5,
  },
  SET_RING_WIDTH: 5,
  EDGE_ARROW: 10,
  EDGE_ARROW_SCALE: 1 / 18.0,
};

/**
 * @const {number}
 */
export const HEX_COLOR = {
  WHITE: 0xffffff,
  BLACK: 0x000000,
  SELECT_FILL: 0x48c6f4,
  SELECT_LINE: 0x0554ff,
  MULTI_SELECT_FILL: 0xeaeaeb,
  CONTROL_POINT: 0x48c6f4,
  NODE_DEFAULT: 0x33387b,
  EDGE_DEFAULT: 0x333333,
  QUAD_TREE_BOUNDARY: 0xff0000,
  NODE_LIGHT: 0x91b4ff,
  EDGE_LIGHT: 0xffffff,
  BACKGROUND_DARK: 0x151515,
  GRID_LINE: 0x888888,
};

/**
 * @const {string}
 */
export const HEX_STRING = {
  WHITE: '#ffffff',
  NODE_DEFAULT: '#33387b',
  EDGE_DEFAULT: '#333333',
  NODE_LIGHT: '#91b4ff',
  EDGE_LIGHT: '#ffffff',
  BACKGROUND_DARK: '#151515',
  BLACK: '#000000',
};

export const LAYOUT = {
  VERTICAL: 'mod-layout-vertical',
  HORIZONTAL: 'mod-layout-horizontal',
  MODEL_PADDING_X: 100,
  MODEL_PADDING_Y: 30,
};

export const SET_COLOR_OPTIONS = [
  '#ff00ee',
  '#00b35f',
  '#992636',
  '#ff6600',
  '#ccff00',
  '#9100d9',
  '#f23d3d',
  '#80a2ff',
  '#660044',
  '#cc8800',
  '#7fff91',
  '#ca79f2',
  '#74a653',
  '#ff8080',
  '#00f241',
  '#aa2db3',
  '#d9c36c',
  '#b35971',
  '#a8e6C0',
  '#3d6df2',
  '#cefff7',
  '#b23e2d',
  '#208079',
  '#ff8800',
  '#59b389',
  '#d9986c',
  '#ffee00',
  '#7f3300',
  '#40ffd9',
  '#ff80b3',
  '#4040ff',
  '#733960',
  '#ffc480',
  '#cc006d',
  '#336366',
  '#7d59b3',
  '#d9ad00',
  '#8c0070',
  '#ff0000',
  '#b35995',
  '#297ca6',
  '#a67f53',
  '#00734d',
  '#fff8c4',
  '#838c00',
  '#a65800',
  '#005fb3',
];

export const GRIDCELL = {
  EDIT: 'gridcell/edit',
};

export const DOM_DELTA_MODE = {
  PIXEL: 0,
  LINE: 1,
  PAGE: 2,
};

export const VERSION = {
  STATUS: {
    QUARANTINED: 'version/status/QUARANTINED',
    SAFE: 'version/status/SAFE',
    VIRUS: 'version/status/VIRUS',
  },
};

/* ECS ENTITY TAGS */

/**
 * @enum {string}
 */
export const ECS_TAG = {
  COLLAPSE_BTN: 'ecs/tag/COLLAPSE_BUTTON',
  NODE_LABELS_VISIBILITY: 'ecs/tag/node/label/VISIBILITY',
  EDGE_LABELS_VISIBILITY: 'ecs/tag/edge/label/VISIBILITY',
  HOVERED_ENTITY: 'ecs/tag/HOVERED_ENTITY',
  MOUSE: 'ecs/tag/MOUSE',
  MULTI_SELECT: 'ecs/tag/MULTI_SELECT',
  PENDING_EDGE: 'ecs/tag/PENDING_EDGE',
  SELECTOR: 'ecs/tag/SELECTOR',
};

/**
 * @const {string}
 */
export const ECS_GROUP = {
  COLLAPSED_SET: 'ecs/group/COLLAPSED_SET',
  DRAWINGS: 'ecs/group/DRAWINGS',
  EDGE_LABELS: 'ecs/group/edge/LABELS',
  EDGES: 'ecs/group/EDGES',
  BACKGROUNDS: 'ecs/group/BACKGROUNDS',
  NODE_LABELS: 'ecs/group/node/LABELS',
  NODES: 'ecs/group/NODES',
  SET: 'ecs/group/SETS',
  SHAPES: 'ecs/group/SHAPES',
  TEXT: 'ecs/group/TEXT',
};

export const CONTROL_POINT_GROUP = {
  CONTROL_POINTS: 'ControlPoints',
};

export const TEXT_RESOLUTION = 3;

export const ACCEPTED_CUI_NOTICE = 'accepted_cui_notice';

export const MATOMO_CATEGORY = {
  PROJECT_PAGE: 'Project Page',
  MODEL_EDITOR: 'Model Editor',
  ADMIN_PANEL: 'Admin Panel',
};

export const MATOMO_ACTION = {
  NEW_GUILD_BUTTON: 'New Guild Button',
  NEW_MODEL_BUTTON: 'New Model Button',
  NEW_FOLDER_BUTTON: 'New Folder Button',
  UPLOAD_FILES_BUTTON: 'Upload Files Button',
  MODELS_NAV_LINK: 'Models Nav Link',
  CREATE_NEW_MODEL_NAV_LINK: 'Create New Model Nav Link',
  ARCHIVES_NAV_LINK: 'Archives Nav Link',
  DELETE_PROJECT_LNIK: 'Delete Project Link',
  ADD_TAG_BUTTON: 'Add Tag Button',
  TAG_ICON_CLICK: 'Tag Icon Click',
  ARCHIVE_BUTTON: 'Archive Button',
  DELETE_BUTTON: 'Delete Button',
  RENAME_BUTTON: 'Rename Button',
  MOVE_BUTTON: 'Move Button',
  COPY_BUTTON: 'Copy Button',
  DOWNLOAD_BUTTON: 'Download Button',
  APPLY_GRAPH_LAYOUT: 'Apply Graph Layout',
  RUN_PATH_DISCOVERY: 'Run Path Discovery Button',
  RUN_K_NEIGHBORHOOD: 'Run K-Nearest Neighbors Button',
  RESET_PATH_DISCOVERY: 'Reset Path Discovery Button',
  ADVANCED_SEARCH_BUTTON: 'Advanced Search Button',
  EDIT_GUILD_BUTTON: 'Edit Guild Button',
  HELP_DROPDOWN: 'Help Drop Down',
  HELP_DROPDOWN_MENU: {
    HELP_DOCS: 'HelpDropDownMenu:HelpDocs',
    HOT_KEYS: 'HelpDropDownMenu:HotKeys',
    ADMIN_PANEL: 'HelpDropDownMenu:AdminPanel',
    ACCOUNT_SETTINGS: 'HelpDropDownMenu:AccountSettings',
    SUPPORT_CENTER: 'HelpDropDownMenu:SupportCenter',
    SIGN_OUT: 'HelpDropDownMenu:SignOut',
  },
  ADMIN_NAV: {
    ORG_LINK: 'AdminNav:OrgLink',
    GUILDS_LINK: 'AdminNav:GuildsLink',
    USERS_LINK: 'AdminNav:UsersLink',
  },
  MANAGE_ORG_API_KEYS: {
    CREATE: 'ManageOrgApiKeys:Create',
    DELETE: 'ManageOrgApiKeys:Delete',
    RENEW: 'ManageOrgApiKeys:Renew',
  },
  MANAGE_ORG_USERS: {
    TOOLBAR: {
      SHOW_ONLY_ENABLED_USERS: 'ManageOrgUsers:Toolbar:ShowOnlyEnabledUsers',
      DISABLE_USERS: 'ManageOrgUsers:Toolbar:DisableUsers',
      ENABLE_USERS: 'ManageOrgUsers:Toolbar:EnableUsers',
      ADD_USER: 'ManageOrgUsers:Toolbar:AddUser',
      SEARCH_FOR_USERS: 'ManageOrgUsers:Toolbar:SearchUsers',
    },
    MANAGE_USERS: 'ManageOrgUsers:ManageUser',
    EDIT_GUILD_MEMBERSHIPS: 'ManageOrgUsers:EditGuildMemberships',
  },
  MANAGE_GUILD_USERS: {
    REMOVE_USER: 'ManageGuildUsers:RemoveUser',
    ADD_USER: 'ManageGuildUsers:AddUser',
  },
  MENUBAR: {
    MODEL: {
      NEW: 'Menubar:Model:New',
      OPEN: 'Menubar:Model:Open',
      SAVE: 'Menubar:Model:Save',
      SAVE_AS: 'Menubar:Model:Save As',
      PERMISSIONS: {
        READONLY: 'Menubar:Model:Permissions:ReadOnly',
        PROTECTED: 'Menubar:Model:Permissions:Protected',
        READWRITE: 'Menubar:Model:Permissions:ReadWrite',
      },
      IMPORT_CSV: 'Menubar:Model:ImportCSV',
      EXPORT: {
        METRA_MODEL: 'Menubar:Model:Export',
        PROPERTIES_TABLE: 'Menubar:Model:Export:MPT_TO_CSV',
        MODEL_AS_PNG: 'Menubar:Model:Export:ModelAsPng',
      },
    },
    EDIT: {
      UNDO: 'Menubar:Edit:Undo',
      REDO: 'Menubar:Edit:Redo',
      DELETE_SELECTED_SHAPES: 'Menubar:Edit:DeleteSelectedShapes',
      SELECT_FILTER: 'Menubar:Edit:SelectFilter',
      GRAPH_LAYOUT: 'Menubar:Edit:GraphLayout',
      SELECTION_EMPHASIS: 'Menubar:Edit:SelectionEmphasis',
    },
    OBJECT: {
      CREATE_SET: 'Menubar:Object:CreateSet',
      ADD_TO_SET: 'Menubar:Object:AddToSet',
      REMOVE_FROM_SET: 'Menubar:Object:RemoveFromSet',
      TOGGLE_COLLAPSED_SET: 'Menubar:Object:ToggleCollapsedSet',
      DEFAULT_LAYOUT: {
        RINGS: 'Menubar:Object:DefaultLayout:Rings',
        GRID: 'Menubar:Object:DefaultLayout:Grid',
      },
      SEND_TO_FOREGROUND: 'Menubar:Object:SendToForeground',
      SEND_TO_BACKGROUND: 'Menubar:Object:SendToBackground',
      BRING_TO_FRONT: 'Menubar:Object:BringToFront',
      BRING_FORWARD: 'Menubar:Object:BringForward',
      SEND_BACKWARD: 'Menubar:Object:SendBackward',
      SEND_TO_BACK: 'Menubar:Object:SendToBack',
    },
    VIEW: {
      ZOOM_IN: 'Menubar:View:ZoomIn',
      ZOOM_OUT: 'Menubar:View:ZoomOut',
      FIT_TO_MODEL: 'Menubar:View:FitToModel',
      FIT_TO_SELECTION: 'Menubar:View:FitToSelection',
      SHOW_EDGES: 'Menubar:View:ShowEdges',
      SHOW_EDGE_NAMES: 'Menubar:View:ShowEdgeNames',
      SHOW_NODES: 'Menubar:View:ShowNodes',
      SHOW_NODE_NAMES: 'Menubar:View:ShowNodeNames',
      SHOW_SET_COLORS: 'Menubar:View:ShowSetColors',
      LABEL_SIZE: 'Menubar:View:LabelSize',
    },
    PROPERTIES_TABLE: {
      DELETE_COLUMNS: 'Menubar:PropertiesTable:DeleteColumns',
      DELETE_ROWS: 'Menubar:PropertiesTable:DeleteRows',
      DUPLICATE_ROWS: 'Menubar:PropertiesTable:DuplicateRows',
      RESIZE_ALL_CELLS: 'Menubar:PropertiesTable:ResizeAllCells',
      YAML_FORMAT: 'Menubar:PropertiesTable:YAMLFormat',
      CREATE_SET: 'Menubar:PropertiesTable:CreateSet',
    },
    ANALYSIS: {
      PATH_DISCOVERY: 'Menubar:Analysis:PathDiscovery',
      K_NEIGHBORHOOD: 'Menubar:Analysis:KNeighborhood',
    },
    FILTER: {
      FILTERED_SELECTED_COLUMNS: 'Menubar:Filter:FilteredSelectedColumns',
      CLEAR_FILTERED_COLUMNS: 'Menubar:Filter:ClearFilteredColumns',
      FILTER_EMPTY_COLUMNS: 'Menubar:Filter:FilterEmptyColumns',
      FILTER_IMAGES: 'Menubar:Filter:FilterImages',
      APPLY_FILTER: 'Menubar:Filter:ApplyFilter',
      CLEAR_FILTER: 'Menubar:Filter:ClearFilter',
      FILTER_OPACITY: 'Menubar:Filter:FilterOpacity',
      DISPLAY_FILTER_OVERLAY: 'Menubar:Filter:DisplayFilterOverlay',
    },
  },
  TOOLBAR: {
    TOGGLE_FILES_VIEW: 'Toolbar:ToggleFilesView',
    UNDO: 'Toolbar:Undo',
    REDO: 'Toolbar:Redo',
    SELECT_TOOL: 'Toolbar:SelectTool',
    PAN_TOOL: 'Toolbar:PanTool',
    NODE_TOOL: 'Toolbar:NodeTool',
    DRAW_TOOL: 'Toolbar:DrawTool',
    EDGE_TOOL: 'Toolbar:EdgeTool',
    IMAGES_BUTTON: 'Toolbar:ImagesButton',
    STYLE_PANEL_BUTTON: 'Toolbar:StylePanelButton',
    LOCK_IMAGES: 'Toolbar:LockImages',
    EDGE_DIRECTION: 'Toolbar:EdgeDirection',
    EDGE_REASSIGN: 'Toolbar:EdgeReassign',
    DRAWING_STROKE_WIDTH: 'Toolbar:DrawingStrokeWidth',
    DRAWING_STROKE_COLOR: 'Toolbar:DrawingStrokeColor',
    DRAWING_FILL_COLOR: 'Toolbar:DrawingFillColor',
    DRAWING_LINE_MODE: 'Toolbar:DrawingLineMode',
    DRAWING_BOX_MODE: 'Toolbar:DrawingBoxMode',
    DRAWING_TEXT_MODE: 'Toolbar:DrawingTextMode',
    TEXT_FONT_SIZE: 'Toolbar:TextFontSize',
    COLOR_TEXT: 'Toolbar:ColorText',
    TEXT_STROKE_COLOR: 'Toolbar:TextStrokeColor',
    TEXT_STROKE_SIZE: 'Toolbar:TextStrokeSize',
    SAVE_FILTER: 'Toolbar:SaveFilter',
    APPLY_FILTER: 'Toolbar:ApplyFilter',
    CLEAR_FILTER: 'Toolbar:ClearFilter',
    TABLE_VIEW: 'Toolbar:TableView',
    CELL_SIZE: 'Toolbar:CellSize',
    POP_OUT_MPT: 'Toolbar:PopOutMPT',
    TOGGLE_MPT_BUTTON: 'Toolbar:ToggleMPTButton',
    CELL_SIZE_MENU: {
      SMALL: 'Toolbar:CellSizeMenu:Small',
      MEDIUM: 'Toolbar:CellSizeMenu:Medium',
      LARGE: 'Toolbar:CellSizeMenu:Large',
      XLarge: 'Toolbar:CellSizeMenu:XLarge',
    },
  },
  FOOTER: {
    EDIT_MODEL_NAME: 'Footer:EditModelName',
    ZOOM: 'Footer:Zoom',
  },
  CONTEXT_MENU: {
    OPEN_QPV: 'ContextMenu:OpenQPV',
    FILTER_PROPERTIES_TABLE: 'ContextMenu:FilterPropertiesTable',
    REVERSE_EDGE_DIRECTION: 'ContextMenu:ReverseEdgeDirection',
    REASSIGN_EDGE: 'ContextMenu:ReassignEdge',
    CREATE_WAYPOINT: 'ContextMenu:CreateWaypoint',
    CREATE_SET: 'ContextMenu:CreateSet',
    ADD_TO_SET: 'ContextMenu:AddToSet',
    REMOVE_FROM_SET: 'ContextMenu:RemoveFromSet',
    SELECT_ALL: 'ContextMenu:SelectAll',
    SELECT_FILTER: 'ContextMenu:SelectFilter',
    ARRANGE: {
      SEND_TO_FOREGROUND: 'ContextMenu:Arrange:SendToForeground',
      SEND_TO_BACKGROUND: 'ContextMenu:Arrange:SendToBackground',
      BRING_TO_FRONT: 'ContextMenu:Arrange:BringToFront',
      BRING_FORWARD: 'ContextMenu:Arrange:BringForward',
      SEND_BACKWARD: 'ContextMenu:Arrange:SendBackward',
      SEND_TO_BACK: 'ContextMenu:Arrange:SendToBack',
    },
    REPLACE_WITH_IMAGE: 'ContextMenu:ReplaceWithImage',
    REVERT_TO_DEFAULT_NODE: 'ContextMenu:RevertToDefaultNode',
    DESELECT: {
      NODES: 'ContextMenu:Deselect:Nodes',
      EDGES: 'ContextMenu:Deselect:Edges',
      IMAGES: 'ContextMenu:Deselect:Images',
    },
    DELETE: 'ContextMenu:Delete',
  },
  MPT_CONTEXT_MENU: {
    LINK_TO_FILE: 'MPTContextMenu:LinkToFile',
    VIEW_IN_EDITOR: 'MPTContextMenu:ViewInEditor',
    OPEN_PROPERTY_VIEWER: 'MPTContextMenu:OpenPropertyViewer',
    REPLACE_WITH_IMAGE: 'MPTContextMenu:ReplaceWithImage',
    DELETE_SELECTED_ROW: 'MPTContextMenu:DeleteSelectedRow',
    DELETE_SELECTED_COLUMN: 'MPTContextMenu:DeleteSelectedColumn',
    INSERT_COLUMN: 'MPTContextMenu:InsertColumn',
    REVERT_DEFAULT_NODE: 'MPTContextMenu:RevertDefaultNode',
    DELETE_SETS_KEEP_MEMEBERS: 'MPTContextMenu:DeleteSetsKeepMembers',
    DELETE_SETS_AND_MEMEBERS: 'MPTContextMenu:DeleteSetsAndMembers',
  },
  HOT_KEYS: {
    CUT: 'HotKeys:Cut',
    COPY: 'HotKeys:Copy',
    PASTE: 'HotKeys:Paste',
    CLEAR_SELECTION: 'HotKeys:ClearSelection',
    UNDO: 'HotKeys:Undo',
    REDO: 'HotKeys:Redo',
    REDO_SHIFT: 'HotKeys:RedoShift',
    OPEN_QUICK_PROPERTY_VIEWER: 'HotKeys:QuickPropertyViewer',
    OPEN_MPT_ARROW_KEY: 'HotKeys:OpenMPTArrowKey',
    CLOSE_MPT_ARROW_KEY: 'HotKeys:CloseMPTArrowKey',
    DOCK_UNDOCK_MPT: 'HotKeys:Dock-UndockMPT',
    SELECT_TOOL: 'HotKeys:SelectTool',
    NODE_TOOL: 'HotKeys:NodeTool',
    IMAGE_TOOL: 'HotKeys:ImageTool',
    EDGE_TOOL: 'HotKeys:EdgeTool',
    PAN_TOOL: 'HotKeys:PanTool',
    FIT_TO_MODEL: 'HotKeys:FitToModel',
    FIT_TO_SELECTION: 'HotKeys:FitToSelection',
    OPEN_POP_OUT_EDITOR: 'HotKeys:OpenPopOutEditor',
    APPLY_FILTER: 'HotKeys:ApplyFilter',
    CLEAR_FILTER: 'HotKeys:ClearFilter',
  },
};

export const URL_SEARCH_PARAM_NAMES = {
  SEARCH_TEXT: 'search',
  IS_EXPRESSION_SEARCH: 'exp-search',
};

export const PATH_DISCOVERY_EDGE_DIRECTION = {
  IGNORE: 'ignore',
  OUTGOING: 'outgoing',
  INCOMING: 'incoming',
};

export const DRAWING_MODE = {
  LINE: 'Line',
  BOX: 'Box',
  TEXT: 'Text',
};

export const PIXI_CANVAS_ID = 'pixi-canvas';

// MICROELECTRONICS COMMONS Organization
export const ORG_MEC = 'mec';
