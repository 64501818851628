import { SEARCH } from 'modules/common';
import { MetraSimpleAction, Pagination, SearchReducer } from 'types';
import { initialSearchState } from './reducer';

export const setSearchText = (
  searchText: string
): MetraSimpleAction<Partial<SearchReducer>> => {
  return {
    type: SEARCH.TEXT,
    payload: {
      searchText,
    },
  };
};

export const setSearchExpressionEnabled = (
  expressionEnabled: boolean
): MetraSimpleAction<Partial<SearchReducer>> => {
  return {
    type: SEARCH.EXPRESSION_ENABLED,
    payload: {
      expressionEnabled,
    },
  };
};
export const setSearchField = (
  searchField: string
): MetraSimpleAction<Partial<SearchReducer>> => {
  return {
    type: SEARCH.FIELD,
    payload: {
      searchField,
    },
  };
};

export const setDisplaySearchResults = (
  displaySearchResults: boolean
): MetraSimpleAction<Partial<SearchReducer>> => {
  return {
    type: SEARCH.DISPLAY,
    payload: {
      displaySearchResults,
    },
  };
};

export const addSearchTag = (
  tagText: string
): MetraSimpleAction<Partial<SearchReducer>> => {
  return {
    type: SEARCH.TAG,
    payload: {
      searchText: tagText,
    },
  };
};

export const clearSearch = (): MetraSimpleAction<Partial<SearchReducer>> => {
  return {
    type: SEARCH.CLEAR,
    payload: initialSearchState,
  };
};

export const setPagination = (
  pagination: Partial<SearchReducer>
): MetraSimpleAction<Partial<SearchReducer>> => {
  return {
    type: SEARCH.UPDATE.PAGINATION,
    payload: pagination,
  };
};

export const setSearchPaginationRows = (
  numberOfRows: Partial<Pagination>
): MetraSimpleAction<Partial<Pagination>> => {
  return {
    type: SEARCH.UPDATE.NUMBER_OF_ROWS,
    payload: numberOfRows,
  };
};
