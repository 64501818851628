import { Reducer } from 'redux';
import type { MetraAction, TableFilterReducer } from 'types';
import { initialTableFilterState } from './initial-state';
import { TABLE, isPayloadAction } from 'modules/common';
import { MODEL } from 'utils/constants';

export const tableFilterReducer: Reducer<
  TableFilterReducer,
  MetraAction<any, any, any>
> = (state = initialTableFilterState, action) => {
  switch (action.type) {
    case MODEL.INIT:
      return initialTableFilterState;
    case TABLE.FILTER.CLEAR:
      return {
        ...state,
        entities: [],
        schemas: [],
        hideUnpopulated: state.baseHideUnpopulated,
      };
    case TABLE.FILTER.CLEAR_SCHEMAS:
      return { ...state, schemas: [] };
    case TABLE.FILTER.TOGGLE_ALL:
      return {
        ...state,
        baseHideUnpopulated: !state.hideUnpopulated,
        hideUnpopulated: !state.hideUnpopulated,
      };
    case TABLE.FILTER.UPDATE:
      if (!isPayloadAction(action)) return state;
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
