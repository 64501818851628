import type { EntityId } from '.';
import type { Atoms } from './atoms';

export class SubAtoms {
  atoms: Atoms;

  constructor(atoms: Atoms) {
    this.atoms = atoms;
  }

  init() {}

  get sys2() {
    return this.atoms.sys2;
  }

  get dispatch() {
    return this.atoms.dispatch;
  }

  get state() {
    return this.atoms.sys2.state;
  }

  toUuids(eids: Set<EntityId>) {
    return this.atoms.toUuids(eids);
  }
}
