import { combineReducers } from 'redux';
import { genericValueReducer, PROJECTS, SEARCH } from 'modules/common';
import { SORT } from 'utils/constants';
import { ProjectReducer } from 'types';

/*
 *
 * REDUCERS
 *
 */

/**
 *
 */
export const initialProjectState: ProjectReducer = {
  loaded: false,
  projectId: '',
  filterTags: [],
  sortBy: SORT.ACTIVITY_NEWEST,
  cardView: true,
  displaySearchResults: false,
  showLicenseLimitError: false,
};

const loaded = genericValueReducer(PROJECTS.LOADED, initialProjectState.loaded);
const filterTags = genericValueReducer(
  PROJECTS.FILTER_TAGS,
  initialProjectState.filterTags
);
const projectId = genericValueReducer(
  PROJECTS.SELECTED,
  initialProjectState.projectId
);
const sortBy = genericValueReducer(
  PROJECTS.SORT_BY,
  initialProjectState.sortBy
);
const cardView = genericValueReducer(
  PROJECTS.CARD_VIEW,
  initialProjectState.cardView
);
const displaySearchResults = genericValueReducer(
  SEARCH.DISPLAY,
  initialProjectState.displaySearchResults
);
const showLicenseLimitError = genericValueReducer(
  PROJECTS.SHOW_LICENSE_ERROR_MODAL,
  initialProjectState.showLicenseLimitError
);

/**
 *
 */
export const projectReducer = combineReducers<ProjectReducer>({
  loaded,
  filterTags,
  projectId,
  sortBy,
  cardView,
  displaySearchResults,
  showLicenseLimitError,
});
