import { HOTKEY_MAP } from 'modules/common';

export const showHotkeyMap = () => {
  return {
    type: HOTKEY_MAP.SHOW,
  };
};

export const closeHotkeyMap = () => {
  return {
    type: HOTKEY_MAP.HIDE,
  };
};
