import { OrgContext } from './OrganizationContext';

export type JsonData =
  | string
  | number
  | boolean
  | null
  | { [key: string]: JsonData }
  | JsonData[];

export class OrgStorage {
  static getItem(key: string): JsonData {
    const org = OrgContext.organization;
    if (!org) return null;

    const storageJson = localStorage.getItem('orgStorage');
    if (!storageJson) return null;

    const storage = JSON.parse(storageJson);
    const orgStorage = storage[org];
    if (!orgStorage) return null;

    // to keep things in line with JsonData, we coerce undefined into null
    return orgStorage[key] || null;
  }

  static setItem(key: string, item: JsonData): void {
    const org = OrgContext.organization;
    if (!org) throw new Error('Cannot set OrgStorage without an OrgContext');

    const storageJson = localStorage.getItem('orgStorage') || '{}';
    const storage = JSON.parse(storageJson);
    storage[org] = storage[org] || {};
    storage[org][key] = item;
    localStorage.setItem('orgStorage', JSON.stringify(storage));
  }

  static removeItem(key: string): void {
    const org = OrgContext.organization;
    if (!org) return;

    const storageJson = localStorage.getItem('orgStorage');
    if (!storageJson) return;

    const storage = JSON.parse(storageJson);
    const orgStorage = storage[org];
    if (!orgStorage[key]) return;

    delete orgStorage[key];
    localStorage.setItem('orgStorage', JSON.stringify(storage));
  }
}
