import { LOADER } from 'modules/common/constants';

export const initialState = {
  guild: {},
  project: {},
};

export const reducer = (state = initialState, action) => {
  // ignore all actions except LOADER actions
  if (Object.values(LOADER).includes(action.type)) {
    const { type, id } = action.payload;
    const updated = state[type] ? { ...state[type] } : {};
    updated[id] = { status: action.type, at: Date.now() };
    return { ...state, [type]: updated };
  }

  return state;
};
