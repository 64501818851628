import { FeatureFlags } from 'utils/features';
import {
  GridNameColumnValues,
  GridNameRowValues,
  GRID,
} from 'utils/constants-extra';

export const DEVMODE: boolean = FeatureFlags.ENABLE_DEVELOPER_TOOLS;
export const TESTMODE: boolean = FeatureFlags.TESTING;

export const NAME_CELL_SCHEMA_IDS = {
  nodes: GRID.NAME_COLUMN.NODES,
  edges: GRID.NAME_COLUMN.EDGES,
  sets: GRID.NAME_COLUMN.SETS,
  modelProps: GRID.NAME_COLUMN.MODEL_PROPS,
  modelCalcs: GRID.NAME_COLUMN.MODEL_CALCS,
  paths: GRID.NAME_COLUMN.PATHS,
} as const;

export const NAME_CELL_PARENT_IDS = {
  nodes: GRID.NAME_ROW.NODES,
  edges: GRID.NAME_ROW.EDGES,
  sets: GRID.NAME_ROW.SETS,
  modelProps: GRID.NAME_ROW.MODEL_PROPS,
  modelCalcs: GRID.NAME_ROW.MODEL_CALCS,
  paths: GRID.NAME_ROW.PATHS,
} as const;

export const EXP_NAME_CELL_PARENT_IDS: GridNameRowValues[] =
  Object.values(NAME_CELL_PARENT_IDS);
export const EXP_NAME_CELL_SCHEMA_IDS: GridNameColumnValues[] =
  Object.values(NAME_CELL_SCHEMA_IDS);

// export const EXP_NAME_CELL_PARENT_KEYS = Object.keys(NAME_CELL_PARENT_IDS);
// export const EXP_NAME_CELL_SCHEMA_KEYS = Object.keys(NAME_CELL_SCHEMA_IDS);

/**
 * These symbols act as a sort of ENUM. In MathJS, null and undefined
 * are both results of valid expressions, so we specify that an expression
 * is unevaluated by setting its status to UNEVALUATED instead and then
 * progress to FINALIZED as it is processed
 *
 */
export const UNEVALUATED: unique symbol = Symbol('UNEVALUATED');
export const ERROR: unique symbol = Symbol('ERROR');
export const UNIMPLEMENTED: unique symbol = Symbol('UNIMPLEMENTED');
export const PREPARSED: unique symbol = Symbol('PREPARSED');
export const PARSED: unique symbol = Symbol('PARSED');
export const EVALUATED: unique symbol = Symbol('EVALUATED');
export const FINALIZED: unique symbol = Symbol('FINALIZED');

export const EXP_NODE_LETTER_PREFIXES = ['c', 'm', 'n', 'e', 'p', 's'];

export const SOURCE_TARGET_MAPS = {
  source: 'outNodeId',
  target: 'inNodeId',
} as const;

export type SourceTargetType = KeyOf<typeof SOURCE_TARGET_MAPS>;
export type TripleType = 'triples';

export const SOURCE_TARGET_TYPES: SourceTargetType[] = ['source', 'target'];
export const TRIPLE_TYPES: TripleType[] = ['triples'];
