import { cloneDeep } from 'lodash';
import { MODEL_LOCK } from 'utils/constants';

export const initialLockedModelsState = {
  lockedmodels: {},
};

export function modellockReducer(state = initialLockedModelsState, action) {
  let newState;
  switch (action.type) {
    case MODEL_LOCK.DELETE:
      newState = cloneDeep(state);
      delete newState[action.payload];
      return newState;
    case MODEL_LOCK.UPDATE:
      newState = { ...state };
      newState.lockedmodels = action.payload;
      return newState;
    default:
      return state;
  }
}
