/*************
 * Analytics *
 *************/

import { globalGetState } from './utils-extra';

// Set true (via Nucleo) to enable Matomo analytics
export const MATOMO_ENABLED = (): boolean =>
  globalGetState()?.configReducer.matomoEnabled === true;

/* URL to the matomo server. Used for retrieving the piwik.js
 * helper script and reporting analytics
 * Prod URL = 'https://matomo.tenet3.com'
 * Staging URL = 'https://staging-matomo.metrahub.com'
 */
export const MATOMO_URL = (): string =>
  globalGetState()?.configReducer.matomoUrl;

/* Matomo supports many sites. Use site id to specify which one
 * Default to 1, MeTRA Production
 */
export const MATOMO_SITE_ID = (): number =>
  globalGetState()?.configReducer.matomoSiteId || 1;
