import { Reducer } from 'redux';
import { TABLE, isPayloadAction } from 'modules/common';
import {
  MetraAction,
  ModelReducer,
  ModelSchemaCategories,
  SortFunc,
  CategorySorting,
  TableSort,
  TableSortKey,
} from 'types';
import { SCHEMA_CATEGORY } from 'utils/constants-extra';
import { SORT } from 'utils/constants';
import { isSome } from 'helpers/utils';
import { getLabel } from 'modules/model/selectors';
import { moveElement } from 'utils/utils';
import { cloneDeep } from 'lodash';

/**
 * STATE
 */

export const initialTableSortState: TableSort = {
  entities: {
    nodes: [],
    edges: [],
    sets: [],
    modelProps: [],
    modelCalcs: [],
    paths: [],
  },
  schemas: {
    edges: [],
    nodes: [],
    sets: [],
    modelProps: [],
    modelCalcs: [],
    paths: [],
  },
};

/*
 * UTILS
 */

/**
 * reconstructs the sorting Ids based on the inherent object value/key return order.
 * should be useful for legacy graphs
 * @param  modelState - Parameter description.
 * @returns  reset sorting data
 */
export const reconstructSort = (modelState: ModelReducer): ModelReducer => {
  const newState = cloneDeep(modelState);

  newState.tableSort = {
    entities: {
      nodes: Object.keys(newState.nodes), //.map((id) => id),
      edges: Object.keys(newState.edges), //.map((id) => id),
      sets: Object.keys(newState.sets), //.map((id) => id),
      modelProps: Object.keys(newState.modelProps), //.map((id) => id),
      modelCalcs: Object.keys(newState.modelCalcs), //.map((id) => id),
      paths: Object.keys(newState.paths), //.map((id) => id),
    },
    schemas: {
      nodes: Object.values(newState.propSchemas)
        .filter(({ category }) => category === SCHEMA_CATEGORY.NODES)
        .map((schema) => schema.id),
      edges: Object.values(newState.propSchemas)
        .filter(({ category }) => category === SCHEMA_CATEGORY.EDGES)
        .map((schema) => schema.id),
      sets: Object.values(newState.propSchemas)
        .filter(({ category }) => category === SCHEMA_CATEGORY.SETS)
        .map((schema) => schema.id),
      modelCalcs: Object.values(newState.propSchemas)
        .filter(({ category }) => category === SCHEMA_CATEGORY.MODELCALCS)
        .map((schema) => schema.id),
      modelProps: Object.values(newState.propSchemas)
        .filter(({ category }) => category === SCHEMA_CATEGORY.MODELPROPS)
        .map((schema) => schema.id),
      paths: Object.values(newState.propSchemas)
        .filter(({ category }) => category === SCHEMA_CATEGORY.PATHS)
        .map((schema) => schema.id),
    },
  };
  return newState;
};

export const nameSort: SortFunc<number> = (a, b, modelState, meta) =>
  getLabel(
    modelState[meta.type][a],
    modelState.expressions,
    meta.type
  ).localeCompare(
    getLabel(modelState[meta.type][b], modelState.expressions, meta.type),
    'en',
    { numeric: true }
  );

export const typeSort: SortFunc<number> = (a, b, modelState, meta) => {
  const safeCompareValue = (x: string) =>
    modelState.expressions.errors[x]?.[meta.id]
      ? 'ERROR'
      : modelState.expressions.values[x]?.[meta.id] ??
        modelState.propValues[x]?.[meta.id] ??
        '';
  const aVals = new StringNumberParser(safeCompareValue(a));
  const bVals = new StringNumberParser(safeCompareValue(b));
  let nextTokenResult = 0;
  while (nextTokenResult === 0) {
    const nextA = aVals.getNextToken();
    const nextB = bVals.getNextToken();
    if (nextA === null && nextB === null) {
      // a and b were equivalent
      break;
    } else if (nextA === null) {
      nextTokenResult = -1;
    } else if (nextB === null) {
      nextTokenResult = 1;
    } else {
      if (typeof nextA === 'number' && typeof nextB === 'number') {
        nextTokenResult = Math.sign(nextA - nextB);
      } else if (typeof nextA === 'number') {
        nextTokenResult = -1;
      } else if (typeof nextB === 'number') {
        nextTokenResult = 1;
      } else {
        nextTokenResult = nextA.localeCompare(nextB, undefined, {
          numeric: true,
        });
      }
    }
  }
  return nextTokenResult;
};

export const SORT_FUNCS: Record<string, SortFunc<unknown>> = {
  [SORT.NAME_ASCENDING.key]: nameSort,
  [SORT.NAME_DESCENDING.key]: (a, b, modelState, meta) =>
    nameSort(b, a, modelState, meta),
  [SORT.TYPE_ASCENDING.key]: typeSort,
  [SORT.TYPE_DESCENDING.key]: (a, b, modelState, meta) =>
    typeSort(b, a, modelState, meta),
};

export const handleApplySort = (
  modelState: ModelReducer,
  payload: {
    type: TableSortKey;
    subtype: ModelSchemaCategories;
    func: string;
    meta: CategorySorting;
  }
) => {
  const { type, subtype, func, meta } = payload;
  if (type === null || subtype === null || func === null) return modelState;
  const newState = Object.clone(modelState);
  const sortFunc = (a: TableSortKey, b: TableSortKey): number =>
    (SORT_FUNCS[func] as SortFunc<number>)(a, b, newState, meta);
  newState.tableSort[type][subtype] = newState.tableSort[type][subtype].sort(
    sortFunc as any
  );
  return newState;
};

/*
 * REDUCER
 */
export const tableSortReducer: Reducer<
  TableSort,
  MetraAction<any, any, any>
> = (state = initialTableSortState, action) => {
  if (isPayloadAction(action)) {
    switch (action.type) {
      case TABLE.SORT.INIT: {
        if (action.payload) {
          return { ...action.payload };
        } else {
          return { ...initialTableSortState };
        }
      }

      case TABLE.SORT.UPDATE: {
        const [sortType, sortings] = action.payload as [
          TableSortKey,
          CategorySorting[]
        ];
        const newState = Object.clone(state);
        sortings.forEach((sorting) => {
          const { type, id, order } = sorting;
          if (type === null || id === null || order === null) return;
          newState[sortType][type].splice(order, 0, id);
        });
        return newState;
      }

      case TABLE.ARRANGE_CELLS: {
        const { dimension, tab, from, to } = action.payload as {
          dimension: TableSortKey;
          tab: ModelSchemaCategories;
          from: number;
          to: number;
        };
        const newState = Object.clone(state);
        moveElement(newState[dimension][tab], from, to);
        return newState;
      }

      default:
        return state;
    }
  } else {
    return state;
  }
};

class StringNumberParser {
  inputString: string;
  re: RegExp;
  lastEndIndex: number;
  reMatch: Option<RegExpExecArray>;

  constructor(inputString: string) {
    this.inputString = inputString;
    this.re = /\d*\.?\d+/g;
    this.lastEndIndex = 0;
    this.reMatch = this.re.exec(this.inputString);
  }
  getNextToken() {
    if (this.lastEndIndex > this.inputString.length - 1) {
      // we ran out of string to parse
      return null;
    }
    if (this.reMatch === null) {
      // no more matches ... give away the rest of the string and we're
      // done after this.
      const nextToken = this.inputString.substring(this.lastEndIndex);
      this.lastEndIndex = this.inputString.length;
      return nextToken;
    } else {
      if (isSome(this.reMatch) && this.lastEndIndex < this.reMatch.index) {
        // look for special case!  a leading hyphen is a negative sign
        if (
          this.lastEndIndex === 0 &&
          this.reMatch.index === 1 &&
          this.inputString.substring(0, 1) === '-'
        ) {
          // special case ... the inputString started with a negative number,
          // we will return that
          this.lastEndIndex = this.reMatch.index + this.reMatch[0].length;
          const nextToken = parseFloat('-' + this.reMatch[0]);
          this.reMatch = this.re.exec(this.inputString);
          return nextToken;
        }
        // there was some string between two matches (or possibly some string
        // before the first match); that's what we're returning.
        const nextToken = this.inputString.substring(
          this.lastEndIndex,
          this.reMatch.index
        );
        this.lastEndIndex = this.reMatch.index;
        return nextToken;
      }
      // if we got here the next token is the reMatch
      this.lastEndIndex = isSome(this.reMatch)
        ? this.reMatch.index + this.reMatch[0].length
        : this.lastEndIndex;
      const nextToken = isSome(this.reMatch)
        ? parseFloat(this.reMatch[0])
        : 0.0;
      this.reMatch = this.re.exec(this.inputString);
      return nextToken;
    }
  }
}
