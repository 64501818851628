import { updateEdges } from 'modules/model/edge';
import { SubAtoms } from '../sub-atoms';
import { withUpdatedExpressions } from 'modules/model/properties';
import { updateShapesByConfig } from 'modules/model/pixi';
import { ModelEdge, ShapeConfig } from 'types';
import { reverseWaypoints } from 'helpers/ecs';
import { Edge } from 'engine/components';
import { isEdge } from 'modules/model/shape/shape-helpers';
import { Entity } from 'ecs/Entity';
import { remember } from 'modules/model/base/actions';
import { cloneDeep } from 'lodash';

export class EdgeAtoms extends SubAtoms {
  init() {}

  changeDirection = (shouldCreateHistory = true) => {
    const {
      base: {
        selected: { shapes: selectedShapes },
      },
      edges,
      shapes,
      edgeColumns,
    } = cloneDeep(this.state.modelReducer);

    const updatedEdges: ModelEdge[] = [];
    const updatedShapes: ShapeConfig[] = [];
    const edgeEntities: Entity[] = [];
    const valuesToUpdate = [];
    for (let i = selectedShapes.length; i--; ) {
      const shapeId = selectedShapes[i];
      const edgeData = edges[shapeId];
      const config = shapes[shapeId];
      if (!edgeData || !isEdge(config)) continue;
      const entity = this.sys2.engine.ecs.tagManager.getEntityByTag(shapeId);
      if (!entity) continue;
      const edge = this.sys2.engine.ecs.getComponent(entity, Edge) as Edge;
      // swap all the ids
      const outAnchorId = edge.outAnchorId;
      const inAnchorId = edge.inAnchorId;
      const outNodeId = edge.outNodeId;
      const inNodeId = edge.inNodeId;
      edge.inAnchorId = outAnchorId;
      edge.inNodeId = outNodeId;
      edge.outAnchorId = inAnchorId;
      edge.outNodeId = inNodeId;
      // reverse the segments
      const updatedConfig = reverseWaypoints(
        this.sys2.engine.ecs,
        entity,
        config,
        edge
      );
      edgeEntities.push(entity);

      updatedEdges.push({
        ...edgeData,
        inNodeId: edgeData.outNodeId,
        outNodeId: edgeData.inNodeId,
      });
      updatedShapes.push({
        ...updatedConfig,
        to: updatedConfig.from,
        from: updatedConfig.to,
      } as ShapeConfig);

      valuesToUpdate.push({
        parentId: edgeData.id, // edge id
        schemaId: edgeColumns.source, // schema 'source/edges' id
      });
      valuesToUpdate.push({
        parentId: edgeData.id, // edge id
        schemaId: edgeColumns.target, // schema 'target/edges' id
      });
    }

    this.dispatch(updateEdges(updatedEdges));
    this.dispatch(
      withUpdatedExpressions(updateShapesByConfig(updatedShapes), false, {
        values: valuesToUpdate,
      })
    );

    if (shouldCreateHistory) {
      // TODO: use new undo/redo system
      this.dispatch(remember());
    }

    return true;
  };
}
