import React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import './ErrorBoundary.scss';
import { buildOrgUrl } from 'utils/url-builders';

//INFO: tests of this do not work in development, but here is a codepen that shows it works:
//https://codepen.io/xevrem/pen/GzbzNo

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    this.state = { error: null, errorInfo: null, eventId: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    /* When running locally with sentry enabled, this will result in issues being
     * double-reported. Don't worry, this won't happen in production.
     * See: https://docs.sentry.io/platforms/javascript/react/#error-boundaries
     */
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    return this.state.error ? (
      <div className="error-boundary">
        <h1>
          Something Went Wrong! <small>{this.state.error.toString()}</small>
        </h1>
        <p className="error-boundary-description">
          The issue has been automatically reported to our development team, and
          we&apos;ll get started on a fix right away. If you&apos;d like to help
          us out further, you can{' '}
          <button
            className="error-boundary-report-feedback error-boundary-button"
            onClick={() =>
              Sentry.showReportDialog({ eventId: this.state.eventId })
            }
          >
            Report Feedback
          </button>
          , describing the events that led up to the issue. Thank you!
        </p>
        <ul>
          <li>
            <button
              className="error-boundary-reload error-boundary-button"
              onClick={window.location.reload.bind(window.location)}
            >
              Reload the Page
            </button>
          </li>
          <li>
            <a href={`${buildOrgUrl()}`} className="mod-blue-link">
              Go back to the MeTRA&reg; Homepage
            </a>
          </li>
        </ul>
      </div>
    ) : (
      this.props.children
    );
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.element,
};
