import { MPT_POPOUT_EDITOR } from 'modules/common';
import { MetraSimpleAction, MPTPopoutEditorReducer } from 'types';

export const initialMptPopoutEditorState: MPTPopoutEditorReducer = {
  type: null,
  wiggle: false,
};

export const mptPopoutEditorReducer = (
  state = initialMptPopoutEditorState,
  action: MetraSimpleAction<Partial<MPTPopoutEditorReducer>>
): MPTPopoutEditorReducer => {
  const { type } = action;
  switch (type) {
    case MPT_POPOUT_EDITOR.WIGGLE.START: {
      return {
        ...state,
        wiggle: true,
      };
    }
    case MPT_POPOUT_EDITOR.WIGGLE.STOP: {
      return {
        ...state,
        wiggle: false,
      };
    }
    default:
      return state;
  }
};
