import { LIBRARY } from './constants';
import type { ThunkActionFunc } from 'types';

export const tickUploadingCount: ThunkActionFunc<[tick: number], void> =
  (tick) => (dispatch, getState) => {
    let uploadingCount = getState().libraryReducer.uploadingCount;
    uploadingCount += tick;
    dispatch({
      type: LIBRARY.UPDATE.UPLOADING_COUNT,
      payload: { uploadingCount },
    });
  };

export const setShowFilePath: ThunkActionFunc<[show: boolean], void> =
  (show) => (dispatch, getState) => {
    const state = getState();
    const tableView = state.libraryReducer.tableView;
    const columnOpts = state.libraryReducer.columnOptions[tableView];

    columnOpts.FileName.showFilePath = show;
    dispatch({
      type: LIBRARY.UPDATE.SHOW_FILE_PATH,
      payload: { ...columnOpts },
    });
  };
