import { GRID_SETTINGS, MODEL_LINKS } from 'modules/common';
import { GridSettingsReducer, MetraSimpleAction } from 'types';
import { MODEL } from 'utils/constants';

export const initialGridSettingsState: GridSettingsReducer = {
  width: 43,
  height: 43,
};

export const gridSettingsReducer = (
  state = initialGridSettingsState,
  action: MetraSimpleAction<any>
): GridSettingsReducer => {
  const { type, payload } = action;

  if (type === GRID_SETTINGS.SET) {
    return {
      ...state,
      ...payload,
    };
  }

  if (type === MODEL.GET_DATA_SUCCESS) {
    return {
      ...state,
      ...payload.gridSettings,
    };
  }

  return state;
};
