import { APP, GRID_SETTINGS } from 'modules/common/constants';
import type { GridSettingsReducer, ThunkAction } from 'types';

export const setGridSettings = (
  payload: Partial<GridSettingsReducer> = {}
): ThunkAction<void> => {
  return (dispatch) => {
    return dispatch({
      type: GRID_SETTINGS.SET,
      payload,
    });
  };
};

export const toggleDisplayGrid = (): ThunkAction<void> => {
  return (dispatch) => {
    return dispatch({
      type: APP.GRID.DISPLAY,
    });
  };
};

export const toggleSnapToGrid = (): ThunkAction<void> => {
  return (dispatch) => {
    return dispatch({
      type: APP.GRID.SNAP,
    });
  };
};
