import {
  CollectionKey,
  EntityKey,
  EntityReducer,
  Enumerated,
  MetraApiResponseAction,
  MutableEntityReducer,
  NormalizedResult,
} from 'types';
import { ENTITIES } from 'modules/common/constants-extra';
import { schema } from 'normalizr';
import SeamlessImmutable, { Immutable } from 'seamless-immutable';

export const validEntities: EntityKey[] = [
  'adminGuilds',
  'admins',
  'guilds',
  'guildSearchResults',
  'historicalproject',
  'historicalmedia',
  'historicalfileversion',
  'historicalmodelversion',
  'license',
  'media',
  'mediaSearchResults',
  'orgMetrics',
  'guildMetrics',
  'orgUsers',
  'projects',
  'projectSearchResults',
  'tags',
  'tasks',
  'users',
  'versions',
];

export function isValidEntityKey(
  value: string | EntityKey | undefined
): value is EntityKey {
  if (!value) return false;
  return validEntities.includes(value as any);
}

export function makeNormalizedResults<K extends EntityKey>(
  records: MutableEntityReducer[K],
  key: K
): NormalizedResult<K> {
  if (!records) throw new Error('cannot transform malformed records!');
  const keys = Object.keys(records);
  const entities = {
    [key]: {
      ...records,
    },
  } as Pick<MutableEntityReducer, K>;
  return {
    count: keys.length,
    entities,
    next: null,
    previous: null,
    results: [...keys],
  };
}

export function makeNormalizedResult<K extends EntityKey>(
  record: ValueOf<MutableEntityReducer[K]> & Enumerated,
  key: K
): NormalizedResult<K> {
  if (!record) throw new Error('cannot transform malformed records!');
  const entities: Pick<MutableEntityReducer, K> = {
    [key]: {
      [record.id]: record,
    },
  } as any;
  return {
    count: 1,
    entities,
    next: null,
    previous: null,
    results: [record.id],
  };
}

/*
 * UTILS
 */

export function readSchema(name: Symbolish) {
  const entity = new schema.Entity(name);
  return new schema.Array(entity);
}

export function createSchema(name: Symbolish) {
  return new schema.Entity(name);
}

export function makeUpdateSuccess<T>(
  payload: T,
  schema: EntityKey,
  collection?: CollectionKey
): MetraApiResponseAction<T> {
  return {
    payload,
    meta: {
      mutation: ENTITIES.MUTATE_UPDATE_ONE,
      collection,
      schema,
    },
    type: ENTITIES.ACTION_SUCCESS,
  };
}

export function makeUpdateManySuccess<T>(
  payload: T,
  schema: EntityKey,
  collection?: CollectionKey
): MetraApiResponseAction<T> {
  return {
    payload,
    meta: {
      mutation: ENTITIES.MUTATE_UPDATE_MANY,
      collection,
      schema,
    },
    type: ENTITIES.ACTION_SUCCESS,
  };
}

export function makeReadSuccess<T extends NormalizedResult>(
  payload: T,
  schema: EntityKey,
  collection?: CollectionKey
): MetraApiResponseAction<T> {
  return {
    payload,
    meta: {
      mutation: ENTITIES.MUTATE_READ,
      collection,
      schema,
    },
    type: ENTITIES.ACTION_SUCCESS,
  };
}

export function makeCreateSuccess<T>(
  payload: T,
  schema: EntityKey,
  collection?: CollectionKey
): MetraApiResponseAction<T> {
  return {
    payload,
    meta: {
      mutation: ENTITIES.MUTATE_CREATE,
      collection,
      schema,
    },
    type: ENTITIES.ACTION_SUCCESS,
  };
}

export function makeDeleteSuccess<T>(
  payload: T,
  schema: EntityKey,
  collection?: CollectionKey
): MetraApiResponseAction<T> {
  return {
    payload,
    meta: {
      mutation: ENTITIES.MUTATE_DELETE,
      collection,
      schema,
    },
    type: ENTITIES.ACTION_SUCCESS,
  };
}

/*
 * helpers
 */

export function updateOrAdd(
  state: Immutable<EntityReducer>,
  schema: EntityKey,
  key: Numberish,
  value: any
) {
  if (state.getIn([schema, key])) {
    const updatedState = state.updateIn([schema, key], (entity) => {
      return entity.merge(SeamlessImmutable(value));
    });
    return updatedState;
  } else {
    const updatedState = state.setIn([schema, key], SeamlessImmutable(value));
    return updatedState;
  }
}
