import { SHAPE } from 'utils/constants-extra';
import { isExpressionString } from 'helpers/utils';
import { NAME_CELL_SCHEMA_IDS } from 'interpreter/constants';
import { GUILD_PERMISSIONS, WRITE_PERMISSIONS } from 'utils/constants';

/**
 * getSelected gets all selected objects
 * @returns {{
 * backgrounds: import('types').BackgroundConfig[],
 * edges: import('types').EdgeConfig[],
 * nodes: import('types').NodeConfig[]
 * }}
 */
export const getSelected = () => {
  return (_, getState) => {
    const state = getState();
    return groupShapes(state, state.modelReducer.base.selected.shapes);
  };
};

/**
 * Groups shapes into  { edges, nodes, backgrounds, drawings, text }.
 * @returns {{
 * backgrounds: import('types').BackgroundConfig[],
 * edges: import('types').ModelEdge[],
 * nodes: import('types').ModelNode[],
 * drawingShapes: import('types').ModelDrawing[]
 * textShapes: import('types').ModelText[]
 * }}
 */
export const groupShapes = (state, shapeIds) => {
  const edges = [];
  const nodes = [];
  const backgrounds = [];
  const drawingShapes = [];
  const textShapes = [];
  shapeIds.forEach((shapeId) => {
    const shape = state.modelReducer.shapes[shapeId];
    if (!shape) {
      return;
    }
    if (shape) {
      if (shape.type === SHAPE.EDGE) {
        shapeId in state.modelReducer.edges &&
          edges.push(state.modelReducer.edges[shapeId]);
      }
      if (shape.type === SHAPE.BACKGROUND) {
        // we already know its a valid shape, so add it
        backgrounds.push(state.modelReducer.shapes[shapeId]);
      }
      if (shape.type === SHAPE.NODE) {
        shapeId in state.modelReducer.nodes &&
          nodes.push(state.modelReducer.nodes[shapeId]);
      }
      if (shape.type === SHAPE.DRAWING) {
        drawingShapes.push(state.modelReducer.shapes[shapeId]);
      }
      if (shape.type === SHAPE.TEXT) {
        textShapes.push(state.modelReducer.shapes[shapeId]);
      }
    }
  });
  return {
    edges,
    nodes,
    backgrounds,
    drawingShapes,
    textShapes,
  };
};

/**
 * @param {import('types').ModelReducer} model reducer state
 * @returns {import('types').ShapeConfig[]}
 */
export const getSelectedShapes = (state) => {
  // const state = globalGetState().modelReducer;
  return (
    state.base.selected.shapes
      // filter backgrounds and grab subShapes from collapsed set
      .reduce((acc, shapeId) => {
        // grab shape from id
        const shape = state.shapes[shapeId];
        if (shape.type === SHAPE.COLLAPSED_SET) {
          const collapsedSet = state.sets[shape.setId];
          collapsedSet.nodeIds.forEach((nodeId) =>
            acc.push(state.shapes[nodeId])
          );
          collapsedSet.edgeIds.forEach((edgeId) =>
            acc.push(state.shapes[edgeId])
          );
        } else if (shape.type !== SHAPE.BACKGROUND) {
          acc.push(shape);
        }
        return acc;
      }, [])
  );
};

/**
 * gets the number of selected nodes
 * @param {Record<UUID, import('types').ShapeConfig>} allShapes each key is a shape ID. Each value is a Shape Object
 * @param {Array<string>} selectedShapeIds
 * @returns {number} count of selected nodes
 */
export const selectedNodeCount = (allShapes, selectedShapeIds) =>
  selectedShapeCount(allShapes, selectedShapeIds, SHAPE.NODE);

/**
 * gets the number of selected edge
 * @param {Record<UUID, import('types').ShapeConfig>} allShapes - each key is a shape ID. Each value is a Shape Object
 * @param {Array<string>} selectedShapeIds
 * @returns {number} count of selected edge
 */
export const selectedEdgeCount = (allShapes, selectedShapeIds) =>
  selectedShapeCount(allShapes, selectedShapeIds, SHAPE.EDGE);

/**
 * gets the number of selected shapes
 * @param {Record<UUID, import('types').ShapeConfig>} allShapes - each key is a shape ID. Each value is a Shape Object
 * @param {Array<string>} selectedShapeIds
 * @param {string} type the shape type
 * @returns {number} count of selected shapes
 */
export const selectedShapeCount = (allShapes, selectedShapeIds, type) => {
  let count = 0;
  for (const selectedShapeId of selectedShapeIds) {
    if (allShapes[selectedShapeId]?.type === type) count++;
  }
  // const count = selectedShapeIds.reduce(
  //   (count, selectedShapeId) =>
  //     (allShapes?.[selectedShapeId]?.type ?? null) === type ? count++ : count,
  //   0
  // );
  return count;
};

export const getModelParentFolderPath = (modelId, state) => {
  const { media } = state.entityReducer;
  let parentFolderPath = '';
  if (media && media[modelId]) {
    let ancestorFolderId = media[modelId].belongs_to;
    while (ancestorFolderId) {
      parentFolderPath = '/' + ancestorFolderId + parentFolderPath;
      ancestorFolderId = media[ancestorFolderId]?.belongs_to;
    }
  }
  return parentFolderPath;
};

export const getPermissions = (state) => {
  const modelId = state.modelReducer.base.id;
  const media = state.entityReducer.media;

  return media[modelId]?.write_permissions;
};

export const userCanModifyPermissions = (state) => {
  const currentUser = state.userReducer.currentUser;
  const modelId = state.modelReducer.base.id;
  const media = state.entityReducer.media;
  const users = state.entityReducer.users;

  return (
    media[modelId]?.created_by === currentUser ||
    users[currentUser]?.is_superuser
  );
};

export const userHasProtectedPermissions = (
  projectId,
  projects,
  users,
  currentUser
) => {
  const owner = projects[projectId]?.owner;
  const currentProject = projects[projectId];

  if (currentProject?.guild_permissions === GUILD_PERMISSIONS.FULL_ACCESS)
    return true;

  return owner === currentUser || users[currentUser]?.is_superuser;
};

export const userHasWritePermission = (state) => {
  const currentUser = state.userReducer.currentUser;
  const modelId = state.modelReducer.base.id;
  const media = state.entityReducer.media;
  const users = state.entityReducer.users;
  const isReadOnly = state.modelReducer.base.isReadOnly;
  const isProtected = state.modelReducer.base.isProtected;
  if (isReadOnly) {
    return false;
  } else if (isProtected) {
    return (
      media[modelId]?.created_by === currentUser ||
      users[currentUser]?.is_superuser
    );
  } else {
    return true;
  }
};

/**
 * Trims quotes and unescapes special spacial characters
 * @param {string} entityType
 * @returns {string}
 */
export const processLabelText = (str) => {
  const escaped = str
    .replaceAll('"', '')
    .replace(/\\n/g, '\n')
    .replace(/\\t/g, '\t')
    .replace(/\\r/g, '\r');
  return escaped;
};

/**
 *
 * @param {import('types').BaseModelEntityType} entity
 * @param {import('types').ModelExpressions} modelExpressions
 * @param {import('types').ModelSchemaCategories} entityType
 * @returns {string}
 */
export const getLabel = (entity, modelExpressions, entityType) => {
  if (
    modelExpressions.errors &&
    modelExpressions.errors?.[entity.id]?.[NAME_CELL_SCHEMA_IDS?.[entityType]]
  ) {
    // interpreter detected an error, so display 'ERROR'
    return 'ERROR';
  }
  const expressionValue =
    modelExpressions.values?.[entity.id]?.[NAME_CELL_SCHEMA_IDS?.[entityType]];
  if (expressionValue) {
    // we have an evaluated expression, so trim any quotes
    // and un-escape white space chars, then return
    const escaped = processLabelText(expressionValue);
    return escaped;
  } else if (!isExpressionString(entity.label)) {
    // if it doesn't require evaluation, just display it
    return entity.label;
  } else {
    // if something went wrong, blank it
    return '';
  }
};

export const getFilterAlpha = (state) => {
  return state.modelReducer.base.filterAlpha;
};

/**
 * @param {import('types').RootReducer} state
 * @returns {Record<string, import('types').Filter>}
 */
export function getAllFilters(state) {
  return state.modelReducer.filters;
}

/**
 * @param {import('types').RootReducer} state
 * @returns {UUID[]>}
 */
export function getFiltersSort(state) {
  return state.modelReducer.filterSort;
}

/**
 * @param {import('types').RootReducer} state
 * @returns {import('types/modules/model/base/reducer.d').ModelReducer}
 */
export function getModelState(state) {
  return state.modelReducer;
}

export function getDrawingStrokeWidth(state) {
  return state.modelReducer.base.drawingStrokeWidth;
}

export function getDrawingStrokeColor(state) {
  return state.modelReducer.base.drawingStrokeColor;
}

export function getDrawingStrokeAlpha(state) {
  return state.modelReducer.base.drawingStrokeAlpha;
}

export function getDrawingFillColor(state) {
  return state.modelReducer.base.drawingFillColor;
}

export function getDrawingFillAlpha(state) {
  return state.modelReducer.base.drawingFillAlpha;
}

export function getDrawingMode(state) {
  return state.modelReducer.base.drawingMode;
}

export function getTextColor(state) {
  return state.modelReducer.base.textColor;
}

export function getTextAlpha(state) {
  return state.modelReducer.base.textAlpha;
}

export function getTextStrokeAlpha(state) {
  return state.modelReducer.base.textStrokeAlpha;
}

export function getTextStrokeColor(state) {
  return state.modelReducer.base.textStrokeColor;
}

export function getTextStrokeThickness(state) {
  return state.modelReducer.base.textStrokeThickness;
}

export function getTextFontSize(state) {
  return state.modelReducer.base.textFontSize;
}

export function getDarkModeStatus(state) {
  return state.modelReducer.base.darkMode;
}

export function getModelProtectedMode(state) {
  return state.modelReducer.base.isProtected;
}

export function getModelReadOnlyMode(state) {
  return state.modelReducer.base.isReadOnly;
}

export function getModelPermissions(state) {
  const isReadOnly = getModelReadOnlyMode(state);
  const isProtected = getModelProtectedMode(state);

  if (isReadOnly) return WRITE_PERMISSIONS.READONLY;
  else if (isProtected) return WRITE_PERMISSIONS.PROTECTED;
  else if (!isReadOnly && !isProtected) return WRITE_PERMISSIONS.WRITE;
  else return '';
}

export function getReadOnlyFlyoutStatus(state) {
  return state.modelReducer.base.readOnlyWarningFlyout.show;
}

export function getLockedModelsList(state) {
  return state.modellockReducer?.lockedmodels;
}

export function getProjectMediaLoading(state) {
  return state.projectReducer.loaded;
}

export function getLibraryReducerLoaded(state) {
  return state.libraryReducer.loaded;
}

export function getLoadedProjectId(state) {
  return state.entityReducer?.projects?.[state.projectReducer.projectId];
}
