import { isPayloadAction } from 'modules/common';
import { Reducer } from 'redux';
import { MetraAction, ModelFilesReducer } from 'types';
import { MODEL } from 'utils/constants';

export const initialModelFilesState: ModelFilesReducer = {
  grid: true,
  editMode: false,
  selectedFiles: new Set(),
  showDeleteImageModal: false,
  shapesToRevert: [],
};

export const modelFilesReducer: Reducer<
  ModelFilesReducer,
  MetraAction<any, any, any>
> = (state = initialModelFilesState, action) => {
  if (!isPayloadAction(action)) return state;
  switch (action.type) {
    case MODEL.FILES.SET_GRID: {
      return {
        ...state,
        grid: action.payload,
      };
    }
    case MODEL.FILES.SET_EDIT_MODE: {
      return {
        ...state,
        editMode: action.payload,
        selectedFiles: new Set<Numberish>(),
      };
    }
    case MODEL.FILES.SET_SELECTED_FILES: {
      return {
        ...state,
        selectedFiles: action.payload,
      };
    }
    case MODEL.FILES.SET_SHAPES_TO_REVERT: {
      return {
        ...state,
        shapesToRevert: action.payload,
      };
    }
    case MODEL.FILES.TOGGLE_DELETE_IMAGE_MODAL: {
      return {
        ...state,
        showDeleteImageModal: action.payload,
      };
    }
    default:
      return state;
  }
};
