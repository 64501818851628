import { ModelReducer } from 'types';

/**
 * returns an array of unique set ids that have been selected
 * @param  state - modelState.
 * @returns collapsed and selected set ids.
 */
export function getSelectedSets(state: ModelReducer): UUID[] {
  const selectedSets = new Set<UUID>();
  if (state.propSelect) {
    const selectedTableCells = state.propSelect.byCells;
    const selectedModelSets = state.base.selected.sets;

    selectedTableCells.flat().forEach(([type, id]) => {
      if (type === 'sets') {
        selectedSets.add(id);
      }
    });
    selectedModelSets.forEach((id) => {
      selectedSets.add(id);
    });
  }
  return Array.from(selectedSets);
}
