import { combineReducers } from 'redux';
import { MODEL } from 'utils/constants';
import { hydrateModel } from 'modules/model/actions';
import { baseModelReducer } from 'modules/model/base/reducer';
import { expressionReducer } from 'modules/model/properties/expressions/reducer';
import { modelFilesReducer } from 'modules/model/files/reducer';
import { propSelectReducer } from 'modules/model/properties/selection';
import { propSizesReducer } from './properties/sizes';
import { tableFilterReducer } from './table/filter/reducer';
import { tableSettingsReducer } from './table/settings';
import { tableSortReducer } from './table/sort';
import { tableViewReducer } from './table/view';
import { mptContextMenuReducer } from './mptContextMenu';
import { mptPopoutEditorReducer } from './mptPopoutEditor';
import { mptRearrangeReducer } from './mptRearrange';
import { hotkeyMapReducer } from './hotkeyMap';
import { urlFlyoutReducer } from './urlFlyout';
import { nodeReducer } from './node';
import { edgeReducer } from './edge';
import { modelPropReducer } from './modelProp';
import { modelCalcReducer } from './modelCalc';
import { selectionFilterReducer } from './selectionFilter';
import { propValuesReducer, schemasReducer } from './properties';
import { shapesReducer } from './pixi';
import { setReducer } from './set';
import { qpvReducer } from './quickPropertyViewer/reducer';
import { highOrderReducer } from './highOrder/reducer';
import { searchResultReducer } from './modelSearchResults';
import { initialModelState } from './initial-state';
import { pathReducer } from './path/reducer';
import { notesEditorReducer } from './notesEditor/reducer';
import { notesCollectionReducer } from './properties/notesCollectionReducer';
import { edgeColumnsReducer } from './edgeColumns';
import { modelLinksReducer } from './modelLinks';
import { gridSettingsReducer } from './gridSettings';
import { filterSortReducer } from './filterSort';
import { MODEL_HISTORY } from './history/actions';
import { reducer as schema } from './schema/reducer';
import { reducer as sheets } from './sheets/reducer';
import { reducer as history } from './history/reducer';
import { modellockReducer } from '../modellock/reducer';

const scriptsReducer = (state = {}, action) => {
  if (action.type === MODEL.SET_SCRIPTS) {
    return action.payload;
  }

  return state;
};

/*
 * REDUCER
 */

/**
 * @type {import('redux').Reducer<import('types').ModelReducer, import('types').MetraAction<any, any, any>>}
 */
const _modelReducer = combineReducers({
  base: baseModelReducer,
  edgeColumns: edgeColumnsReducer,
  edges: edgeReducer,
  expressions: expressionReducer,
  files: modelFilesReducer,
  filters: selectionFilterReducer,
  filterSort: filterSortReducer,
  gridSettings: gridSettingsReducer,
  history,
  hotkeyMap: hotkeyMapReducer,
  modelCalcs: modelCalcReducer,
  modelLinks: modelLinksReducer,
  modelLock: modellockReducer,
  modelProps: modelPropReducer,
  mptContextMenu: mptContextMenuReducer,
  mptPopoutEditor: mptPopoutEditorReducer,
  mptRearrange: mptRearrangeReducer,
  nodes: nodeReducer,
  notes: notesCollectionReducer,
  notesEditor: notesEditorReducer,
  paths: pathReducer,
  propSchemas: schemasReducer,
  propSelect: propSelectReducer,
  propSizes: propSizesReducer,
  propValues: propValuesReducer,
  quickPropertyViewers: qpvReducer,
  schema,
  scripts: scriptsReducer,
  searchResults: searchResultReducer,
  sets: setReducer,
  shapes: shapesReducer,
  sheets,
  tableFilter: tableFilterReducer,
  tableSettings: tableSettingsReducer,
  tableSort: tableSortReducer,
  tableView: tableViewReducer,
  urlFlyout: urlFlyoutReducer,
});

/**
 * core model reducer
 * @type {import('types').Reducer<
 *   import('types').ModelReducer,
 *   import('types').MetraAction<any, any, any, import('types').ModelReducer>
 * >}
 */
export const modelReducer = (
  rawState = Object.clone(initialModelState),
  action
) => {
  // process all regular reducers first
  const modelState =
    action.type === MODEL.RESET
      ? _modelReducer(Object.clone(initialModelState), action)
      : _modelReducer(rawState, action);

  // all our parent-level reducing here
  const highOrderState = highOrderReducer(modelState, action);

  if (action.type === MODEL_HISTORY.UNIVERSAL) {
    const joined = {
      ...highOrderState,
      ...action.payload,
      base: {
        ...highOrderState?.base,
        ...action.payload?.base,
      },
      // We want the creation of Notes to be ignored by Model History. Prior to adding these lines,
      // model history would notice the updates to the Notes ID list and create an entry. Undoing
      // this would cause a jarring user experience.
      // These changes always persist the current, up-to-date model state regardless of what is
      // in the action.payload (possibly an undo)
      notes: highOrderState.notes,
    };

    // need to do this because these fields can be removed entirely by prune
    // if they're empty. But we can't let them be overwritten by their previous state
    // will be a non-issue when we can write custom undo/redo actions for these
    if (action.payload.sheets == null) delete joined.sheets;
    if (action.payload.schema == null) delete joined.schema;

    const hydrated = modelReducer(joined, hydrateModel(joined));
    return hydrated;
  }

  return highOrderState;
};
