import type { GetsDirty } from './types';

export const ComponentSymbol: unique symbol = Symbol('Component');

class Prop<T> {
  _value: T;
  parent: GetsDirty<T>;

  constructor(initial: T, parent: GetsDirty<T>) {
    this.parent = parent;
    this._value = initial;
  }

  get val() {
    return this._value;
  }

  set val(value) {
    if (this._value !== value) {
      if (!this.parent.dirty) {
        this.parent.dirtyCallback?.(value);
      }
      this.parent.dirty = true;
      this._value = value;
    }
  }

  set(value: T) {
    if (this._value !== value) {
      if (!this.parent.dirty) {
        this.parent.dirtyCallback?.(value);
      }
      this.parent.dirty = true;
      this._value = value;
    }
  }

  get(): T {
    return this._value;
  }
}

export class Component implements GetsDirty<Component> {
  static type = -1;
  owner = -1;
  dirty = false;
  onDirty?: (me: Component) => void;

  static get componentName() {
    return this.name;
  }

  get componentName() {
    return this.constructor.name;
  }

  dirtyCallback(_changed: any) {
    this.onDirty?.(this);
  }

  update() {
    if (!this.dirty) {
      this.dirty = true;
      this.dirtyCallback(undefined);
    }
  }

  prop<T>(init: T) {
    return new Prop(init, this);
  }

  opt<T>() {
    return this.prop<Option<T>>(null);
  }

  /**
   * this allows us to interogate a type to<Component> see if it is a component type
   * @returns whether type is a type of Component
   */
  static get [ComponentSymbol](): boolean {
    return true;
  }

  /**
   * this allows us to interogate an object to see if it is a component
   * @returns whether an object is a Component
   */
  get [ComponentSymbol](): boolean {
    return true;
  }

  /**
   * get the registerd type of this component
   */
  get type(): number {
    const inst = this.constructor as typeof Component;
    return inst.type;
  }
  /**
   * set the type number for all components of this type
   */
  set type(value: number) {
    const inst = this.constructor as typeof Component;
    inst.type = value;
  }
}

export class ValueComponent<T> extends Component {
  _value: Prop<T>;

  constructor() {
    super();

    // this isn't type-safe because we're mimicking
    // our bad habit of declaring props as mandatory using `!`
    // but not actually initializing them in the constructor
    this._value ||= this.prop(undefined as T);
  }

  get value() {
    return this._value.val;
  }

  set value(value) {
    this._value.val = value;
  }

  get val() {
    return this._value.val;
  }

  set val(value) {
    this._value.val = value;
  }

  set(value: T) {
    this._value.set(value);
  }

  get(): T {
    return this._value.get();
  }
}
