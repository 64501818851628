import { InterpreterWorker, ModelPropertyValueUpdate } from 'types';
import { evaluator } from './evaluator';
import { finalizer } from './finalizer';
import { parser } from './parser';
import { preparser } from './preparser';
import { renameFinalizer } from './renameFinalizer';
import { renameParser } from './renameParser';
import { renamePreparser } from './renamePreparser';
import { renamer } from './renamer';

export const defaultWorkers: InterpreterWorker<any, any>[] = [
  preparser,
  parser,
  evaluator,
  finalizer,
];

export const renamerWorkers: InterpreterWorker<
  ModelPropertyValueUpdate[],
  any
>[] = [renamePreparser, renameParser, renamer, renameFinalizer];
