export const SCHEMA_NAME = 'media';

const LibraryUpdateConstants = {
  ARCHIVING: 'library/UPDATE/ARCHIVING',
  COLUMN_LIST: 'library/UPDATE/COLUMN_LIST',
  CREATING_NEW_FOLDER: 'library/UPDATE/CREATING_NEW_FOLDER',
  CRUMB_PATH: 'library/UPDATE/CRUMB_PATH',
  CURRENT_FOLDER: 'library/UPDATE/CURRENT_FOLDER',
  DELETING: 'library/UPDATE/DELETING',
  CANCELING: 'library/UPDATE/CANCELING',
  UPLOADING_COUNT: 'library/UPDATE/UPLOADING_COUNT',
  SELECTED_FILE_ID_NOT_ON_PAGE: 'library/UPDATE/SELECTED_FILE_ID_NOT_ON_PAGE',
  EDITING_FILE: 'library/UPDATE/EDITING_FILE',
  LOADING: 'library/UPDATE/LOADING',
  NEW_NAME: 'library/UPDATE/NEW_NAME',
  PAGINATION: 'library/UPDATE/PAGINATION',
  RENAMING_FILE: 'library/UPDATE/RENAMING_FILE',
  DOWNLOADING_FILE: 'library/UPDATE/DOWNLOADING_FILE',
  SCROLLED_RIGHT: 'library/UPDATE/SCROLLED_RIGHT',
  SELECTED: 'library/UPDATE/SELECTED',
  SELECTED_FOLDER: 'library/UPDATE/SELECTED_FOLDER',
  SHOW_DELETE_FILE_CONFIRMATION_MODAL:
    'library/UPDATE/SHOW_DELETE_FILE_CONFIRMATION_MODAL',
  SHOW_DELETE_PROJECT_CONFIRMATION_MODAL:
    'library/UPDATE/SHOW_DELETE_PROJECT_CONFIRMATION_MODAL',
  SHOW_MODEL_PROCESSING_WARNING_MODAL:
    'library/UPDATE/SHOW_MODEL_PROCESSING_WARNING_MODAL',
  SHOW_UPLOAD_MODAL: 'library/UPDATE/SHOW_UPLOAD_MODAL',
  SHOW_PREVIEW: 'library/UPDATE/SHOW_PREVIEW',
  PREVIEW_FILE: 'library/UPDATE/PREVIEW_FILE',
  SHOW_INVALID_FLYOUT: 'library/UPDATE/SHOW_INVALID_FLYOUT',
  SHOW_PERMISSIONS_FLYOUT: 'library/UPDATE/SHOW_PERMISSIONS_FLYOUT',
  SHOW_TAG_FLYOUT: 'library/UPDATE/SHOW_TAG_FLYOUT',
  SHOW_FILE_PATH: 'library/UPDATE/SHOW_FILE_PATH',
  SORT: 'library/UPDATE/SORT',
  // GENERIC: 'library/UPDATE/',
  COLUMN_WIDTH: 'library/UPDATE/COLUMN_WIDTH',
  VIEW_TYPE: 'library/UPDATE/VIEW_TYPE',
} as const;

const LibraryFetchConstants = {
  MEDIA: 'library/FETCH/MEDIA',
} as const;

type LibraryConstant<
  U extends keyof typeof LibraryUpdateConstants,
  F extends keyof typeof LibraryFetchConstants
> = {
  UPDATE: {
    [K in U]: `library/UPDATE/${K}`;
  };
  FETCH: {
    [K in F]: `library/FETCH/${K}`;
  };
};

export const LIBRARY: LibraryConstant<
  KeyOf<typeof LibraryUpdateConstants>,
  KeyOf<typeof LibraryFetchConstants>
> = {
  UPDATE: LibraryUpdateConstants,
  FETCH: LibraryFetchConstants,
};

export const LIBRARY_GENERIC = 'library/UPDATE/' as const;
