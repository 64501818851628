import { APP } from 'modules/common/constants';
import { EXPRESSIONS } from 'modules/common/constants';
import { MODEL } from 'utils/constants';

export const initialState = {
  showModal: '',
  searchEnabled: false,
  expressions: {
    isRunning: false,
  },
  grid: {
    display: false,
    snap: false,
  },
};

export const reducer = (state = initialState, action) => {
  const newState = { ...state };
  switch (action.type) {
    case APP.MODAL.SHOW:
      return { ...state, showModal: action.payload };
    case APP.MODAL.HIDE:
      return { ...state, showModal: '' };
    case APP.TOOLBAR.SETTINGS:
      newState.toolbarColor = action.payload.color;
      newState.cuiDisplay = action.payload.cui_display;
      newState.searchEnabled = action.payload.search_enabled;
      if (action.payload.logo?.url !== undefined) {
        newState.logoUrl = action.payload.logo?.url;
      }
      return newState;
    case EXPRESSIONS.IS_RUNNING:
      return {
        ...state,
        expressions: {
          ...state.expressions,
          isRunning: action.payload,
        },
      };
    case MODEL.RESET:
      const display = false;
      const snap = false;

      return {
        ...state,
        grid: {
          ...state.grid,
          display,
          snap,
        },
      };
    case APP.GRID.DISPLAY:
      return {
        ...state,
        grid: {
          ...state.grid,
          display: !state.grid.display,
        },
      };
    case APP.GRID.SNAP:
      return {
        ...state,
        grid: {
          ...state.grid,
          snap: !state.grid.snap,
        },
      };
    default:
      return state;
  }
};
