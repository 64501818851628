import cloneDeep from 'clone-deep';
import { Reducer } from 'redux';
import {
  MetraSimpleAction,
  MetraConstant,
  MetraConstantValues,
  MetraBasicAction,
} from 'types';
import { isSimpleAction } from './api';
import { t3dev } from 't3dev';

export declare type GenericValueReducer<Payload, State> = (
  prefix: string,
  initialValue: State
) => (state: State, action: MetraSimpleAction<Payload>) => State;

export declare type ActionHandler<State, Payload = any> = (
  state: State,
  payload: Payload
) => State;

export declare type ActionHandlerObject<
  State,
  Payload = any,
  E extends readonly string[] = any[]
> = {
  [Key in E[number]]: ActionHandler<State, Payload>;
};

/**
 * Provided an action prefix, returns a reducer who's state is the payload
 * of actions beginning with that prefix
 * @param actionPrefix - E.g. `NODE` is a prefix of `NODE_CREATE` actions
 * @param initialValue - Starting value of this reducer
 * @return - returns the action payload or the current value if action doesn't have prefix.
 */
export function genericValueReducer<State, Payload extends State = State>(
  actionPrefix: string,
  initialValue: State
) {
  const reducer: Reducer<State, MetraBasicAction<Payload>> = (
    value,
    action
  ) => {
    value ??= initialValue;
    let newValue: State;
    // WARNING: it is illegal in redux to return undefined state
    // so we ignore undefined payloads
    if (
      isSimpleAction(action) &&
      actionPrefix.includes(action.type) &&
      action.payload !== undefined
    ) {
      newValue = action.payload;
    } else {
      newValue = value;
    }

    return newValue;
  };

  return reducer;
}

export const createInNormalStore: ActionHandler<any, any> = (
  state,
  payload
) => {
  let data = !Array.isArray(payload) ? [payload] : payload;
  data = data.reduce((acc, record) => {
    acc[record.id] = record;
    return acc;
  }, {});
  return {
    ...state,
    ...data,
  };
};

export const updateInNormalStore: ActionHandler<any, any> = createInNormalStore;

export const deleteFromNormalStore: ActionHandler<any, any> = (
  state,
  payload
) => {
  const data = Array.isArray(payload) ? payload : [payload];
  return data.reduce((newState, record) => {
    delete newState[record.id];
    return newState;
  }, cloneDeep(state));
};

/**
 * Given a base type object, creates a map of ActionTypes to event handlers.
 * Intended for use with reducerFromHandlers
 * @param  baseTypes - the object of baseTypes to use for the basic CUD actions
 * @param  extraTypes - a mapping of additional action types and handlers to include in the result
 * @returns  - A new object that can be consumed by the reducerFromHandlers
 */
export function generateDefaultActionHandlers<
  State = any,
  Payload = any,
  const T extends string = any,
  const E extends readonly string[] = any[]
>(
  baseTypes: MetraConstant<T, 'create' | 'update' | 'delete' | E[number]>,
  extraTypes: ActionHandlerObject<State, Payload, E>
): ActionHandlerObject<State, Payload, E> {
  return {
    [baseTypes.CREATE]: createInNormalStore,
    [baseTypes.UPDATE]: updateInNormalStore,
    [baseTypes.DELETE]: deleteFromNormalStore,
    ...extraTypes,
  };
}

// * Given a mapping of action types to their associated handlers, creates a new reducer
// * that matches actions to handlers and allows them to mutate state

export function reducerFromHandlers<
  State = any,
  Payload = any,
  const T extends string = any,
  const E extends readonly string[] = any[]
>(handlerMap: ActionHandlerObject<State, Payload, E>) {
  const reducer: Reducer<Option<State>, MetraBasicAction<Payload>> = (
    state,
    action
  ) => {
    const actionType = action.type as MetraConstantValues<
      T,
      'create' | 'update' | 'delete' | E[number]
    >;
    let newState;
    if (actionType in handlerMap) {
      const handler = handlerMap[actionType];
      if (!handler) return state;
      if ('payload' in action && state) {
        newState = handler(state, action.payload);
      } else {
        newState = state;
      }
    } else {
      newState = state;
    }
    // if newState is undefined, redux requires we return `null`
    if (newState === undefined) newState = null;
    return newState;
  };
  return reducer;
}
