import { topContainerSet } from 'helpers/sets/utils';

/**
 * Determine the endpoint for an edge.
 * It may be anchored to a node or a collapsed set.
 * @param {string} id the id of the node to which this end of the edge is connected
 * @param {import('types').ModelReducer} model
 * @returns {string} the id of the entity to which the edge appears to be anchored
 */
export const getEdgeAnchorId = (id, model) => {
  const {
    sets,
    shapes,
    nodes,
    base: { collapsed },
  } = model;
  let nodeSetIds = nodes[id]?.setIds;
  let collapsedId = nodeSetIds.reduce(
    (value, setId) => value || collapsed.sets[setId],
    null
  );

  if (shapes[collapsedId]) {
    const colSetId = collapsed.shapes[collapsedId];
    const collapsedSetIds = Object.keys(collapsed.sets);
    let top = topContainerSet(colSetId, collapsedSetIds, model);
    let collapsedShape = shapes[collapsed.sets[top]];
    if (collapsedShape.visible === false) {
      nodeSetIds.forEach((setId) => {
        let nSet = sets[setId];
        if (nSet.visible && nSet.collapsed) {
          top = setId;
        }
      });
    }
    if (top) collapsedId = collapsed.sets[top];
  }

  if (collapsedId && shapes[collapsedId].visible) {
    return collapsedId;
  }
  return id;
};
