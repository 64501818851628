/*eslint no-unused-expressions: "off"*/
import {
  AnyMetraMathNode,
  EvaluatorCache,
  KahnsPlan,
  MetraScriptScope,
  QualifiedRef,
} from 'types';
import { FeatureFlags } from 'utils/features';
import { isNone, isSome } from 'helpers/utils';
import { getInterpreter, isNamedNode } from 'interpreter/helpers';
import {
  DEVMODE,
  ERROR,
  UNEVALUATED,
  EVALUATED,
  PARSED,
} from 'interpreter/constants';
import { parse, mathjs } from 'interpreter/t3math';
import {
  serialize,
  makeScriptsScope,
  indexAdjustCallback,
  parseExp,
  parseLane,
} from 'interpreter/t3math-extra';
import type { Interpreter } from 'interpreter/interpreter';
import type { Expression } from 'interpreter/expression';
import {
  MetraNode,
  makeMetraMathAccessorNode,
  makeMetraMathNode,
  makeMetraNode,
} from 'interpreter/nodes';

function inlineCallback<Results, Options>(
  cache: EvaluatorCache,
  rootRef: QualifiedRef,
  mathNode: AnyMetraMathNode,
  itpr: Interpreter<Results, Options>
): AnyMetraMathNode {
  const root = itpr.getExp(rootRef);
  if (isNone(root)) return mathNode;

  let modified = mathNode;

  // inline cell references for accessors:
  // C4.x becomes { x: 42 }.x
  if (mathjs.isAccessorNode(mathNode)) {
    const dep = isNamedNode(mathNode.object) && itpr.grab(mathNode.object.name);
    if (dep) {
      const value = parse(serialize(dep.evaluated));

      modified = makeMetraMathAccessorNode(
        mathNode,
        makeMetraMathNode(value, dep.ref, root.ref, mathNode.name),
        root.ref
      );
    }
  } else if (isNamedNode(mathNode)) {
    const dep = itpr.grab(mathNode.name);
    if (dep) {
      modified = parseExp(dep.ref, root.ref, itpr);
    }
    const lane = itpr.grabLane(mathNode.name, root.ent);
    if (lane) {
      modified = parseLane(cache, lane, root.ref, itpr);
    }
  }
  return modified;
}

/**
 * raw functions can access these values to do meta stuff within mathjs
 */
function instrumentationCallback<R, O>(
  mathNode: AnyMetraMathNode,
  root: Expression<R, O>
): MetraNode {
  return makeMetraNode(mathNode, root.ref);
}

/**
 * The evaluator is special because it's currently the only worker which
 * uses a while loop to act on Expressions that have no unevaluated
 * dependencies.
 *
 * We use Kahn's Algorithm to create a list of expressions sorted by their
 * dependency requirements.
 *
 * First we traverse the parsed tree for an expression to:
 * - replace dependency Cell References with their evaluated values
 * - fix array indexes since MathJS makes them 1-based by default
 * - add metadata to each node, which can be used by custom MathJS functions
 *
 * Then we evaluate the modified tree to get an evaluated expression
 */
export function evaluator<Results, Options>(
  itpr: Interpreter<Results, Options>
): Interpreter<Results, Options> {
  const cache = {
    row: {
      modelCalcs: {},
      modelProps: {},
      nodes: {},
      edges: {},
      sets: {},
      paths: {},
    },
    column: {
      modelCalcs: {},
      modelProps: {},
      nodes: {},
      edges: {},
      sets: {},
      paths: {},
    },
  } as EvaluatorCache;

  const [plan, unplanned] = kahnsPlan<Results, Options>(itpr);
  itpr.onlySave = [...plan, ...unplanned];

  const scriptsScope = makeScriptsScope<math.MathNode>(itpr.state);

  plan.forEach((ready) => {
    const deps = ready.deps;
    if (!deps) {
      return;
    }

    // if dependencies have errors, we cannot evaluate
    const errorDeps = deps.dependencyList.filter(
      (dep) => dep.exp && dep.exp.hasErrors
    );
    if (errorDeps.length > 0) {
      const list = errorDeps.map((e) => (e.exp ? e.exp.ref : '')).join(', ');
      ready.errors.push(`Error in referenced cells: ${list}`);
      ready.evaluated = 'ERROR';
      ready.status = ERROR;
      return;
    }

    if (isNone(ready.parsed) && !ready.hasErrors) {
      ready.preparsed = '';
      ready.parsed = new mathjs.ConstantNode('');
      ready.status = PARSED;
    }

    // script scope will be something like this:
    // const val = scriptsScope['hi'];
    // const foo = val<[string, number, Error]>('hi', 5, new Error(''));

    try {
      if (
        isSome(ready.parsed) &&
        ready.status === PARSED &&
        'transform' in ready.parsed
      ) {
        let transformed = ready.parsed.transform((mathNode) =>
          inlineCallback(cache, ready.ref, mathNode, itpr)
        );
        // we traverse because we're modifying nodes, not replacing them
        transformed.traverse(indexAdjustCallback);
        transformed.traverse((mathNode) =>
          instrumentationCallback(mathNode, ready)
        );
        ready.evaluated = transformed.evaluate({
          ...scriptsScope,
          currentEnt: ready,
        });

        if (isNone(ready.evaluated)) {
          ready.evaluated = '';
        }
        itpr.scope[ready.ref] = ready;
        ready.status = EVALUATED;
      }
      if (ready.status === UNEVALUATED) {
        ready.evaluated = 'ERROR';
        ready.status = ERROR;
        ready.errors.push(
          'Could not resolve: circular or undefined cell reference'
        );
      }
    } catch (e: any) {
      let message = e.message;
      const index = e.index;
      if (e instanceof RangeError || message.includes('Index out of range')) {
        if (index < 0) message = `Index out of range (${index} < 0)`;
      }
      if (message === 'SymbolNode or AccessorNode expected as "object"')
        message = 'Cannot assign values to a cell reference';
      ready.errors.push(message);
      if (DEVMODE)
        console.error(
          `(mpt expression error when processing ${ready.ref}) `,
          e
        );
    }
  });

  unplanned.forEach((exp) => {
    if (isNone(exp)) return;
    exp.errors.push('Could not resolve: circular or undefined cell reference');
    exp.evaluated = 'ERROR';
    exp.status = ERROR;
  });

  return itpr;
}

/* use Kahn's algorithm to generate an evaluation plan.
 * A slight variation on the standard algorithm: we decrement unsatisfiedDeps
 * to -1 for queued up items so that they cannot be queued up again (because
 * we only look for ones that equal 0 exactly). This is important because
 * Kahn's is designed for acyclic graphs but if our users accidentally add
 * cyclic references, we need to handle them gracefully
 */
export function kahnsPlan<R, O>(itpr: Interpreter<R, O>): KahnsPlan<R, O> {
  itpr.expressionRefs.forEach((ref) => {
    itpr.populateDependencies(ref);
  });

  let queue: Expression<R, O>[] = [];
  let unplanned: OptionRecord<QualifiedRef, Expression<R, O>> = {};

  // modified may have been initialized to a blank array, so check its length
  const list = itpr?.modified?.length
    ? getListFromModified(itpr)
    : itpr.expressionList;

  queue = list.filter((exp) => {
    Object.add(unplanned, exp.ref, exp);
    return exp.unsatisfiedDeps === 0;
  });

  const sorted: Expression<R, O>[] = [];
  let exp: Option<Expression<R, O>>;
  while (isSome((exp = queue.pop()))) {
    delete unplanned[exp.ref];
    sorted.push(exp);
    if (!exp.deps) continue;
    exp.unsatisfiedDeps -= 1;
    const deps = exp.deps.dependantList;
    for (let i = deps.length; i--; ) {
      const dep = deps[i];
      if (!dep.exp) continue;
      dep.exp.unsatisfiedDeps -= 1;
      if (dep.exp.unsatisfiedDeps === 0) {
        queue.push(dep.exp);
      }
    }
  }

  const maybeUnplanned = Object.values(unplanned);

  itpr.dependencyCache.freeze();
  return [sorted, maybeUnplanned];
}

export function getListFromModified<R, O>(
  itpr: Interpreter<R, O>
): Expression<R, O>[] {
  const list: Expression<R, O>[] = [];

  const seenDependants = new Set<QualifiedRef>();
  const seenDependencies = new Set<QualifiedRef>();

  let refsToCheck: QualifiedRef[] = itpr.modified ? [...itpr.modified] : [];
  // full dep calc via recursive iteration
  for (let i = 0; i < refsToCheck.length; i++) {
    const modRef = refsToCheck[i];
    if (isNone(modRef)) continue;
    // since modified were constructed in a different manner than the
    // internal expressions and dependencies, we need to pull those
    // instead of using them directly
    const checking = itpr.grab(modRef);
    if (isNone(checking) || seenDependants.has(checking.ref)) continue;
    seenDependants.add(checking.ref);
    list.push(checking);

    const deps = checking.deps;

    if (deps) {
      let toProcess = [...deps.dependencyRefs];
      for (let j = 0; j < toProcess.length; j++) {
        const ref = toProcess[j];
        if (seenDependencies.has(ref)) continue;
        seenDependencies.add(ref);
        const exp = itpr.grab(ref);
        const dep = itpr.getDeps(ref);

        // add the new reference;
        exp && list.push(exp);

        if (!dep) continue;
        const depDeps = dep.dependencyRefs;
        if (depDeps.length) {
          toProcess.add(depDeps);
        }
      }

      const more = [...deps.dependantRefs];
      for (let i = 0; i < more.length; i++) {
        const ref = more[i];
        const exp = itpr.grab(ref);
        if (exp && !seenDependants.has(ref)) {
          refsToCheck.push(exp.ref);
        }
      }
    }
  }

  return list;
}
