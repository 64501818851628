import { MPT_CONTEXT_MENU } from 'modules/common';
import { MetraSimpleAction, MPTContextMenuReducer } from 'types';

export const initialMptContextMenuState: MPTContextMenuReducer = {
  show: false,
  x: 0,
  y: 0,
};

export const mptContextMenuReducer = (
  state = initialMptContextMenuState,
  action: MetraSimpleAction<Partial<MPTContextMenuReducer>>
): MPTContextMenuReducer => {
  switch (action.type) {
    case MPT_CONTEXT_MENU.SHOW: {
      const payload = action.payload;
      return {
        show: true,
        x: payload.x || 0,
        y: payload.y || 0,
        rowId: payload.rowId,
        columnId: payload.columnId,
      };
    }
    case MPT_CONTEXT_MENU.HIDE: {
      return {
        show: false,
        x: state.x,
        y: state.y,
      };
    }
    default:
      return state;
  }
};
