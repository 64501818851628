import type { Reducer } from 'redux';
import { MetraSimpleAction } from 'types';
import { MODEL_HISTORY } from './actions';
import { ActionSignatures, HistoryState } from './types';

export const initialState: HistoryState = {
  undoActions: [],
  redoActions: [],
};

export const reducer: Reducer<
  HistoryState,
  MetraSimpleAction<ActionSignatures>
> = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case MODEL_HISTORY.CREATE: {
      return {
        ...state,
        undoActions: [...state.undoActions, payload],
      };
    }
    case MODEL_HISTORY.UNDO: {
      const actionSignature = state.undoActions[state.undoActions.length - 1];

      if (actionSignature) {
        return {
          undoActions: state.undoActions.slice(0, -1),
          redoActions: [...state.redoActions, actionSignature],
        };
      }

      return state;
    }
    case MODEL_HISTORY.REDO: {
      const actionSignature = state.redoActions[state.redoActions.length - 1];

      if (actionSignature) {
        return {
          undoActions: [...state.undoActions, actionSignature],
          redoActions: state.redoActions.slice(0, -1),
        };
      }

      return state;
    }
    case MODEL_HISTORY.CLEAR_REDO: {
      return {
        undoActions: state.undoActions,
        redoActions: [],
      };
    }
    case MODEL_HISTORY.CLEAR: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};
