import cloneDeep from 'clone-deep';
import { reducerFromHandlers } from 'modules/common';
import { PROPERTY_VALUE } from 'utils/constants';

/*******************
 * Action Types
 *******************/

/*******************
 * Action Creators
 *******************/

/**
 * @params {Partial<import('types').ModelPropertyValueUpdate>} args
 * @returns {import('types').ModelPropertyValueUpdate} a new model property value
 */
export const createPropertyValue = ({ parentId, schemaId, value }) => ({
  parentId,
  schemaId,
  value,
});

/**
 * Makes an action for creating new property values. Note that propertyValues have a compound (parentId, schemaId) unique identifier.
 *
 * @param {import('types').ModelPropertyValueUpdate} value - the passed property
 * @return {import('types').MetraSimpleAction<import('types').ModelPropertyValueUpdate[]>} A property value creation action
 */
export const setPropertyValue = (value) => setPropertyValues([value]);

/**
 * Makes an action for creating new property values.
 * Note that propertyValues have a compound (parentId, schemaId) unique identifier.
 * @param {import('types').ModelPropertyValueUpdate[]} values - the passed property
 * @return {import('types').MetraSimpleAction<
 * import('types').ModelPropertyValueUpdate[]>} A property value creation action
 */
export const setPropertyValues = (values) => {
  let trimmedValues = values.map((value) => {
    let theValue = value.value;
    theValue = theValue && theValue.trim();
    return {
      ...value,
      value: theValue,
    };
  });
  return {
    type: PROPERTY_VALUE.UPDATE,
    payload: trimmedValues,
  };
};

/**
 * Makes an action for clearing property values (making them undefined). Note that propertyValues have a compound (parentId, schemaId) unique identifier.
 *
 * @param {import('types').ModelPropertyValueUpdate} value
 * @return {import('types').MetraSimpleAction<
 *   import('types').ModelPropertyValueUpdate[]>
 * } A property value delete action
 */
export const clearPropertyValue = (value) => clearPropertyValues([value]);
/**
 * @param {import('types').ModelPropertyValueUpdate} value
 * @return {import('types').MetraSimpleAction<
 *   import('types').ModelPropertyValueUpdate[]>
 * } A property value delete action
 */
export const clearPropertyValues = (values) => ({
  type: PROPERTY_VALUE.DELETE,
  payload: values,
});

export const initPropertyValues = (values) => ({
  type: PROPERTY_VALUE.INITIALIZE,
  payload: values,
});

/*******************
 * Reducer
 *******************/

/**
 * @param {import('types').ModelReducer['propValues']} state
 * @param {import('types').ModelPropertyValueUpdate[]} values
 */
export const propValuesUpdateReducer = (state, values) => {
  const newState = cloneDeep(state);
  values.forEach((v) => {
    const { parentId, schemaId, value } = v;
    newState[parentId] = newState[parentId] || {};

    // prune empty cells to save memory
    if (value.trim() === '') {
      delete newState[parentId][schemaId];
      if (Object.keys(newState[parentId]).length < 1) {
        delete newState[parentId];
      }
    } else {
      newState[parentId][schemaId] = value;
    }
  });
  return newState;
};

export const deleteReducer = (state, values) => {
  const newState = { ...state };
  values.forEach((value) => {
    const newPropValDict = { ...newState[value.parentId] };
    delete newPropValDict[value.schemaId];
    newState[value.parentId] = newPropValDict;
  });
  return newState;
};

export const initPropValuesReducer = (_state, values = {}) => {
  return values;
};

/**
 * Map the action types to the handlers for generic reducer usage.
 * @readonly
 * enum {function}
 */
export const handlerMap = {
  [PROPERTY_VALUE.DELETE]: deleteReducer,
  [PROPERTY_VALUE.INITIALIZE]: initPropValuesReducer,
};

export const propValuesReducer = reducerFromHandlers(handlerMap);
