import cloneDeep from 'clone-deep';
import { KBMCallback, KeyboardState } from 'types';
import { KEY } from './constants';
import { KEY_STATE } from './constants-extra';
import { t3dev } from 't3dev';
import { getIsMac } from './utils-extra';

export class KeyboardManager {
  private static _state: KeyboardState;

  static init() {
    KeyboardManager._state = {
      prev: {},
      curr: {},
      watchedKeys: {},
      suspended: 0,
      windows: [],
    };
    document.body.addEventListener('keyup', KeyboardManager.onEvent, {
      passive: false,
    });
    document.body.addEventListener('keydown', KeyboardManager.onEvent, {
      passive: false,
    });
    document.body.addEventListener('keypress', KeyboardManager.onEvent, {
      passive: false,
    });
    document.body.addEventListener('mouseup', KeyboardManager.onMouseEvent, {
      passive: false,
    });
    document.body.addEventListener('mousedown', KeyboardManager.onMouseEvent, {
      passive: false,
    });
    window.addEventListener('blur', KeyboardManager.onBlur, {
      passive: false,
    });
  }

  static addWindow = (win: Window) => {
    KeyboardManager._state.windows.push(win);
    win.document.body.addEventListener('keyup', KeyboardManager.onEvent, {
      passive: false,
    });
    win.document.body.addEventListener('keydown', KeyboardManager.onEvent, {
      passive: false,
    });
    win.document.body.addEventListener('keypress', KeyboardManager.onEvent, {
      passive: false,
    });
    win.document.body.addEventListener(
      'mouseup',
      KeyboardManager.onMouseEvent,
      {
        passive: false,
      }
    );
    win.document.body.addEventListener(
      'mousedown',
      KeyboardManager.onMouseEvent,
      {
        passive: false,
      }
    );
  };

  static removeWindow = (win: Window) => {
    KeyboardManager._state.windows = KeyboardManager._state.windows.filter(
      (w) => w !== win
    );
    win.document.body.removeEventListener('keyup', KeyboardManager.onEvent);
    win.document.body.removeEventListener('keydown', KeyboardManager.onEvent);
    win.document.body.removeEventListener('keypress', KeyboardManager.onEvent);
    win.document.body.addEventListener('mouseup', KeyboardManager.onMouseEvent);
    win.document.body.addEventListener(
      'mousedown',
      KeyboardManager.onMouseEvent
    );
  };

  static clearState = () => {
    KeyboardManager._state = {
      prev: {},
      curr: {},
      watchedKeys: {},
      suspended: 0,
      windows: [],
    };
  };

  static resetState = () => {
    KeyboardManager._state.prev = {};
    KeyboardManager._state.curr = {};
  };

  static destroy = () => {
    document.body.removeEventListener('keyup', KeyboardManager.onEvent);
    document.body.removeEventListener('keydown', KeyboardManager.onEvent);
    document.body.removeEventListener('keypress', KeyboardManager.onEvent);
    document.body.removeEventListener('mouseup', KeyboardManager.onMouseEvent);
    document.body.removeEventListener(
      'mousedown',
      KeyboardManager.onMouseEvent
    );
    window.removeEventListener('blur', KeyboardManager.onBlur);
    KeyboardManager._state.windows.forEach((win) => {
      KeyboardManager.removeWindow(win);
    });
    KeyboardManager.clearState();
  };

  static registerKey = (keycode: string, callback: KBMCallback) => {
    KeyboardManager._state.watchedKeys = {
      ...KeyboardManager._state.watchedKeys,
      [keycode]: callback,
    };
    // return deregister function after returning.
    return () => {
      KeyboardManager._state.watchedKeys &&
        delete KeyboardManager._state.watchedKeys[keycode];
    };
  };

  static onBlur = (_event: Event) => {
    KeyboardManager._state.prev = cloneDeep(KeyboardManager._state.curr);
    KeyboardManager._state.curr = {};
  };

  static onEvent = (event: KeyboardEvent) => {
    KeyboardManager.updateState(event);
    if (
      Object.prototype.hasOwnProperty.call(
        KeyboardManager._state.watchedKeys,
        event.key
      ) &&
      KeyboardManager._state.suspended === 0
    ) {
      KeyboardManager._state.watchedKeys[event.key](
        event.key,
        KeyboardManager._state
      );
    }
  };

  static onMouseEvent = (event: MouseEvent) => {
    // System-level shortcuts (i.e cmd+shift+5) prevent us from receiving keyup events.
    // We are adjusting them manually to prevent a bug that will keep them in a keydown state.
    if (event.shiftKey && !isShiftDown()) {
      KeyboardManager.updateState({
        key: KEY.SHIFT,
        type: KEY_STATE.DOWN,
      } as KeyboardEvent);
    }
    if (!event.shiftKey && isShiftDown()) {
      KeyboardManager.updateState({
        key: KEY.SHIFT,
        type: KEY_STATE.UP,
      } as KeyboardEvent);
    }

    if (event.ctrlKey && !isControlKeyDown()) {
      KeyboardManager.updateState({
        key: KEY.CTRL,
        type: KEY_STATE.DOWN,
      } as KeyboardEvent);
    }
    if (!event.ctrlKey && isControlKeyDown()) {
      KeyboardManager.updateState({
        key: KEY.CTRL,
        type: KEY_STATE.UP,
      } as KeyboardEvent);
    }

    if (event.metaKey && !isMetaKeyDown()) {
      KeyboardManager.updateState({
        key: KEY.META,
        type: KEY_STATE.DOWN,
      } as KeyboardEvent);
    }
    if (!event.metaKey && isMetaKeyDown()) {
      KeyboardManager.updateState({
        key: KEY.META,
        type: KEY_STATE.UP,
      } as KeyboardEvent);
    }

    if (event.altKey && !isAltDown()) {
      KeyboardManager.updateState({
        key: KEY.ALT,
        type: KEY_STATE.DOWN,
      } as KeyboardEvent);
    }
    if (!event.altKey && isAltDown()) {
      KeyboardManager.updateState({
        key: KEY.ALT,
        type: KEY_STATE.UP,
      } as KeyboardEvent);
    }
  };

  static resetModifierKeys = () => {
    KeyboardManager._state.prev = cloneDeep(KeyboardManager._state.curr);
    const keys = [
      'Shift',
      'ShiftLeft',
      'ShiftRight',
      'Control',
      'ControlLeft',
      'ControlRight',
      'Meta',
      'MetaLeft',
      'MetaRight',
      'OS',
      'OSLeft',
      'OSRight',
      'Alt',
      'AltLeft',
      'AltRight',
    ];
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      if (KeyboardManager._state.curr[key]) {
        KeyboardManager._state.curr[key] = {
          ...KeyboardManager._state.curr[key],
          [KEY_STATE.UP]: false,
          [KEY_STATE.DOWN]: false,
          [KEY_STATE.PRESS]: false,
          [KEY_STATE.REPEAT]: false,
        };
      }
    }
  };

  static updateState = (event: KeyboardEvent) => {
    KeyboardManager._state.prev = cloneDeep(KeyboardManager._state.curr);
    KeyboardManager._state.curr[event.key] = {
      ...KeyboardManager._state.curr[event.key],
      [KEY_STATE.UP]: event.type === KEY_STATE.UP ? true : false,
      [KEY_STATE.DOWN]: event.type === KEY_STATE.DOWN ? true : false,
      [KEY_STATE.PRESS]: event.type === KEY_STATE.PRESS ? true : false,
      [KEY_STATE.REPEAT]: event.repeat,
    };
  };

  static getState = () => {
    return KeyboardManager._state;
  };

  static isKeyDown(key: string, state = KeyboardManager._state) {
    if (!Object.prototype.hasOwnProperty.call(state.curr, key)) return false;
    return state.curr[key][KEY_STATE.DOWN] === true;
  }

  static isKeyUp(key: string, state = KeyboardManager._state) {
    if (!Object.prototype.hasOwnProperty.call(state.curr, key)) return false;
    return state.curr[key][KEY_STATE.UP] === true;
  }

  static isKeyPressed(key: string, state = KeyboardManager._state) {
    if (!Object.prototype.hasOwnProperty.call(state.prev, key)) return false;
    if (!Object.prototype.hasOwnProperty.call(state.curr, key)) return false;
    return state.curr[key][KEY_STATE.PRESS];
  }

  static isKeyToggled(key: string, state = KeyboardManager._state) {
    if (!Object.prototype.hasOwnProperty.call(state.prev, key)) return false;
    if (!Object.prototype.hasOwnProperty.call(state.curr, key)) return false;
    return state.prev[key][KEY_STATE.DOWN] && state.curr[key][KEY_STATE.UP];
  }

  static isRepeat(key: string, state = KeyboardManager._state) {
    if (!Object.prototype.hasOwnProperty.call(state.curr, key)) return false;
    return state.curr[key][KEY_STATE.REPEAT] === true;
  }

  static isSuspended() {
    return !!KeyboardManager._state.suspended;
  }

  static getWindowsArray() {
    return KeyboardManager._state.windows;
  }

  static suspend = () => {
    KeyboardManager._state.suspended++;
  };

  static resume = () => {
    // no need to resume
    if (KeyboardManager._state.suspended === 0) return;
    KeyboardManager._state.suspended--;
  };
}

/*
 * utility function for common use of testing shift state
 */
export const isShiftDown = () =>
  KeyboardManager.isKeyDown(KEY.SHIFT) ||
  KeyboardManager.isKeyDown(KEY.SHIFT_LEFT) ||
  KeyboardManager.isKeyDown(KEY.SHIFT_RIGHT);

/**
utility function for testing ALT (windows) or OPTION (Mac) state
@return true if an Alt / Option key is down; false otherwise.
 */
export const isAltDown = () =>
  KeyboardManager.isKeyDown(KEY.ALT) ||
  KeyboardManager.isKeyDown(KEY.ALT_LEFT) ||
  KeyboardManager.isKeyDown(KEY.ALT_RIGHT);

export const isSpaceKeyDown = () => KeyboardManager.isKeyPressed(KEY.SPACE);

/**
 * is cmd/ctrl held down?
 * Firefox uses OS_*, Chrome/Safari use META_*
 */
export const isMetaKeyDown = () =>
  KeyboardManager.isKeyDown(KEY.META) ||
  KeyboardManager.isKeyDown(KEY.META_LEFT) ||
  KeyboardManager.isKeyDown(KEY.OS_LEFT) ||
  KeyboardManager.isKeyDown(KEY.META_RIGHT) ||
  KeyboardManager.isKeyDown(KEY.OS_RIGHT);

export const isControlKeyDown = () =>
  KeyboardManager.isKeyDown(KEY.CTRL) ||
  KeyboardManager.isKeyDown(KEY.CTRL_LEFT) ||
  KeyboardManager.isKeyDown(KEY.CTRL_RIGHT);

export const isModifierKeyDown = () => {
  const isMac = getIsMac();

  if (isMac) {
    return isMetaKeyDown();
  }

  return isControlKeyDown();
};

t3dev().setValue('keyboard', KeyboardManager);
