import { ModelEdge, ModelNode, ModelPath, ModelReducer, ModelSet } from 'types';
import type { Interpreter } from 'interpreter/interpreter';
import { TABLE_EVENT } from 'utils/constants';
import { ENGINE, GRID } from 'utils/constants-extra';
import { EventManager } from 'metra-events';
import { isNone, isSome } from 'helpers/utils';
import { serialize } from 'interpreter/t3math-extra';
import { FINALIZED } from 'interpreter/constants';
import { removeRecord, setRecord } from 'interpreter/helpers';

/**
 * Finalizer simply saves results to the redux state.
 * It does a little clean-up to make sure data isn't left behind
 * when a cell moves between plain, calculated, and error
 */
export function finalizer<Results, Options>(
  itpr: Interpreter<ModelReducer, Options>
): Interpreter<Results, Options> {
  const list = itpr.onlySave || itpr.expressionList;
  const renamedNodes: ModelNode[] = [];
  const renamedEdges: ModelEdge[] = [];
  const renamedSets: ModelSet[] = [];
  const renamedPaths: ModelPath[] = [];

  list.forEach((exp) => {
    if (isNone(exp)) return;
    const { parentId, schemaId } = exp.ids;
    if (exp.isPlain) {
      removeRecord(itpr.state.expressions.values, parentId, schemaId);
      removeRecord(itpr.state.expressions.errors, parentId, schemaId);
    } else if (exp.hasErrors) {
      const errors = exp.errors.join('\n');
      setRecord(itpr.state.expressions.errors, parentId, schemaId, errors);
      removeRecord(itpr.state.expressions.values, parentId, schemaId);
    } else {
      let serialized!: string, serializationError!: string;
      try {
        serialized = serialize(exp.evaluated);
      } catch (e: any) {
        serializationError = `Serialization Error: ${e.message}`;
      }

      if (serializationError) {
        setRecord(
          itpr.state.expressions.errors,
          parentId,
          schemaId,
          serializationError
        );
        removeRecord(itpr.state.expressions.values, parentId, schemaId);
      } else {
        setRecord(
          itpr.state.expressions.values,
          parentId,
          schemaId,
          serialized
        );
        removeRecord(itpr.state.expressions.errors, parentId, schemaId);
      }
    }

    if (
      GRID.NAME_COLUMN.NODES === schemaId &&
      isSome(itpr.state.nodes[parentId])
    ) {
      renamedNodes.push(itpr.state.nodes[parentId]);
    } else if (
      GRID.NAME_COLUMN.EDGES === schemaId &&
      isSome(itpr.state.edges[parentId])
    ) {
      renamedEdges.push(itpr.state.edges[parentId]);
    } else if (
      GRID.NAME_COLUMN.SETS === schemaId &&
      isSome(itpr.state.sets[parentId])
    ) {
      renamedSets.push(itpr.state.sets[parentId]);
    } else if (
      GRID.NAME_COLUMN.PATHS === schemaId &&
      isSome(itpr.state.paths[parentId])
    ) {
      renamedPaths.push(itpr.state.paths[parentId]);
    }

    exp.status = FINALIZED;
  });

  if (itpr.results && 'shapes' in itpr?.results) {
    EventManager.emit(ENGINE.UPDATE.SHAPES.PROPERTIES, itpr.results.shapes);
  }
  if (renamedNodes.length) {
    EventManager.emit(TABLE_EVENT.NODE.LABEL.UPDATE, renamedNodes);
  }
  if (renamedEdges.length) {
    EventManager.emit(TABLE_EVENT.EDGE.LABEL.UPDATE, renamedEdges);
  }
  if (renamedSets.length) {
    EventManager.emit(TABLE_EVENT.SET.LABEL.UPDATE, renamedSets);
  }
  if (renamedPaths.length) {
    EventManager.emit(TABLE_EVENT.PATH.LABEL.UPDATE, renamedPaths);
  }

  return itpr as Interpreter<Results, Options>;
}
