import type { MetraConstant } from 'types';

/**
 * Given a type and an optional list of extra actions, returns an object
 * containing keys and """unique""" strings for redux actions
 * Action strings follow the pattern `${type}/{action}`
 * Where action is a verb such as create, update, or a value in the extras array
 *
 * @param actionType
 * @param extras
 * @returns an automatically generated `MetraConstant`
 */
export function genericActionsForType<
  const T extends string,
  const E extends readonly string[]
>(
  actionType: T,
  extras?: E
): MetraConstant<T, 'create' | 'update' | 'delete' | E[number]> {
  const result: any = {
    //}MetraConstant<typeof actionType, 'create' | 'update' | 'delete'>  = {
    CREATE: `${actionType}/CREATE`,
    UPDATE: `${actionType}/UPDATE`,
    DELETE: `${actionType}/DELETE`,
  };
  extras?.forEach((action) => {
    const key = action.toUpperCase();
    result[key] = `${actionType}/${key}`;
  });

  return result as MetraConstant<T, 'create' | 'update' | 'delete' | E[number]>;
}
