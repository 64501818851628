/*
  We set gid when the model loads to the highest unused number in the model.
  That way we can call gid() to get unique integer IDs for new items in the model reducer
*/

export type GID = string;

const gidRx = /^g\d+$/;
export const findHighestGid = (state: Record<string, any>, highest = 0) => {
  for (const key in state) {
    if (gidRx.test(key)) {
      const num = Number(key.slice(1));
      if (num > highest) {
        highest = num;
      }
    }

    const value = state[key];
    if (typeof value === 'object' && !Array.isArray(value)) {
      highest = findHighestGid(value, highest);
    }
  }

  return highest;
};

export const gidMaker = ({ prefix = 'g' } = {}) => {
  let _gid = 1;

  const setGid = (value: number) => {
    _gid = value;
  };

  const gid = () => {
    return `${prefix}${++_gid}`;
  };

  const newestGid = () => {
    return `${prefix}${_gid}`;
  };

  return { setGid, gid, newestGid };
};

export const { setGid, gid, newestGid } = gidMaker();
