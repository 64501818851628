/*
 * ENTITIES
 */

export const ENTITIES = {
  MUTATE_CREATE: 'entities/mutate/CREATE',
  MUTATE_READ: 'entities/mutate/READ',
  MUTATE_UPDATE_ONE: 'entities/mutate/update/ONE',
  MUTATE_UPDATE_MANY: 'entities/mutate/update/MANY',
  MUTATE_DELETE: 'entities/mutate/DELETE',
  ACTION_SUCCESS: 'entities/action/SUCCESS',
  USERS_LOADING: 'entities/users/LOADING',
} as const;

export const HOTKEY_MAP = {
  SHOW: 'model/hotkeyMap/SHOW',
  HIDE: 'model/hotkeyMap/HIDE',
  MOVE: 'model/hotkeyMap/MOVE',
} as const;

export enum LIBRARY_VIEW_TYPE {
  MEDIA_BY_FOLDER = 'byFolder',
  MODELS = 'allModels',
}

/**
 * MEDIA
 */

export const MEDIA = {
  ARCHIVE_FAILURE: 'media/ARCHIVE_FAILURE',
  GET_SUCCESS: 'media/GET_SUCCESS',
  GET_FAILURE: 'media/GET_FAILURE',
  GET_VERSION_FAILURE: 'media/GET_VERSION_FAILURE',
  CREATE_SUCCESS: 'media/CREATE_SUCCESS',
  CREATE_FAILURE: 'media/CREATE_FAILURE',
  CREATE_CANCELLED: 'media/CREATE_CANCELLED',
  DELETE_SUCCESS: 'media/DELETE_SUCCESS',
  DELETE_FAILURE: 'media/DELETE_FAILURE',
  CANCEL_FAILURE: 'media/CANCEL_FAILURE',
  FILTER_TAGS: 'media/filter/TAGS',
  ADD_TAG_TO_CACHE: 'media/tag/cache/ADD',
  REMOVE_TAGS_FROM_CACHE: 'media/tag/cache/REMOVE',
  CLEAR_TAG_CACHE: 'media/tag/cache/CLEAR',
  ADD_URL_TO_CACHE: 'media/download/cache/ADD',
  UPDATE_FAILURE: 'media/update/FAILURE',
  UPLOAD_ABORT: 'media/upload/ABORT',
  UPLOAD_SUCCESS: 'media/upload/SUCCESS',
  UPLOAD_FAILURE: 'media/upload/FAILURE',
  DOWNLOAD_SUCCESS: 'media/download/SUCCESS',
  DOWNLOAD_FAILURE: 'media/download/FAILURE',
  RESTORE_FAILURE: 'media/restore/FAILURE',
  MODAL_UPLOAD_TOGGLE: 'media/modal/upload/TOGGLE',
  MODAL_IMPORT_VERSION: 'media/modal/import/UPDATE_VERSION',
  MODEL_CREATE_FAILURE: 'media/model/CREATE_FAILURE',
  MODEL_UPDATE_FAILURE: 'media/model/UPDATE_FAILURE',
  MODIFIED_CHECK_SUCCESS: 'media/modified/check/SUCCESS',
  MODIFIED_CHECK_FAILURE: 'media/modified/check/FAILURE',
  QUERY_EXISTS: 'media/query/EXISTS',
} as const;

export const UPDATE_RECENT_TAGS = 'UPDATE_RECENT_TAGS';
