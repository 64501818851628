import {
  Assets,
  Graphics,
  Rectangle,
  Sprite as PSprite,
  Text as PText,
  TextStyle,
  Texture,
} from 'pixi.js';
import { v4 as uuid } from 'uuid';
import { clamp } from 'lodash';
import {
  ModelNode,
  ModelSet,
  ModelReducer,
  BackgroundConfig,
  CollapsedConfig,
  EdgeConfig,
  ModelShapeConfig,
  NodeConfig,
  ShapeConfig,
  TransitionData,
  TextConfig,
  WaypointTuple,
  DrawingConfig,
} from 'types';
import { getTextResolution, isNone, isOk } from 'helpers/utils';
import type { EcsInstance } from 'ecs/EcsInstance';
import type { Entity } from 'ecs/Entity';
import {
  Alpha,
  Asset,
  Background,
  Bindable,
  Bindings,
  Bounds,
  CollapseButton,
  Collapsed,
  CollapsedSet,
  Color,
  DarkMode,
  GIFSprite,
  Graphic,
  LabelRef,
  MultiSelect,
  Node,
  Order,
  Position,
  Related,
  Scale,
  Selectable,
  Selection,
  SetColors,
  ShapeUUID,
  Spatial,
  Sprite,
  Label,
  Visible,
  Aspect,
  Waypoint,
  MetraShape,
  Dimensions,
  ShapeStyle,
  Moveable,
  Resizeable,
  MetraLayer,
  MetraSet,
  MinScaledSize,
  Interactable,
  ActiveSetColor,
  Displayables,
  RestartGif,
  Hidden,
  Interactive,
  Mouse,
  Resource,
  Oriented,
  Drawing,
  DrawingBox,
  Renderable,
  Text,
  DrawingText,
  Selected,
} from 'engine/components';
import type { ModelEngine } from 'engine/engine';
import { RectangleRange } from 'engine/range';
import {
  DRAWING_MODE,
  ECS_GROUP,
  ECS_TAG,
  HEX_COLOR,
  HEX_STRING,
  LAYER,
  SIZING,
  TEXT_RESOLUTION,
} from 'utils/constants';
import { EVENT, EventModeType } from 'utils/constants-extra';
import { Vector2 } from 'utils/vector';
import { calcAspectRatioFit, colorParse } from 'utils/utils';
import { withAssetType } from 'modules/model/shape/shape';
import { getLabel } from 'modules/model/selectors';
import { determineSetVisibility } from 'helpers/sets/visibility';
import { setContainedInOtherCollapsedSet } from 'helpers/sets';
import { updateOrCreateGIFSprite, updateOrCreateLabel } from 'helpers/assets';
import { getAssetUrl } from 'modules/model/assets';
import { Component } from 'ecs/Component';

export function buildWaypoint(
  ecs: EcsInstance,
  owner: Entity,
  tuple: WaypointTuple,
  order: Order,
  index: number,
  visible = false
): Result<Entity> {
  const maybeEntity = ecs
    .create()
    .addWith(() => {
      const waypoint = new Waypoint();
      waypoint.value = index;
      return waypoint;
    })
    .addWith(() => {
      const related = new Related();
      related.value = owner;
      return related;
    })
    .add(new Moveable())
    .add(new Selectable())
    .addWith(() => {
      const layer = new MetraLayer();
      layer.value = LAYER.EDGE_WAYPOINT;
      return layer;
    })
    .addWith(() => {
      // waypoints need their own graphics so we can fire separate events and have
      // separate event owners that lead back to this Waypoint
      const graphic = new Graphic();
      graphic.asset = new Graphics();
      graphic.asset.zIndex = order.value + 1;
      graphic.asset.eventMode = EventModeType.STATIC;
      return graphic;
    })
    .addWith((builder) => {
      const displayables = new Displayables();
      displayables.val = [
        {
          textureName: null,
          asset: builder.get(Graphic).asset,
          layer: LAYER.EDGE_WAYPOINT,
        },
      ];
      return displayables;
    })
    .addWith(() => {
      const color = new Color();
      color.value = 0xf27b4e;
      return color;
    })
    .addWith(() => {
      const position = new Position();
      position.value = new Vector2(tuple[0], tuple[1]);
      return position;
    })
    .addWith(() => {
      const bounds = new Bounds();
      bounds.value = new Rectangle(0, 0, 5, 5);
      return bounds;
    })
    .addWith(() => {
      const scale = new Scale();
      scale.value = Vector2.identity;
      return scale;
    })
    .addWith((builder) => {
      const spatial = new Spatial();
      const pos = builder.get(Position).value;
      spatial.range = new RectangleRange(pos.x, pos.y, 5, 5);
      return spatial;
    })
    .add(new Selectable())
    .addWith(() => {
      const shapeUUID = new ShapeUUID();
      shapeUUID.value = uuid();
      return shapeUUID;
    })
    .add(new Bindable())
    .addWith((builder) => {
      const bindings = new Bindings();
      bindings.bindingName = builder.get(ShapeUUID).value;
      bindings.component = Graphic;
      bindings.events = [
        EVENT.CLICK,
        EVENT.MOUSEDOWN,
        EVENT.MOUSEMOVE,
        EVENT.MOUSEUP,
        EVENT.MOUSEUPOUTSIDE,
        EVENT.MOUSEOVER,
        EVENT.RIGHTDOWN,
        EVENT.RIGHTUP,
      ];
      return bindings;
    })
    .addMaybe(visible ? new Visible() : undefined)
    .tagWith((builder) => {
      return builder.get(ShapeUUID).value;
    })
    .build();

  return maybeEntity;
}

export function buildWaypoints(
  ecs: EcsInstance,
  owner: Entity,
  config: EdgeConfig,
  order: Order
): Entity[] {
  const entities: Entity[] = [];
  for (let i = 0; i < config.waypoints.length; i++) {
    const waypoint = buildWaypoint(ecs, owner, config.waypoints[i], order, i);
    isOk(waypoint) && entities.push(waypoint);
  }

  return entities;
}

export function buildLabel(
  ecs: EcsInstance,
  labelText: string,
  owner: Entity,
  config: ShapeConfig,
  entityGroup: string,
  order: Order,
  visible: boolean,
  collapsed: boolean,
  fontSize: number,
  darkMode: boolean,
  isEdge = false
): Result<Entity> {
  return ecs
    .create()
    .addWith(() => {
      const shapeUUID = new ShapeUUID();
      shapeUUID.value = config.id;
      return shapeUUID;
    })
    .addWith(() => {
      const layer = new MetraLayer();
      layer.value = LAYER.LABEL;
      return layer;
    })
    .add(new Selectable())
    .add(new Moveable())
    .add(new Alpha())
    .addWith(() => {
      const related = new Related();
      related.value = owner;
      return related;
    })
    .addWith(() => {
      const position = new Position();
      // initial position doesnt matter, it will be adjusted by
      // the appropriate system when it is added to the world
      // but it should not be zero (i.e., don't upset the QuadTree)
      // Setting the position to the shape config's position avoids a
      // bug that initially places the label at 0,0 when the model loads.
      position.value = new Vector2(config.pos.x, config.pos.y);
      return position;
    })
    .addWith(() => {
      const labelOrder = new Order();
      labelOrder.val = order.val;
      return labelOrder;
    })
    .addWith(() => {
      const [label] = updateOrCreateLabel(labelText, { fontSize }, darkMode);
      return label;
    })
    .addWith((builder) => {
      const displayables = new Displayables();
      const label = builder.get(Label);
      displayables.val = [
        {
          textureName: null,
          asset: label.asset,
          layer: LAYER.LABEL,
        },
      ];
      return displayables;
    })
    .addMaybeWith((builder) => {
      // only edge labels can rotate
      // and oriented collisions are slower, so we avoid them
      // unless necessary
      if (!isEdge) return;
      const text = builder.get(Label).asset;
      const oriented = new Oriented();
      oriented.ref.val = text;
      return oriented;
    })
    .addWith((_builder) => {
      const bounds: Bounds = new Bounds();

      // bounds will get set later by the Label System
      bounds.value = new Rectangle(0, 0, 0, 0);
      return bounds;
    })
    .add(new Bindable())
    .add(new Interactive())
    .addWith((builder) => {
      const bindings = new Bindings();
      bindings.bindingName = builder.get(ShapeUUID).value;
      bindings.component = Label;
      bindings.events = [
        EVENT.CLICK,
        EVENT.MOUSEDOWN,
        EVENT.MOUSEMOVE,
        EVENT.MOUSEOVER,
        EVENT.MOUSEUP,
        EVENT.MOUSEUPOUTSIDE,
        EVENT.RIGHTDOWN,
        EVENT.RIGHTUP,
      ];
      return bindings;
    })
    .addMaybe(!collapsed && visible ? new Visible() : undefined)
    .group(entityGroup)
    .build();
}

export function buildCollapseButtonLabel(
  ecs: EcsInstance,
  labelText: string,
  owner: Entity
): Entity {
  const entity = ecs.createEntity();

  const layer = new MetraLayer();
  layer.value = LAYER.LABEL;
  ecs.addComponent(entity, layer);

  const related = new Related();
  related.value = owner;
  ecs.addComponent(entity, related);

  const position = new Position();
  position.value = new Vector2(Math.random(), Math.random());
  ecs.addComponent(entity, position);

  const label = new Label();
  label.value = labelText;
  const textStyle = new TextStyle({
    fill: 0x48454a, // $theme-pallette-smoke HSL(285,3,28)
    fontFamily: 'Roboto,sans-serif',
    fontSize: 14, // .797em
    fontWeight: 'bold',
    lineJoin: 'round',
    align: 'center',
    stroke: 0xffffff,
    strokeThickness: 5,
  });
  label.asset = new PText(labelText, textStyle);
  label.asset.resolution = 3;
  label.asset.anchor.set(0.5, 0.5);
  ecs.addComponent(entity, label);

  const displayables = new Displayables();
  displayables.val = [
    {
      textureName: null,
      asset: label.asset,
      layer: LAYER.LABEL,
    },
  ];
  ecs.addComponent(entity, displayables);

  const bounds: Bounds = new Bounds();
  const localBounds = label.asset.getLocalBounds();
  const scale = label.asset.scale;
  bounds.value = new Rectangle(
    0,
    0,
    localBounds.width / scale.x,
    localBounds.height / scale.y
  );
  ecs.addComponent(entity, bounds);

  const spatial = new Spatial();
  spatial.point = position.value.toObject();
  ecs.addComponent(entity, spatial);

  return entity;
}

export function buildNode(
  ecs: EcsInstance,
  config: NodeConfig,
  modelNode: ModelNode,
  model: ModelReducer,
  showNodeLabels: boolean,
  hiddenSetsMap: Set<string>,
  engine: ModelEngine
): Result<Entity, Error> {
  const {
    sets,
    nodes,
    base: { allSetsAlpha, allNodesAlpha, darkMode },
  } = model;

  return ecs
    .create()
    .addWith(() => {
      const shapeUUID = new ShapeUUID();
      shapeUUID.value = config.id;
      return shapeUUID;
    })
    .add(new Node())
    .add(new Interactable())
    .add(new Selectable())
    .add(new Moveable())
    .addWith(() => {
      const minScale = new MinScaledSize();
      minScale.value = 8.0;
      return minScale;
    })
    .addWith(() => {
      const resizeable = new Resizeable();
      resizeable.componentId = Scale.type;
      return resizeable;
    })
    .addWith(() => {
      const layer = new MetraLayer();
      layer.value = config.layer;
      return layer;
    })
    .addWith(() => {
      const order: Order = new Order();
      order.value = config.order;
      return order;
    })
    .addWith(() => {
      const position: Position = new Position();
      position.value = Vector2.fromPoint(config.pos);
      return position;
    })
    .addWith(() => {
      const color = new Color();
      color.value = colorParse(config.color);
      return color;
    })
    .addWith((builder) => {
      const alphaComp = new Alpha();
      let alpha = config.alpha;
      const setIds = nodes[builder.get(ShapeUUID).value]?.setIds ?? [];
      // apply set alphas multiplicatively for each set that owns this shape
      setIds.forEach((sid) => {
        alpha *= sets[sid].alpha;
      });
      // apply the "All Sets" alpha multiplicatively, if this belongs to a set
      if (setIds.length > 0) alpha *= allSetsAlpha;

      alpha *= allNodesAlpha;
      alphaComp.value = clamp(alpha, 0.05, 1.0);
      return alphaComp;
    })
    .addWith(() => {
      const asset = new Asset();
      const assetConfig = withAssetType(config);
      asset.value = assetConfig.asset;
      asset.assetType.val = assetConfig.assetType;
      return asset;
    })
    .addMaybeWith((builder) => {
      if (model.base.selected.shapes.includes(config.id)) {
        return new Selected();
      }
    })
    .addMaybeWith((builder) => {
      const asset = builder.get(Asset);
      if (asset.assetType.val !== 'preset') return;

      const color = builder.get(Color);
      if (![HEX_COLOR.NODE_DEFAULT, HEX_COLOR.NODE_LIGHT].includes(color.value))
        return;

      const defaultComponent = new DarkMode();
      return defaultComponent;
    })
    .addMaybeWith((builder) => {
      const asset = builder.get(Asset);
      if (engine.loader.assetIsGif(getAssetUrl(asset))) {
        return new RestartGif();
      }
    })
    .addMaybeWith((builder) => {
      const asset = builder.get(Asset);
      if (asset.assetType.val === 'preset') {
        return;
      }

      const selection = new Selection();
      selection.asset = new Graphics();
      selection.asset.eventMode = EventModeType.STATIC;
      return selection;
    })
    .add(new ActiveSetColor())
    .addWith((builder) => {
      const colors = [];
      const setIds = modelNode.setIds;
      // Graphic for Set Rings, when there is at least one set with a visible color
      const setRingsGraphic = new Graphic();
      setRingsGraphic.asset = new Graphics();
      // note: for performance reasons, graphics are not added to the container yet
      // this happens only when a graphic is actively being used

      builder.add(setRingsGraphic);
      // set vis by default
      let anyVisibleSet = false,
        isCollapsed = false;
      if (setIds.length) {
        for (let i = 0; i < setIds.length; i++) {
          const setId = setIds[i];
          const setData = model.sets?.[setId];
          if (isNone(setData)) continue;
          if (setData.collapsed) {
            isCollapsed = true;
          }
          builder.group(setId);
          // Track all viewable set colors
          if (hiddenSetsMap.has(setId)) {
            continue;
          } else {
            colors.push(colorParse(sets[setId].color));
            anyVisibleSet =
              anyVisibleSet ||
              determineSetVisibility({
                ecs,
                setData,
                model,
                hiddenSets: hiddenSetsMap,
              } as TransitionData);
          }
        }
        builder.addMaybe(isCollapsed ? new Collapsed() : undefined);
      }
      builder.setData('isCollapsed', isCollapsed);
      const setColors = new SetColors();
      setColors.values = colors;
      return setColors;
    })
    .addWith((builder) => {
      const asset = builder.get(Asset);
      // build sprite
      const [sprite] = updateOrCreateGIFSprite(asset, undefined, false, [
        engine,
      ]);
      sprite.asset.val.eventMode = EventModeType.STATIC;
      return sprite;
    })
    .addWith((builder) => {
      const sprite = builder.get(GIFSprite).asset.val;
      const asset = builder.get(Asset).value;
      const displayables = new Displayables();
      displayables.val = [
        {
          textureName: asset,
          asset: sprite,
          layer: LAYER.NODE,
        },
      ];
      return displayables;
    })
    .add(new Bindable())
    .addWith((builder) => {
      const bindings = new Bindings();
      bindings.bindingName = builder.get(ShapeUUID).value;
      bindings.component = GIFSprite;
      bindings.events = [
        EVENT.CLICK,
        EVENT.MOUSEDOWN,
        EVENT.MOUSEMOVE,
        EVENT.MOUSEOVER,
        EVENT.MOUSEUP,
        EVENT.MOUSEUPOUTSIDE,
        EVENT.RIGHTDOWN,
        EVENT.RIGHTUP,
      ];
      return bindings;
    })
    .addWith(() => {
      const scale = new Scale();
      scale.value = Vector2.fromPoint(config.scale);
      return scale;
    })
    .addMaybe(config.visible ? new Visible() : undefined)
    .addMaybe(config.hidden ? new Hidden() : undefined)
    .addWith((builder) => {
      const labelRef = new LabelRef();
      labelRef.position.val = config.labelPosition;
      labelRef.content.val = config.labelContent;
      const maybeLabel = buildLabel(
        ecs,
        getLabel(modelNode, model.expressions, 'nodes'),
        builder.getEntity(),
        config,
        ECS_GROUP.NODE_LABELS,
        builder.get(Order),
        showNodeLabels,
        builder.getData('isCollapsed'),
        model.base.labelSize,
        darkMode
      );
      if (isOk(maybeLabel)) labelRef.value = maybeLabel;
      return labelRef;
    })
    .addWith((builder) => {
      const aspect = new Aspect();
      const sprite = builder.get(GIFSprite);
      aspect.value = calcAspectRatioFit(
        sprite.baseWidth.val,
        sprite.baseHeight.val,
        SIZING.NODE.X,
        SIZING.NODE.Y
      );
      return aspect;
    })
    .add(new Interactive())
    .addWith((builder) => {
      const bounds: Bounds = new Bounds();
      const aspect = builder.get(Aspect);
      const scale = builder.get(Scale);
      bounds.value = new Rectangle(
        0,
        0,
        aspect.value.width * scale.value.x,
        aspect.value.height * scale.value.y
      );

      return bounds;
    })
    .tag(config.id)
    .group(ECS_GROUP.NODES)
    .build();
}

export function buildDrawing(
  ecs: EcsInstance,
  config: DrawingConfig
): Result<Entity, Error> {
  return ecs
    .create()
    .addWith(() => {
      const shapeUUID = new ShapeUUID();
      shapeUUID.value = config.id;
      return shapeUUID;
    })
    .addWith(() => {
      const position: Position = new Position();
      position.value = Vector2.fromPoint(config.pos);
      return position;
    })
    .addWith((builder) => {
      const drawing = new Drawing();
      drawing.length.val = config.lineLength;
      drawing.strokeWidth.val = config.strokeWidth;
      drawing.width.val = config.width;
      drawing.color.val = config.color;
      drawing.angle.val = config.angle;
      drawing.drawingType.val = config.shape;

      // calculate and set the starting x/y coordinates
      const position = builder.get(Position);
      const halfLength = config.lineLength / 2;
      const startX = position.val.x - halfLength * Math.cos(drawing.angle.val);
      const startY = position.val.y - halfLength * Math.sin(drawing.angle.val);
      drawing.startPos.val = new Vector2(startX, startY);
      return drawing;
    })
    .add(new Interactable())
    .add(new Interactive())
    .add(new Selectable())
    .add(new Moveable())
    .add(new Renderable())
    .addWith(() => {
      const layer = new MetraLayer();
      layer.value = config.layer;
      return layer;
    })
    .addWith(() => {
      const order: Order = new Order();
      order.value = config.order;
      return order;
    })
    .addWith(() => {
      const alpha = new Alpha();
      alpha.value = config.alpha;
      return alpha;
    })
    .addWith(() => {
      const scale = new Scale();
      scale.value = Vector2.fromPoint(config.scale);
      return scale;
    })
    .addWith((builder) => {
      const drawing = builder.get(Drawing);
      const graphics = new Graphics();
      graphics.zIndex = builder.get(Order).value;
      if (config.shape === DRAWING_MODE.LINE) {
        // rotation and angle are only set for straight lines, not boxes
        graphics.rotation = config.angle;
        graphics.position.set(drawing.startPos.val.x, drawing.startPos.val.y);
      } else {
        graphics.rotation = 0;
      }
      const graphic = new Graphic();
      graphic.asset = graphics;
      return graphic;
    })
    .addWith((builder) => {
      const graphics = builder.get(Graphic).asset;
      const displayables = new Displayables();
      displayables.val = [
        {
          textureName: null,
          asset: graphics,
          layer: config.layer,
        },
      ];
      return displayables;
    })
    .addWith((builder) => {
      const oriented = new Oriented();
      oriented.ref.val = builder.get(Graphic).asset;
      return oriented;
    })
    .addWith(() => {
      const bounds = new Bounds();
      bounds.value = new Rectangle(0, 0, config.width, config.height);
      return bounds;
    })
    .addWith(() => {
      const color = new Color();
      color.value = colorParse(config.color);
      return color;
    })
    .addMaybeWith(() => {
      // set the necessary values for drawing boxes
      if (config.shape === DRAWING_MODE.BOX) {
        const drawingBox = new DrawingBox();
        drawingBox.boxWidth.val = config.width;
        drawingBox.boxHeight.val = config.height;
        drawingBox.fillColor.val = config.fillColor;
        drawingBox.fillAlpha.val = config.fillAlpha;
        return drawingBox;
      } else return undefined;
    })
    .addMaybeWith(() => {
      const color = config.color;
      if (color === HEX_STRING.WHITE || color === HEX_STRING.BLACK) {
        const darkModeComponent = new DarkMode();
        return darkModeComponent;
      } else {
        return;
      }
    })
    .addMaybe(config.visible ? new Visible() : undefined)
    .addMaybe(config.hidden ? new Hidden() : undefined)
    .tag(config.id)
    .group(ECS_GROUP.DRAWINGS)
    .build();
}

export function buildShapeLabelsVisibility(
  ecs: EcsInstance,
  visibility: boolean,
  tag: string,
  extraComponents: Component[]
): void {
  const entity = ecs.createEntity();
  if (visibility) {
    ecs.addComponent(entity, new Visible());
  }
  ecs.tagManager.tagEntity(tag, entity);
  for (const extraComponent of extraComponents) {
    ecs.addComponent(entity, extraComponent);
  }
}

export function buildMultiSelect(ecs: EcsInstance): Result<Entity> {
  return ecs
    .create()
    .add(new MultiSelect())
    .addWith(() => {
      const position = new Position();
      position.value = Vector2.zero;
      return position;
    })
    .addWith(() => {
      const alpha = new Alpha();
      alpha.value = 1.0;
      return alpha;
    })
    .addWith(() => {
      const scale = new Scale();
      scale.value = Vector2.identity;
      return scale;
    })
    .addWith(() => {
      const bounds = new Bounds();
      bounds.value = new Rectangle(0, 0, 0, 0);
      return bounds;
    })
    .add(new Interactable())
    .add(new Moveable())
    .tag(ECS_TAG.MULTI_SELECT)
    .build();
}

export function buildCollapsedSet(
  ecs: EcsInstance,
  config: CollapsedConfig,
  _engine: ModelEngine,
  model: ModelReducer,
  hiddenSetsMap: Set<string>
): Result<Entity, Error> {
  const setId = config.setId;
  if (!setId) {
    throw new Error('all collapsed sets configs must have a setId!');
  }
  const modelSet = model.sets[setId];
  return ecs
    .create()
    .addWith(() => {
      const collapsed = new CollapsedSet();
      collapsed.setId = config.setId;
      return collapsed;
    })
    .addWith(() => {
      const uuid = new ShapeUUID();
      uuid.value = config.id;
      return uuid;
    })
    .add(new Selectable())
    .addWith(() => {
      const layer = new MetraLayer();
      layer.value = config.layer;
      return layer;
    })
    .addWith(() => {
      const order = new Order();
      order.value = 0;
      return order;
    })
    .addWith(() => {
      return new Interactive();
    })
    .addWith((builder) => {
      const setColors = new SetColors();
      if (!modelSet) {
        throw new Error('all collapsed sets must have an associated set!');
      }
      builder.setData('modelSet', modelSet);

      setColors.values = hiddenSetsMap.has(setId)
        ? []
        : [colorParse(modelSet.color)];
      return setColors;
    })
    .addWith((builder) => {
      const alpha = new Alpha();
      alpha.value =
        builder.getData<ModelSet>('modelSet').alpha * model.base.allSetsAlpha;
      return alpha;
    })
    .addWith((builder) => {
      const graphic = new Graphic();
      const graphics = new Graphics();
      graphics.eventMode = EventModeType.STATIC;
      graphic.asset = graphics;
      return graphic;
    })
    .addWith((builder) => {
      const displayables = new Displayables();
      displayables.val = [
        {
          textureName: null,
          asset: builder.get(Graphic).asset,
          layer: config.layer,
        },
      ];
      return displayables;
    })
    .addWith((builder) => {
      const bindings = new Bindings();
      bindings.bindingName = builder.get(ShapeUUID).value;
      bindings.events = [
        EVENT.CLICK,
        EVENT.MOUSEDOWN,
        EVENT.MOUSEMOVE,
        EVENT.MOUSEUP,
        EVENT.MOUSEUPOUTSIDE,
        EVENT.RIGHTDOWN,
        EVENT.RIGHTUP,
      ];
      return bindings;
    })
    .add(new Bindable())
    .addWith(() => {
      const position = new Position();
      position.value = Vector2.fromPoint(config.pos);
      return position;
    })
    .add(new Selectable())
    .addWith(() => {
      const selection = new Selection();
      selection.asset = new Graphics();
      return selection;
    })
    .add(new Moveable())
    .addWith(() => {
      const scale = new Scale();
      scale.value = Vector2.fromPoint(config.scale);
      return scale;
    })
    .addWith(() => {
      const bounds: Bounds = new Bounds();
      bounds.value = new Rectangle(
        0,
        0,
        SIZING.COLLAPSED_SET.X,
        SIZING.COLLAPSED_SET.Y
      );
      return bounds;
    })
    .addWith((builder) => {
      const spatial = new Spatial();
      const position = builder.get(Position).value;
      spatial.range = new RectangleRange(
        position.x,
        position.y,
        SIZING.COLLAPSED_SET.X / 2,
        SIZING.COLLAPSED_SET.Y / 2
      );
      return spatial;
    })
    .addMaybeWith((builder) => {
      return builder.getData<ModelSet>('modelSet').visible
        ? new Visible()
        : new Hidden();
    })
    .addMaybeWith((builder) => {
      return builder.getData<ModelSet>('modelSet').hidden
        ? new Hidden()
        : undefined;
    })
    .addWith((builder) => {
      const labelRef = new LabelRef();
      labelRef.position.val = 'above';
      labelRef.content.val = 'name/column/SETS';
      const maybeLabel = buildLabel(
        ecs,
        getLabel(modelSet, model.expressions, 'sets'),
        builder.getEntity(),
        config,
        ECS_GROUP.NODE_LABELS,
        builder.get(Order),
        model.base.showNodeLabels,
        false,
        model.base.labelSize,
        model.base.darkMode
      );
      if (isOk(maybeLabel)) labelRef.value = maybeLabel;
      return labelRef;
    })
    .addMaybeWith((builder) => {
      const setData = builder.getData<ModelSet>('modelSet');
      if (
        setContainedInOtherCollapsedSet(setData.id, {
          ecs,
          model,
          setData,
        } as TransitionData)
      ) {
        return new Collapsed();
      }
    })
    .tag(config.id)
    .group(ECS_GROUP.COLLAPSED_SET)
    .build();
}

export function buildBackground(
  ecs: EcsInstance,
  config: BackgroundConfig,
  engine: ModelEngine,
  model: ModelReducer
): void {
  ecs
    .create()
    .add(new Background())
    .addWith(() => {
      const shapeUUID = new ShapeUUID();
      shapeUUID.value = config.id;
      return shapeUUID;
    })
    .addWith(() => {
      const position = new Position();
      position.value = Vector2.fromPoint(config.pos);
      return position;
    })
    .add(new Interactable())
    .addWith(() => {
      const selection = new Selection();
      selection.asset = new Graphics();
      return selection;
    })
    .add(new Moveable())
    .addWith(() => {
      const resizeable = new Resizeable();
      resizeable.componentId = Scale.type;
      return resizeable;
    })
    .addWith(() => {
      const layer = new MetraLayer();
      layer.value = config.layer;
      return layer;
    })
    .addWith(() => {
      const order = new Order();
      order.value = config.order;
      return order;
    })
    .addWith(() => {
      const asset = new Asset();
      const assetConfig = withAssetType(config);
      asset.value = assetConfig.asset;
      asset.assetType.val = assetConfig.assetType;
      return asset;
    })
    .addWith(() => {
      const asset = new Alpha();
      asset.value = config.alpha;
      return asset;
    })
    .addMaybeWith((builder) => {
      const asset = builder.get(Asset);
      if (engine.loader.assetIsGif(getAssetUrl(asset))) {
        return new RestartGif();
      }
    })
    .addWith((builder) => {
      const asset = builder.get(Asset);
      const [sprite] = updateOrCreateGIFSprite(asset, undefined, false, [
        engine,
      ]);
      sprite.asset.val.zIndex = builder.get(Order).value;
      sprite.asset.val.eventMode = EventModeType.STATIC;
      return sprite;
    })
    .addWith((builder) => {
      const textureName = builder.get(Asset).value;
      const sprite = builder.get(GIFSprite).asset.val;
      const displayables = new Displayables();
      displayables.val = [
        {
          textureName,
          asset: sprite,
          layer: config.layer,
        },
      ];
      return displayables;
    })
    .add(new Visible())
    .addWith((builder) => {
      const bindings = new Bindings();
      bindings.bindingName = builder.get(ShapeUUID).value;
      bindings.events = [
        EVENT.CLICK,
        EVENT.MOUSEDOWN,
        EVENT.MOUSEMOVE,
        EVENT.MOUSEUP,
        EVENT.MOUSEUPOUTSIDE,
        EVENT.RIGHTDOWN,
        EVENT.RIGHTUP,
      ];
      return bindings;
    })
    .addMaybe(!model.base.lockBackground ? new Bindable() : undefined)
    .addMaybe(!model.base.lockBackground ? new Selectable() : undefined)
    .addWith(() => {
      const scale = new Scale();
      scale.value = Vector2.fromPoint(config.scale);
      return scale;
    })
    .addWith((builder) => {
      const spatial = new Spatial();
      const sprite = builder.get(GIFSprite);
      const scale = builder.get(Scale);
      const position = builder.get(Position);
      const scaledDims = new Vector2(
        sprite.baseWidth.val,
        sprite.baseHeight.val
      ).mult(scale.value);

      spatial.range = new RectangleRange(
        position.value.x,
        position.value.y,
        scaledDims.x / 2,
        scaledDims.y / 2
      );
      builder.setData('scaledDims', scaledDims);
      return spatial;
    })
    .addWith((builder) => {
      const scaledDims = builder.getData<Vector2>('scaledDims');
      const bounds = new Bounds();
      bounds.value = new Rectangle(0, 0, scaledDims.x, scaledDims.y);
      return bounds;
    })
    .tag(config.id)
    .group(ECS_GROUP.BACKGROUNDS)
    .build();
}

export function buildCollapseButton(ecs: EcsInstance): void {
  const entity = ecs.createEntity();

  ecs.addComponent(entity, new CollapseButton());

  const shapeUUID = new ShapeUUID();
  shapeUUID.value = ECS_TAG.COLLAPSE_BTN;
  ecs.addComponent(entity, shapeUUID);

  const position = new Position();
  position.value = new Vector2(Math.random(), Math.random());
  ecs.addComponent(entity, position);

  const bindings = new Bindings();
  bindings.bindingName = ECS_TAG.COLLAPSE_BTN;
  bindings.component = Sprite;
  bindings.events = [
    EVENT.CLICK,
    EVENT.MOUSEDOWN,
    EVENT.MOUSEUP,
    EVENT.MOUSEENTER,
    EVENT.MOUSEMOVE,
    EVENT.MOUSEOVER,
  ];
  ecs.addComponent(entity, bindings);
  ecs.addComponent(entity, new Bindable());

  const sprite = new Sprite();
  const texture: Texture = Assets.cache.get('collapsibleIcon');
  sprite.asset = new PSprite(texture);
  sprite.asset.eventMode = EventModeType.STATIC;
  sprite.asset.visible = false;
  sprite.baseWidth = texture.width;
  sprite.baseHeight = texture.height;
  ecs.addComponent(entity, sprite);

  const spatial = new Spatial();
  spatial.range = new RectangleRange(-1, -1, 0, 0);
  ecs.addComponent(entity, spatial);

  const scale = new Scale();
  scale.value = new Vector2(
    SIZING.COLLAPSIBLE_SET_BTN.GRAPHIC_SCALE,
    SIZING.COLLAPSIBLE_SET_BTN.GRAPHIC_SCALE
  );
  ecs.addComponent(entity, scale);

  const labelRef = new LabelRef();
  labelRef.content.val = 'name/column/SETS';
  labelRef.value = buildCollapseButtonLabel(ecs, '', entity);
  ecs.addComponent(entity, labelRef);

  const displayables = new Displayables();
  displayables.val = [
    {
      textureName: 'collapsibleIcon',
      asset: sprite.asset,
      layer: LAYER.DECORATOR_OVERLAY,
    },
  ];
  ecs.addComponent(entity, displayables);

  ecs.tagManager.tagEntity(ECS_TAG.COLLAPSE_BTN, entity);
}

export function buildShape(engine: ModelEngine, config: ModelShapeConfig) {
  engine.ecs
    .create()
    .addWith(() => {
      const shape = new MetraShape();
      shape.value = config.shapeType;
      return shape;
    })
    .addWith(() => {
      const shapeUUID = new ShapeUUID();
      shapeUUID.value = config.id;
      return shapeUUID;
    })
    .addWith(() => {
      const order: Order = new Order();
      order.value = config.order;
      return order;
    })
    .addWith(() => {
      const selection = new Selection();
      selection.asset = new Graphics();
      return selection;
    })
    .addWith((builder) => {
      const graphic = new Graphic();
      graphic.asset = new Graphics();
      graphic.asset.eventMode = EventModeType.STATIC;
      return graphic;
    })
    .addWith(() => {
      const position = new Position();
      position.value = new Vector2(config.pos.x, config.pos.y);
      return position;
    })
    .addWith(() => {
      const dimensions = new Dimensions();
      dimensions.width = config.width;
      dimensions.height = config.height;
      return dimensions;
    })
    .addWith(() => {
      const color = new Color();
      color.value = colorParse(config.color);
      return color;
    })
    .addWith(() => {
      const style = new ShapeStyle();
      style.lineColor = colorParse(config.lineColor);
      style.lineWidth = config.lineWidth;
      return style;
    })
    .addWith(() => {
      const alpha = new Alpha();
      alpha.value = config.alpha;
      return alpha;
    })
    .addWith((builder) => {
      const position = builder.get(Position);
      const dimensions = builder.get(Dimensions);
      const spatial = new Spatial();
      spatial.range = new RectangleRange(
        position.value.x,
        position.value.y,
        dimensions.width / 2,
        dimensions.height / 2
      );
      return spatial;
    })
    .add(new Bindable())
    .addWith((builder) => {
      const bindings = new Bindings();
      bindings.bindingName = builder.get(ShapeUUID).value;
      bindings.component = Graphic;
      bindings.events = [
        EVENT.CLICK,
        EVENT.MOUSEDOWN,
        EVENT.MOUSEMOVE,
        EVENT.MOUSEUP,
        EVENT.MOUSEUPOUTSIDE,
      ];
      return bindings;
    })
    .addWith((builder) => {
      const dimensions = builder.get(Dimensions);
      const bounds: Bounds = new Bounds();
      bounds.value = new Rectangle(0, 0, dimensions.width, dimensions.height);
      return bounds;
    })
    .addWith(() => {
      const scale = new Scale();
      scale.value = Vector2.fromPoint(config.scale);
      return scale;
    })
    .add(new Moveable())
    .addWith(() => {
      const resizeable = new Resizeable();
      resizeable.componentId = Scale.type;
      return resizeable;
    })
    .add(new Selectable())
    .addMaybe(config.visible ? new Visible() : undefined)
    .addMaybe(config.hidden ? new Hidden() : undefined)
    .tagWith((builder) => builder.get(ShapeUUID).value)
    .group(ECS_GROUP.SHAPES)
    .build();
}

export function buildText(
  ecs: EcsInstance,
  config: TextConfig,
  engine: ModelEngine
): Result<Entity, Error> {
  return ecs
    .create()
    .addWith(() => {
      const text = new Text();
      text.fill.val = config.color;
      text.textString.val = config.text;
      text.fontSize.val = config.fontSize;
      text.stroke.val = config.stroke;
      text.strokeThickness.val = config.strokeThickness;
      text.strokeAlpha.val = config.strokeAlpha;
      return text;
    })
    .addWith(() => {
      const shapeUUID = new ShapeUUID();
      shapeUUID.value = config.id;
      return shapeUUID;
    })
    .addWith(() => {
      const position = new Position();
      position.value = new Vector2(config.pos.x, config.pos.y);
      return position;
    })
    .addWith(() => {
      const color = new Color();
      color.value = colorParse(config.color);
      return color;
    })
    .addWith(() => {
      const order = new Order();
      order.value = config.order;
      return order;
    })
    .addWith(() => {
      const layer = new MetraLayer();
      layer.value = config.layer;
      return layer;
    })
    .addWith(() => {
      const alpha = new Alpha();
      alpha.value = config.alpha;
      return alpha;
    })
    .addWith((builder) => {
      const position = builder.get(Position);
      const textStyle = new TextStyle({
        fill: config.color,
        lineJoin: 'round',
        fontSize: config.fontSize,
        stroke: config.stroke,
        strokeThickness: config.strokeThickness,
      });
      const textObject = new PText(config.text, textStyle);
      textObject.x = position.val.x;
      textObject.y = position.val.y;
      textObject.alpha = config.alpha;
      textObject.resolution = getTextResolution(textObject, engine.app);

      // pixi's text object sets the position by the top/left coords,
      // internally we set a shapes position to it's center.
      // converting the center pos to top/left to redraw in the correct location.
      const left = position.val.x - textObject.width / 2;
      const top = position.val.y - textObject.height / 2;
      textObject.position.set(left, top);
      textObject.zIndex = builder.get(Order).value;

      const drawingText = new DrawingText();
      drawingText.asset = textObject;
      return drawingText;
    })
    .addWith((builder) => {
      const pixiText = builder.get(DrawingText);
      const displayables = new Displayables();
      displayables.val = [
        {
          asset: pixiText.asset,
          layer: config.layer,
          textureName: null,
        },
      ];

      return displayables;
    })
    .addWith(() => {
      const scale = new Scale();
      scale.value = Vector2.fromPoint(config.scale);
      return scale;
    })
    .addWith(() => {
      const bounds: Bounds = new Bounds();
      bounds.value = new Rectangle(0, 0, config.width, config.height);
      return bounds;
    })
    .addMaybeWith(() => {
      const color = config.color;
      if (
        (color === HEX_STRING.WHITE || color === HEX_STRING.BLACK) &&
        config.strokeThickness === 0
      ) {
        const darkModeComponent = new DarkMode();
        return darkModeComponent;
      } else {
        return;
      }
    })
    .add(new Selectable())
    .add(new Moveable())
    .add(new Interactive())
    .addMaybe(config.visible ? new Visible() : undefined)
    .addMaybe(config.hidden ? new Hidden() : undefined)
    .tagWith((builder) => builder.get(ShapeUUID).value)
    .group(ECS_GROUP.TEXT)
    .build();
}

export function buildMouse(ecs: EcsInstance) {
  ecs
    .create()
    .add(new Mouse())
    .add(new Resource())
    .addWith(() => {
      const position = new Position();
      position.value = Vector2.zero;
      return position;
    })
    .tag(ECS_TAG.MOUSE)
    .build();
}

export function buildSet(
  ecs: EcsInstance,
  _model: ModelReducer,
  data: ModelSet
): void {
  ecs
    .create()
    .add(new MetraSet())
    .addWith(() => {
      const uuid = new ShapeUUID();
      uuid.value = data.id;
      return uuid;
    })
    .addMaybe(data.visible ? new Visible() : undefined)
    .tag(data.id)
    .group(ECS_GROUP.SET)
    .build();
}

export function buildStage(ecs: EcsInstance): void {
  ecs
    .create()
    .add(new Interactable())
    .addWith(() => {
      const uuid = new ShapeUUID();
      uuid.value = 'stage';
      return uuid;
    })
    .tag('stage')
    .build();
}
