import { APP, NO_OP } from 'modules/common/constants';
import { apiGet, apiPatch, isApiErrorAction } from 'modules/common/api';
import { MESSAGES } from 'modules/ui/messages';
import {
  MetraActionFunc,
  MetraThunkAction,
  MetraApiAction,
  NormalizedResult,
  MetraVoidAction,
  ThunkAction,
} from 'types';

export const showModal: MetraActionFunc<[string], string> = (modalName) => {
  return { type: APP.MODAL.SHOW, payload: modalName };
};

export const hideModal = (): MetraVoidAction => {
  return { type: APP.MODAL.HIDE };
};

export interface UpdateOrgValuesArgs {
  orgColor?: string;
  fileName?: string | null;
  callback?: (uploadUrl: string) => void;
}

interface NucleoOrgMeta {
  color?: null | string;
  logo?: null | {
    name: string;
    url?: string;
  };
}

export const updateOrgValues =
  ({
    orgColor,
    fileName,
    callback,
  }: UpdateOrgValuesArgs): ThunkAction<
    Promise<MetraApiAction<NucleoOrgMeta>>
  > =>
  async (dispatch) => {
    const requestBody: NucleoOrgMeta = {};
    if (orgColor) {
      requestBody.color = orgColor;
    }

    if (fileName !== undefined) {
      if (fileName === null) {
        requestBody.logo = null;
      } else {
        requestBody.logo = { name: fileName };
      }
    }

    // There's a quirk on the back-end.  If we do a GET on the org/_meta it wants
    // to give us back the logo with a URL.  That URL can be used to GET the
    // graphic.  If we do a PATCH on the org/_meta we get back the same response
    // structure; but the URL we get back is PUT-only.  It cannot be used to
    // retrieve any existing graphic.  We want to grab the upload-URL and remove
    // it from the response before we dispatch, or the redux-state for the org
    // logo will have a URL that can't be used. In the case where we actually
    // *are* updating the org logo, we make a separate call to loadOrgMeta after
    // we're done to get a new value for it.
    const patchResponse = await dispatch(
      apiPatch<NucleoOrgMeta>({
        entity: '_meta',
        body: JSON.stringify(requestBody),
        meta: {
          excludeGuild: true,
        },
        headers: {
          'Content-Type': 'application/json',
        },
        types: [NO_OP.SUCCESS, NO_OP.FAILURE],
        error: MESSAGES.ERROR.UPDATE,
      })
    );
    if (isApiErrorAction(patchResponse)) {
      return patchResponse;
    }
    const uploadUrl = patchResponse.payload.logo?.url;
    const transformedResponse = {
      ...patchResponse,
      payload: patchResponse.payload,
      type: APP.TOOLBAR.SETTINGS,
    };
    delete transformedResponse.payload?.logo;
    // If this update includes a change to the logo, we got back the upload-to
    // URL; and we need to start the upload.
    if (callback && uploadUrl) {
      callback(uploadUrl);
    }
    return dispatch(transformedResponse);
  };

export const loadOrgMeta =
  (): MetraThunkAction<
    void,
    NormalizedResult,
    Promise<MetraApiAction<NormalizedResult>>
  > =>
  async (dispatch) => {
    return await dispatch(
      apiGet({
        entity: '_meta',
        meta: {
          excludeGuild: true,
        },
        types: [APP.TOOLBAR.SETTINGS, NO_OP.FAILURE],
      })
    );
  };
