import { globalGetState } from 'utils/utils-extra';
import { MEDIA } from 'modules/common';

/*
 * tagCache reducer. The tagCache is used to store the text and IDs for invalid
 * tags that the user has typed into search
 * (as opposed to valid tags with entries in our DB)
 */
export const tagCacheReducer = (state = {}, action) => {
  switch (action.type) {
    case MEDIA.ADD_TAG_TO_CACHE:
      return { ...state, [action.payload.id]: action.payload };
    case MEDIA.REMOVE_TAGS_FROM_CACHE:
      return Object.fromEntries(
        Object.entries(state).filter(
          ([id]) => !action.payload.includes(Number(id))
        )
      );
    case MEDIA.CLEAR_TAG_CACHE:
      return {};
    default:
      return state;
  }
};

// export default tagCacheReducer;

/*
 * Actions for caching invalid tags. We need their IDs and names
 * in multiple places, so they go in redux, but we don't want to
 * mix them in with our other tags because they're not real entities.
 */
export const addTagToCache = (tag) => {
  const tagCache = globalGetState().mediaReducer.tagCache;
  let i = -1;
  while (tagCache[i]) i--;
  return {
    type: MEDIA.ADD_TAG_TO_CACHE,
    payload: { ...tag, id: i },
  };
};

export const removeTagFromCache = (...tagIds) => ({
  type: MEDIA.REMOVE_TAGS_FROM_CACHE,
  payload: tagIds,
});

export const clearTagCache = () => ({
  type: MEDIA.CLEAR_TAG_CACHE,
});
