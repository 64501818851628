import type { RootReducer } from 'types';
import type { SchemaReducerState, PrunedSchemaReducerState } from './reducer';

export const pruneSchema = (state: SchemaReducerState) => {
  if (!state) return;

  const pruned = {} as PrunedSchemaReducerState;
  pruned.properties = state.properties;

  pruned.categories = { byId: {} };

  let hasCategories = false;

  for (const catId in state.categories.byId) {
    hasCategories = true;
    pruned.categories.byId[catId] = {
      name: state.categories.byId[catId].name,
      sheet: state.categories.byId[catId].sheet,
      members: state.categories.byId[catId].members,
    };
  }
  if (!hasCategories) return;

  return pruned;
};

export function getCatMembersBySheet(sheet: string) {
  return (state: RootReducer) => {
    const catId = state.modelReducer.schema.categories.bySheet[sheet];
    return state.modelReducer.schema.categories.byId[catId].membersById;
  };
}

export function getProperties(state: RootReducer) {
  return state.modelReducer.schema.properties;
}
