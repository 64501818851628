import {
  BaseModelEntityType,
  ModelEdge,
  ModelNode,
  ModelPath,
  ModelSet,
} from 'types';

export function isModelEdge(entity: BaseModelEntityType): entity is ModelEdge {
  if ('inNodeId' in entity && 'outNodeId' in entity && 'setIds' in entity) {
    return true;
  } else {
    return false;
  }
}

export function isModelNode(entity: BaseModelEntityType): entity is ModelNode {
  if ('edgeIds' in entity && 'setIds' in entity) {
    return true;
  } else {
    return false;
  }
}

export function isModelSet(entity: BaseModelEntityType): entity is ModelSet {
  if ('edgeIds' in entity && 'nodeIds' in entity) {
    return true;
  } else {
    return false;
  }
}

export function isModelPath(entity: BaseModelEntityType): entity is ModelPath {
  if ('id' in entity && 'label' in entity && 'triples' in entity) {
    return true;
  } else {
    return false;
  }
}
