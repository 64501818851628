import { SubAtoms } from '../sub-atoms';
import { DebugTools, Resource } from 'engine/components';
import type { Query } from '..';

export class DebugAtoms extends SubAtoms {
  debugTools!: Query;

  init() {
    this.debugTools = this.sys2.query(DebugTools, Resource);
  }

  toggleQTree() {
    const debug = this.sys2.assert(this.debugTools.one(), DebugTools);
    debug.showQTree.val = !debug.showQTree.val;
  }
}
