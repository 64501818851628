import {
  MetraActionFunc,
  MetraGuild,
  MetraUser,
  OrgMetrics,
  OrgUsersReducer,
  AddUserResult,
  MetraApiAction,
  MetraApiResponseAction,
  NormalizedResult,
  ThunkActionFunc,
} from 'types';
import {
  ADMIN,
  USERS,
  NO_OP,
  apiGet,
  apiPost,
  isApiErrorAction,
} from 'modules/common';
import { MESSAGES } from 'modules/ui/messages';
import { OrgContext } from 'utils/OrganizationContext';
import { makeNormalizedResult, makeReadSuccess } from 'modules/entities/utils';

/**
 * Loads the current org's metrics into redux
 */
export const fetchOrgMetrics: ThunkActionFunc<
  [],
  Promise<Option<MetraApiAction<NormalizedResult<'orgMetrics'>>>>
> = () => async (dispatch) => {
  // we don't get back the usual kind of entity-response for org metrics.
  // We're going to feed in a 'fake' success type (something no reducer
  // reacts to), capture the response, and transform it to look like a
  // typical entity-response (giving it a 'real' type at the same time).
  const response = await dispatch(
    apiGet<OrgMetrics>({
      entity: 'metrics',
      schema: 'orgMetrics',
      meta: {
        excludeGuild: true,
      },
      types: [NO_OP.SUCCESS, NO_OP.FAILURE],
      error: MESSAGES.ERROR.GET.METRICS,
    })
  );
  if (isApiErrorAction(response)) {
    return null;
  }
  if (OrgContext.organization) {
    response.payload.id = OrgContext.organization;
    const transformedResponse = makeReadSuccess(
      makeNormalizedResult(response.payload, 'orgMetrics'),
      'orgMetrics'
    );
    if (transformedResponse) return dispatch(transformedResponse);
  }
  return null;
};

export const fetchGuildMetrics: ThunkActionFunc<
  [guild: MetraGuild],
  Promise<Option<MetraApiResponseAction<NormalizedResult<'guildMetrics'>>>>
> = (guild) => async (dispatch) => {
  // see fetchOrgMetrics above ... we need to transform the response
  const response = await dispatch(
    apiGet<OrgMetrics>({
      entity: `guild/${guild.cname}/metrics`,
      meta: {
        schema: 'guildMetrics',
        excludeGuild: true,
      },
      types: [NO_OP.SUCCESS, NO_OP.FAILURE],
      error: MESSAGES.ERROR.GET.METRICS,
    })
  );
  if (isApiErrorAction(response)) {
    return null;
  }

  response.payload.id = guild.cname;
  const transformedResponse = makeReadSuccess(
    makeNormalizedResult(response.payload, 'guildMetrics'),
    'guildMetrics'
  );
  if (transformedResponse) dispatch(transformedResponse);
  return null;
};

export const showAddGuildUserModal = (guild: MetraGuild, show: boolean) => ({
  type: USERS.MODAL.SHOW_ADD_GUILD_USER,
  payload: {
    collection: 'guild_' + guild.cname,
    showAddGuildUserModal: show,
  },
});

export const showSearchForUsersModal = (show: boolean) => ({
  type: ADMIN.MODAL.SHOW_SEARCH_FOR_USERS_MODAL,
  payload: show,
});

export const showAddOrgUserModal = (show: boolean) => ({
  type: ADMIN.MODAL.SHOW_ADD_ORG_USER,
  payload: show,
});

export const showCreateKeyModal = (show: boolean) => ({
  type: ADMIN.MODAL.SHOW_CREATE_KEY_MODAL,
  payload: show,
});

export const showDeleteKeyModal = (show: boolean) => ({
  type: ADMIN.MODAL.SHOW_DELETE_KEY_MODAL,
  payload: show,
});

export const showRenewKeyModal = (show: boolean) => ({
  type: ADMIN.MODAL.SHOW_RENEW_KEY_MODAL,
  payload: show,
});

export const showRenewedKeyInfo = (show: boolean) => ({
  type: ADMIN.SHOW_RENEWED_KEY_INFO,
  payload: show,
});

export const showManageUserModal = (show: boolean, userId?: string) => ({
  type: ADMIN.MODAL.SHOW_MANAGE_USER_MODAL,
  payload: {
    show,
    userId,
  },
});

export const showLicenseLimitError: MetraActionFunc<
  [boolean],
  Partial<OrgUsersReducer>
> = (showModal) => ({
  type: USERS.SHOW_LICENSE_ERROR_MODAL,
  payload: { showLicenseErrorModal: showModal },
});

export const showGuildMembershipFlyout = (
  show: boolean,
  top = 0,
  left = 0
) => ({
  type: ADMIN.FLYOUT.SHOW_GUILD_MEMBERSHIP_FLYOUT,
  payload: {
    show: show,
    top: top,
    left: left,
  },
});

export const setFlyoutUser = (user: MetraUser) => ({
  type: ADMIN.FLYOUT.SET_FLYOUT_USER,
  payload: user,
});

export const clearFlyoutUser = () => ({
  type: ADMIN.FLYOUT.SET_FLYOUT_USER,
  payload: null,
});

export interface AddUserArgs {
  firstName: string;
  lastName: string;
  email: string;
  groups: string[];
  isGovernmentUser: boolean;
  isCreatePassword: boolean;
  isPKIRegistration: boolean;
  configureTOTP?: Option<boolean>;
}

export const addUser: ThunkActionFunc<
  [args: AddUserArgs],
  Promise<MetraApiAction<AddUserResult>>
> =
  ({
    firstName,
    lastName,
    email,
    groups,
    isGovernmentUser,
    isCreatePassword,
    isPKIRegistration,
    configureTOTP,
  }) =>
  async (dispatch) => {
    const requestBody = {
      email: email,
      first_name: firstName, // eslint-disable-line camelcase
      last_name: lastName, // eslint-disable-line camelcase
      reset_password: isCreatePassword, // eslint-disable-line camelcase
      pki_registration: isPKIRegistration, // eslint-disable-line camelcase
      groups: groups,
      attributes: {
        is_government: isGovernmentUser, // eslint-disable-line camelcase
      },
      configure_totp: configureTOTP,
    };

    const result = await dispatch(
      apiPost<AddUserResult>({
        entity: 'users',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody),
        types: [NO_OP.SUCCESS, NO_OP.FAILURE],
        success: MESSAGES.SUCCESS.CREATE.USER,
        meta: { excludeGuild: true },
      })
    );
    return result;
  };
