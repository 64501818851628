import { URL_FLYOUT } from 'modules/common';
import { URLFlyoutReducer, MetraActionFunc } from 'types';
import { v4 as uuid } from 'uuid';

export const showURLFlyout: MetraActionFunc<
  [Partial<URLFlyoutReducer>],
  Partial<URLFlyoutReducer>
> = (payload) => {
  const foo = {
    type: URL_FLYOUT.SHOW,
    payload: { ...payload, id: uuid(), visible: true },
  };
  return foo;
};

export const hideURLFlyout: MetraActionFunc<
  [string],
  Partial<URLFlyoutReducer>
> = (id) => {
  return {
    type: URL_FLYOUT.HIDE,
    payload: {
      id,
      visible: false,
      links: [],
      x: 0,
      y: 0,
    },
  };
};
