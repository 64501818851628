// import 'react-app-polyfill/stable';
// import 't3dev';
import 'extensions';
import { lazy, StrictMode, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import 'normalize.css';
import './index.scss';
import './common.scss';
import { EventManager } from 'metra-events';
import { KeyboardManager } from 'utils/keyboard';
import { isValidBrowser } from 'utils/utils';
import { ErrorBoundary } from './components/ui/ErrorBoundary';
import { AssetsManager } from 'assets/manager';
import { LoadingBar } from 'components/ui/LoadingBar';
import { metraHistory } from './setupHistory';
import { metraStore } from './store';
import { Helmet, HelmetProvider } from 'react-helmet-async';

declare global {
  interface Window {
    rdom: typeof ReactDOM;
    supportedBrowser: boolean;
    proc: NodeJS.ProcessEnv;
  }
}

window.rdom = ReactDOM;

window.supportedBrowser = isValidBrowser();
if (!window.supportedBrowser) {
  alert(
    "You're using a browser we do not support. Please upgrade to the latest versions of Google Chrome or Firefox to get the most of your experience."
  );
}

window.proc = (() => process.env)();

AssetsManager.initialize();
EventManager.init();
KeyboardManager.init();

const Boundaries = lazy(() => import('components/auth/Boundaries' as any));
// react-v18 setup
const container = document.getElementById('root');
if (!container) throw new Error('could not find root container');
const root = createRoot(container);

root.render(
  // FIXME: we need to re-enable StrictMode and fix various indempotent issues
  //        but for now it is disabled for the react-18 conversion
  // <StrictMode>
  <HelmetProvider>
    <Helmet defaultTitle="MeTRA®" />

    <ErrorBoundary>
      <Provider store={metraStore}>
        <Router history={metraHistory}>
          <Suspense
            fallback={
              <LoadingBar isLoading={true}>
                <div className="auth"></div>
              </LoadingBar>
            }
          >
            <Boundaries />
          </Suspense>
        </Router>
      </Provider>
    </ErrorBoundary>
  </HelmetProvider>
  // </StrictMode>
);
