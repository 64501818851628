import { TABLE } from 'modules/common';
import { remember } from 'modules/model/base/actions';

export const initialTableSettingsState = {
  orientation: TABLE.ORIENTATION.VERTICAL,
  height: 0,
  width: window.innerWidth * 0.4,
  showQuickResize: false,
  yaml: true,
};

export const updateViewportHeight = (height) => async (dispatch) => {
  await dispatch({
    type: TABLE.SETTINGS.UPDATE.HEIGHT,
    payload: height,
  });

  dispatch(remember());
};

export const updateViewportWidth = (width) => async (dispatch) => {
  await dispatch({
    type: TABLE.SETTINGS.UPDATE.WIDTH,
    payload: width,
  });

  dispatch(remember());
};

export const toggleYaml = () => async (dispatch, getState) => {
  dispatch({
    type: TABLE.SETTINGS.UPDATE.YAML,
    payload: !getState().modelReducer.tableSettings.yaml,
  });
};

export const showQuickResizeMenu = (show) => async (dispatch, getState) => {
  dispatch({
    type: TABLE.SETTINGS.UPDATE.SHOW_QUICK_RESIZE,
    payload: show,
  });
};

/** @returns {import('types').MetraThunkAction<unknown, void>} */
export const changeOrientation = () => (dispatch, getState) => {
  const { orientation } = getState().modelReducer.tableSettings;
  const payload = {};
  switch (orientation) {
    case TABLE.ORIENTATION.VERTICAL:
      payload.orientation = TABLE.ORIENTATION.HORIZONTAL;
      payload.height = window.innerHeight * 0.4;
      break;
    case TABLE.ORIENTATION.HORIZONTAL:
      payload.orientation = TABLE.ORIENTATION.VERTICAL;
      payload.width = window.innerWidth * 0.4;
      break;
    default:
      throw new Error('Table Orientation is invalid');
  }

  dispatch({
    type: TABLE.SETTINGS.UPDATE.ORIENTATION,
    payload,
  });

  dispatch(remember());
};

/** @returns {import('types').MetraThunkAction<unknown, void>} */
export const setVerticalOrientation = () => (dispatch, getState) => {
  const { orientation } = getState().modelReducer.tableSettings;
  if (orientation === TABLE.ORIENTATION.VERTICAL) return;

  const payload = {
    orientation: TABLE.ORIENTATION.VERTICAL,
    width: window.innerWidth * 0.4,
  };

  dispatch({
    type: TABLE.SETTINGS.UPDATE.ORIENTATION,
    payload,
  });

  dispatch(remember());
};

/** @returns {import('types').MetraThunkAction<unknown, void>} */
export const setHorizontalOrientation = () => (dispatch, getState) => {
  const { orientation } = getState().modelReducer.tableSettings;
  if (orientation === TABLE.ORIENTATION.HORIZONTAL) return;

  const payload = {
    orientation: TABLE.ORIENTATION.HORIZONTAL,
    width: window.innerHeight * 0.4,
  };

  dispatch({
    type: TABLE.SETTINGS.UPDATE.ORIENTATION,
    payload,
  });

  dispatch(remember());
};

export const tableSettingsReducer = (
  state = Object.clone(initialTableSettingsState),
  action
) => {
  switch (action.type) {
    case TABLE.SETTINGS.INIT:
      return action.payload;
    case TABLE.SETTINGS.UPDATE.ORIENTATION:
      return {
        ...state,
        ...action.payload,
      };
    case TABLE.SETTINGS.UPDATE.WIDTH:
      return {
        ...state,
        width: action.payload,
      };
    case TABLE.SETTINGS.UPDATE.HEIGHT:
      return {
        ...state,
        height: action.payload,
      };
    case TABLE.SETTINGS.UPDATE.SHOW_QUICK_RESIZE:
      return {
        ...state,
        showQuickResize: !!action.payload,
      };
    case TABLE.SETTINGS.UPDATE.YAML:
      return {
        ...state,
        yaml: !!action.payload,
      };
    default:
      return state;
  }
};
