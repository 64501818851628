import { combineReducers } from 'redux';
import { apiGet } from 'modules/common/api';
import { getCurrentScope } from '@sentry/browser';
import { genericValueReducer, ENTITIES, USERS, apiPatch } from 'modules/common';
import { setMatomoUserId } from 'utils/matomo';

export const SCHEMA_NAME = 'users';

/**
 * @type {import('types').RootReducer['userReducer']}
 */
export const initialState = {
  currentUser: null,
};

/**
 *
 * @type {import('types').ThunkActionFunc<[],
 * Promise<
 *   import('types').MetraApiAction<
 *   import('types').NormalizedResult<'users'>,
 *   void
 * >>>}
 */
export const getCurrentUser = () => {
  return async (dispatch) => {
    const resp = await dispatch(
      apiGet({
        entity: SCHEMA_NAME,
        schema: SCHEMA_NAME,
        userCheck: true,
        record: '_current',
        types: [ENTITIES.ACTION_SUCCESS, USERS.GET_FAILURE],
        meta: {
          excludeGuild: true,
        },
      })
    );
    dispatch(handleUser(resp.error));
    return resp;
  };
};

export const handleUser = (error) => (dispatch, getState) => {
  const user = Object.values(getState().entityReducer.users)[0];

  if (error) {
    //Clear matomo and sentry users
    setMatomoUserId(null);
    getCurrentScope().setUser(null);

    return dispatch({ type: USERS.GET_FAILURE });
  }

  //setup sentry
  getCurrentScope().setUser({
    id: user.id,
    email: user.email,
  });

  return dispatch({
    type: USERS.CURRENT,
    payload: user.id,
  });
};

export const updatePassword = () => async (_, getState) => {
  const state = getState();
  window.location.replace(
    `${state.configReducer.updatePasswordUrl}?redirect_uri=${window.location.href}`
  );
};

/**
 * Updates the currentUser (api/users/_current) by making a PATCH request
 * @param {object} body - fields to update for the current user. Ex: { is_government: true }
 */
export const updateCurrentUser = (body) => {
  return apiPatch({
    entity: SCHEMA_NAME,
    record: '_current',
    body: JSON.stringify(body),
    types: [ENTITIES.ACTION_SUCCESS, USERS.UPDATE_FAILURE],
    headers: { 'Content-Type': 'application/json' },
  });
};

/**
 * @type {import('redux').Reducer<import('types').RootReducer['userReducer']>}
 */
export const reducer = combineReducers({
  currentUser: genericValueReducer(USERS.CURRENT, initialState.currentUser),
});
