import { selectedNodeCount, selectedEdgeCount } from 'modules/model/selectors';
import { checkDefaultSelected } from 'modules/model/other';
import { applyFilter } from 'modules/model/table/utils';
import { MetraSimpleAction, SelectedShapes, ThunkAction } from 'types';
import { ENGINE, MODEL } from 'utils/constants';

export function issueApplyFilter(
  filterAlpha: number,
  filterIds?: UUID[]
): ThunkAction<void> {
  return (dispatch, _gs, { emit }) => {
    const entities = dispatch(applyFilter(filterAlpha, filterIds)).payload
      .entities;

    if (entities.length < 1) {
      // filter is now empty, there should be nothing to isolate on; so
      // unisolate everything.
      emit(ENGINE.UPDATE.SHAPES.UNISOLATE);
    }
  };
}

/**
 * FIXME: this probably need to be refactored to be deterministic
 *        according to the current way we do selection
 * @returns a metra thunk action containing the selected shape info
 */
export function ensureSelectionConsistency(): ThunkAction<
  MetraSimpleAction<Pick<SelectedShapes, 'edgesSelected'>>
> {
  return (dispatch, getState) => {
    const {
      base: {
        selected: { shapes: selectedShapeIds },
      },
      shapes: allShapes,
    } = getState().modelReducer;

    dispatch({
      type: MODEL.OBJECTS_SELECTED_NODE_COUNT,
      payload: {
        nodesSelected: selectedNodeCount(allShapes, selectedShapeIds),
      },
    });
    dispatch({
      type: MODEL.OBJECTS_SELECTED_ONLY_DEFAULT_NODE,
      payload: {
        onlyDefaultNodesSelected: checkDefaultSelected(
          allShapes,
          selectedShapeIds
        ),
      },
    });
    return dispatch({
      type: MODEL.OBJECTS_SELECTED_EDGE_COUNT,
      payload: {
        edgesSelected: selectedEdgeCount(allShapes, selectedShapeIds),
      },
    });
  };
}
