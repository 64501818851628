import { SESSION } from 'modules/common/constants';

export const getInitialState = () => ({
  table: {
    // eslint-disable-next-line camelcase
    created_by: 0,
    modified: 0,
    name: 0,
    type: 0,
  },
  ...JSON.parse(sessionStorage.getItem('state')),
});

export const initialState = getInitialState();

export const saveSession = (state) => {
  sessionStorage.setItem('state', JSON.stringify(state));
};

export const reducer = (state = initialState, action) => {
  let updateMade = true;
  const s = (() => {
    switch (action.type) {
      case SESSION.MODIFY.TABLE:
        return {
          ...state,
          table: action.payload,
        };
      default:
        updateMade = false;
        return state;
    }
  })();
  if (updateMade) saveSession(s);
  return s;
};

export default reducer;
