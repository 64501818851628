import { getSys2 } from 'ecs2/core/sys2';
import { EventManager } from 'metra-events';
import { AnyAction, Dispatch, Middleware } from 'redux';
import { ThunkAPI } from 'types';

export function createMetraThunkMiddleware<E>(extraArgs?: E) {
  const middleware = (
    api: ThunkAPI<Dispatch<AnyAction>, any, E>
  ): ReturnType<Middleware> => {
    return (next: Dispatch<AnyAction>) => (action: any) => {
      if (typeof action === 'function') {
        const { dispatch, getState } = api;
        return action(dispatch, getState, extraArgs);
      }
      return next(action);
    };
  };
  return middleware;
}

export const metraThunkMiddleware = createMetraThunkMiddleware({
  emit: (event: string, ...args: any[]) => EventManager.emit(event, ...args),
  get sys2() {
    return getSys2();
  },
}) as Middleware;
